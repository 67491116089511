import React from "react";


function Footer() {

	const date = new Date();
	const year = date.getFullYear();
	

  return (
    <>
        <div className="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop footer-border">
			<div className="kt-container  kt-container--fluid ">
				<div className="kt-footer__copyright">
					Copyright © {year} | Telsim | All Rights Reserved
				</div>
				<div className="kt-footer__menu">
					<a href="https://telsim.com.au/about-us" target="_blank" className="kt-footer__menu-link kt-link">About Us</a>
					<a target="_blank" href="https://telsim.com.au/legals" className="kt-footer__menu-link kt-link">Legals</a>
					<a href="https://telsim.com.au/contact-us" target="_blank" className="kt-footer__menu-link kt-link">Contact Us</a>
				</div>
			</div>
		</div> 
    </>
  );
}

export default Footer;
