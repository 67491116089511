import React, { Component } from 'react'
import YourTelsimSim from './your-telsim-sim'
import YourPlan from './your-plan'
import YourMobile from './your-mobile'
import SetupYourProfile from './setup-your-profile'
import PaymentMethod from './payment-method'
import IdValidation from './id-validation'
import Success from "./success";

export class wizard extends Component {
    //name: "John  Smith", street: "131 Dartmouth St", city: "Boston", state: "MA", zip: "02116" 
    state = {
        step: 1,
        from: {
            name: '',
            street: '',
            city: '',
            state: '',
            zip: '',
        },
        to: {
            name: '',
            street: '',
            city: '',
            state: '',
            zip: '',
        },
        tabactive:''
    };

    nextStep = () => {
        const { step } = this.state;
        this.setState({
            step: step + 1
        });
    };

    goNext = (stepNo) => {
        this.setState({
            step: stepNo
        });
    }

    gotoStep = (stepNo) => {

        if(stepNo >= '1')
        { 
            var elementExists = document.getElementById("activation_tab2");
            if(elementExists)
            {
                for(let i=2; i <= stepNo; i++){
                    if(i == 5 || i == 6 || i == 7){
                        leftmenu(5);
                    } else if(i == 8 || i == 9 || i == 10){
                        leftmenu(6);
                    } else {
                        leftmenu(i);
                    }
                    
                }
            }
        }
        function leftmenu(activetab)
        {
            document.getElementById("activation_tab"+activetab).setAttribute("class", "stepper-item completed");

            let nexttab= activetab+1;
            if(nexttab <=7){ document.getElementById("activation_tab"+nexttab).setAttribute("class", "stepper-item"); }

            var a = document.getElementById("activation_tab"+activetab).children; 
            // a[0].style.display = "flex";
            // a[1].style.display = "none";

            if(activetab >1)
            {
                // let prevtab=activetab-1;
                // var c = document.getElementById("activation_tab"+prevtab).children; 
                // c[0].style.display = "none";
                // c[1].style.display = "flex";
            }
        }
        if(stepNo == 5 || stepNo == 6 || stepNo == 7){
            this.setState({step: 5 + 1});
        } else if(stepNo == 8 || stepNo == 9 || stepNo == 10){
            this.setState({step: 6 + 1});
        } else {
            this.setState({step: stepNo + 1});
        }

        
    }


    prevStep = () => {
        const { step } = this.state;
        
        this.setState({
            step: step - 1
        });
    };

    handleChange = (input, address) => e => {

        switch (address) {
            case 'from':
                this.setState({
                    ...this.state,
                    from: {
                        ...this.state.from,
                        [input]: e.target.value
                    }
                });
                break;
            case 'to':
                this.setState({
                    ...this.state,
                    to: {
                        ...this.state.to,
                        [input]: e.target.value
                    }
                });
                break;
            case 'cost':
                this.setState({
                    [input]: e.target.value
                })

                break;
            default:
                this.setState({
                    [input]: e.target.value
                }
                );
                break;
        }

    }


    render() {
        const { step } = this.state;

        let activetabstate = this.state.step;

        if(activetabstate >= '2')
        { 

            var elementExists = document.getElementById("activation_tab1");
            if(elementExists)
            {
            leftmenu(activetabstate);
            }
        }   
        
        function leftmenu(activetab)
        {
            document.getElementById("activation_tab"+activetab).setAttribute("class", "stepper-item completed");

            let nexttab= activetab+1;
            if(nexttab <=7){ document.getElementById("activation_tab"+nexttab).setAttribute("class", "stepper-item"); }

            var a = document.getElementById("activation_tab"+activetab).children; 
            //a[0].style.display = "flex";
            //a[1].style.display = "none";

            if(activetab >1)
            {
                // let prevtab=activetab-1;
                // var c = document.getElementById("activation_tab"+prevtab).children; 
                // c[0].style.display = "none";
                // c[1].style.display = "flex";
            }
        }

        


 




        switch (step) {
            // case 1:
            //     return (
            //         <CreateNewAccount
            //             nextStep={this.nextStep}
            //             handleChange={this.handleChange}
            //             gotoStep={this.gotoStep}
            //             values={this.state}
            //         />
            //     );
            case 1:
                return (
                    <YourTelsimSim
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange={this.handleChange}
                        values={this.state}
                    />
                );
            case 2:
                return (
                    <YourPlan
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange={this.handleChange}
                        values={this.state}
                    />
                );
            case 3:
                return (
                    <YourMobile
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        goNext={this.goNext}
                        handleChange={this.handleChange}
                        values={this.state}
                    />
                )
            case 4:
                return (
                    <SetupYourProfile
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange={this.handleChange}
                        values={this.state}
                    />
                );
            case 5:
                return (
                    <PaymentMethod
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange={this.handleChange}
                        values={this.state}
                    />
                );
            case 6:
                return (
                    <IdValidation
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange={this.handleChange}
                        values={this.state}
                    />
                );
            case 7:
                return <Success 
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange={this.handleChange}
                        values={this.state}
                />;
            default:
                return
        }
    }
}

export default wizard