import { Component } from "react";
import Loader from "./../../../components/Loader/LoadingIcon"
import axios from "axios";
import { withRouter } from "react-router-dom";
import { getApiHeader } from "../../../utils/CommonFunctions";


const apiUrl = process.env.REACT_APP_SERVER_URL;




class Address extends Component {
    constructor(props) {
 
        super(props)
        this.timeout = null;
        this.state = {
            address: [],
            text: "",
            addressDrop: "",
            suggestions: [],
            addressReset: true,
            isReadonly: false,
            validAddress: false,
            searchLoader: false,
            techType: false,
            nbnSessionId: "",
            error:""
        }
    }
      
    
     onChangeHandler = async (addressText) => {
    
        this.setState({ addressDrop: false });
        const { token } = this.props;
        this.setState({ text: addressText });
        this.props.addressError(false)
   

        if (addressText.length >= 4) {
            this.setState({ addressReset: true, searchLoader: true });
            const userInput = addressText;
            clearTimeout(this.timeout);
            this.timeout = setTimeout(async () => {
              
            try {
                let headers = await getApiHeader();
                const payload = {
                    "address_search_term": addressText,
                    "nbn_session_id": this.state.nbnSessionId,
                    "token": token
                };

                const response = await axios.post(apiUrl + 'nbn/nbn_get_autocomplete_address', payload, { headers });

                if (response.status === 200) {
                 if (userInput === this.state.text) {  
                    this.setState({ searchLoader: false, addressReset: true });
                    let addressData = response.data.data.data.results;
                    this.setState({ address: addressData, addressDrop: true, addressReset: false });
                    this.setState({ suggestions: addressData, text: addressText });
                    if (response.data.data.nbn_session_id) {
                        let nbn_session_id = response.data.data.nbn_session_id;
                        this.setState({ nbnSessionId: nbn_session_id });
                    }
                 }
                } else {
                    this.setState({ addressDrop: false });
                }
            } catch (error) {
                this.setState({ addressDrop: false });
            }
           
        }, 500);
        } else {
            this.setState({ searchLoader: false, suggestions: [], addressDrop: false, addressReset: true });

        }
        
    };







    checkVispQualification = async (addressName, addressId) => {
        let headers = await getApiHeader();
        const { getVispHandler, passNbnSessionId, addressVerified, getAvailablePlan } = this.props

        this.setState({ searchLoader: true, addressReset: true })


        if (addressId !== '' && this.state.nbnSessionId !== '') {

            const runAddressCheck = async () => {


                const payload = {
                    "addressId": addressId,
                    "addressName": addressName,
                    "nbn_session_id": this.state.nbnSessionId,
                    "activation_id": sessionStorage.getItem('selected_number')
                }

                let res = await axios.post(apiUrl + 'nbn/nbn_address_verification', payload, { headers }).then(async response => {
                    if (response.status === 200) {

                        if (response.data.data.service_availability_response) {
                            //setTimeout(() => {

                            const payload = {

                                "visp_qualification_id": response.data.data.visp_qualification_id,

                                "nbn_session_id": this.state.nbnSessionId

                            }

                            setTimeout(() => {

                                let res = axios.post(apiUrl + 'nbn/fetch_nbn_visp_qualification_details', payload, { headers }).then(async apiresponse => {

                                    if (apiresponse.status === 200) {

                                        if (apiresponse.data.data.address_availability === "yes") {
                                            this.setState({ techType: response.data.data.service_availability_response.technologyType, validAddress: "valid", searchLoader: false, addressReset: false });
                                            getVispHandler(response.data.data.visp_qualification_id);
                                            passNbnSessionId(this.state.nbnSessionId);
                                            addressVerified(true);
                                            getAvailablePlan(apiresponse.data.data.plans);

                                        } else {
                                            this.setState({ validAddress: "invalid", searchLoader: false, addressReset: false });
                                            getVispHandler(null);
                                            addressVerified(false);
                                            getAvailablePlan([]);
                                        }
                                    }
                                }).catch(error => {
                                    this.setState({ validAddress: "invalid", searchLoader: false, addressReset: false });
                                    getVispHandler(null);
                                    addressVerified(false);
                                    getAvailablePlan([]);
                                });
                            }, 15000);

                        }


                    } else {
                        this.setState({ validAddress: "invalid", searchLoader: false, addressReset: false })
                        getVispHandler(null);
                        addressVerified(false);

                    }

                }).catch(error => {
                     console.log("error--->",error.response.data.message)
                    this.setState({ validAddress: "invalid", searchLoader: false, addressReset: false ,error:error.response.data.message })

                    getVispHandler(null);
                    addressVerified(false);
                    getAvailablePlan([]);




                });
            }

            runAddressCheck();

        } else {
            this.setState({ validAddress: false, searchLoader: false, addressReset: false })
            getAvailablePlan([]);
            getVispHandler(null);
            addressVerified(false);

        }
    }






    planSelect = () => {
        const releventDiv = document.getElementById('planSelectionBox');
        releventDiv.scrollIntoView({ behavior: "smooth" });
    }


    resetHandler = () => {
        this.setState({ addressReset: true, suggestions: [], text: "", isReadOnly: false, validAddress: false, addressVerified: false })

    };




    suggestionHandler = (suggestion, addressId) => {

        this.setState({ text: suggestion, isReadOnly: true, suggestion: [], addressDrop: false });

        if (addressId !== null) {
            this.checkVispQualification(suggestion, addressId);
        }

    }

    render() {
        return (
            <div>
                <div className="container" id="AdressSelectBox">
                    <section className="common-section">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="header-title">
                                    <h2 className="h3">1. Enter Your Address</h2>
                                </div>
                                <div className="search-bar-container">
                                    <div className="search-bar">

                                        <input type="text" className="form-control search-input" placeholder="Unit 43, 85 Low Street, Thomastown, SA 5149..." readOnly={this.state.isReadonly} onChange={e => this.onChangeHandler(e.target.value)} value={this.state.text} />
                                        <div className="search-icon">

                                            {this.state.addressReset ?
                                                <button type="submit" style={{ border: 'none', backgroundColor: 'transparent', color: '#d30058' }}><i className="fa fa-search"></i></button>
                                                :
                                                <button type="submit" style={{ border: 'none', backgroundColor: 'transparent', color: '#d30058' }} onClick={e => this.resetHandler()}><i className="fa fa-times"></i></button>
                                            }

                                        </div>


                                        {this.state.text !== '' && this.state.addressDrop && this.state.suggestions && (
                                            <ul className="dropDown">
                                                {this.state.addressDrop && this.state.suggestions && this.state.suggestions.map((suggestion, i) =>

                                                    <li key={i} onClick={() => this.suggestionHandler(suggestion.name, suggestion.addressId)}>{suggestion.name}</li>


                                                )}
                                            </ul>
                                        )}

                                    </div>
                                </div>

                                {this.state.text === false && (
                                    <p style={{ textAlign: 'left', paddingLeft: '15px', fontSize: '14px', color: '#8c8c8c' }}>
                                        Start typing your address to get started.
                                    </p>
                                )}

                          {this.props.AddressError===true&&(
                            <p>
							<span style={{ color: 'red', textAlign: 'center' }}>Please enter a valid address to proceed with choosing plans.</span>
						  </p> )}

                                {this.state.searchLoader === true && (

                                    <div className="search-loader">
                                        <Loader />
                                        <p>If you don't get a result in 20 seconds refresh the browser and try again.</p>
                                    </div>

                                )}

                                {this.state.validAddress === 'valid' && this.state.searchLoader === false && (

                                    <div className="search-content">
                                        <h6><i className="fa fa-check-circle" aria-hidden="true"></i> {this.state.text}</h6>
                                        <hr />
                                        <h4>A NBN service is available at your location. Check out our plans below</h4>
                                        <p style={{ textAlign: 'center' }}>{this.state.techType && (<>(Your connection type is {this.state.techType}) </>)}</p>
                                        <center><button className="button-57" role="button" onClick={e => this.planSelect()}><span className="text">VIEW PLANS</span><span>VIEW PLANS</span></button></center>
                                    </div>

                                )}

                                {this.state.validAddress === 'invalid' && this.state.searchLoader === false && (

                                    <div className="search-content-2">
                                        <h6><i className="fa fa-times-circle" aria-hidden="true"></i> {this.state.text}</h6>
                                        <hr />
                                            <h5 className="search-content-location">A NBN service is not available at your location now.</h5>
                                        <p className="anotherAddress" onClick={e => this.resetHandler()}> {"Check another address"}   </p>
                                    </div>

                                )}


                            </div>
                        </div>
                    </section>
                </div>

            </div>
        )
    }
}
export default withRouter(Address);