import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import axios from 'axios';
import {getApiHeader} from '../../utils/CommonFunctions';
import swal from '@sweetalert/with-react';
import PageLoader from './../Loader/loader';
 
const SERVER_URL    = process.env.REACT_APP_SERVER_URL;   


class IDsuccess extends Component {


constructor(props) {
        super(props);
        
        this.state = { 
            firstName:'',
            middleName:'',
            surname:'',
            dob:'',
            sal:'',
            customer_id:'',
            activation_data:[],
            activation_id:'',
            dvs_status_check:'',
            session_id:'',
            loading:false
        };

    }


componentDidMount() { 
    this.setState({loading:true});
    this.getActivationAttemptDetailsLogin();   
    this.setState({loading:false});
}




    getActivationAttemptDetailsLogin = async () => {
        let headers  = await getApiHeader();
        let act_attempt_data        = await axios.get(`${ SERVER_URL }attemptDetailsLogin`, { headers }); 
        let attempt_data=act_attempt_data.data.data;

        this.setState({ customer_id: attempt_data.activation_attempt.customer_id});
        
        if((attempt_data.activation_attempt.address) && (attempt_data.activation_attempt.address.address_full))
        {
            this.setState({ address_full: attempt_data.activation_attempt.address.address_full});
        }
        
        this.setState({ sal: attempt_data.activation_attempt.customer.sal });
        this.setState({ firstName: attempt_data.activation_attempt.customer.firstName });
        this.setState({ middleName: attempt_data.activation_attempt.customer.middleName });
        this.setState({ surname: attempt_data.activation_attempt.customer.surname });
        this.setState({ dob: attempt_data.activation_attempt.customer.dob });
        this.setState({ activation_id: attempt_data.activation_attempt.activation_id });
        this.setState({ dvs_status_check: attempt_data.activation_attempt.customer.dvs_status});
        this.setState({ session_id: attempt_data.activation_attempt.session_id});

        this.setState({ activationcompleted: attempt_data.activation_attempt.customer.activation_completed }, ()=>{

            if(this.state.activationcompleted ==1)
            {
                this.props.history.push("/home");
            }

        });
        
        this.getActivationData();  
    };




    getActivationData = async () => {
        const post_data =   {   activation_id: this.state.activation_id 
                            };  
        let headers     = await getApiHeader();

        let res         = await axios.post(`${ SERVER_URL }activation/activation-details`,post_data, { headers });
        let data        = res.data.data.activation_data; 
        console.log(res.data.data.activation_data)
        this.setState({ activation_data: data });
    }




    mySubmitHandler = async (event) => { 
        this.setState({loading:true}); 
        event.preventDefault();  
        const post_data =   {   activation_id: this.state.activation_id ,
                                session_id: this.state.session_id,
                                dvs_status: this.state.dvs_status_check
                            };  
        let headers     = await getApiHeader();
        let res         = await axios.post(`${ SERVER_URL }attemptDetailsLogin`,post_data, { headers }).then(response => {
            
            sessionStorage.removeItem('activation_session_id');
            
            swal({
                text: response.data.message,
				allowOutsideClick: false,
				closeOnClickOutside: false, 
                icon: "success"}).then(()=>{

                    this.props.history.push("/home");

                });
            
        }).catch(error => {
            if((typeof(error.response.data.message) !== 'undefined') && (typeof(error.response.data) !== 'undefined'))
            {
 
               swal(error.response.data.message, "", "warning");
            }
            else
            { 
                swal("Internal Server Error", "", "warning");
            }

            
        });

         
 
        this.setState({loading:false});
        //this.props.nextStep();
    };

 

  render() {
    return (
        <>
        {(this.state.loading === true) ? <PageLoader/> : null}


     <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
              <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
                        <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                            <div className="kt-portlet">
                                <div className="kt-portlet__head">
                                    <div className="kt-portlet__head-label">
                                        <h3 className="kt-portlet__head-title">Summary</h3>
                                    </div>
                                </div>


        <form className="kt-form kt-section__content kt-section__content--border IDverification" id="kt_form" method="post" onSubmit={this.mySubmitHandler}>
                                   
                    <div className="kt-wizard-v2__content" data-ktwizard-type="step-content">
                        <div className="kt-separator kt-separator--height-xs"></div>
                        <div className="kt-form__section kt-form__section--first" id="summary_section">

                                                            <div className="row">
                                    <div className="col-lg-6 kt-widget-5 kt-padding-20">
                                        <div className="kt-widget-5__item kt-widget-5__item--danger">
                                            <div className="kt-widget-5__item-info">
                                                <a  className="kt-widget-5__item-title">
                                                    Name
                                                </a>
                                                <div className="kt-widget-5__item-datetime">
                                                    <strong>{this.state.sal} {this.state.firstName} {this.state.middleName} {this.state.surname}</strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="kt-widget-5__item kt-widget-5__item--danger">
                                            <div className="kt-widget-5__item-info">
                                                <a  className="kt-widget-5__item-title">
                                                    Date of Birth
                                                </a>
                                                <div className="kt-widget-5__item-datetime">
                                                    <strong>{this.state.dob}</strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="kt-widget-5__item kt-widget-5__item--danger">
                                            <div className="kt-widget-5__item-info">
                                                <a  className="kt-widget-5__item-title">
                                                        Address
                                                </a>
                                                <div className="kt-widget-5__item-datetime">
                                                    <strong>{this.state.address_full}</strong>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="col-lg-6 kt-widget-5 kt-padding-20">
                                        <div className="kt-widget-5__item kt-widget-5__item--danger">
                                            <div className="kt-widget-5__item-info">
                                                <a  className="kt-widget-5__item-title">
                                                    Plan Selected
                                                </a>
                                                <div className="kt-widget-5__item-datetime">
                                                    <strong>${this.state.activation_data.plan_amount} / {this.state.activation_data.data_value}GB</strong>
                                                </div>
                                            </div>
                                        </div>
                                         
                                        <div className="kt-widget-5__item kt-widget-5__item--danger">
                                            <div className="kt-widget-5__item-info">
                                                <a  className="kt-widget-5__item-title">
                                                        Amount Paid
                                                </a>
                                                <div className="kt-widget-5__item-datetime">
                                                <strong>${this.state.activation_data.amount}</strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="kt-widget-5__item kt-widget-5__item--danger">
                                            <div className="kt-widget-5__item-info">
                                                <a  className="kt-widget-5__item-title">
                                                        Next Payment Date
                                                </a>
                                                <div className="kt-widget-5__item-datetime">
                                                <strong>{this.state.activation_data.next_recharge}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                        </div>

                        <br/>

                    <div className="kt-form__actions">
                       {/* <button type="submit" className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm" id="backBtn" onClick={this.back}>Previous</button>&nbsp; */}
                       <button type="submit" className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm" id="wizard_next_btn" data-ktwizard-type="action-next">
                       Click here to ACTIVATE
                       </button>
                       <br/>
                    </div>

                    </div>
        </form>   

                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>

        </> 
    );
  }
}
 
export default withRouter(IDsuccess);