import React, { Component } from "react";
import { Link } from "react-router-dom";
import LogoWhite from '../../assets/img/logo-white-small.png';
export default class CustomerMobileHeader extends Component {


render()
{
    return(<div id="kt_header_mobile" className="kt-header-mobile  kt-header-mobile--fixed ">
            <div className="kt-header-mobile__logo">
                <Link to="/home">
                    <img height="40" alt="Logo" src={LogoWhite} />
                </Link>
            </div>
            <div className="kt-header-mobile__toolbar">
                <button className="kt-header-mobile__toolbar-toggler kt-header-mobile__toolbar-toggler--left" id="kt_aside_mobile_toggler"><span></span></button>
                <button className="kt-header-mobile__toolbar-topbar-toggler" id="kt_header_mobile_topbar_toggler"><i className="flaticon-more"></i></button>
            </div>
        </div>)
}


}