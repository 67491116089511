// import React from "react";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from 'axios';
import swal from '@sweetalert/with-react';
import LogoWhite from '../../assets/img/logo-white-small.png';
import { getApiHeader, removeUserSession } from '../../utils/CommonFunctions';

// function Navigation(props) {

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class Navigation extends Component {


	constructor(props) {

		super(props);

		this.state = { selected_number: null, button: "" }

	}


	componentDidMount = async () => {
		let data = await sessionStorage.getItem('is_nbn')
		this.setState({ button: data })
		window.addEventListener('storage', this.handleStorageChange);
		this.getActivations();
		this.checkIsNbnCustomer();
	}

	componentWillUnmount() {
		// Remove event listener to prevent memory leaks
		window.removeEventListener('storage', this.handleStorageChange);
	}

	handleStorageChange = (event) => {
		if (event.key === 'is_nbn') {
			// Update state when session storage changes
			this.setState({ button: event.newValue });
		}
	}


	checkIsNbnCustomer = async () => {
		let headers = await getApiHeader();
		let res = await axios.get(`${SERVER_URL}nbn/check_is_nbn`, { headers }).then(response => {
			  let data = response.data;
			  sessionStorage.setItem('is_nbn',data.data.is_nbn_customer)
			  sessionStorage.setItem('nbn_activation_status',data.data.nbn_activation_status)
			   const activation=sessionStorage.getItem('nbn_activation_status')
			  console.log("activation status checking",activation)
		      this.setState({ button: data.data.is_nbn_customer })

			  console.log('buttonstatus',this.state.button);

		}).catch(error => {
			this.setState({ loading: false });
			if ((typeof (error.response.data.message) !== 'undefined') && (typeof (error.response.data) !== 'undefined')) {
				swal({
					text: error.response.data.message,
					allowOutsideClick: false,
					closeOnClickOutside: false,
					icon: "warning"
				}).then(() => {
					this.handleLogout();
				});
			}
			else {
				swal("Internal Server Error", "", "warning");
			}
		});
	}


	getActivations = async () => {
		let headers = await getApiHeader();
		let res = await axios.get(`${SERVER_URL}user_activations`, { headers }).then(response => {
			let data = response.data.data.list;
			let selected_number = '';
			if (data.length > 0) {
				selected_number = data[0].activation_id;
			} else {
				selected_number = null;
			}

			sessionStorage.setItem('selected_number', selected_number);

			this.setState({
				selected_number: selected_number,
			});

		}).catch(error => {
			this.setState({ loading: false });
			if ((typeof (error.response.data.message) !== 'undefined') && (typeof (error.response.data) !== 'undefined')) {

				//swal(error.response.data.message, "", "warning");

				if ((this.props.location.pathname == '/IDverification') || (this.props.location.pathname == '/IDsuccess')) {
					return;
				}

				swal({
					text: error.response.data.message,
					allowOutsideClick: false,
					closeOnClickOutside: false,
					icon: "warning"
				}).then(() => {

					this.handleLogout();

				});
			}
			else {
				swal("Internal Server Error", "", "warning");
			}


		});

	};

	handleLogout = async () => {



		let p = new Promise(async (resolve, reject) => {

			let headers = await getApiHeader();
			let res = await axios.get(`${SERVER_URL}logout`, { headers });
			let data = res.data.data;
			console.log(data);

			removeUserSession();
			resolve();

		});

		p.then(
			() => {

				this.props.history.push("/login");

			},
			() => {
				alert("Error in logout");
			}
		)

	}

	menuClick = () => {
		document.getElementById("kt_aside_close_btn").click();
	}

	render() {
		return (
			<>
				<button className="kt-aside-close " id="kt_aside_close_btn"><i className="la la-close"></i></button>
				<div className="kt-aside  kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop" id="kt_aside">
					<div className="kt-aside__brand kt-grid__item " id="kt_aside_brand">
						<div className="kt-aside__brand-logo">
							<Link to="/">
								<img alt="Telsim" height="40" src={LogoWhite} />
							</Link>
						</div>
						<div className="kt-aside__brand-tools">
							<button className="kt-aside__brand-aside-toggler kt-aside__brand-aside-toggler--left" id="kt_aside_toggler"><span></span></button>
						</div>
					</div>
					<div className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_aside_menu_wrapper">
						<div id="kt_aside_menu" className="kt-aside-menu " data-ktmenu-vertical="1" data-ktmenu-scroll="1" data-ktmenu-dropdown-timeout="500">
							<ul className="kt-menu__nav " >


								{(this.state.selected_number !== null) ?
									<>

										<li className={`kt-menu__item  ${((this.props.location.pathname === "/MyServices") || (this.props.location.pathname === "/")) ? "kt-menu__item--active" : ""}`} aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
											<Link onClick={this.menuClick} to="/MyServices" className="kt-menu__link  active"><i className="kt-menu__link-icon flaticon2-graphic"></i><span className="kt-menu__link-text">My Service</span></Link>
										</li>
										<li className={`kt-menu__item  ${((this.props.location.pathname === "/home") || (this.props.location.pathname === "/")) ? "kt-menu__item--active" : ""}`} aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
											<Link onClick={this.menuClick} to="/home" className="kt-menu__link  active"><i className="kt-menu__link-icon flaticon2-graphic"></i><span className="kt-menu__link-text">Account Summary</span></Link>
										</li>
										<li className={`kt-menu__item  ${this.props.location.pathname === "/MyProfile" ? "kt-menu__item--active" : ""}`} aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
											<Link onClick={this.menuClick} to="/MyProfile" className="kt-menu__link "><i className="kt-menu__link-icon flaticon-avatar"></i><span className="kt-menu__link-text">My Profile</span></Link>
										</li>
										<li className={`kt-menu__item  ${this.props.location.pathname === "/MyPayments" ? "kt-menu__item--active" : ""}`} aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
											<Link onClick={this.menuClick} to="/MyPayments" className="kt-menu__link "><i className="kt-menu__link-icon fa fa-credit-card"></i><span className="kt-menu__link-text">My Payments</span></Link>
										</li>
										<li className={`kt-menu__item  ${this.props.location.pathname === "/AddaMobilePlan" ? "kt-menu__item--active" : ""}`} aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
											<Link onClick={this.menuClick} to="/AddaMobilePlan" className="kt-menu__link "><i className="kt-menu__link-icon fas fa-sim-card"></i><span className="kt-menu__link-text">Add a Mobile Plan</span></Link>
										</li>
										<li className={`kt-menu__item  ${this.props.location.pathname === "/RechargeHome" ? "kt-menu__item--active" : ""}`} aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
											<Link onClick={this.menuClick} to="/RechargeHome" className="kt-menu__link "><i className="kt-menu__link-icon fa fa-bolt "></i><span className="kt-menu__link-text">Recharge</span></Link>
										</li>
										<li className={`kt-menu__item  ${this.props.location.pathname === "/RechargeAFriend" ? "kt-menu__item--active" : ""}`} aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
											<Link onClick={this.menuClick} to="/RechargeAFriend" className="kt-menu__link "><i className="kt-menu__link-icon fa fa-bolt "></i><span className="kt-menu__link-text">Recharge a Friend</span></Link>
										</li>
										<li className={`kt-menu__item  ${this.props.location.pathname === "/DataGifting" ? "kt-menu__item--active" : ""}`} aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
											<Link onClick={this.menuClick} to="/DataGifting" className="kt-menu__link "><i className="kt-menu__link-icon fa fa-gift "></i><span className="kt-menu__link-text">Data Gifting</span></Link>
										</li>
										<li className={`kt-menu__item  ${this.props.location.pathname === "/ReferAbuddy" ? "kt-menu__item--active" : ""}`} aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
											<Link onClick={this.menuClick} to="/ReferAbuddy" className="kt-menu__link "><i className="kt-menu__link-icon fa fa-location-arrow"></i><span className="kt-menu__link-text">Refer A Buddy</span></Link>
										</li>
										{
										(this.state.button === 1 || this.props.location.pathname =='/connection')?
										null
                                        :
										<li className={`kt-menu__item  ${ this.props.location.pathname === "/AddNbn" ? "kt-menu__item--active" : ""  }`} aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
										<Link onClick={this.menuClick} to="/AddNbn" className="kt-menu__link "><i className="kt-menu__link-icon fa fa-location-arrow"></i><span className="kt-menu__link-text">Purchase NBN Service</span></Link>
									</li> 

									}



										{/*<li className={`kt-menu__item  ${ props.location.pathname === "/UsageHistory" ? "kt-menu__item--active" : ""  }`} aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
										<Link to="/UsageHistory" className="kt-menu__link "><i className="kt-menu__link-icon fa fa-credit-card"></i><span className="kt-menu__link-text">Usage History</span></Link>
									</li> */}
										{/*<li className={`kt-menu__item  ${ props.location.pathname === "/IDverification" ? "kt-menu__item--active" : ""  }`} aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
										<Link to="/IDverification" className="kt-menu__link "><i className="kt-menu__link-icon fa fa-credit-card"></i><span className="kt-menu__link-text">ID Verification</span></Link>
									</li> */}
									</>
									:
									<>
										{(this.props.location.pathname === "/IDverification") ?
											<li className={`kt-menu__item  ${this.props.location.pathname === "/IDverification" ? "kt-menu__item--active" : ""}`} aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
												<a className="kt-menu__link"><i className="kt-menu__link-icon fa fa-user"></i><span className="kt-menu__link-text">ID verification</span></a>
											</li>
											:
											null}

										{(this.props.location.pathname === "/IDsuccess") ?
											<li className={`kt-menu__item  ${this.props.location.pathname === "/IDsuccess" ? "kt-menu__item--active" : ""}`} aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
												<a className="kt-menu__link"><i className="kt-menu__link-icon fa fa-rocket"></i><span className="kt-menu__link-text">Activation</span></a>
											</li>
											:
											null}
									</>}
							</ul>
						</div>
					</div>
				</div>
			</>
		);

	}

}

export default withRouter(Navigation);
