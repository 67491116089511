import { Component } from "react"
import axios from "axios"
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { getApiHeader } from "../../../utils/CommonFunctions";




const apiUrl = process.env.REACT_APP_SERVER_URL;



class Cart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      defaultPlanID: false,
      defaultPlanName: false,
      defaultPlanAmount: "0",
      defaultModemID: false,
      defaultModemName: false,
      defaultModemAmount: "0",
      defaultshippingCharge: "0",
      isNotValidVisp: false,
      isLoading: false,
      totalCart: "0",
      gstPlanAmount:"0",
      gstModemAmount:"0",

    }



  }

  componentDidMount() {
    this.updateStateFromProps(this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.defaultPlan !== this.props.defaultPlan ||
      prevProps.defaultModem !== this.props.defaultModem ||
      prevProps.gstShippingCharge !== this.props.gstShippingCharge) {
      this.updateStateFromProps(this.props);
    }
    
  }

  updateStateFromProps(props) {
    console.log("props",props)
    const { defaultPlan, defaultModem, shippingCharge,gstShippingCharge } = props;
    let defaultPlanAmount = defaultPlan ? defaultPlan.website_sale_amount : "0";
    let gstPlanAmount= defaultPlan?defaultPlan.gst_inclusive_sale_amount:"0";
    let defaultModemID = false;
    let defaultModemName = false;
    let defaultModemAmount = "0";
    let gstModemAmount="0";

    if (defaultModem && defaultModem.nbn_product_id !== undefined) {
      defaultModemID = defaultModem.nbn_product_id;
      defaultModemName = defaultModem.nbn_product_name;
      defaultModemAmount = defaultModem.product_website_sell_amount;
      gstModemAmount=defaultModem.gst_inclusive_sale_amount;
      
      
    }

    this.setState({
      defaultPlanID: defaultPlan ? defaultPlan.plan_id : '',
      defaultPlanName: defaultPlan ? defaultPlan.website_plan_name : '',
      defaultPlanAmount: defaultPlanAmount,
      defaultModemID: defaultModemID,
      defaultModemName: defaultModemName,
      defaultModemAmount: defaultModemAmount,
      defaultshippingCharge: shippingCharge,
      gstShippingCharge:gstShippingCharge,
      gstPlanAmount:gstPlanAmount,
      gstModemAmount:gstModemAmount,
      totalCart: (Number(gstPlanAmount) + Number(gstShippingCharge) + Number(gstModemAmount)).toFixed(2),
    });
  }


  checkValidAddressVisp = async () => {


    const { addressVerify, vispID, defaultPlan, shippingCharge, history,addressError } = this.props


    this.setState({ isNotValidVisp: false })
    if (addressVerify === false) {
      addressError(true)
      this.setState({ isNotValidVisp: true })
      const releventDiv = document.getElementById('AdressSelectBox');
      releventDiv.scrollIntoView({ behavior: "smooth" });
    } else {


      const payload = {
        "visp_qualification_id": vispID,
        "plan_id": defaultPlan.plan_id,
        "product_id": this.state.defaultModemID,

      }


      let res;
      try {
        this.setState({ isLoading: true })

        // setTimeout(() => {
        // }, 10000);
        let headers = await getApiHeader();
        res = await axios.post(apiUrl + 'nbn/update_order_details', payload, { headers });
        setTimeout(() => {

          if (res.status === 200) {

            history.push({
              pathname: '/connection',
              state: {
                vispID: vispID,
                plan_id: defaultPlan.plan_id,
                product_name: this.state.defaultModemName,
                product_amount: this.state.defaultModemAmount,
                amount: this.state.totalCart,
                shipping_charge: shippingCharge,
                monthly_charge: this.state.defaultPlanAmount,
                gst_plan_amount:this.state.gstPlanAmount,
                gst_modem_amount:this.state.gstModemAmount,
                gst_shipping_charge:this.props.gstShippingCharge

              }
            });

          } else {
            console.log("Error: Unexpected status code ", res.status);
          }
          this.setState({ isLoading: false })

        }, 2000); // 2 seconds delay
      } catch (error) {
        this.setState({ isLoading: false })

      }

    }
  }




  render() {
    return (
      <div className="container-fluid" style={{ backgroundColor: '#000' }}>
        <section className="result-section">
          <div className="container-cart">
            <div className="row">
              <div className="result-header-title">
                <h2>4. Ready for Checkout: Your Selected Items.</h2>
                <p>Reviewing your checkout page allows you to confirm and modify your order information before completing your transaction.</p>
              </div>
              <div className="col-md-12">
                <center>
                  <table className="table table-dark table-hover table-striped">
                    <tbody>

                      <tr>
                        <td style={{ borderTopLeftRadius: '30px' }}>{this.state.defaultPlanName}</td>
                        <td style={{ textAlign: 'right' }}>${this.state.defaultPlanAmount}</td>
                      </tr>
                      {this.state.defaultModemID && (
                        <tr>
                          <td >{this.state.defaultModemName} & Shipping charge</td>
                          <td style={{ textAlign: 'right' }}>${(Number(this.state.defaultModemAmount) + Number(this.state.defaultshippingCharge)).toFixed(2)}</td>
                        </tr>
                      )}

                      <tr>
                        <td>Total upfront cost</td>
                        <td style={{ textAlign: 'right' }}>${this.state.totalCart}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td style={{ fontWeight: '300' }}>TOTAL MONTHLY COST</td>
                        <th style={{ borderBottomRightRadius: '30px', borderColor: 'transparent' }}>${this.state.defaultPlanAmount}</th>
                      </tr>
                    </tfoot>
                  </table><br />

                  {/* {this.state.isNotValidVisp && this.props.addressVerify === false && (

                    <p>
                      <span style={{ color: '#fff', textAlign: 'center' }}>This address can't be serviced.</span>
                    </p>

                  )} */}


                  <button className="button-57" role="button" disabled={this.state.isLoading} onClick={e => this.checkValidAddressVisp()}> {this.state.isLoading ? <> <span className="text">Processing.....</span> <span>Processing.....</span> </> : <> <span className="text">ORDER NOW</span><span>ORDERNOW</span></>}</button></center>

                <br />
              </div>
            </div>
          </div>
        </section>
      </div>

    )
  }
}

export default withRouter(Cart) 