// import React from "react";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from 'axios';
import swal from '@sweetalert/with-react';
import LogoWhite from '../../assets/img/logo-white-small.png';
import { getApiHeader, removeUserSession } from '../../utils/CommonFunctions';

// function Navigation(props) {

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class NBNNavigation extends Component {


    constructor(props) {

        super(props);

        this.state = { 
            selected_number: null ,
            is_prepaid:sessionStorage.getItem('is_prepaid'),
            nbn_activation:""
         }

    }

    handleLogout = async () => {

        let p = new Promise(async (resolve, reject) => {

            let headers = await getApiHeader();
            let res = await axios.get(`${SERVER_URL}logout`, { headers });
            let data = res.data.data;
            console.log(data);

            removeUserSession();
            resolve();

        });

        p.then(
            () => {

                this.props.history.push("/login");

            },
            () => {
                alert("Error in logout");
            }
        )

    }

  
    
    checkIsNbnCustomer = async () => {
		let headers = await getApiHeader();
		let res = await axios.get(`${SERVER_URL}nbn/check_is_nbn`, { headers }).then(response => {
			  let data = response.data;
			  sessionStorage.setItem('is_nbn',data.data.is_nbn_customer)
			  sessionStorage.setItem('nbn_activation_status',data.data.nbn_activation_status)
			   const activation=sessionStorage.getItem('nbn_activation_status')
		      this.setState({ button: data.data.is_nbn_customer })
		}).catch(error => {
			this.setState({ loading: false });
			if ((typeof (error.response.data.message) !== 'undefined') && (typeof (error.response.data) !== 'undefined')) {
				swal({
					text: error.response.data.message,
					allowOutsideClick: false,
					closeOnClickOutside: false,
					icon: "warning"
				}).then(() => {
					this.handleLogout();
				});
			}
			else {
				swal("Internal Server Error", "", "warning");
			}
		});
	}



    componentDidMount() {
        this.checkIsNbnCustomer()
        const activation = sessionStorage.getItem('nbn_activation_status');
        console.log("activation status checking", activation);
        this.setState({
            nbn_activation: activation
        });
    }

  
    componentDidUpdate(prevProps, prevState) {
        // Only update state if the activation status has changed
        const activation = sessionStorage.getItem('nbn_activation_status');
        if (prevState.nbn_activation !== activation) {
            this.setState({
                nbn_activation: activation
            });
        }
        console.log("activation number",activation)
    }





    menuClick = () => {
        document.getElementById("kt_aside_close_btn").click();
    }

    render() {
        return (
            <>
                <button className="kt-aside-close " id="kt_aside_close_btn"><i className="la la-close"></i></button>
                <div className="kt-aside  kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop" id="kt_aside">
                    <div className="kt-aside__brand kt-grid__item " id="kt_aside_brand">
                        <div className="kt-aside__brand-logo">
                            <Link to="/">
                                <img alt="Telsim" height="40" src={LogoWhite} />
                            </Link>
                        </div>
                        <div className="kt-aside__brand-tools">
                            <button className="kt-aside__brand-aside-toggler kt-aside__brand-aside-toggler--left" id="kt_aside_toggler"><span></span></button>
                        </div>
                    </div>
                    <div className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_aside_menu_wrapper">
                        <div id="kt_aside_menu" className="kt-aside-menu " data-ktmenu-vertical="1" data-ktmenu-scroll="1" data-ktmenu-dropdown-timeout="500">
                            <ul className="kt-menu__nav " >
                                <li className={`kt-menu__item  ${((this.props.location.pathname === "/nbn_services") || (this.props.location.pathname === "/")) ? "kt-menu__item--active" : ""}`} aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
                                    <Link onClick={this.menuClick} to="/nbn_services" className="kt-menu__link  active"><i className="kt-menu__link-icon flaticon2-graphic"></i><span className="kt-menu__link-text">My Services</span></Link>
                                </li>
                                <li className={`kt-menu__item  ${this.props.location.pathname === "/nbn-my-account-summary" ? "kt-menu__item--active" : ""}`} aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
                                    <Link onClick={this.menuClick} to="/nbn-my-account-summary" className="kt-menu__link "><i className="kt-menu__link-icon flaticon2-architecture-and-city"></i><span className="kt-menu__link-text">NBN Account summary</span></Link>
                                </li>
                                <li className={`kt-menu__item  ${this.props.location.pathname === "/nbn_my_profile" ? "kt-menu__item--active" : ""}`} aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
                                    <Link onClick={this.menuClick} to="/nbn_my_profile" className="kt-menu__link "><i className="kt-menu__link-icon flaticon-avatar"></i><span className="kt-menu__link-text">My Profile </span></Link>
                                </li>
                                <li className={`kt-menu__item  ${ this.props.location.pathname === "/nbn_my_payments" ? "kt-menu__item--active" : ""  }`} aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
									<Link onClick={this.menuClick} to="/nbn_my_payments" className="kt-menu__link "><i className="kt-menu__link-icon fa fa-credit-card"></i><span className="kt-menu__link-text">My Payments</span></Link>
								</li>
                                 {this.state.nbn_activation=="1" && 
                                 <li className={`kt-menu__item  ${this.props.location.pathname === "/nbn_change_plan" ? "kt-menu__item--active" : ""}`} aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
                                    <Link onClick={this.menuClick} to="/nbn_change_plan" className="kt-menu__link "><i className="kt-menu__link-icon flaticon2-calendar-8  "></i><span className="kt-menu__link-text">Change Plan  </span></Link>
                                </li> 
                                      }

                                {this.state.is_prepaid === '0' &&
                                <li className={`kt-menu__item  ${this.props.location.pathname === "/Activatev2" ? "kt-menu__item--active" : ""}`} aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
                                    <Link onClick={this.menuClick} to="/Activatev2" className="kt-menu__link "><i className="kt-menu__link-icon fa fa-group"></i><span className="kt-menu__link-text">Activate Prepaid Mobile Plan </span></Link>
                                </li>
                                }
                                {/* <li className={`kt-menu__item  ${this.props.location.pathname === "/MyProfile" ? "kt-menu__item--active" : ""}`} aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
                                    <Link onClick={this.menuClick} to="" className="kt-menu__link "><i className="kt-menu__link-icon flaticon-avatar"></i><span className="kt-menu__link-text">Modify Services  </span></Link>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(NBNNavigation);
