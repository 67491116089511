import React, { Component } from "react";
import axios from "axios";
import { getApiHeader,removeUserSession } from "../../utils/CommonFunctions";
import swal from "@sweetalert/with-react";
import PageLoader from './../Loader/loader';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class DataGifting extends Component {
  constructor(props) {
    super(props);
    const gb = [
                { value: "1", text: "1GB" },
                { value: "2", text: "2GB" },
                { value: "3", text: "3GB" },
                { value: "4", text: "4GB" },
                { value: "5", text: "5GB" },
                { value: "6", text: "6GB" },
                { value: "7", text: "7GB" },
                { value: "8", text: "8GB" },
                { value: "9", text: "9GB" },
                { value: "10", text: "10GB" },
              ];

    this.state = {
      customer_activations: [],
      sender_activation_id: null,
      loading: false,
      gift_balance: 0,
      available_gift_balance: 0,
      gift_gb: gb,
      receiver_number:"",
      data_gift:null,
      sender_cust_no:null,
      sender_lineseq:null,
      sender_plan_id:null,
      receiver_cust_no:null,
      receiver_lineseq:null,
      receiver_activation_id:null,
      receiver_plan_id:null
    };
  }

  componentDidMount() {

    this.getUserStatus();
    this.getActivations();
  }



   // force logout --------------------------------------
  getUserStatus = async () => {
        let headers     = await getApiHeader();
        let res         = await axios.get(`${ SERVER_URL }user_status`, { headers }).then(async response => {
            
            if(response.data.data.forceLogout){
                if(response.data.data.forceLogout != 'undefined'){
                    if(response.data.data.forceLogout == '1'){
                        this.handleLogout();
                    }
                }
            }
            
        }).catch(error => {            
        });
 
  };


    handleLogout = async () => {  
        let p = new Promise(async (resolve, reject)=> {
            let headers     = await getApiHeader();
            let res         = await axios.get(`${ SERVER_URL }logout`, { headers });
            let data        = res.data.data;   
            console.log(data);
            removeUserSession();  
            resolve();

        });

        p.then(
            ()=>{ 
                    // this.props.history.push("/login");
                    window.location.href = "/login";
            },
            ()=>{
                alert("Error in logout");
            }
        )
    }

     // force logout --------------------------------------







  getActivations = async () => {
    let headers = await getApiHeader();
    let res = await axios.get(`${SERVER_URL}user_activations`, { headers });
    let data = res.data.data.list;
    this.setState({ customer_activations: data });
  };

  checkActivationStatus = async (sender_activation_id) => {
    this.setState({ loading: true });
    const post_data = { sender_activation_id: sender_activation_id };
    let headers     = await getApiHeader();
    let res = await axios
      .post(`${SERVER_URL}data-gift-sender`, post_data, { headers })
      .then((response) => {
        this.setState({ loading: false });
        this.setState({
          available_gift_balance: response.data.data.available_gift_balance,
          sender_activation_id:response.data.data.sender_activation_id,
          sender_cust_no:response.data.data.sender_cust_no,
          sender_lineseq:response.data.data.sender_lineseq,
          sender_plan_id:response.data.data.sender_plan_id,
        });
        // console.log(response.data.data.available_gift_balance);
      })
      .catch((error) => {
        this.setState({ loading: false });
        if (
          typeof error.response.data.message !== "undefined" &&
          typeof error.response.data !== "undefined"
        ) {
          swal(error.response.data.message, "", "warning");
        } else {
          swal("Internal Server Error", "", "warning");
        }
        swal({
          text: error.response.data.message,
          allowOutsideClick: false,
          closeOnClickOutside: false, 
          icon: "warning"}).then(()=>{
  
            window.location.href = "/DataGifting";
  
          });
        
      });
  };

  handleChangeSender = (e) => {
    var sender_activation_id = e.target.value;

    if (sender_activation_id === "") {
      swal("Select a valid number", "", "warning");
    } else {
      this.setState({
        sender_activation_id: sender_activation_id,
      });

      this.checkActivationStatus(sender_activation_id);
    }
  };

  handleChangeData = (e) => {
    var data_gift = e.target.value;

    if (data_gift === "") {
      swal("Select Data Gift in GB", "", "warning");
    } else {
      this.setState({data_gift: data_gift});
    }
  };

  receiverNumber = (event) => {
    this.setState({receiver_number: event.target.value}); 
  }

  giftData = async () => {
    var pattern = new RegExp('^[0-9]+$');

    if(this.state.sender_activation_id == "" || this.state.sender_activation_id == null) {
      swal("Select a sender number", "", "warning"); 
    } else if (this.state.receiver_number == "" || this.state.receiver_number == null) {
      swal("Enter receiver number.", "", "warning");
    } else if (!pattern.test(this.state.receiver_number)) {
      swal("Enter a valid receiver number", "", "warning");
    } else if (this.state.receiver_number.length != 10) {
      swal("Receiver number should contain 10 digit.", "", "warning");
    } else if (this.state.data_gift == "" || this.state.data_gift == null) {
      swal("Select Data Gift in GB", "", "warning");
    } else {
      this.dataGiftToReceiver();
    }
  };

  dataGiftToReceiver = async () => {
    this.setState({ loading: true });
    const post_data = { sender_activation_id: this.state.sender_activation_id,
                        receiver_number: this.state.receiver_number,
                        data_gift: this.state.data_gift };
    let headers     = await getApiHeader();
    let res = await axios
      .post(`${SERVER_URL}data-gift-receiver`, post_data, { headers })
      .then((response) => {
        // this.setState({ loading: false });
        this.setState({
          receiver_cust_no: response.data.data.receiver_cust_no,
          receiver_lineseq:response.data.data.receiver_lineseq,
          receiver_activation_id:response.data.data.receiver_activation_id,
          receiver_plan_id:response.data.data.receiver_plan_id
        });

        this.dataGiftApi();
      })
      .catch((error) => {
        this.setState({ loading: false });
        if (
          typeof error.response.data.message !== "undefined" &&
          typeof error.response.data !== "undefined"
        ) {
          swal(error.response.data.message, "", "warning");
        } else {
          swal("Internal Server Error", "", "warning");
        }
        swal({
          text: error.response.data.message,
          allowOutsideClick: false,
          closeOnClickOutside: false, 
          icon: "warning"}).then(()=>{
  
            window.location.href = "/DataGifting";
  
          });
        
      });
  };

  dataGiftApi = async () => {

    const post_data = { sender_custNo: this.state.sender_cust_no,
                        sender_lineseq: this.state.sender_lineseq,
                        receiver_custNo: this.state.receiver_cust_no,
                        receiver_lineseq: this.state.receiver_lineseq,
                        data_amount: this.state.data_gift,
                        sender_activation_id: this.state.sender_activation_id,
                        receiver_activation_id: this.state.receiver_activation_id,
                        sender_plan_id: this.state.sender_plan_id,
                        receiver_plan_id: this.state.receiver_plan_id 
                      };
    let headers     = await getApiHeader();
    let res = await axios
      .post(`${SERVER_URL}data-gift`, post_data, { headers })
      .then((response) => {
        this.setState({ loading: false });
        swal({
          text: response.data.message,
          allowOutsideClick: false,
          closeOnClickOutside: false, 
          icon: "success"}).then(()=>{
  
            window.location.href = "/DataGifting";
  
          });
      })
      .catch((error) => {
        this.setState({ loading: false });
        if (
          typeof error.response.data.message !== "undefined" &&
          typeof error.response.data !== "undefined"
        ) {
          swal(error.response.data.message, "", "warning");
        } else {
          swal("Internal Server Error", "", "warning");
        }
        
      });
  };

  render() {
    return (
      <>
      {(this.state.loading === true) ? <PageLoader/> : null}
      <div
        className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor common-class"
        id="kt_content"
      >
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="row">
            <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
              <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                <div className="kt-portlet">
                  <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                      <h3 className="kt-portlet__head-title">Data Gifting</h3>
                    </div>
                  </div>
                  <div className="kt-portlet__body">
                    <div className="kt-section kt-section--first">
                      <div className="kt-section__body">
                        <div>
                          <div className="form-group row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <label className="">Select Sender Number</label>
                              <select
                                className="form-select custom-select kt_selectpicker  refer-select"
                                defaultValue={this.state.sender_activation_id}
                                onChange={this.handleChangeSender}
                                name="activation"
                              >
                                <option
                                  value=""
                                  className="m--bg-success kt-font-inverse-success"
                                >
                                  Select Sender Number
                                </option>
                                {this.state.customer_activations.map(
                                  (person) => (
                                    <option
                                      className="m--bg-success kt-font-inverse-success"
                                      value={person.activation_id}
                                      key={person.activation_id}
                                    >
                                      {person.mobile_number}
                                    </option>
                                  )
                                )}
                              </select>

                              <span
                                className="col-lg-12 col-md-12 col-sm-12"
                                role="alert"
                                id="mobile_operator_error"
                                style={{ color: "red" }}
                              ></span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <label className="col-xl-12 col-lg-12 col-form-label txt-left">
                                Enter Receiver Number
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                value={this.state.receiver_number}
                                onChange={this.receiverNumber}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <label className="">Select Data in GB</label>
                              <select
                                className="form-select custom-select kt_selectpicker  refer-select"
                                name="mobile_provider"
                                id="mobile_provider"
                                defaultValue={this.state.data_gift}
                                onChange={this.handleChangeData}
                              >
                                <option
                                  value=""
                                  className="m--bg-success kt-font-inverse-success"
                                >
                                  Select Data Gift in GB
                                </option>
                                {this.state.gift_gb.slice(0, this.state.available_gift_balance).map(
                                  (data_gb) => (
                                    <option
                                      className="m--bg-success kt-font-inverse-success"
                                      value={data_gb.value}
                                      key={data_gb.value}
                                    >
                                      {data_gb.text}
                                    </option>
                                  )
                                )}
                              </select>

                              <span
                                className="col-lg-12 col-md-12 col-sm-12"
                                role="alert"
                                id="mobile_operator_error"
                                style={{ color: "red" }}
                              ></span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <div className="kt-form__actions">
                                <button
                                  type="submit"
                                  className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm"
                                  id="wizard_next_btn"
                                  data-ktwizard-type="action-next"
                                  onClick={this.giftData}
                                >
                                  Gift Data
                                </button>
                                <br />
                                <br />
                                <br />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="kt-portlet__foot">
                    <div className="kt-form__actions">
                      <div className="row"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default DataGifting;
