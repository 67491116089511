import React, { Component } from 'react';
export default class ErrorMsg extends Component {

    constructor(props)
    {
        super(props);
        let message = ( typeof this.props.message !== undefined) ? this.props.message : [{}];
        this.state={message: message};
    }

    render()
    {
        return(<>

                <div>
                   {Object.keys(this.state.message).map((keyName)=>{
                    return (<p>{this.state.message[keyName]}</p>)
                   })}
                </div>

        </>);
    }

}