import React, { Component } from "react"; 
import '../../assets/css/wizards/wizard-v1.css';
import '../../assets/css/wizards/wizard-v2.css';
import '../../assets/css/wizards/wizard-v3.css';
import Wizard from './guestRecharge/wizard';
import GuestHeader from './../../components/Layout/GuestHeader';
import GuestFooter from "./../../components/Layout/GuestFooter";

const SERVER_URL 	= process.env.REACT_APP_SERVER_URL;   

export default class GuestRecharge extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            email: '' ,
            password: '' ,
            page: 'Activate' ,
        };
    }

    mySubmitHandler = async (event) => {
        event.preventDefault(); 
        const post_data =   { username: this.state.email ,
                                password : this.state.password ,   
                            };
		
    }

    changeEmail = (event) => {
        this.setState({email: event.target.value});
    }

    changePassword = (event) => {
        this.setState({password: event.target.value});
    }

    render() {
       

    
  return (
    <>
            <div className="kt-grid kt-grid--ver kt-grid--root">
            <div className="kt-grid__item   kt-grid__item--fluid kt-grid  kt-grid kt-grid--hor kt-login-v2" id="kt_login_v2">
            <GuestHeader/>
            <div className="kt-grid__item  kt-grid  kt-grid--ver  kt-grid__item--fluid" id="activate-your-number">

                <div className="kt-login-v2__body">

                    
                    <div className="kt-portlet" style={{width: '100%',boxShadow: 'none'}}>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div className="kt-grid  kt-wizard-v2 row" id="kt_wizard_v2" data-ktwizard-state="step-first">
                                
                                <div className="kt-section kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper col-xl-12 col-lg-12">

                                    
                                            <Wizard />
                                    

                                </div>
                            </div>
                        </div>
                    </div>


                   
                </div>

            </div>

            <GuestFooter/>

            </div>
            </div>

             
	</>
   )
}
}