import React, { Component } from "react"; 
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';   
import { getApiHeaderGuest, removeUserSession, setUserSession, getApiHeader } from '../../utils/CommonFunctions';
import App from "../../App";
import { Link, withRouter} from "react-router-dom";
import GuestHeader from './../../components/Layout/GuestHeader';
import GuestFooter from "./../../components/Layout/GuestFooter";
import PublicSlider from './slider/PublicSlider';
import PageLoader from './../Loader/loader';
import swal from 'sweetalert';

const SERVER_URL 	= process.env.REACT_APP_SERVER_URL;   
const headers 		= getApiHeaderGuest();
class Login extends Component {
    
    constructor(props) {

        super(props);

        this.validator = new SimpleReactValidator();

        this.state = { 
            email: '' ,
            password: '' ,
            isLoggedIn: false,
            redirection: false,
            backendErrorShow:'d-none',
            backendError:null,
            isValid: false
        };
    }

    patternValidation = password => {
        return /\s/g.test(password);
    };

    onFormSubmit = () => {
        const { password } = this.state;
        console.log("Value: ", password);
    };

    handleKeyDown = e => {
        if (e.key === " ") {
          e.preventDefault();
        }
    };

   // Activation process for customers leaving after payment

   getActivationAttemptDetailsLogin = async () => {
        let headers  = await getApiHeader();
        let act_attempt_data        = await axios.get(`${ SERVER_URL }attemptDetailsLoginredirect`, { headers });
        let attempt_data=act_attempt_data.data.data;
        if(attempt_data.activation_attempt !=null)
        {
            this.setState({ dvs_status_check: attempt_data.activation_attempt.customer.dvs_status});
            if(this.state.dvs_status_check ==1){ this.props.history.push("/IDsuccess"); }
            else{ this.props.history.push("/IDverification"); }
        }

    };


    getActivations = async () => {
        let headers     = await getApiHeader();
        let res         = await axios.get(`${ SERVER_URL }user_activations`, { headers }).then(response => {
            // this.props.history.push("/home");

            window.location.href = "/home";
            
        }).catch(error => {
            this.setState({loading:false});
            if((typeof(error.response.data.message) !== 'undefined') && (typeof(error.response.data) !== 'undefined'))
            {
                  this.getActivationAttemptDetailsLogin();  
            }

            
        });
 
    };

    // Activation process for customers leaving after payment

    async componentDidMount(){
        await sessionStorage.setItem('redirection_from', "");
    }




    mySubmitHandler = async (event) => {
        
        event.preventDefault(); 

        this.setState({loading:true});

        if (this.validator.allValid() == false) 
        {

            this.setState({loading:false});

            this.validator.showMessages();

            this.forceUpdate();

            return;

        } 

        const post_data =   {   
                                username: this.state.email ,
                                password : this.state.password ,   
                            };
		 
        axios.post(`${ SERVER_URL }login`,post_data, {headers}).then(async response => {
            console.log('login ==>', response);
            if(response.data.status === 200){
                if(response.data.data.login_url === 'activation'){
                    await sessionStorage.setItem('activation_session_id', response.data.data.session_id)
                    await sessionStorage.setItem('redirection_from', 'login');
                    
                    window.location.href = "/activate"
                    return;
                }
                if(response.data.data.login_url === 'nbn-my-account-summary'){
                    await sessionStorage.setItem('nbn_activation_id', response.data.data.nbn_activation_id)

                    console.log(sessionStorage.getItem('nbn_activation_id'));
                    window.location.href = "/nbn-my-account-summary";
                  //  return;
                }else{
                    window.location.href = response.data.data.login_url;
                }



                await sessionStorage.setItem('is_prepaid',response.data.data.user.is_prepaid)
                await sessionStorage.setItem('is_nbn',response.data.data.user.is_nbn)

            }
            if(response.status !== 200)
            {
             
                console.log("fdgdfg");
                if(response.data.is_postpaid === true){
                    console.log('Postpaid number');
                }else{
                    console.log('Invalid login');
                    this.setState({password: ""});
                    this.setErrorMessages(response.data.message);
                    return;
                }

            }

            let p = new Promise((resolve, reject)=>{

                setUserSession(response.data.data.token, response.data.data.user);

                resolve();



            })

            p.then(
                ()=>{

                        //this.props.history.push("/home");

                        this.getActivations()

                        

                },
                ()=>{
                    alert("Error in Login");
                }
            )

        }).catch(error => { 
            this.setState({password: ""});
            if(error.response.data.data.is_postpaid == true){ 

                swal({
                    title: error.response.data.message,
                    content: '', 
                    buttons: false,
                    timer: 5000
                });
                setTimeout(function() {
                    window.location.href = "http://myaccount.telsim.com.au/";
                    }, 5000);

                
            }else{
                this.setErrorMessages(error.response.data.message);
            }
            

            removeUserSession();

        }); 


        this.setState({loading:false});

    }



    setErrorMessages = (errorMessage)=>{

        let responseError = []

        responseError[0] = 'Internal server error';

        if (typeof(errorMessage) !== 'undefined' && errorMessage != null)
        {
            if(typeof errorMessage === 'object')
            {

                responseError = [];

                Object.keys(errorMessage).map(function(key, index) {

                    responseError.push(errorMessage[key]);

                });

            }
            else
            {
                responseError[0] = errorMessage;
            }

        }

        this.setState({backendErrorShow:'d-block', backendError:responseError});

    }

    changeEmail = (event) => {
        this.setState({email: event.target.value});
    }

    changePassword = (event) => {
        if (event.currentTarget.value.includes(" ")) {
            event.currentTarget.value = event.currentTarget.value.replace(/\s/g, "");
        }

        const { value } = event.target;
        const isValid = this.patternValidation(value);
    
        this.setState({
            password: value,
          isValid
        });
    
        // console.log(this.patternValidation(value))

        this.setState({password: event.target.value});
    }

    showError = ()=>
    {

        if(this.state.backendError != null)
            return this.state.backendError.map(data => <><p>{data}</p></>)
        return null;

    }

    render() {
        const { redirection, isValid, password} = this.state;


  return (
    <>
        {(this.state.loading === true) ? <PageLoader/> : null}
        {redirection ? (
            <App />
) : (
    <div className="kt-grid kt-grid--ver kt-grid--root">
    <div className="kt-grid__item   kt-grid__item--fluid kt-grid  kt-grid kt-grid--hor kt-login-v2" id="kt_login_v2"> 

    <GuestHeader/>

        <div className="kt-grid__item  kt-grid  kt-grid--ver  kt-grid__item--fluid">
            <section className="login-block">
            <div className="container" style={{ boxShadow: '15px 20px 0px rgba(0,0,0,0.1)' }}>

                    <div className="row">
                        <div className="col-md-4 login-sec">
                            <h2 className="text-center">Sign In To Telsim My Account</h2>
                            <div className={`backendErrorMsg text-center text-danger ${this.state.backendErrorShow}`}>{(this.state.backendErrorShow === 'd-block') ? this.showError() : null}</div>
                            <form className="login-form" onSubmit={this.mySubmitHandler}>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1" className="text-uppercase">Enter your Telsim My Account Login Email</label>
                                    <input name="email" type="text" className="form-control" placeholder="" onChange={this.changeEmail} value={this.state.email}/>
                                    {this.validator.message('email', this.state.email, 'required|email', { className: 'text-danger' })}
                                </div>
                                <div className="form-group">
                                    <label for="exampleInputPassword1" className="text-uppercase">Enter Password</label>
                                    <input name="input2" type="password" className="form-control" placeholder="" onChange={this.changePassword} value={this.state.password} onKeyDown={this.handleKeyDown}/>
                                    {this.validator.message('password', this.state.password, 'required', { className: 'text-danger' })}
                                    {this.state.isValid && (<div style={{ color: "red" }}>Empty spaces are not allowed.</div>)}
                                </div>
                                <div className="form-check">
                                    <Link to="/ResetPassword" className="kt-link kt-link--brand" >
                                        Forgot Your Password?
                                    </Link>
                                    <br/>  
                                    <label className="form-check-label">
                                    <Link to="/ForgotUserId" className="kt-link kt-link--brand" >
                                        Forgot Your User ID?
                                    </Link>
                                    </label> 
                                    <br/> 
                                    <button type="submit" className="btn btn-brand btn-elevate btn-pill float-right btn-signin" onClick={this.onFormSubmit}>Sign In</button>
                                </div>
                            </form>
                            

                            <div className="text-center" style={{marginTop:'40px'}}>
                                Not Registered for Telsim My Account?  <br/>
                                <Link to="/Register" className="kt-link kt-link--brand" >
                                    Register Here
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-8 banner-sec">
                            <PublicSlider/>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <GuestFooter/>

        </div>
        </div> 
          )}               
	</>
   )
}
}


export default withRouter(Login);