import React, { Component } from 'react';
import { Link } from "react-router-dom"; 
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';   
import {getApiHeaderGuest , getActivationAttemptDataCreateAccount} from '../../../utils/CommonFunctions';
import swal from 'sweetalert';
import ErrorMsg from '../errorMsg';
import PageLoader from './../../Loader/loader';
import Moment from 'moment';

const SERVER_URL    = process.env.REACT_APP_SERVER_URL;  
const headers 		= getApiHeaderGuest();
export class CreateNewAccount extends Component {
    constructor(props) {

        super(props); 
        this.validator = new SimpleReactValidator();


        this.state = {
            username: '', 
            password: '',
            attempt_data:[],
            password_display:false,
            loading:false,
            verifiedEmail:false,
            verificationUser:null,
            verificationSession:null,
            verificationAttempt:null,
            sendEOTP: false,
            emailOTP:'',
            otptoken:'',
            attemptid:null
        }
    }

    componentDidMount() { 
        this.getActivationAttemptData();  
	}










// Email OTP verification



maskUsername = (username) => {
    // Your email masking logic goes here
    // This is a simple example that replaces some characters with asterisks
    return username.substring(0, 3) + '*'.repeat(username.length - 3);
  };





onEmailOTPEditHandle = (event) => {
   
        this.setState({emailOTP: event.target.value});
  
}








// Requesting OTP function
onEmailRequestOTPBtnClick = async (event) => { 
    
 event.preventDefault();  
    if (this.validator.allValid() == false) 
    {

        this.validator.showMessages();

        this.forceUpdate();

        return; 

    }
    this.setState({loading: true}) 
    const post_data =   {   

        username: this.state.username ,
        password: this.state.password ,
        

    }; 
    let res = await axios.post(`${ SERVER_URL }activation/account-creation`, post_data, { headers }).then(response => {
        
            // this.setState({otptoken:response.data.data.otp_token})
            this.setState({attemptid:response.data.data.attempt_id})
            this.setState({verificationSession:response.data.data.session_id})
            
         sessionStorage.setItem('activation_session_id',response.data.data.session_id );
            
            
        if(typeof(response.data.message) !== 'undefined') { 
            this.setState({ email_failed: '', email_success: response.data.message }); 
        } else { 
            this.setState({ email_failed: '', email_success: "OTP Sent" }); 
        }
        this.setState({
            sendEOTP: true,
            

        })
        swal(response.data.message, "", "success");
    }).catch(error => {
        if((typeof(error.response.data.message) !== 'undefined') && (typeof(error.response.data) !== 'undefined')) { 
            this.setState({ email_failed: error.response.data.message, email_success: '' });
            swal(error.response.data.message)
        } else {
            swal("Internal Server Error", "", 'warning');
        }

    });
    this.setState({loading: false});
}

// end requesting OTP function


// verifying Email OTP
onEmailVerifyBtnClick = async(event) => {
    event.preventDefault()
    
    if (this.validator.allValid() == false) 
        {

            this.validator.showMessages();

            this.forceUpdate();

            return;

        }
        this.setState({loading: true})
    const post_data =   { 
        
        username: this.state.username ,
        session_id: this.state.verificationSession,
        attempt_id: this.state.attemptid,
        otp:this.state.emailOTP,
        }; 
        
     await axios.post(`${ SERVER_URL }activation/verify-email`, post_data, { headers }).then(response => {
            this.setState({
                verifiedEmail: true, emailOTP_failed: '',
            })
            this.props.nextStep();
             

        // if(typeof(response.data.message) !== 'undefined') { 
        //     this.setState({ number_failed: '', number_success: response.data.message }); 
        // } else { 
        //     this.setState({ number_failed: '', number_success: "OTP Sent" }); 
        // }
           
        
    }).catch(error => {
        if((typeof(error.response.data.message) !== 'undefined') && (typeof(error.response.data) !== 'undefined')) { 
            this.setState({ emailOTP_failed: error.response.data.message});
            swal(error.response.data.message,"", "warning")
        } else {
            swal("Internal Server Error", "", 'warning');
        }
    });
    this.setState({loading: false});
}
// end verifying Email OTP


// end email otp verification function



    getActivationAttemptData = async () => {
       
        this.setState({loading:true});
		let attempt_data     = await getActivationAttemptDataCreateAccount();  
        //console.log('get Activation Attempt Data no==>', attempt_data.activation_attempt.step_number);
        
        // console.log('get Activation Attempt Data ==>', attempt_data.activation_attempt);
        if(attempt_data.activation_attempt != null && attempt_data.activation_attempt !== undefined)
        {
            if(sessionStorage.getItem('redirection_from') == 'login'){
            await sessionStorage.setItem('activation_session_id', attempt_data.activation_attempt.session_id);
            
            }
            this.setState({ username: attempt_data.activation_attempt.username});
           
        } 
        this.setState({loading:false});
	};


    setErrorMessages = (errorMessage,type)=>{

        //let responseError = []

       let responseError = 'Internal server error';

        if (typeof(errorMessage) !== 'undefined' && errorMessage != null)
        {
            if(typeof errorMessage === 'object')
            {

                responseError = '';

                Object.keys(errorMessage).map(function(key, index) {

                    // responseError.push(errorMessage[key]);
                    responseError+=errorMessage[key];

                });

            }
            else
            {
                responseError = errorMessage;
            }

        }

        swal(responseError, "", type);

    }



 // ----------------------------------------------------------- Email Link Verification -------------------------------------------------------------------------------------------




    // mySubmitHandler = async (event) => {  
    //     event.preventDefault();  
    //     if (this.validator.allValid() == false) 
    //     {

    //         this.validator.showMessages();

    //         this.forceUpdate();

    //         return;

    //     }


    //     if(this.state.verifiedEmail == true){

    //         if(this.state.verificationUser == this.state.username && this.state.verificationUser !=null && this.state.verificationSession !=null &&  this.state.verificationAttempt !=null){

    //                 this.setState({loading: true})
    //                 const post_data =   {   
    //                 username: this.state.verificationUser ,
    //                 session_id: this.state.verificationSession , 
    //                 attempt_id: this.state.verificationAttempt
    //                 }; 
    //                 let headers     = await getApiHeaderGuest();

    //                 try
    //                 {

    //                     let res         = await axios.post(`${ SERVER_URL }activation/verify-email`,post_data, { headers });
    //                     let data        = res.data;  
    //                     if(data.status==200){  
                            
    //                        this.setState({loading: false});

    //                        this.props.nextStep();
                            
    //                     }else{
    //                         swal(data.message, "", "warning");
    //                     }

    //                 }
    //                 catch(err)
    //                 {

    //                     this.setState({loading:false});

    //                     if(err){
    //                         let errorMessage = '';

    //                         if(err.response && err.response.data && err.response.data.message){
    //                             if(typeof(err.response.data.message) === 'object'){

    //                                 swal({
    //                                     title: "Error",
    //                                     content: <ErrorMsg message={err.response.data.message}/>,
    //                                     icon: "waring"});
                    
    //                             }else{
    //                                 errorMessage = err.response.data.message;
    //                                 swal({
    //                                     title: err.response.data.message,
    //                                     icon: "error"});
                                    
    //                             }
    //                         }else{
    //                             errorMessage = err;
    //                             swal(errorMessage,err);
    //                         }
    //                     }

    //                 }

    //             return;
    //         }
    //     }


    //     this.setState({loading: true})
    //     const post_data =   {   
    //         username: this.state.username ,
    //         password: this.state.password , 
    //     }; 
    //     let headers     = await getApiHeaderGuest();

    //     try
    //     {

        
    //         let res 		= await axios.post(`${ SERVER_URL }activation/account-creation`,post_data, { headers });
    //         let data 		= res.data;  
    //         if(data.status==200){  
    //             if(data.data.session_id){ 
    //                 await sessionStorage.setItem('activation_session_id', data.data.session_id);

    //                 swal(data.message, "", "success");

    //                 this.setState({verifiedEmail: true});
    //                 this.setState({verificationUser: data.data.username});
    //                 this.setState({verificationSession: data.data.session_id});
    //                 this.setState({verificationAttempt: data.data.attempt_id});


    //                 this.setState({loading: false})
    //             }else{
    //                 swal(data.message, "", "success");
    //             }  
                
    //         }else{
    //             swal(data.message, "", "warning");
    //         }

    //     }
    //     catch(err)
    //     {

    //         this.setState({loading:false});

    //         if(err)
    //         {
    //             let errorMessage = '';

    //             if(err.response && err.response.data && err.response.data.message)
    //             {
    //                 if(typeof(err.response.data.message) === 'object')
    //                 {

    //                     swal({
    //                         title: "Error",
    //                         content: <ErrorMsg message={err.response.data.message}/>,
    //                         icon: "waring"});
        
    //                 }
    //                 else
    //                 {
    //                     errorMessage = err.response.data.message;
    //                     // swal(errorMessage,"error");
    //                     swal({
    //                         title: err.response.data.message,
    //                         icon: "error"});
                        
    //                 }
    //             }
    //             else
    //             {
    //                 errorMessage = err;
    //                 swal(errorMessage,err);
    //             }
    


    //         }
            

    //     }

    // };




//------------------------------------------------------------------------------ End Email Link verificatioon------------------------------------------------------------------------













    
    getUsername = (event) => { 
        this.setState({ username: event.target.value}); 
    }
    getPassword = (event) => { 
        this.setState({ password: event.target.value}); 
    }

    ////////////////////Password Display ////////////////////
    passwordDisplay =  (event) => {   
		this.setState({ password_display: !this.state.password_display });  
	};

    
    onKeyPress(event) {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    var pattern = new RegExp(/^(([a-z])|([0-9])|@|\.|_|-)/i);
        if (!pattern.test(keyValue)) {
            event.preventDefault();
        }
    }

    resetEmail = () => {
        this.setState({
            sendEOTP: false, 
            username: '',
            password: '',
            emailOTP: '',
            email_success: ''
        })

    }






    render() {
        const { values, handleChange,sendEOTP,otptoken,attemptid } = this.state;
        
        return (
            <>
            {(this.state.loading === true) ? <PageLoader/> : null}
            <form className="kt-form kt-section__content kt-section__content--border" id="kt_form">
                <div className="kt-wizard-v2__content" data-ktwizard-type="step-content" data-ktwizard-state="current">
                    <div className="kt-heading kt-heading--md">Activate Your Account</div>
                    <label>Enter the email address and password you'd like to use to login to your Telsim account:</label>
                    <div className="kt-separator kt-separator--height-xs"></div>
                    <div className="kt-form__section kt-form__section--first">
                        <div className="row">
                            <div className="col-xl-12">

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                {/* <div className="form-group">
                                    <label>Email Address:</label>
                                   <input disabled={sendEOTP == true} type="email" className="form-control" name="username" placeholder="Enter email" id="username"  title="Enter valid email" autoComplete="off" onPaste={(e)=>{ e.preventDefault(); return false; }}  onCopy={(e)=>{ e.preventDefault(); return false; }}  onKeyPress={this.onKeyPress.bind(this)}  onChange={this.getUsername} value={this.state.username}/>
                                   
                                    
                                   </div> */}


                                    <div className="form-group">
                                    <label>Email Address:</label>
                                    
                                    {/* <input type="email" className="form-control" name="username" placeholder="Enter email" id="username"  title="Enter valid email" autoComplete="off" onPaste={(e)=>{ e.preventDefault(); return false; }}  onCopy={(e)=>{ e.preventDefault(); return false; }}  onKeyPress={this.onKeyPress.bind(this)}  onChange={this.getUsername} value={this.state.username}/> */}
                                    <input type="email" disabled={sendEOTP == true} className="form-control" name="username" placeholder="Enter email" id="username"  title="Enter valid email" autoComplete="off" onPaste={(e)=>{ e.preventDefault(); return false; }}  onCopy={(e)=>{ e.preventDefault(); return false; }}  onKeyPress={this.onKeyPress.bind(this)}  onChange={this.getUsername} value={this.state.username}/>


                                    {this.validator.message('Email', this.state.username, 'required|email', { 
            className: 'text-danger',
            messages: {
                required: 'Required field. Please enter your email address.',
                email: 'Please enter a valid email address.'
            }
        })}
                                </div>
                                
                                
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="form-group">
                                    <label>Password:</label>
                                    <div className="input-group">
                                        <input disabled={sendEOTP == true} type={this.state.password_display ? "text" : "password"} className="form-control" name="password" id="password" placeholder="Enter password"   title="Enter a valid password with atleast 6 characters" pattern="\s*(\S\s*){6,}" minLength="6" onChange={this.getPassword} style={{width: '90%'}} value={this.state.password} />
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                <button className="btn btn-icon" id="show_password" type="button" onClick={this.passwordDisplay}><i className="la la-eye" id="show_pass_icon"></i></button>
                                            </span>
                                           
                                        </div>

                                    </div>
                                    
                                        {this.validator.message('Password', this.state.password, 'required', { className: 'text-danger' })} 
                                    <span className="form-text text-muted"></span>
                                </div>
                            </div>
                        </div>
                        {(sendEOTP == true) ?  <div className="input-group" style={{gap:5}}>
                        <input className="form-control" name="OTP" id="otp" placeholder="Enter OTP"   title="Enter a valid OTP" style={{width: '100%'}} onChange={this.onEmailOTPEditHandle} value={this.state.emailOTP}/>
                        
                        </div> : ''}
                        {sendEOTP == true ? (<p style={{color:'green'}}>Verification OTP has been sent to your Email ID, Please enter the OTP.</p>):''}
                       
                       
                        <div className=" text-center" style={{marginTop: '40px'}}>
                            Already have an account? <Link to="/Login" className="kt-link kt-link--brand">Sign In</Link>
                        </div>
                    </div>

                    
                    <br/>
                    <div className="kt-form__actions">

                    {sendEOTP == true ? (<button onClick={this.resetEmail} className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm" id="wizard_next_btn">Reset</button>):''}
                    
                       <button type="submit" className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm" id="wizard_next_btn" data-ktwizard-type="action-next" onClick={(sendEOTP == false) ? this.onEmailRequestOTPBtnClick : this.onEmailVerifyBtnClick}>{(sendEOTP == true ? 'Verify' : 'Next Step')}</button>
                      
                      
                      
                      
                      
                    </div>

           
                </div>
                

            </form>
            </>
                

        )
    }
}

export default CreateNewAccount
