import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { getUser, removeUserSession } from './../../utils/CommonFunctions';
import Clock from 'react-live-clock';
import { withRouter } from 'react-router';
import axios from 'axios';    
import {getApiHeader} from '../../utils/CommonFunctions';
import maleUserImg from './../../assets/media/users/computer-icons-user-profile-avatar.jpg'
import femaleUserImg from './../../assets/media/users/user_female_avatar.png';

const SERVER_URL 	= process.env.REACT_APP_SERVER_URL; 

class Header extends Component {

    constructor(props)
    {
        super(props);
        
        let user = getUser();

        this.state = {
            user: user,
            isLoggedOut:false,
            redirectToReferrer:false
        }
    }

    handleLogout = async () => {  

        

        let p = new Promise(async (resolve, reject)=> {

            let headers     = await getApiHeader();
            let res 		= await axios.get(`${ SERVER_URL }logout`, { headers });
            let data 		= res.data.data;   
            console.log(data);

            removeUserSession();  
            resolve();

        });

        p.then(
            ()=>{ 

                    this.props.history.push("/login");

            },
            ()=>{
                alert("Error in logout");
            }
        )
        
    }

    render()
    {

        const from = { pathname: '/login' };
        const { redirectToReferrer } = this.state; // redirectToReferrer is true in the initial state
        if (redirectToReferrer) { // if true the user will be redirected to /dashboard
          return <Redirect to={from} />;
        }


        var account_details = ((this.state.user) && (this.state.user.gender)) ? this.state.user.gender : 'Male' ; 
        if(account_details){
            // if (account_details == 'Male') {
            //     var profile_image = "assets/media/users/computer-icons-user-profile-avatar.jpg";
            // } else if (account_details == 'Female') {
            //     var profile_image = "assets/media/users/user_female_avatar.png";
            // }else{
            //     var profile_image = "assets/media/users/computer-icons-user-profile-avatar.jpg";
            // }
            
            
            if (account_details == 'Male') {
                var profile_image = maleUserImg;
            } else if (account_details == 'Female') {
                var profile_image = femaleUserImg;
            }else{
                var profile_image = maleUserImg;
            }

        }
        else
        {
            var profile_image = maleUserImg;
        }

        return (
        
            <>
            {((this.state.isLoggedOut === false) && (this.state.user !== undefined)) ?
                <div id="kt_header" className="kt-header kt-grid__item  kt-header--fixed "> 
                    <button className="kt-header-menu-wrapper-close" id="kt_header_menu_mobile_close_btn">
                    <i className="la la-close"></i>
                    </button>
                    <div className="kt-header-menu-wrapper" id="kt_header_menu_wrapper">
                        <div id="kt_header_menu" className="kt-header-menu kt-header-menu-mobile  kt-header-menu--layout- ">
                            <ul className="kt-menu__nav ">
                                <li className="kt-menu__item  kt-menu__item--submenu kt-menu__item--rel " data-ktmenu-submenu-toggle="click">
                                    <a className="kt-menu__link kt-menu__toggle">
                                        <span className="kt-menu__link-text">
                                            <h4><i>"5G For The Unstoppable Today!"</i></h4>
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div> 
                    <div className="kt-header__topbar">
                        <input type="hidden" value="45824" id="hr_mn_se_seconnds" />
                        <div id="kt_header_menu" className="kt-header-menu kt-header-menu-mobile  kt-header-menu--layout- ">
                            <ul className="kt-menu__nav ">
                                <li className="kt-menu__item  ">
                                    <a className="kt-menu__link kt-menu__toggle">
                                    {/*<span className="kt-menu__link-text"><span className="">14-09-2021 <span id="hr_mn_se_timer"></span></span></span>*/}
                                    <Clock className="kt-menu__link-text" format={'DD-MM-YYYY HH:mm:ss'} ticking={true} timezone={'Australia/Sydney'} />
                                    </a>
        
                                </li>
                            </ul>
                        </div> 
                        <div className="kt-header__topbar-item dropdown">
                            <div className="kt-header__topbar-wrapper" data-toggle="dropdown" data-offset="30px,0px">
                                <span className="kt-header__topbar-icon ">
                                <i className="flaticon2-bell-alarm-symbol "></i>
                                <span className="kt-badge kt-badge--dot kt-badge--notify kt-badge--sm kt-badge--brand"></span>
                                </span>
                            </div>
                            <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
                            </div>
                        </div> 
                        <div className="kt-header__topbar-item kt-header__topbar-item--user">
                            <div className="kt-header__topbar-wrapper" data-toggle="dropdown" data-offset="0px,0px"> 
                                <div className="kt-header__topbar-user kt-rounded-">
                                    <span className="kt-header__topbar-username kt-hidden-mobile">{((this.state.user) && ((this.state.user.firstName))) ? this.state.user.firstName : null} { ((this.state.user) && ((this.state.user.middleName))) ? this.state.user.middleName : null } {((this.state.user) && ((this.state.user.surname))) ? this.state.user.surname : null}</span>
                                    <img src={profile_image} alt="Pic" className="kt-rounded-"/> 
                                    <span className="kt-badge kt-badge--username kt-badge--lg kt-badge--brand kt-hidden kt-badge--bold">S</span>
                                </div>
                            </div>
                            <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-sm">
                                <ul className="kt-nav kt-margin-b-10">
                                    <li className="kt-nav__item">
                                    {(sessionStorage.getItem('is_prepaid') === '1') ?
                                    
                                       <Link to="/ChangePassword" className="kt-nav__link "><i className="kt-nav__link-icon flaticon2-graphic"></i><span className="kt-nav__link-text">Change Password</span></Link> : 
                                       <Link to="/nbn_change_password" className="kt-nav__link "><i className="kt-nav__link-icon flaticon2-graphic"></i><span className="kt-nav__link-text">Change Password</span></Link> 
                                       
                                    }
                                    </li>
                                    <li className="kt-nav__item">
                                        <a className="kt-nav__link"  onClick={this.handleLogout}>
                                        <span className="kt-nav__link-icon"><i className="flaticon2-browser-2"></i></span>
                                        <span className="kt-nav__link-text">Logout</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div> 
                    </div> 
                </div> : null }
            </>
          );
    }

}

export default withRouter(Header);
