import React, { Component } from 'react';
import Loader from "react-loader-spinner";

export default class PageLoader extends Component {

    render()
    {

        return (<div className="loader">      
        <div className="loaderCenter">
            <Loader
            type="Bars"
            color="#e90000"
            secondaryColor="white"
            height={80}
            width={80}
        />
    </div>
      </div>);
        
    }

}