import React, { Component } from "react"; 
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';   
import { getApiHeaderGuest } from '../../utils/CommonFunctions'; 
import swal from 'sweetalert';
import GuestHeader from './../../components/Layout/GuestHeader';
import GuestFooter from "./../../components/Layout/GuestFooter";
import PublicSlider from './slider/PublicSlider';
import PageLoader from './../Loader/loader';
import { Link, withRouter} from "react-router-dom";

const SERVER_URL 	= process.env.REACT_APP_SERVER_URL;   
const headers 		= getApiHeaderGuest();
export default class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            email: '' , 
        };

        this.validator = new SimpleReactValidator();

    }

    changeEmail = (event) => {
        this.setState({email: event.target.value});
    }

    mySubmitHandler = async (event) => { 
        event.preventDefault();

        this.setState({loading:true});

        if (this.validator.allValid() == false) 
        {

            this.validator.showMessages();

            this.forceUpdate();

            this.setState({loading:false});
            return;

        }
         
        const post_data =   { email: this.state.email , 
                            };
        let res         = await axios.post(`${ SERVER_URL }forgot-password`,post_data, { headers }).then(response => {


            if(typeof(response.data.data.message_part_one) !== 'undefined'){

                var msg1 = response.data.data.message_part_one;
                var msg2 = response.data.data.message_part_two;

                swal(msg1, msg2, "success");

            }else{
                swal(response.data.message, "", "success");
            }

            this.setState({email: ''});

        }).catch(error => {
        if((typeof(error.response.data.message) !== 'undefined') && (typeof(error.response.data) !== 'undefined'))
        { 
        swal(error.response.data.message, "", "warning");
        }
        else
        {
        swal("Internal Server Error", "", 'warning');
        }

        }); 


        this.setState({loading:false});
    }

     

    render() { 

    
  return (
    <>
        <style>
        {
          `.swal-text {
                color: #e90000;
                text-align:center;
            }`
        }
      </style>


        {(this.state.loading === true) ? <PageLoader/> : null}
        <div className="kt-grid kt-grid--ver kt-grid--root">
        <div className="kt-grid__item   kt-grid__item--fluid kt-grid  kt-grid kt-grid--hor kt-login-v2" id="kt_login_v2">
        <GuestHeader/>
        <div className="kt-grid__item  kt-grid  kt-grid--ver  kt-grid__item--fluid">
            <section className="login-block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 login-sec">
                            <h2 className="text-center">Reset Password</h2>
                            <form className="login-form"  onSubmit={this.mySubmitHandler}>
                                <div className="form-group">
                                    <p style={{padding: '10px', background: '#ff00001c', borderRadius: '5px', color: '#000'}}>To receive a reset password link, please enter your Telsim My Account login email. If you do not remember your Telsim My Account login email, retrieve it <Link to="/ForgotUserId" style={{color:'red'}} >here</Link>.</p>
                                    <label for="exampleInputEmail1" className="text-uppercase">Enter your Telsim My Account Login Email</label>
                                    
                                    <input type="text" className="form-control" placeholder="Enter your Telsim My Account Login Email" value={this.state.email} onChange={this.changeEmail}/>
                                    {this.validator.message('Registered login email', this.state.email, 'required', { className: 'text-danger' })}
                                </div> 
                                <div className="form-check text-center"> 
                                    <button type="submit" className="btn btn-brand btn-elevate btn-pill float-right btn-signin" style={{margin:'0 auto'}}>Reset Password</button>
                                </div>
                                <div className="form-check mt10" style={{textAlign:"center"}}>
                                    <label className="form-check-label">
                                        <Link to="/Login" className="kt-link kt-link--brand" >Back to Login
                                        </Link>
                                    </label> 
                                </div>
                            </form> 
                        </div>
                        <div className="col-md-8 banner-sec">
                            <PublicSlider/>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <GuestFooter/>

        </div>
        </div>
	</>
   )
}
}