import React, { Component } from "react"; 
import axios from 'axios';   
import { getApiHeaderGuest, setUserSession } from '../../utils/CommonFunctions'; 
import App from "../../App";
import swal from '@sweetalert/with-react';
import { Link, withRouter} from "react-router-dom";
import GuestHeader from './../../components/Layout/GuestHeader';
import GuestFooter from "./../../components/Layout/GuestFooter";
import PublicSlider from './slider/PublicSlider';

const SERVER_URL 	= process.env.REACT_APP_SERVER_URL;   


const headers 		= getApiHeaderGuest();
class Register extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            mobile_number: ''
        };
    }


    enterNumber = (event) => {
        this.setState({mobile_number: event.target.value});
    }

    mySubmitHandler = async (event) => { 
        event.preventDefault();  
         
        const post_data =   { mobile_number: this.state.mobile_number ,
                            };
        let res 		= await axios.post(`${ SERVER_URL }loginRedirection`,post_data, { headers }).then(response => {

            
            if((response.data) && (response.data.data) && (response.data.data.user_type))
            {  
                if(response.data.data.user_type==1){ window.location.href = "http://myaccount.telsim.com.au/"; }
                if(response.data.data.user_type==2){ this.props.history.push("/"); }
            }
            else
            {
                swal('Unexpected Error', "", 'warning');
            }


        }).catch(error => {
            swal('Invalid Mobile Number', "", 'warning');  
        }); 
    }
   

    chooseNbn = (event) => {
       this.props.history.push("/");
    }




     

render() { 
                
  return (
    <>
    
        <div className="kt-grid kt-grid--ver kt-grid--root">
        <div className="kt-grid__item   kt-grid__item--fluid kt-grid  kt-grid kt-grid--hor kt-login-v2" id="kt_login_v2">
        <GuestHeader/>
        <div className="kt-grid__item  kt-grid  kt-grid--ver  kt-grid__item--fluid common-class">
            <section className="login-block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 login-sec" style={{paddingBottom: '40px' }}>
                            <h2 className="text-center" style={{fontSize: '16px'}}>Verify Your Telsim Mobile Number</h2>
                          
                                <form className="login-form"  onSubmit={this.mySubmitHandler}>
                                    <div className="form-group">
                                        <input type="hidden" className="form-control" placeholder="" defaultValue="" Value=""/>
                                        <label for="exampleInputEmail1" className="text-uppercase">Enter Your Telsim Mobile Number</label>
                                        <input type="text" className="form-control" placeholder="e.g. 0471111111" onChange={this.enterNumber}  required />
                                    </div> 
                                    <div className="form-check" style={{textAlign:'center'}}> 
                                        <button type="submit" className="btn btn-brand btn-elevate btn-pill float-right btn-signin" style={{margin:'0 auto'}}>Verify</button>
                                    </div>
                                </form> 


                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <p className='chooseService'>*If you are an NBN customer, please <span onClick={this.chooseNbn}>click here</span>.</p>

                        </div>


                        <div className="col-md-8 banner-sec">
                            <PublicSlider/>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <GuestFooter/>

        </div>
        </div>     
      
	</>
    )
}
}
export default withRouter(Register);