import React, { Component } from 'react';
import axios from 'axios';
import swal from '@sweetalert/with-react';
import {getApiHeader} from './../../../utils/CommonFunctions';
import Row from 'react-bootstrap/Row';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
// import PlanTabContent from './../plans/planTabContent';
import PlanTabContent from './planTabContent';
import {getApiHeaderGuest} from './../../../utils/CommonFunctions';
import ErrorMsg from './../../public/errorMsg';
import PageLoader from './../../Loader/loader';

const SERVER_URL 	= process.env.REACT_APP_SERVER_URL;

export default class PlanTabs extends Component {

    constructor(props)
    {
        super(props);
        this.state = {
            plan_28: [{}],
            plan_180: [{}],
            plan_365: [{}],
            data_bundle: [{}],
            data_only: [{}],
            idd: [{}],
            credit: [{}],
            ir_plan: [{}],
            selectedPlan: false,
            selectedIdd: false,
            iddPack: null,
            reference_number_applied: false,
            loading: false,
            data_only_status: true,
            tab_active: this.props.activeSection,
            double_data_plan: null,
            double_data_plan_name: null,
            ir_enabled:false
        }
    }

    componentDidMount(){
        this.getPlanList();
    }

    componentWillReceiveProps(nextProps) {
        //alert('plan refreshed if prop received');
        if(this.props.from == "recharge"){
            this.componentDidMount();
        }
    }

    getPlanList =  async () => {
        const {from, customer_id, mobileNumber} = this.props;
        this.setState({loading:true});
        let headers = await getApiHeader();
        let params = {};
        if(from == 'recharge'){
            console.log('recharge', mobileNumber);
            params = {
                mobile_number: sessionStorage.getItem('selected_number')
            }
        }else if(from == 'recharge-a-friend') {
            params = {
                mobile_number: mobileNumber,
                friend_customer_id: customer_id,
                type: from
            } 
        }else if(from == 'guest-recharge') {

            params = {
                mobile_number: sessionStorage.getItem('guest_recharge_mobile_number'),
                guest_customer_id: sessionStorage.getItem('guest_recharge_customer_id'),
                type: from
            } 
            
        }
        
		let res = await axios.post(`${ SERVER_URL }plans/ts-pl`, params, { headers })
        .then(response => {
            this.setState({
                plan_28: response.data.data.plans['28'], plan_180: response.data.data.plans['180'], loading: false,
                plan_365: response.data.data.plans['365'], data_bundle: response.data.data.data_bundle, idd: response.data.data.idd, 
                credit: response.data.data.credit, plan_5g: response.data.data.plans['5g'], data_only: response.data.data.data_only, double_data_plan: response.data.data.double_data_plan,  double_data_plan_name: response.data.data.double_data_plan_name,
                ir_plan: response.data.data.ir_plans,ir_enabled:response.data.data.ir_enabled
            });
            if(response.data.data.data_only.length === 0) {
                this.setState({data_only_status:false}); 
            }
        }).catch((error) => {
            this.setState({ loading: false });
            if (
              typeof error.response.data.message !== "undefined" &&
              typeof error.response.data !== "undefined"
            ) {
                //console.log(error.response.data.message)
            } else {
                //console.log("Internal Server Error")
            }
        });
        
        if(this.props.from === 'activation') {
            try {
                let headers = await getApiHeaderGuest();
                let params = {session_id: sessionStorage.getItem('activation_session_id')};
                let response = await axios.post(`${ SERVER_URL }activation/g-a-p`, params, { headers });
                if((response.data.data) &&(response.data.data.plan) && (response.data.data.plan.plan_id)) {
                    this.setState({selectedPlan: response.data.data.plan.plan_id, reference_number_applied: response.data.data.is_reference_number_applied,is_credit_sim:response.data.data.is_sim_credit_plan}, ()=>{
                        if(response.data.data.is_reference_number_applied) {
                            this.props.setSelectedPlan(response.data.data.plan.plan_id, response.data.data.is_reference_number_applied, response.data.data.plan, response.data.data.reference_number, response.data.data.is_sim_credit_plan);
                        }else if(response.data.data.is_sim_credit_plan) {
                            //console.log(response.data.data.is_sim_credit_plan);
                            sessionStorage.setItem("isCredit", 1)
                            this.props.setSelectedPlan(response.data.data.plan.plan_id, response.data.data.is_sim_credit_plan, response.data.data.plan, response.data.data.reference_number, response.data.data.is_sim_credit_plan);
                        }
                    });
                }
            } catch(err) {
                this.setState({loading:false});
                if(err) {
                    let errorMessage = '';
                    if(err.response && err.response.data && err.response.data.message) {
                        if(typeof(err.response.data.message) === 'object') {
                            swal({
                                title: "Error",
                                content: <ErrorMsg message={err.response.data.message}/>,
                                icon: "error"
                            });
                        } else {
                            errorMessage = err.response.data.message;
                            swal(errorMessage,"error");
                        }
                    } else {
                        errorMessage = err;
                        swal(errorMessage,err);
                    }
                }
            }
            this.setState({loading:false});
        }
        
    }

    addPlan  = async (plan_id) => {
        try 
        {
            let headers = await getApiHeader();
            let params = {session_id: sessionStorage.getItem('activation_session_id'), plan_id: plan_id};
            let response = await axios.post(`${ SERVER_URL }activation/select-plan`, params, { headers });
            swal({
                text: response.data.message,
                icon: "success"})
            .then(() => {
                this.getPlanList();
            });
        } catch(err) {
            this.setState({loading:false});
            if(err) {
                let errorMessage = '';
                if(err.response && err.response.data && err.response.data.message) {
                    if(typeof(err.response.data.message) === 'object') {
                        swal({
                            title: "Error",
                            content: <ErrorMsg message={err.response.data.message}/>,
                            icon: "error"
                        });
                    } else {
                        errorMessage = err.response.data.message;
                        swal(errorMessage,"error"); 
                    }
                } else {
                    errorMessage = err;
                    swal(errorMessage,err);
                }
            }
        }
    }

    changePlan = async (plan, type)=>{
        let plan_id = plan.plan_id;
        if(type === 'plan') {
            await this.setState({selectedPlan:plan_id});
            if(this.props.from === 'activation'){
                this.addPlan(plan.plan_id)
            }else{
                this.getPlanList();
            }
            return plan_id;
        }
        if(type === 'idd') {
            await this.setState({selectedIdd:plan_id});
            this.getPlanList();
            return plan_id;
        }
        return false;
    }

    render() {
        const {mobileNumber, activeStatus, from, goNextStep, activation_id, seletedPlanId, doubleDataPlan, doubleDataPlanName} = this.props;
        return(
            <>
                {(this.state.loading === true) ? <PageLoader/> : null}
                <Row>
                    {(mobileNumber != null && activeStatus != '1' && activeStatus != '9') ?
                        <h5 style={{color: 'red'}}>Mobile Number {mobileNumber} is not active. Your request is in progress. We will send you an email when the activation is complete. If you have any queries, please feel free to contact Telsim Customer Care through chat or email at support@telsim.com.au or call us at 1300-TELSIM.</h5>
                    :
                    <Tabs defaultActiveKey={this.props.tab_active} id="uncontrolled-tab-example" className="mb50">
                        <Tab eventKey="28-day_plan" title="28 Days Plans" >
                            <PlanTabContent idd={this.state.iddPack} changePlan={this.changePlan} selectedPlan={this.state.selectedPlan} type="plan" plan={this.state.plan_28} activeStatus={activeStatus} mobileNumber={mobileNumber} from={from} goNextStep={goNextStep} activation_id={activation_id} seletedPlanId={seletedPlanId} doubleDataPlan={this.state.double_data_plan} doubleDataPlanName={this.state.double_data_plan_name} />
                        </Tab>
                        <Tab eventKey="180-day_plan" title="180 Days Plans">
                            <PlanTabContent idd={this.state.iddPack} changePlan={this.changePlan} selectedPlan={this.state.selectedPlan} type="plan" plan={this.state.plan_180} activeStatus={activeStatus} mobileNumber={mobileNumber} from={from} goNextStep={goNextStep} activation_id={activation_id} seletedPlanId={seletedPlanId} doubleDataPlan={this.state.double_data_plan} doubleDataPlanName={this.state.double_data_plan_name}/>
                        </Tab>
                        <Tab eventKey="365-day_plan" title="365 Days Plans">
                            <PlanTabContent idd={this.state.iddPack} changePlan={this.changePlan} selectedPlan={this.state.selectedPlan} type="plan" plan={this.state.plan_365} activeStatus={activeStatus} mobileNumber={mobileNumber} from={from} goNextStep={goNextStep} activation_id={activation_id} seletedPlanId={seletedPlanId} doubleDataPlan={this.state.double_data_plan} doubleDataPlanName={this.state.double_data_plan_name}/> 
                        </Tab>
                        {((this.state.data_only_status === true)) && (
                            <Tab eventKey="data_only" title="Data Only Plan">
                                <PlanTabContent idd={this.state.iddPack} changePlan={this.changePlan} selectedPlan={this.state.selectedPlan} type="plan" plan={this.state.data_only} activeStatus={activeStatus} mobileNumber={mobileNumber} from={from} goNextStep={goNextStep} activation_id={activation_id} seletedPlanId={seletedPlanId} doubleDataPlan={this.state.double_data_plan} doubleDataPlanName={this.state.double_data_plan_name}/> 
                            </Tab>
                        )}
                        {((from !== 'activation') && (from !== 'add-mobile-plan')) && (
                            <Tab eventKey="idd" title="IDD Add-ons">
                                <PlanTabContent changePlan={this.changePlan} selectedPlan={this.state.selectedIdd} type="idd" plan={this.state.idd} activeStatus={activeStatus} mobileNumber={mobileNumber} from={from} goNextStep={goNextStep} activation_id={activation_id}/> 
                            </Tab>
                        )}
                        {((from !== 'activation') && (from !== 'add-mobile-plan')) && (

                            <Tab eventKey="data_bundle" title="Data Bundle">
                                <PlanTabContent changePlan={this.changePlan} plan={this.state.data_bundle} activeStatus={activeStatus} mobileNumber={mobileNumber} from={from} goNextStep={goNextStep} activation_id={activation_id}/> 
                            </Tab>
                        )}
                        {((from !== 'activation') && (from !== 'add-mobile-plan')) && (
                            <Tab eventKey="pay_as_you_go" title="Extras">
                                <PlanTabContent plan={this.state.credit} activeStatus={activeStatus} mobileNumber={mobileNumber} from={from} goNextStep={goNextStep} activation_id={activation_id}/> 
                            </Tab>
                        )}
                         {((from !== 'activation') && (from !== 'add-mobile-plan')) && (
                            <Tab eventKey="int_roaming" title="International Roaming"> 
                                <PlanTabContent plan={this.state.ir_plan} activeStatus={activeStatus} mobileNumber={mobileNumber} from={from} goNextStep={goNextStep} activation_id={activation_id}/> 
                            </Tab>
                        )}
                    </Tabs>
                    }
                    {/* {(this.props.from === 'activation') ?

                        <div>

                                <input
                                type="checkbox"
                                id={`custom-checkbox-1`}
                                value="1"/>
                                <label htmlFor={`custom-checkbox-1`}>Idd</label>

                        </div>

                    : null} */}
                </Row>
            </>
        );
    }
}