import React, { Component } from "react"; 
import './../../../assets/css/wizards/wizard-v1.css';
import './../../../assets/css/wizards/wizard-v2.css';
import './../../../assets/css/wizards/wizard-v3.css';
import Wizard from './activation/wizard';
import GuestHeader from './../../../components/Layout/GuestHeader';
import GuestFooter from "./../../../components/Layout/GuestFooter";

const SERVER_URL 	= process.env.REACT_APP_SERVER_URL;   

export default class Activatev2 extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            email: '' ,
            password: '' ,
            page: 'activation' ,
        };
    }

    mySubmitHandler = async (event) => {
        event.preventDefault(); 
        const post_data =   { username: this.state.email ,
                                password : this.state.password ,   
                            };
		
    }

    changeEmail = (event) => {
        this.setState({email: event.target.value});
    }

    changePassword = (event) => {
        this.setState({password: event.target.value});
    }

    render() {
       

    
  return (
    <>
            <div className="kt-grid kt-grid--ver kt-grid--root">
            <div className="kt-grid__item   kt-grid__item--fluid kt-grid  kt-grid kt-grid--hor kt-login-v2" id="kt_login_v2">
            {/* <GuestHeader/> */}
            <div className="kt-grid__item  kt-grid  kt-grid--ver  kt-grid__item--fluid" id="activate-your-number">

                <div className="kt-login-v2__body">

                    
                    <div className="kt-portlet">
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div className="kt-grid  kt-wizard-v2 row" id="kt_wizard_v2" data-ktwizard-state="step-first">
                                <div className="kt-grid__item  col-xl-12 col-lg-12 ">

                                    
                                    {/* <div className="kt-wizard-v2__nav">
                                        <div className="kt-wizard-v2__nav-items" style={{padding: '30px'}}>

                                            <a className="kt-wizard-v2__nav-item" href="#" data-ktwizard-type="step" data-ktwizard-state="current" id="activation_tab1">
                                                <span>1</span><i className="fa fa-check"></i> Create New Account
                                            </a>
                                            <a className="kt-wizard-v2__nav-item" href="#" data-ktwizard-type="step"  id="activation_tab2">
                                                <span>2</span><i className="fa fa-check"></i> Your Telsim SIM
                                            </a>
                                            <a className="kt-wizard-v2__nav-item" href="#" data-ktwizard-type="step" id="activation_tab3">
                                                <span>3</span><i className="fa fa-check"></i> Select Plan
                                            </a>
                                            <a className="kt-wizard-v2__nav-item" href="#" data-ktwizard-type="step" id="activation_tab4">
                                                <span>4</span><i className="fa fa-check"></i> Your Mobile Number
                                            </a>
                                            <a className="kt-wizard-v2__nav-item" href="#" data-ktwizard-type="step" id="activation_tab5">
                                                <span>5</span><i className="fa fa-check"></i> Setup Your Profile
                                            </a>
                                            <a className="kt-wizard-v2__nav-item" href="#" data-ktwizard-type="step" id="activation_tab6">
                                                <span>6</span><i className="fa fa-check"></i> Payment Method
                                            </a>
                                            <a className="kt-wizard-v2__nav-item" href="#" data-ktwizard-type="step" id="activation_tab7">
                                                <span>7</span><i className="fa fa-check"></i> ID Validation
                                            </a>
                                            <a className="kt-wizard-v2__nav-item" href="#" data-ktwizard-type="step" id="activation_tab8">
                                                <span>8</span><i className="fa fa-check"></i> Summary & Activation
                                            </a>
                                        </div>
                                    </div> */}
                                    <div className="stepper-wrapper">
                                        <div className="stepper-item completed" data-ktwizard-type="step"  data-ktwizard-state="current" id="activation_tab1">
                                            <div className="step-counter">1</div>
                                            <div className="step-name">Your Telsim SIM</div>
                                        </div>
                                        <div className="stepper-item" data-ktwizard-type="step" id="activation_tab2">
                                            <div className="step-counter">2</div>
                                            <div className="step-name">Select Plan</div>
                                        </div>
                                        <div className="stepper-item" data-ktwizard-type="step" id="activation_tab3">
                                            <div className="step-counter">3</div>
                                            <div className="step-name">Your Mobile Number</div>
                                        </div>
                                        <div className="stepper-item" data-ktwizard-type="step" id="activation_tab4">
                                            <div className="step-counter">4</div>
                                            <div className="step-name">Your Profile</div>
                                        </div>
                                        <div className="stepper-item" data-ktwizard-type="step" id="activation_tab5">
                                            <div className="step-counter">5</div>
                                            <div className="step-name">Payment Method</div>
                                        </div>
                                        <div className="stepper-item" data-ktwizard-type="step" id="activation_tab6">
                                            <div className="step-counter">6</div>
                                            <div className="step-name">ID Validation</div>
                                        </div>
                                        <div className="stepper-item" data-ktwizard-type="step" id="activation_tab7">
                                            <div className="step-counter">7</div>
                                            <div className="step-name">Summary & Activation</div>
                                        </div>
                                        </div>

                                   
                                </div>
                                <div className="kt-section kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper col-xl-12 col-lg-12">

                                    
                                            <Wizard />
                                    

                                </div>
                            </div>
                        </div>
                    </div>


                   
                </div>

            </div>

            {/* <GuestFooter/> */}

            </div>
            </div>

             
	</>
   )
}
}