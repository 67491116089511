import { Component } from "react"
import axios from "axios"
import { withRouter } from 'react-router-dom';
import { getApiHeader } from "../../../utils/CommonFunctions";








const  SERVER_URL = process.env.REACT_APP_SERVER_URL;


class Modem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modem: [],
            needModem: "no",
            modemSelection: false,
            dftModemId: false,
            dftModemDetails: false,

        }
    }

    loadModem = async (value) => {
        const { getDefaultModem, getShippingCharge ,getGstShippingCharge} = this.props
        if (this.state.needModem === 'no' && value === 'yes') {

            const payload = {}
            let headers = await getApiHeader();
            let res = await axios.post(SERVER_URL + 'nbn/get_nbn_products', "", { headers }).then(async response => {
                if (response.status === 200) {
                    this.setState({ modemSelection: response.data.data.products, needModem: "yes" })

                    var arrayLength = response.data.data.products.length - 1;
                    if (response.data.data.products[arrayLength]) {
                        this.setState({ dftModemId: response.data.data.products[arrayLength].nbn_product_id, dftModemDetails: response.data.data.products[arrayLength] })

                        getDefaultModem(response.data.data.products[arrayLength]);
                        getShippingCharge(response.data.data.shipping_charge);
                        getGstShippingCharge(response.data.data.gst_inclusive_shipping_charge)
                    }


                } else {

                    this.setState({ needModem: "no" })


                }

            }).catch(error => {
                this.setState({ needModem: "no" })


            });

        } else if (value === 'yes') {

            this.setState({ needModem: "yes" })
            getDefaultModem(this.state.dftModemDetails);


        } else {
            this.setState({ needModem: "no" })

            getDefaultModem(false);
            getShippingCharge(null);
            getGstShippingCharge(null);

        }

    }


    getDefaultModemHanlder = (modems, modemId) => {
        this.setState({ dftModemId: modemId, })
        this.props.getDefaultModem(modems);

    }



    render() {
        return (
            <div className="container">
                <section className="common-section">
                    <div className="row">
                        <div className="col-md-12"><br />
                            <div className="header-title">
                                <h2 className="h3">3. Would you Like To Include A Modem?</h2>
                                <p style={{ textAlign: 'left' }}>Enhance connectivity by including a modem with your purchase for improved performance.</p>
                            </div>
                            <button className="button-57" role="button" onClick={e => this.loadModem('yes')} id={this.state.needModem == 'yes' ? 'activeModemview' : 'inactiveModemview'}><span className="text">YES</span><span>YES</span></button>
                            <button className="button-57" role="button" onClick={e => this.loadModem('no')} id={this.state.needModem == 'no' ? 'activeModemview' : 'inactiveModemview'}><span className="text">NO</span><span>NO</span></button><br /><br /><br />
                        </div>


                        {this.state.needModem === 'yes' && this.state.modemSelection && this.state.modemSelection.map((modemDetails, i) =>

                            <div key={i} className="col-md-6">
                                <div className="modem-card">
                                    <h3>{modemDetails.nbn_product_name}</h3>
                                    <strong style={{ color: '#777' }}>
                                        <>

                                        </>
                                    </strong>
                                    <hr />
                                    <img src={modemDetails.product_image} width="250px" height="auto" />
                                    <div className="modem-bg">
                                        <h2>{'$' + modemDetails.product_website_sell_amount}<span>/ UPFRONT</span></h2>
                                    </div><br />
                                    <div className="modem-features">
                                        <h6>Features of Modem</h6>
                                        <ul>
                                            {modemDetails.description && modemDetails.description.map((descriptions, j) =>

                                                <li>{descriptions}</li>

                                            )}
                                        </ul>
                                    </div>

                                    <button onClick={e => this.getDefaultModemHanlder(modemDetails, modemDetails.nbn_product_id)}>

                                        {this.state.dftModemId === modemDetails.nbn_product_id ?
                                            <>
                                                ✓ SELECTED
                                            </>
                                            :
                                            <>
                                                SELECT
                                            </>
                                        }


                                    </button>
                                </div>
                            </div>

                        )}

                    </div>
                </section>
            </div>
        )
    }















}

export default withRouter(Modem);
