import React, { Component } from "react";
import CustomerMobileHeader from "./../Layout/CustomerMobileHeader";
import { Route } from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Home from "../ProtectedComponents/Home";
import Navigation from "../Layout/Navigation";
import NbnServiceList from "../ProtectedComponents/NBN/NbnServiceList";
import NbnNavigation from "../Layout/NbnNavigation";
import NbnAccountSummary from "../ProtectedComponents/NBN/NbnAccountSummary";
import NbnMyProfile from "../ProtectedComponents/NBN/NbnMyProfile";
import NbnMyPayment from "../ProtectedComponents/NBN/NbnMyPayment";
import Activatev2 from "../ProtectedComponents/NBN/Activatev2";
import NbnChangePlan from "../ProtectedComponents/NBN/NbnChangePlan";
import swal from "sweetalert";
import ForbiddenPage from "../ForbiddenPage";
import { getToken } from "../../utils/CommonFunctions";
import ChangePassword from "../ProtectedComponents/ChangePassword";

export default class NBNProtectedComponents extends Component {

    isPerimissionForAccess = () => {
        const isPerimissionForAccessUrl = sessionStorage.getItem('is_nbn');
        return isPerimissionForAccessUrl  
    }

    render() {
        const isPerimissionForAccessUrl = this.isPerimissionForAccess();
        if (isPerimissionForAccessUrl ==='0') {
           return <ForbiddenPage></ForbiddenPage>
        }
        return <>
            <CustomerMobileHeader />
            <div className="kt-grid kt-grid--hor kt-grid--root">
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                    <NbnNavigation />
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                        <Header />
                        <Route path="/nbn_services" exact component={() => <NbnServiceList/>} />
                          <Route path="/nbn-my-account-summary" exact component={() => <NbnAccountSummary/>} />
                          <Route path="/nbn_my_profile" exact component={() => <NbnMyProfile/>} />
                          <Route path="/nbn_my_payments" exact component={() => <NbnMyPayment/>} />
                          <Route path="/Activatev2" exact component={() => <Activatev2/>} />
						  <Route path="/nbn_change_password" exact component={() => <ChangePassword />} /> 
                          <Route path="/nbn_change_plan" exact component={() => <NbnChangePlan/>} />
                          
                        <Footer />
                    </div>
                </div>
            </div>
            <div id="kt_scrolltop" className="kt-scrolltop">
                <i className="la la-arrow-up"></i>
            </div>
        </>
    }
}