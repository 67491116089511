import React, { Component } from "react";
import DatePicker from 'react-date-picker';
import PlanData from './PlanData';
import ReservedNumbers from './ReservedNumbers';
import axios from 'axios';    
import swal from '@sweetalert/with-react';
import {getApiHeader, getApiHeaderGuest, removeUserSession} from '../../utils/CommonFunctions';
import { Link } from "react-router-dom";
import PageLoader from './../Loader/loader';
import PlanTabs from './../public/plans/planTabs';
import Plan from './../public/plans/plan';
import ErrorMsg from './../public/errorMsg';
import {withRouter} from "react-router-dom";
import { WidgetInput } from "react-addressfinder";
import SimpleReactValidator from 'simple-react-validator';

import Modal  from 'react-bootstrap/Modal';
import DropIn from "braintree-web-drop-in-react";
import Button  from 'react-bootstrap/Button';
import MyPayments from './../ProtectedComponents/MyPayments';
import esim_image from './../../assets/media/esim/esim.png';
import physical_image from './../../assets/media/esim/physical.png';

const SERVER_URL    = process.env.REACT_APP_SERVER_URL;   
const headers       = getApiHeader();
const isValidNo = /^[0-9]*$/;

const AddressFinder = ({ addressFinderKey, inputClassName, id ,onChange}) => {
    return(
      <WidgetInput
        addressFinderKey={addressFinderKey}
        inputClassName={inputClassName}
        id={id}
        onSelected={(fullAddress) => {
           onChange(fullAddress);
        }}
      />
      )
}

class AddaMobilePlan extends Component {

     constructor(props) {
        super(props);
        this.email = React.createRef();


        let today   = new Date();
        let dd      = today.getDate();
        let mm      = today.getMonth() + 1; //January is 0 so need to add 1 to make it 1!
        let yyyy    = today.getFullYear();
        let maxyyyy = yyyy - 16;
        let minyyyy = yyyy - 110;



        this.validator = new SimpleReactValidator();
        
        this.state = { 
            firstName: '' ,
            customer_profile: [],
            customer_address: [],
            sim_number :'',
            ref_number :'',
            reserved :'2',
            verified :false,
            existotpcheck :'0',
            reservedcheck :'0',
            enteroperator :'0',
            seletedPlanId:null,
            reference_number_applied:false,
            reference_number_plan:null,
            reference_number:null,
            assigned_service_number:null,
            activation_attempt_session_id:null,
            display_service_number:false,
            existing_otp:null,
            otp_session:null,
            amountDue:'0.00',
            reservationId:null,
            mobile_selection_type:'new',
            service_type:null,
            port_acc_id:null,
            providers_list:[],
            provider_list_fetch:false,
            mobile_provider:"",
            other_provider_div:false,
            port_other_provider:"",
            port_dob:"",
            agree_customer:false,
            disabled_btn:false,
            is_credit_sim:false,
            model_visibility:false,
            sim_field_disable:false,
            sim_email:'',
            oldsim:0,
            sim_fname:'',
            sim_lname:'',
            address_full:'',
            enquiry_id:'',
            port_type:'block',
            new_type:'block',
            isChecked:false,
            simMessage:"Choose if you'd like to get a new number, or port your number from your current provider",
            takefull_amount:null,
            port_in_check_message:null,
            payable_amount: null,
            maxdob:new Date(new Date().setFullYear(new Date().getFullYear()-16)),
            mindob:new Date(new Date().setFullYear(new Date().getFullYear()-110)),
            offerPlan:false,
            offerPlanAmount:0,
            simType: 'physical',
            simVoucher: '',
            idType: '',
            passportCountry:'',
            passportExpiry:'',
            passportNumber:'',
            licenceNumber:'',
            licenceCardNumber:'',
            licenceState:'',
            licenceExpiry:'',
            commonDOB:'',
            commonGender:'',
            medicareCardNumber:'',
            FirstName:'',
            MiddleName:'',
            LastName:'',
            medicareIRN:'',
            medicareColour:'',
            medicareExpiry:'',
            dvsCheck:'',
            customerror:'',
            all_countries:[],
            maxDvsDob: new Date(`${maxyyyy}-${mm}-${dd}`),
            minDvsDob: new Date(`${minyyyy}-${mm}-${dd}`),
            mandatoryCard : true,
            numberSelected: false,
            existing_mobile:null,
            numberSelectionVerified:false,
            hideNext:true,
            iDverified:false,
            disableVerify:false,
            dvs_enabled:false,
            businessMessage:null,
            resendOtp:true,
            maskMobile:false,
            portInMobile:null,
            simCode: ''
        };
    }



     componentDidMount() {
        // alert(this.state.maxdob);
        this.getUserStatus();
        this.getProfile();
        this.getCountry();
    }















    //dvs section----------------------------------- ----------------------------------------------------- start

    getCountry = async () => {
        let headers = await getApiHeader();
        let res         = await axios.get(`${ SERVER_URL }getCountryDetails`, { headers });
        let countrydata        = res.data.data.countries;
        this.setState({ all_countries: countrydata }); 
    };



        changeID = async (type) => { 
        console.log(type);
        this.setState({ idType: type});
        const post_data ={
            id_type : type
        };
        if(type!=''){
            let headers     = await getApiHeaderGuest();
            let res         = await axios.post(`${ SERVER_URL }activation/verification-type-images`,post_data, { headers }).then(response => {
            let passport    = response.data.data.passport;
            let medicare    = response.data.data.medicare;
            this.setState({ image: ''});
            if(passport){
                    if(passport[0].id_type){
                        this.setState({ image: passport[0].image});
                    } 
                }
                if(medicare){
                    if(medicare[0].id_type){
                        this.setState({ image: medicare[0].image});
                    }
                }
            });
        }else{
            this.setState({ image: ''});
        }
    }






    // personal

    firstName  = (event) => { this.setState({ FirstName: event.target.value});}
    lastName   = (event) => { this.setState({ LastName: event.target.value});}
    middleName = (event) => { this.setState({ MiddleName: event.target.value});}

    // Passport
    passportCountry = (country) => { this.setState({ passportCountry: country});}
    passportNumber = (event) => { this.setState({ passportNumber: event.target.value});}
    // passportExpiry = (value) => { this.setState({ passportExpiry: value});}
    // Passport


     // Licence
    licenceNumber = (event) => { this.setState({ licenceNumber: event.target.value});}
    licenceCardNumber = (event) => { this.setState({ licenceCardNumber: event.target.value});}
    licenceState = async (value) => { 
        this.setState({ licenceState: value});

        if(value == 'QLD' || value == 'VIC'){
        this.setState({ mandatoryCard: false});
        }else{
        this.setState({ mandatoryCard: true});
        }
        const post_data ={
            id_type : 2,
            state_value : value,
        };
        if(value!=''){
            let headers     = await getApiHeaderGuest();
            let res         = await axios.post(`${ SERVER_URL }activation/verification-type-images`,post_data, { headers }).then(response => {
            let driving    = response.data.data.driving;
            this.setState({ image: ''});
                if((driving.length)>0){               
                    this.setState({ image: driving[0].image});               
                }
            });
        }else{
            this.setState({ image: ''});
        }
    }
    // licenceExpiry = (value) => { this.setState({ licenceExpiry: value});}
    commonDOB = (value) => { this.setState({ commonDOB: value});}
    commonGender = (gender) => { this.setState({ commonGender: gender});}
    // Licence



    //medicare
    medicareCardNumber = (event) => { this.setState({ medicareCardNumber: event.target.value});} 
    medicareIRN = (event) => { this.setState({ medicareIRN: event.target.value});}
    medicareColour = (value) => { this.setState({ medicareColour: value});}
    medicareExpiry = (value) => { this.setState({ medicareExpiry: value});}
    //medicare


    dvsCheckbox = (check) => { this.setState({ dvsCheck: check});}





    myDVSHandler = async (event) => { 
        event.preventDefault();  
        this.setState({loading:true});

        swal({ 
            text: "Please confirm the ID details provided are exactly matching with the ID document. Incorrect ID details will block your sim and delay the service activation. ",
            icon: "warning", 
            dangerMode: true,
            buttons: {
                confirm : {text:'Yes, Proceed!',className:'sweet-warning'},
                cancel : 'No, Review!'
            }
        })
        .then(async (willDelete) => {
            if (willDelete) {
                if(this.state.idType == 3){
                  var expDate = this.state.medicareExpiry;
                  const today = Date.now();
                  if(today > new Date(expDate)){
                    swal({
                       // title: "Error",
                        text: "Past date cannot be accepted",
                        icon: "error"
                    });
                    this.setState({loading: false});
                    return;
                  }
                    //console.log('myDVSHandler ==>', today, new Date(expDate), (today > new Date(expDate)))
                }
                this.setState({loading:true});
                try
                {
                    if(this.state.dvsCheck==true)
                    {
            
            
                        const post_data =   {
                            dvsCheck : this.state.dvsCheck,
                            service_type : this.state.idType,
                            sim : this.state.sim,
                            firstName  : this.state.FirstName,
                            lastName   : this.state.LastName,
                            middleName : this.state.MiddleName,
                            passportCountry : this.state.passportCountry,
                            passportNumber : this.state.passportNumber,
                            licenceNumber : this.state.licenceNumber,
                            licenceCardNumber : this.state.licenceCardNumber,
                            licenceState : this.state.licenceState,
                            commonDOB : this.state.commonDOB,
                            commonGender : this.state.commonGender,
                            medicareCardNumber : this.state.medicareCardNumber,
                            medicareIRN : this.state.medicareIRN,
                            medicareColour : this.state.medicareColour,
                            medicareExpiry : this.state.medicareExpiry,
                            session_id : this.state.activation_attempt_session_id,
                        };
                        
                        let headers     = await getApiHeader();
                        let res         = await axios.post(`${ SERVER_URL }verifyAddPlanDvs`,post_data, { headers }).then(response => {
    
                            this.setState({ customerror: ''});
                            if(response.status == 200){
                                this.setState({iDverified: true});
                                swal("ID Verification", response.data.message, "success");
                            }else{
                                swal({
                                    title: "Error",
                                    content: response.message,
                                    icon: "error"});
                            }
                            
                            
                        }).catch(error => {
                            console.log(error);
                            if((typeof(error.response.data.message) !== 'undefined') && (typeof(error.response.data) !== 'undefined'))
                            {
                
                               this.setState({ customerror: error.response.data.message});

                               if(error.response.data.data.dvs_stopped != 'undefined'){
                                    if(error.response.data.data.dvs_stopped == true){
                                            this.setState({ disableVerify: true});
                                    }
                               }

                            }
                            else
                            {
                                this.setState({ customerror: "Internal Server Error"});
                            }
                
                            
                        });
            
                    }
                    else{
                        this.setState({ customerror: "Please Confirm CheckBox"});
                    }

                }
                catch(err)
                {
         
        
                    if(err)
                    {
                        let errorMessage = '';
        
                        if(err.response && err.response.data && err.response.data.message)
                        {
                            if(typeof(err.response.data.message) === 'object')
                            {
        
                                swal({
                                    title: "Error",
                                    content: "Error occurred in delete payment method",
                                    icon: "error"});
                
                            }
                            else
                            {
                                errorMessage = err.response.data.message;
                                swal(errorMessage,"error");
                                
                            }
                        }
                        else
                        {
                            errorMessage = err;
                            swal(errorMessage,err);
                        }
            
        
        
                    }
                    
        
                }
            


        
                this.setState({loading:false});
                
                
            } else {
                swal("Cancelled", "", "success");
                this.setState({loading:false});
            }
        });


        
    }




    numberSelectionVerify = (event)=>{
        if(this.state.service_type === 'Post-paid'){
            if(this.state.port_acc_id == null){
                swal("Please enter valid account number", "", "error");
            }else{
                this.setState({numberSelectionVerified: true});
                this.setState({hideNext: false});
            }
        }else{
            this.setState({numberSelectionVerified: true});
            this.setState({hideNext: false});

        }
    }



    //dvs section----------------------------------- ------------------------------------------------------- end
























    getUserStatus = async () => {
        let headers     = await getApiHeader();
        let res         = await axios.get(`${ SERVER_URL }user_status`, { headers }).then(async response => {
            
            if(response.data.data.forceLogout){
                if(response.data.data.forceLogout != 'undefined'){
                    if(response.data.data.forceLogout == '1'){
                        this.handleLogout();
                    }
                }
            }
            
        }).catch(error => {            
        });
 
    };


     handleLogout = async () => {  
        let p = new Promise(async (resolve, reject)=> {
            let headers     = await getApiHeader();
            let res         = await axios.get(`${ SERVER_URL }logout`, { headers });
            let data        = res.data.data;   
            console.log(data);
            removeUserSession();  
            resolve();

        });

        p.then(
            ()=>{ 
                    this.props.history.push("/login");
            },
            ()=>{
                alert("Error in logout");
            }
        )
    }

     // force logout --------------------------------------


   setActivationType = async () => {

    if(this.state.display_service_number == true)
        {
            // sim type 25-03-2022 - set state above.
            if(this.state.sim_activation_type=="new"){  this.state.port_type = 'none';  this.state.simMessage = 'Get a new number for your Telsim Service'; this.numberType(1); }
            if(this.state.sim_activation_type=="port_in"){ this.state.new_type = 'none';  this.state.isChecked=true; this.state.simMessage = 'Port your number from current provider'; this.numberType(0);  }
            // sim type 25-03-2022
        }

   };




    getProfile = async () => {
        let headers     = await getApiHeader();
        let res         = await axios.get(`${ SERVER_URL }profile`, { headers });
        let profile_data        = res.data.data.profile;
        let address_data        = res.data.data.address;
        let dvs_status          = res.data.data.dvs_status;
        let businessMessage     = res.data.data.businessMessage;
        console.log(res.data.data.profile);
        this.setState({ customer_profile: profile_data });
        this.setState({ customer_address: address_data });
        this.setState({ businessMessage: businessMessage });
        if(dvs_status == true){
            this.setState({ dvs_enabled: true });
        }

    };

    // Sim number verfification

    simnumber = (event) => {
        if(event.target.value.match(isValidNo)){
            this.setState({ sim_number: event.target.value});
        }else{
            console.log('false', event.target.value);
        }
    }

    simVoucherCheck = (event) => {
        if(event.target.value.match(isValidNo)){
            this.setState({ simVoucher: event.target.value});
        }else{
            console.log('false', event.target.value);
        }
    }

    simCodeCheck = (event) => {
        const value = event.target.value;
        const maxLength = 25;
        if (value.length > maxLength) {
            return; 
        }
        this.setState({ simCode: value });
    }

    simnumcheck = async (event) => {
        
        if(this.state.simType == 'physical' && this.state.sim_number == ''){
            return swal({text: 'Please enter a valid SIM number', icon: "warning"});
        }
        if(this.state.simType == 'esim' && this.state.simCode == ''){
            return swal({text: 'Please enter a valid activation code', icon: "warning"});
        }
        this.setState({loading:true, reference_number_applied: false});
        const post_data =   {   
            sim: this.state.simType == 'physical' ? this.state.sim_number : (this.state.simVoucher == '' ? "" : this.state.simVoucher),
            sim_type: this.state.simType == 'physical' ? 'Physical Sim' : 'eSIM',
            session_id:this.state.activation_attempt_session_id,
            sim_code : this.state.simCode
        };

        try
        {

            let headers     = await getApiHeader();
            let res         = await axios.post(`${ SERVER_URL }add-a-mobile-plan/verify-sim-number`,post_data, { headers });
            let data        = res.data;

            //==credit sim==//

                //12-05-22
                sessionStorage.setItem("showBonus", 0); 

                // 25-03-2022
                if(data.data.simtype){
                    var act_type=data.data.simtype;
                    this.setState({sim_activation_type:act_type});
                    // 10-05-22  activation sim type check
                    if(act_type=="port_in"){ sessionStorage.setItem("showBonus", 1); }
                    // 10-05-22
                }
                // 25-03-2022

                
                // 12-04-2022
                if(data.data.bonus_allow){
                    if(data.data.bonus_allow=="1"){
                     sessionStorage.setItem("showBonus", 1); }
                }
                // 12-04-2022



                this.setState({sim_field_disable:true});

                if(data.data.is_credit_plan === true)
                {

                    this.setState({is_credit_sim: true, verified: true, activation_attempt_session_id:data.data.session_id, display_service_number:true, reserved: '1'}, ()=>{

                        if((res.data.data) && (data.data.plan) && (data.data.plan.plan_id))
                        {

                            this.setState({reference_number_applied:true, reference_number_plan: data.data.plan, seletedPlanId: data.data.plan.plan_id, amountDue:'0.00', display_service_number:true, loading:false},()=>{

                                this.reserveTelsimServiceNumber();
                                this.setActivationType();
                            
                            });
                        }
                        else
                        {
                            this.setState({loading:false}, ()=>{

                                swal({
                                    text: "Error in fetch credit SIM plan.",
                                    icon: "error"});

                                });
                        }


                        

                    });
                }

            //==credit sim==//

                else
                {
                    
                    this.setState({ is_credit_sim: false, verified: true, reserved: '1', loading:false, activation_attempt_session_id:data.data.session_id}, ()=>{

                        this.reserveTelsimServiceNumber();
                        this.setActivationType();
        
                    });


                    // activation $40 to 30
                    if(data.data.has_offerPlan !='undefined' && data.data.has_offerAmount !='undefined'){
                        if(data.data.has_offerPlan.length > 0){
                            console.log(data.data.has_offerPlan)
                        }
                        if(data.data.has_offerPlan.length > 0 && data.data.has_offerAmount !='0'){
                            this.setState({offerPlan: data.data.has_offerPlan, offerPlanAmount: data.data.has_offerAmount});
                        }
                    }



                }

        }
        catch(err)
        {

            this.setState({loading:false});

            let errorstatus = '';
            if(err.response.data.data.error_status)
            {
            errorstatus = JSON.parse(err.response.data.data.error_status);
            }
            if(err.response.data.message)
            {
            const error_msg = JSON.stringify(err.response.data.message);
            }
            if(errorstatus == 1)
            { 
                const email = JSON.stringify(err.response.data.data.email);
                const emailid = email.slice(1, -1);
                this.setState({ sim_email: emailid}); 
                this.setState({ oldsim: 1});   
            }

            if((err) && (err.response) && (err.response.data) && (err.response.data.status))
            {

                if((err) && (err.response) && (err.response.data) && (err.response.data.message))
                {

                    if((err) && (err.response) && (err.response.data) && (err.response.data.message))
                    {

                        swal({
                            text: err.response.data.message,
                            icon: "error"}).then(()=>{

                                this.props.history.push("/MyPayments");

                            });

                        return;

                    }

                }

            }

            if((err) && (err.response) && (err.response.data) && (err.response.data.message))
            {
                swal({
                    text: err.response.data.message,
                    icon: "warning"});
                    
                return;

            }
            else
            {
                swal({
                    text: "Error in verify SIM",
                    icon: "error"});

                return;

            }
            

        }
        
    }

    // end Sim number verfification

    // refernce number check

    refnumber = (event) => 
    {
        if(event.target.value.match(isValidNo)){
            this.setState({ref_number: event.target.value, loading: false});
        }else{
            console.log('false', event.target.value);
        }
        //this.setState({ref_number: event.target.value});
    }

    refnumcheck = async (event) => {   

        this.setState({loading:true, reference_number_applied: false});

        const post_data =   {   
                                session_id:this.state.activation_attempt_session_id,
                                reference_number: this.state.ref_number,
                                sim_type: this.state.simType == 'physical' ? 'Physical Sim' : 'eSIM',
                            };
                            console.log(post_data);

        try
        {
            let headers     = await getApiHeader();
            let res         = await axios.post(`${ SERVER_URL }activation/verify-reference-number`, post_data, { headers });
            // let data        = res.data;
            console.log('verify-reference-number ==>', res);

            if((res.data.data) && (res.data.data.plan) && (res.data.data.plan.plan_id))
            {
                this.setState({reference_number_applied: true, reference_number_plan: res.data.data.plan, seletedPlanId: res.data.data.plan.plan_id},()=>{
                    this.setState({amountDue:'0.00', display_service_number: true});

                    if(this.state.sim_activation_type=="port_in"){ this.state.new_type = 'none';  this.state.isChecked=true; this.state.simMessage = 'Port your number from current provider'; this.numberType(0);  }
                });
                // this.setState({seletedPlanId:plan_id});
            }

            this.setState({loading:false});
        }
        catch(err)
        {

            this.setState({loading:false});

            if(err)
            {
                
                let errorMessage = '';

                if(err.response && err.response.data && err.response.data.message)
                {
                    if(typeof(err.response.data.message) === 'object')
                    {

                        swal({
                            title: "Error",
                            content: <ErrorMsg message={err.response.data.message}/>,
                            icon: "error"});
        
                    }
                    else
                    {
                        errorMessage = err.response.data.message;

                        swal({
                            title:errorMessage,
                            icon:"warning"
                        });
                        
                    }
                }
                else
                {
                    errorMessage = err;
                    swal({
                        title:errorMessage,
                        icon:"warning"
                    });
                }
    


            }
            

        }

    }

    // end refernce number check

    // check existing number or new number 'get new number & keep my number'

    numberType(type) { 
    
        if(type == 1)
        {
            this.setState({ reserved: '1', mobile_selection_type: 'new', existotpcheck: '0', reservedcheck: '0', numberSelected:true});             
            this.reSetreSendOtp();
        }
        if(type == 0)
        {
            if(this.state.provider_list_fetch === false)
            {
                this.getProviderList();   
            }

            this.setState({ reserved: '0', mobile_selection_type: 'port', provider_list_fetch: true, existotpcheck: '0', reservedcheck: '0', numberSelected:false}); 
            
        }

    }
    // check existing number or new number 'get new number & keep my number'

    // for send otp - existing Mobile Verification

    existingmobile = (event) => {
        this.setState({existing_mobile: event.target.value});
        this.setState({portInMobile: event.target.value});
    }


    reSetreSendOtp() { 
            this.setState({resendOtp:true});
            this.setState({maskMobile: false});
    }
                                                                       

    requestOtp = async (event) => {
        
        const re = /^[0-9\b]+$/;
        if(this.state.existing_mobile != null && this.state.portInMobile != null){
            if(this.state.existing_mobile.toString().length == 10 && re.test(this.state.existing_mobile))
            {

                this.setState({ existotpcheck: '0' });
                const post_data =   {  
                                        session_id:this.state.activation_attempt_session_id,
                                        mobile_number:this.state.portInMobile
                                    };
                                    console.log(post_data);
                let headers     = await getApiHeader();

                try
                {
                    let res         = await axios.post(`${ SERVER_URL }activation/port-request-otp`,post_data, { headers });
                    let data        = res.data;  

                    if(data.data.takefull_amount ==1)
                    {
                        this.winbackVerify(data);
                    }
                    else
                    {
                      this.setState({ existotpcheck: '1', otp_session:data.data.otp_token, takefull_amount:data.data.takefull_amount, port_in_check_message:data.data.port_in_check_message, payable_amount:data.data.payable_amount });
                    }

                    this.setState({resendOtp:false});
                    setTimeout( ()=>{
                    this.setState({resendOtp:true});
                    }, 180000);

                }
                catch(err)
                {
                    this.setState({loading:false});

                    if(err)
                    {
                        
                        let errorMessage = '';

                        if(err.response && err.response.data && err.response.data.message)
                        {
                            if(typeof(err.response.data.message) === 'object')
                            {

                                swal({
                                    title: "Error",
                                    content: <ErrorMsg message={err.response.data.message}/>,
                                    icon: "error"});
                
                            }
                            else
                            {
                                errorMessage = err.response.data.message;

                                swal({
                                    title:errorMessage,
                                    icon:"warning"
                                });
                                
                            }
                        }
                        else
                        {
                            errorMessage = err;
                            swal({
                                title:errorMessage,
                                icon:"warning"
                            });
                        }
            


                    }
                }
            }
            else
            {


                                swal({
                                    title: "Enter Valid Mobile Number",
                                    icon: "error"});


            }
        }
        else
        {


                            swal({
                                title: "Enter Valid Mobile Number",
                                icon: "error"});


        }

    }
    // for send otp - existing Mobile Verification

    // for verify otp - existing Mobile Verification

    existotp = (event) => {
        this.setState({existing_otp: event.target.value});
    }

    verify_OTP() { 
    this.verifyOTP();   
    }

    verifyOTP = async (event) => {  

        const post_data =   {   mobile_number: this.state.portInMobile,
                                otp:this.state.existing_otp,
                                session_id:this.state.otp_session,
                                act_session_id:this.state.activation_attempt_session_id

                            };
                            console.log(post_data);

        try
        {
            let headers     = await getApiHeader();
            let res         = await axios.post(`${ SERVER_URL }activation/port-otp-verify`,post_data, { headers });
             
            this.setState({maskMobile: true});

            this.setState({existing_mobile: this.state.portInMobile});
            this.setState({ reservedcheck: '1' }); 
            swal({
                title:res.data.message,
                icon:"success"
            });
            
        }
        catch(err)
        {
            this.setState({loading:false});

            if(err)
            {
                
                let errorMessage = '';

                if(err.response && err.response.data && err.response.data.message)
                {
                    if(typeof(err.response.data.message) === 'object')
                    {

                        swal({
                            title: "Error",
                            content: <ErrorMsg message={err.response.data.message}/>,
                            icon: "error"});
        
                    }
                    else
                    {
                        errorMessage = err.response.data.message;

                        swal({
                            title:errorMessage,
                            icon:"warning"
                        });
                        
                    }
                }
                else
                {
                    errorMessage = err;
                    swal({
                        title:errorMessage,
                        icon:"warning"
                    });
                }
    


            }
        }


    }

    // end for verify otp - existing Mobile Verification





      //winback full amount yes or no
        winbackVerify = (data)=>{
          

          //alert(data);

         swal({
                    buttons: {
                    cancel: true,
                    confirm: {text: 'Yes'},
                    },
                    title: "",
                    text: data.data.port_in_check_message,
                    dangerMode: true,
                    })
                    .then(willDelete => {

                        if (willDelete) {
                           this.setState({ existotpcheck: '1', otp_session:data.data.otp_token, takefull_amount:data.data.takefull_amount, port_in_check_message:data.data.port_in_check_message, payable_amount: data.data.payable_amount });
                        }
                        else
                        {
                            // no action
                        }

                    });


    };











    goNextStep = (plan)=>{

        let plan_id = plan.plan_id;
        let plan_added = true;
        if(this.state.seletedPlanId == null)
        {
            plan_added = false;
        }
        if(this.state.offerPlan.length > 0){
            var isExisted = this.state.offerPlan.some((item)=>plan_id == item);
            if(isExisted == true){
                this.setState({seletedPlanId:plan_id, amountDue:this.state.offerPlanAmount},()=>{
                    this.setState({display_service_number:true});
                });
            }else{
                this.setState({seletedPlanId:plan_id, amountDue:plan.amount},()=>{
                    this.setState({display_service_number:true});
                });
            }
        }else {
            this.setState({seletedPlanId:plan_id, amountDue:plan.amount},()=>{
                this.setState({display_service_number:true});
            });
        }
        this.setActivationType();
        return;
        
    }

    // fetch service number //

        reserveTelsimServiceNumber = async ()=>{


            let seletedPlanId                   = this.state.seletedPlanId;

            this.setState({loading:true});
        
            let post_data =   {   sim: this.state.sim_number, session_id: this.state.activation_attempt_session_id, seletedPlanId:seletedPlanId
                                };
                                console.log(post_data);
            try
            {
     
                const API_TOKEN     = process.env.REACT_APP_API_TOKEN;
                const headers       =   { 
                                        'Authorization': 'Bearer '+sessionStorage.getItem('token'),
                                        'api-token': API_TOKEN ,
                                        'session-id':this.state.activation_attempt_session_id,
                                        'process-type' :"Add A Mobile Plan"
                                    };
                let res         = await axios.get(`${ SERVER_URL }activation/fetch-numbers`, { headers });
                let data        = res.data;


                if(data.data.numbers.sessionExpired != 'undefined'){
                    if(data.data.numbers.sessionExpired == true){

                        var fetchMsg = "Session expired, Please try again";
                        if(data.message){
                            fetchMsg = data.message;
                        }


                        swal({text: fetchMsg, icon: "error"});
                        this.props.history.push("/home");
                        return;
                        
                    }
                }



                this.setState({assigned_service_number:data.data.numbers.mobile_number,reservationId:data.data.numbers.reservationId, loading:false,numberSelected: true});

                
    
            }
            catch(err)
            {
                this.setState({loading:false});
    
                swal({
                    text: "Error in fetch service number",
                    icon: "error"});
                
    
            }

        }          

    // fetch service number //


    //select service number //

        selectTelsimServiceNumber = async ()=>{

            this.setState({loading:true});

            let other_provider = this.state.other_provider;

            if(this.state.other_provider == null)
            {
                other_provider = this.state.mobile_provider;
            }

            let post_data = {
                                existing_number:this.state.existing_mobile,
                                mobile_provider: this.state.mobile_provider,
                                mobile_selection_type:this.state.mobile_selection_type,
                                other_provider: this.state.port_other_provider,
                                port_acc_id:this.state.port_acc_id,
                                port_dob:this.state.port_dob,
                                port_service_type:this.state.service_type,
                                reservationId:this.state.reservationId,
                                select_number:this.state.assigned_service_number,
                                session_id:this.state.activation_attempt_session_id
                            };

            
            try
            {
                
    
                let headers     = await getApiHeader();
                let res         = await axios.post(`${ SERVER_URL }activation/select-your-number`,post_data, { headers });
                let data        = res.data;

                return true;
    
            }
            catch(err)
            {
                this.setState({loading:false});
    
                swal({
                    text: "Error in fetch service number",
                    icon: "error"});
                
    
            }

        }

    //select service number //

    selectServiceType = (event)=>
    {

        this.setState({service_type: event.target.value});
        this.setState({numberSelected: false});

    }

    accountIdChange = (event)=>{
        this.setState({port_acc_id: event.target.value});
        if(event.target.value != null){
            this.setState({ numberSelected: true });
        }else{
            this.setState({ numberSelected: false });
        }
    }

    getProviderList = async () => {
        let headers 	= await getApiHeaderGuest();
        let res 		= await axios.get(`${ SERVER_URL }activation/fetch-mobile-providers`, { headers });
        let data 		= res.data.data.providers;  
            
        this.setState({ providers_list: data });   
    };

    getProvider =  (event) => 
    {  
        let mobile_provider = event.target.value;
        this.setState({ mobile_provider: mobile_provider});  
        if(mobile_provider=="Other")
        {  
            this.setState({ other_provider_div: true}); 
        }
        else
        { 
            this.setState({ other_provider_div: false}); 
        }
    };

    getOtherProvider =  (event) => {  
        this.setState({ port_other_provider: event.target.value });  
    };

    getPortDob =  (value) => {  
        this.setState({ port_dob: value});  
        if(value !=null){
            this.setState({ numberSelected: true });
        }else{
            this.setState({ numberSelected: false });
        }
    };

    submitHandler = async (event)=>
    {
        event.preventDefault();

        if(this.state.seletedPlanId === null)
        {
          
            swal({
                text: "Select a plan to continue.",
                icon: "warning"});

            return;
            
        }

        if(this.state.mobile_selection_type === 'port')
        {
            console.log("mobile_provider", this.state.mobile_provider)
            
            if((this.state.mobile_provider == '') || (this.state.mobile_provider === null)){
                swal({
                    text: "Select mobile provider to continue.",
                    icon: "warning"});

                return;
            }else if(this.state.mobile_provider == 'Other' && (this.state.port_other_provider == '' || this.state.port_other_provider === null)){
                swal({
                    text: "Enter mobile provider name to continue.",
                    icon: "warning"});

                return;
            }

            if((this.state.service_type === '') || (this.state.service_type === null))
            {
                swal({
                    text: "Select service type to continue.",
                    icon: "warning"});

                return;
            }

            if((this.state.service_type === 'Pre-paid') && ((this.state.port_dob === null) || (this.state.port_dob === '')))
            {
               
                swal({
                    text: "Enter date of birth to continue port in.",
                    icon: "warning"});

                return;
                
            }

            if((this.state.service_type === 'Post-paid') && ((this.state.port_acc_id === null) || (this.state.port_acc_id === '')))
            {
                
                swal({
                        text: "Enter account id to continue port in.",
                        icon: "warning"});

                return;

            }

        }


        if(this.state.agree_customer === false)
        {

            swal({
                     text: "Agree terms and conditions to continue.",
                     icon: "warning"});

            return;

        }

        this.setState({disabled_btn: true, loading: true});

        let success = false;

        if(this.state.is_credit_sim !== true)
        {
            success = await this.addPlan();
        }
        else
        {
            success = true;
        }



        if(success === true)
        {
            success = await this.selectTelsimServiceNumber();
        }
        if(success === true)
        {
            success = await this.paymentSubmit();
            
            if((success === 'add-payment') || (success === 'payment-failed'))
            {
                return;
            }
            
        }

        

        if(success === true)
        {
            success = await this.activate();
        }

        if(success === true)
        {

            return;

            // this.setState({loading:false}, ()=>{

            //     swal({
            //         text: "Activation completed successfully.",
            //         icon: "success"});

            // });
            
        }

        if(success == true)
        {

            this.setState({loading:false}, ()=>{

                swal({
                    text: "Your Telsim SIM activation request is submitted. You will receive an email once the activation is completed.",
                    icon: "success"}).then(()=>{
                        window.location.reload();
                    });

            });

        }else{

             this.setState({loading:false}, ()=>{

                swal({
                    text: "Your Telsim SIM activation request failed. Please Contact Telsim Support.",
                    icon: "error"}).then(()=>{
                        window.location.reload();
                    });

            });

        }

    }


    paymentSubmit = async ()=>{

        this.setState({loading:true});

        let post_data = {
                            termsCheck:this.state.agree_customer,
                            session_id:this.state.activation_attempt_session_id,
                            auto_renew:true,
                            takefull_amount:this.state.takefull_amount,
                            payable_amount:this.state.payable_amount,
                            is_credit_sim :this.state.is_credit_sim === true ? 1 : 0,
                        };
        
        try
        {
            

            let headers     = await getApiHeader();
            let res         = await axios.post(`${ SERVER_URL }add-a-mobile-plan/payments`,post_data, { headers });
            let data        = res.data;

            return true;

        }
        catch(err)
        {
            this.setState({loading:false});


            if((err) && (err.response) && (err.response.data) && (err.response.data.data) && (err.response.data.data.payment_not_found) && (err.response.data.data.payment_not_found === true))
            {

                this.setState({model_visibility:true});

                return 'add-payment';

            }
            else if((err) && (err.response) && (err.response.data) && (err.response.data.message)){
                // console.log(err.response.data);
                // console.log(err.response.data.message);
                swal({
                        text: err.response.data.message,
                        icon: "error"
                    });

                return 'payment-failed';
            }
            else
            {
                swal({
                        text: "Error in capture your payment",
                        icon: "error"
                    });

                return 'payment-failed';
            }
            

        }

    }




    addPlan = async ()=>
    {

        let post_data = {
            session_id:this.state.activation_attempt_session_id,
            plan_id:this.state.seletedPlanId
        };

        try
        {
            

            let headers     = await getApiHeader();
            let res         = await axios.post(`${ SERVER_URL }activation/select-plan`,post_data, { headers });
            let data        = res.data;
            return true;

        }
        catch(err)
        {
            this.setState({loading:false});

            swal({
                text: "Error in fetch service number",
                icon: "error"});
            

        }

    }



    activate = async ()=>{

        this.setState({loading:true});

        let post_data = {
                            session_id:this.state.activation_attempt_session_id,
                            dvs_status:1,
                            add_a_mobile_plan:true
                        };
        
        try
        {
            

            let headers     = await getApiHeader();
            let res         = await axios.post(`${ SERVER_URL }activation/activate`,post_data, { headers });
            let data        = res.data;

            this.setState({loading:false}, ()=>{

                swal({
                    text: data.message,
                    icon: "success"}).then(()=>{
                        this.props.history.push("/home");
                    });

            });

            return true;

        }
        catch(err)
        {
            this.setState({loading:false});

            swal({
                text: "Error in activation",
                icon: "error"}).then(()=>{
                    this.props.history.push("/home");
                });
            

        }

    }



    userAgree = (event)=>
    {

        this.setState({agree_customer:event.target.checked});

    }



    handleClose = () => this.setState({ model_visibility: false });
    handleShow = () => this.setState({ model_visibility: true });

    callBackPayment = async (result)=>{

        if(result === true)
        {
            let success = false;

            success = await this.paymentSubmit();
    
            if(success === true)
            {
                this.setState({model_visibility:false, loading:true});
                success = await this.activate();
                return;
            }
    
            if(success !== true)
            {
    
                this.setState({model_visibility:false, loading:false}, ()=>{
    
                    swal({
                        text: "Error in activation.",
                        icon: "error"});
    
                });
    
            }
        }

    }

    myoldHandler = async (event) => { 
        event.preventDefault();  


        if (this.validator.allValid() == false) 
        {

            this.validator.showMessages();

            this.forceUpdate();

            return;

        } 


        const post_data =   {
                                enquiry_sim: this.state.sim_number ,
                                enquiry_firstName: this.state.sim_fname ,
                                enquiry_lastName: this.state.sim_lname ,
                                enquiry_email: this.state.sim_email ,
                                enquiry_address: this.state.address_full ,
                                session_id : this.state.activation_attempt_session_id
                            };

        console.log(post_data);

        let headers     = await getApiHeaderGuest();
        let res         = await axios.post(`${ SERVER_URL }activation/verify/oldsim`,post_data, { headers }).then(response => {
            // this.showMessages(response.data);
            let enqid=response.data.data.enquiry_id;
            this.setState({ oldsim: 2});
            this.setState({ enquiry_id: enqid });

        }).catch(error => {
            if((typeof(error.response.data.message) !== 'undefined') && (typeof(error.response.data) !== 'undefined'))
            {
                this.setErrorMessages(error.response.data.message,'warning');
            }
            else
            {
                swal("Internal Server Error", "", 'warning');
            }
            
        });
    }

    setaddressFinder= async (full_address) => {
        this.setState({ address_full: full_address });
    };

    oldSimFName = (event) => { this.setState({ sim_fname: event.target.value}); }
    oldSimLName = (event) => { this.setState({ sim_lname: event.target.value}); }
    oldSimAddress = (event) => { this.setState({ sim_address: event.target.value}); }




    confirmOld = async (event) => { 
        event.preventDefault();  
        const post_data =   {   
                                session_id : this.state.activation_attempt_session_id,
                                enquiry_id : this.state.enquiry_id,
                            };
        console.log(post_data);
        let headers     = getApiHeaderGuest();
        let res         = axios.post(`${ SERVER_URL }activation/verify/oldsim_confirm`,post_data, { headers }).then(response => {
            // this.showMessages(response.data);
            swal({
            title: 'Confirmed with Given Details!',
            text: 'We will send new sim card.',
            icon: 'success'
            }).then(()=> {
                this.props.history.push("/Home");
                    });

        }).catch(error => {
            if((typeof(error.response.data.message) !== 'undefined') && (typeof(error.response.data) !== 'undefined'))
            {
                this.setErrorMessages(error.response.data.message,'warning');
            }
            else
            {
                swal("Internal Server Error", "", 'warning');
            }
            
        });
    }



    cancelOld = (event) => {
        this.setState({ oldsim: 0});
        this.setState({ address_full: '' });
        this.setState({ sim_fname: '' });
        this.setState({ sim_lname: '' });
        this.setState({ sim_email: '' });
    }


    render() {

        var profile_details = this.state.customer_profile;


  return (
    <>









        <Modal className="modal-fullscreen" size='lg' show={this.state.model_visibility} onHide={this.handleClose}>
            <form onSubmit={this.mySubmitHandler}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Payment Method</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{marginTop:'15px', marginBottom:'15px'}}>
                        <MyPayments onlyPayment={true} callBackPayment={this.callBackPayment}/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>
                        Close
                    </Button>
                    {/* <button onClick={this.buy.bind(this)} className="btn btn-brand" id="saveBtn">Save & Submit</button> */}
                </Modal.Footer>
            </form>
        </Modal>

        {(this.state.loading === true) ? <PageLoader/> : null}
        <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor common-class" id="kt_content"> 
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
                        <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                            <div className="kt-portlet">
                                <div className="kt-portlet__head">
                                    <div className="kt-portlet__head-label">
                                        <h3 className="kt-portlet__head-title">Add a Mobile Plan</h3>
                                    </div>
                                </div>
                                {/* <form className="kt-form kt-form--label-right" id="idForm" action="" method="post"> */}
                                    <input type="hidden" name="_token" value="Q4mFr23si4Znoz5hjD1Q2zAcb2dazRVE7n5A26wv"/>
                                    <div className="kt-portlet__body">
                                        <div className="kt-section kt-section--first">
                                            <div className="kt-section__body">

                                                {(this.state.businessMessage != null) ? <p style={{padding: '5px 15px', background: '#f7f7f7', borderRadius: '5px', fontStyle: 'italic'}}>{this.state.businessMessage}</p>: null}

                                                {((this.state.oldsim !== 1) && (this.state.oldsim !== 2)) &&
                                                    <div>
                                                        <div className="form-group row">
                                                            
                                                            <div className="col-lg-4 col-xl-4">
                                                                <label className="col-xl-12 col-lg-12 col-form-label txt-left">First Name</label>
                                                                <input className="form-control" type="text" defaultValue={profile_details.firstName} disabled />
                                                            </div>
                                                            
                                                            <div className="col-lg-4 col-xl-4">
                                                                <label className="col-xl-12 col-lg-12 col-form-label txt-left">Middle Name</label>
                                                                <input className="form-control" type="text" defaultValue={profile_details.middleName} disabled/>
                                                            </div>
                                                            
                                                            <div className="col-lg-4 col-xl-4">
                                                                <label className="col-xl-12 col-lg-12 col-form-label txt-left">Last Name</label>
                                                                <input className="form-control" type="text" defaultValue={profile_details.surname} disabled/>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <div className="col-lg-4 col-xl-4">
                                                                <label className="col-xl-12 col-lg-12 col-form-label txt-left">Date of Birth</label>
                                                                <input className="form-control" type="text" defaultValue={profile_details.dob} disabled/>
                                                            </div>
                                                            
                                                            <div className="col-lg-8 col-xl-8">
                                                                <label className="col-xl-12 col-lg-12 col-form-label txt-left">Current Address</label>
                                                                <div className="input-group" id="curr_addr_div">                                                            
                                                                
                                                                    <input type="text" className="form-control" defaultValue={this.state.customer_address.address_full} value={this.state.customer_address.address_full} name="curr_address" id="curr_address" disabled={true}/>
                                                                </div>
                                                            </div>  
                                                        </div>

                                                        <label className="col-xl-12 col-lg-12 col-form-label txt-left" >
                                                            {<p>Choose SIM type<span style={{color: 'red'}}>*</span></p>}
                                                        </label>
                                                        <div className="form-group row simImgView simPadding" >
                                                            <div className="col-lg-2 col-xl-2 simView" style={{border: this.state.simType === 'physical' ? '1px solid #e41e26' : '1px solid #cccccc', height: 120, justifyContent: 'center', alignItems: 'center', display: 'flex'}} onClick={()=>{   
                                                                this.setState({simType: 'physical', verified: false, sim_field_disable: false, existotpcheck: '0', reservedcheck: '0', amountDue: '0.00'})
                                                               
                                                            }}>
                                                                <div style={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                                                                    <img src={physical_image} alt="image" style={{width: 40, height: 30}}/>
                                                                    <label className="col-xl-12 col-lg-12 col-form-label" style={{textAlign: 'center'}}>Physical SIM</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-xl-2 esimView simView simleft" style={{border: this.state.simType === 'esim' ? '1px solid #e41e26' : '1px solid #cccccc', height: 120, justifyContent: 'center', alignItems: 'center', display: 'flex'}} onClick={()=>{
                                                                this.setState({simType: 'esim', verified: false, sim_field_disable: false, existotpcheck: '0', reservedcheck: '0', amountDue: '0.00'})
                                                            }}>
                                                                <div style={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                                                                    <img src={esim_image} alt="image" style={{width: 40, height: 30}}/>
                                                                    <label className="col-xl-12 col-lg-12 col-form-label" style={{textAlign: 'center'}}>eSIM</label>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        {((this.state.verified == true) && (this.state.is_credit_sim === false)) &&
                                                        <div className="form-group row ref_div hide_ref_div simView1">
                                                            
                                                            <div className="col-lg-12 col-xl-12 promo_div" >
                                                                <label className="promo_label" style={{textAlign: 'center'}}><b><i>Note: If you have already purchased the Telsim plan online or you have a promo code, enter your purchase reference number or promo code here. Purchase Reference Number will be mentioned on the email confirmation sent to you or in the invoice.</i></b></label>
                                                            </div>
                                                            
                                                        </div>
                                                        }

                                                        <div className="form-group row" style={{marginTop: 25}}>
                                                            {this.state.simType == 'physical' && (<div className="col-lg-6 col-xl-6">
                                                                <label className="col-xl-12 col-lg-12 col-form-label txt-left">SIM Number</label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" placeholder="Enter SIM Number" aria-describedby="basic-addon1" name="sim" id="sim" title="Enter Valid Sim Number" onChange={this.simnumber} disabled={this.state.sim_field_disable} required={true} value={this.state.sim_number}/>
                                                                    {this.state.verified == false &&
                                                                    <div className="input-group-append">
                                                                        <button className="btn btn-brand" id="verify_sim" type="button" style={{padding:'.75rem'}}  onClick={this.simnumcheck}>Verify!</button>
                                                                    </div>
                                                                    }

                                                                    {this.state.verified == true &&
                                                                    <p style={{color:'green',display:'block',width:'100%'}}>Successfully verified.</p>
                                                                    }
                                                                </div>

                                                                <input type="hidden" name="sim_credit_plan" value="0" id="sim_credit_plan"/>
                                                                <span className="" role="alert" id="sim_error" style={{color: 'red'}}></span>
                                                                <span className="" role="alert" id="sim_success" style={{color: 'green'}}></span>
                                                            </div>)}
                                                            {this.state.simType == 'esim' && (<div className="col-lg-6 col-xl-6">
                                                                {this.state.simType != 'esim' && (<label className="col-xl-12 col-lg-12 col-form-label txt-left">SIM Number (optional)</label>)}
                                                                <label className="col-xl-12 col-lg-12 col-form-label txt-left">Enter code in order to verify the SIM</label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" placeholder="Enter code in order to verify the SIM" aria-describedby="basic-addon1" name="sim" id="sim" title="Enter Valid activation code" required={true} onChange={this.simCodeCheck} value={this.state.simCode}/>
                                                                    {this.state.simType != 'esim' && (<input type="text" className="form-control" placeholder="Enter SIM Number" aria-describedby="basic-addon1" name="sim" id="sim" title="Enter Valid Sim Number" onChange={this.simVoucherCheck} disabled={this.state.sim_field_disable} value={this.state.simVoucher}/>)}
                                                                    {this.state.verified == false &&
                                                                    <div className="input-group-append">
                                                                        <button className="btn btn-brand" id="verify_sim" type="button" style={{padding:'.75rem'}}  onClick={this.simnumcheck}>Verify!</button>
                                                                    </div>
                                                                    }

                                                                    {(this.state.verified == true && this.state.simType != 'esim') && 
                                                                    (<p style={{color:'green',display:'block',width:'100%'}}>Successfully verified.</p>)
                                                                    }
                                                                </div>

                                                                <input type="hidden" name="sim_credit_plan" value="0" id="sim_credit_plan"/>
                                                                <span className="" role="alert" id="sim_error" style={{color: 'red'}}></span>
                                                                <span className="" role="alert" id="sim_success" style={{color: 'green'}}></span>
                                                            </div>)}
                                                            
                                                            {((this.state.verified === true) && (this.state.is_credit_sim === false)) &&
                                                                <div className="col-lg-6 col-xl-6 ref_div hide_ref_div" >   {/*display_none*/}
                                                                    <label className="col-xl-12 col-lg-12 col-form-label ref_div hide_ref_div  txt-left">Reference Number</label>
                                                                    <div className="input-group">
                                                                        <input type="text" className="form-control" placeholder="Enter Reference Number" aria-describedby="basic-addon1" name="reference" id="reference" onChange={this.refnumber} value={this.state.ref_number}/>
                                                                        <div className="input-group-append">
                                                                            <button className="btn btn-brand" id="verify_ref" type="button" style={{padding:'.75rem'}} onClick={this.refnumcheck}>Apply!</button>
                                                                        </div>
                                                                    </div>
                                                                    <span className="" role="alert" id="ref_error" style={{color: 'red'}}></span>
                                                                    <span className="" role="alert" id="ref_success" style={{color: 'green'}} ></span>
                                                                </div>
                                                            }
                                                        </div>

                                                        {this.state.verified == true &&
                                                        <div className="form-group row form-group-marginless ref_div "  id="list_plan" style={{display: 'block'}}>
                                                            <label className="col-xl-12 col-lg-12 col-form-label txt-left">Select a Plan</label>
                                                            {/* <PlanData />  */}

                                                            {(this.state.reference_number_applied === false) ?
                                                            <PlanTabs from="add-mobile-plan" goNextStep={this.goNextStep}/>
                                                            : <Plan plan_reference_number={this.state.reference_number} referenceNumberApplied={true} plan={this.state.reference_number_plan} selectedPlan={this.state.reference_number_plan.plan_id}/>}

                                                        </div>
                                                        }


                                                        {(this.state.display_service_number == true && this.state.verified == true) &&
                                                            (<div>
                                                                <div className="form-group row">
                                                                    <label className="col-xl-2 col-lg-2 col-form-label"></label>
                                                                    <div className="col-lg-4 col-xl-4">
                                                                        <span className="col-lg-12 col-md-12 col-sm-12 error " role="alert" id="plan-error" style={{color: 'red'}}></span>
                                                                    </div>
                                                                </div>
                                                            
                                                                {this.state.verified == true &&
                                                                <div className="form-group row ref_div" style={{marginTtop: '1rem'}}>
                                                                    
                                                                    <div className="col-lg-12 col-md-12 col-sm-12">

                                                                        <label className="col-xl-12 col-lg-12 col-form-label txt-left">{this.state.simMessage}</label>
                                                                        <div className="row">
                                                                            <div className="col-md-6" style={{display:(this.state.new_type)}}>
                                                                                <label className="kt-option" onClick={() => this.numberType(1)}>
                                                                                <span className="kt-option__control">
                                                                                <span className="kt-radio kt-radio--check-bold">
                                                                                <input type="radio" name="your_mobile_number" value="new" defaultChecked id="your_mobile_number_new"/>
                                                                                <span></span>
                                                                                </span>
                                                                                </span>
                                                                                <span className="kt-option__label">
                                                                                <span className="kt-option__head">
                                                                                <span className="kt-option__title">
                                                                                Get New Number
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </label>
                                                                            </div>


                                                                            <div className="col-md-6" style={{display:(this.state.port_type)}}>
                                                                                <label className="kt-option" onClick={() => this.numberType(0)}>
                                                                                <span className="kt-option__control">
                                                                                <span className="kt-radio kt-radio--check-bold">
                                                                                <input type="radio" name="your_mobile_number" value="port" defaultChecked={this.state.isChecked}  id="your_mobile_number_port"/>
                                                                                <span></span>
                                                                                </span>
                                                                                </span>
                                                                                <span className="kt-option__label">
                                                                                <span className="kt-option__head">
                                                                                <span className="kt-option__title">
                                                                                Keep My Number
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                }

                                                                {this.state.reserved == 1 && 
                                                                <div id="new_number_div" className="ref_div">
                                                                    <div className="form-group row">
                                                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                                                            <label className="col-xl-12 col-lg-12 col-form-label txt-left">Your Telsim Number</label>
                                                                            <input type="text" value={this.state.assigned_service_number} className="form-control" placeholder="" name="assigned_telsim_number" id="assigned_telsim_number"/>
                                                                            <span id="assigned_telsim_number_error" style={{color: 'red'}} role="alert"></span>  
                                                                        </div>

                                                                        {this.state.dvs_enabled == true &&
                                                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                                                            {(this.state.numberSelected === true) ?
                                                                                <>
                                                                                {(this.state.hideNext === true) ?
                                                                            
                                                                                  <>
                                                                                  <label className="col-xl-12 col-lg-12 col-form-label txt-left" style={{visibility: 'hidden'}}>Proceed ID Verification</label>
                                                                                  <div className="input-group-append">
                                                                                    <button className="btn btn-brand" type="button" style={{ padding: '0.75rem' }} onClick={this.numberSelectionVerify}>Next</button>
                                                                                  </div>
                                                                                  </>

                                                                                  : null}

                                                                                  </>

                                                                            : null}
                                                                        </div>
                                                                        }

                                                                    </div>
                                                                </div>
                                                                }



                                                                {/* {this.state.reserved == 1 &&
                                                                <div className="form-group row" id="reserved_numbers_div">

                                                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                                                    <label className=""></label>
                                                                                    <div className="row">
                                                                                        <ReservedNumbers /> 
                                                                                    </div>
                                                                            </div>

                                                                </div>
                                                                } */}




                                                        

                                                                {this.state.reserved == 0 &&
                                                                <div className="form-group row keep_my_num_div" >
                                                                    
                                                                    <div className="col-lg-6 col-xl-6">
                                                                    <label className="col-xl-12 col-lg-12 col-form-label txt-left">Enter Your Existing Mobile Number</label>
                                                                    {(this.state.maskMobile == false) ?
                                                                    <>

                                                                       {(this.state.resendOtp == true) ? 
                                                                            <div className="input-group">
                                                                                <input type="text" className="form-control" placeholder="Enter Existing Number" aria-describedby="basic-addon1" maxLength="10" name="existing_number" id="existing_number" onChange={this.existingmobile}/>
                                                                                <div className="input-group-append">
                                                                                    <button className="btn btn-brand" id="request_port_otp" type="button" style={{padding:'.75rem'}} onClick={this.requestOtp}>Request OTP</button>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                            :
                                                                            
                                                                                <div className="input-group">
                                                                                    <input type="text" className="form-control" placeholder="Enter Existing Number" aria-describedby="basic-addon1" maxLength="10" name="existing_numbers" readOnly/>
                                                                                    <div className="input-group-append">
                                                                                        <button className="btn btn-brand" id="request_port_otp" type="button" style={{padding:'.75rem', backgroundColor:'grey'}} disabled="disabled">Request OTP</button>
                                                                                    </div>
                                                                                </div>
                                                                            
                                                                        }

                                                                        <span className="col-lg-12 col-md-12 col-sm-12" role="alert" id="existing_number_error" style={{color: 'red'}} ></span>
                                                                        <span className="col-lg-12 col-md-12 col-sm-12" role="alert" id="existing_number_success" style={{color: 'green'}}></span>

                                                                        {this.state.resendOtp == false &&
                                                                        <span className="col-lg-12 col-md-12 col-sm-12" role="alert" id="resendOtpSection" style={{color: 'grey'}}>You can resend OTP in 3 minutes. </span>
                                                                        }


                                                                    </>
                                                                    :
                                                                    <>
                                                                         <div className="input-group">
                                                                                    <input type="text" className="form-control" placeholder="Enter Existing Number" aria-describedby="basic-addon1" maxLength="10" name="existing_number" readOnly/>
                                                                        </div>
                                                                    </>

                                                                    }

                                                                    </div>
                                                                    {this.state.existotpcheck == 1 &&
                                                                    <div className="col-lg-6 col-xl-6 verify_port_otp_div" >
                                                                    <label className="col-xl-2 col-lg-2 col-form-label verify_port_otp_div txt-left" >Enter OTP</label>
                                                                        
                                                                        {(this.state.maskMobile == false) ? 
                                                                        <div className="input-group">
                                                                            <input type="text" className="form-control" placeholder="Enter OTP" aria-describedby="basic-addon1" name="port_otp" id="port_otp" onChange={this.existotp}/>
                                                                            <div className="input-group-append">
                                                                                <button className="btn btn-brand" id="verify_port_otp" type="button" style={{padding:'.75rem'}} onClick={() => this.verify_OTP()}>Verify OTP</button>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className="input-group">
                                                                            <input type="text" className="form-control" placeholder="Enter OTP" aria-describedby="basic-addon1" name="port_otp" readOnly/>
                                                                        </div>
                                                                        }

                                                                    </div>
                                                                    }
                                                                </div>
                                                                }

                                                                
                                                                
                                                                {this.state.reservedcheck == 1 &&
                                                                <div className="form-group row section_port" >
                                                                    
                                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                                        <label className="">Select Mobile Provider</label>
                                                                        <select className="form-control " name="mobile_provider" id="mobile_provider" onChange={this.getProvider} value={this.state.mobile_provider}>
                                                                            <option value="">Select Operator</option>
                                                                            { this.state.providers_list.map(provider => <option value={provider.provider}>{provider.provider}</option>)} 
                                                                        </select>
                                                                        {/* {this.validator.message('Mobile provider', this.state.mobile_provider, 'required', { className: 'text-danger' })} */}
                                                                        <span className="col-lg-12 col-md-12 col-sm-12" role="alert" id="mobile_operator_error" style={{color: 'red'}}></span>
                                                                    </div>
                                                                    
                                                                    {this.state.other_provider_div == true &&
                                                                    <div className="col-lg-6 col-xl-6 enter_provider_txt" >
                                                                    <label className="col-xl-12 col-lg-12 col-form-label txt-left">Enter Mobile Provider</label>
                                                                        <input type="text" className="form-control" placeholder="Enter Mobile Provider Name" aria-describedby="basic-addon1" name="other_provider" id="other_provider" onChange={this.getOtherProvider} defaultValue={this.state.port_other_provider}/>
                                                                        <span className="col-lg-12 col-md-12 col-sm-12" role="alert" id="other_provider_error" style={{color: 'red'}}> </span>
                                                                    </div>
                                                                    }
                                                                    
                                                                    <div className="col-lg-6 col-xl-6">
                                                                        <label className="col-xl-12 col-lg-12 col-form-label txt-left">Type of Service</label>
                                                                        <select className="form-control kt_selectpicker" name="port_service_type" id="port_service_type" onChange={this.selectServiceType}>
                                                                            <option value="">Select Type of Service</option>
                                                                            <option value="Pre-paid">Pre-paid</option>
                                                                            <option value="Post-paid">Post-paid</option>
                                                                        </select>
                                                                        <span className="col-lg-12 col-md-12 col-sm-12" role="alert" id="port_service_type_error" style={{color: 'red'}}></span>
                                                                    </div>
                                                                
                                                                    {(this.state.service_type === 'Pre-paid') &&

                                                                        <div className="col-lg-6 col-xl-6 port_prepaid_div">
                                                                            <label className="col-xl-12 col-lg-12 col-form-label port_prepaid_div txt-left" >Enter Date of Birth</label>
                                                                            
                                                                            <DatePicker
                                                                                onChange={this.getPortDob}
                                                                                value={this.state.port_dob}
                                                                                className="form-control rmvdftstyle"
                                                                                format="y-MM-dd" 
                                                                                defaultValue={new Date(this.state.maxdob)}
                                                                                minDate={new Date(this.state.mindob)}
                                                                                maxDate={new Date(this.state.maxdob)}
                                                                                onKeyDown={(e) => {
                                                                                e.preventDefault();
                                                                                }}

                                                                                
                                                                            />
                                                                            
                                                                            {/* <input type="text" className="form-control kt_datepicker_1" id="port_dob" name="port_dob" placeholder="Select date of Birth" /> */}
                                                                            {/* <span className="col-lg-12 col-md-12 col-sm-12" role="alert" id="port_dob-error" style={{color: 'red'}}></span> */}
                                                                        </div>

                                                                    }

                                                                    {(this.state.service_type === 'Post-paid') &&
                                                                        <div className="col-lg-6 col-xl-6 port_postpaid_div" >
                                                                            <label className="col-xl-12 col-lg-12 col-form-label port_postpaid_div txt-left" >Enter Account Number</label>
                                                                            <div className="input-group">
                                                                                <input type="text" className="form-control" placeholder="Enter Account Number" aria-describedby="basic-addon1" name="port_acc_id" id="port_acc_id" onChange={this.accountIdChange}/>
                                                                            </div>
                                                                            <span className="col-lg-12 col-md-12 col-sm-12" role="alert" id="port_acc_id_error" style={{color: 'red'}}> </span>
                                                                        </div>
                                                                    }



                                                                    {(this.state.numberSelected === true) && (this.state.dvs_enabled == true) ?
                                                                        <>
                                                                        {(this.state.hideNext === true) ?
                                                                            <div className="col-lg-6 col-xl-6" >
                                                                                <label className="col-xl-12 col-lg-12 col-form-label txt-left" style={{visibility: 'hidden'}}>Proceed ID Verification</label>
                                                                                <div className="input-group-append">
                                                                                <button className="btn btn-brand" type="button" style={{ padding: '0.75rem' }} onClick={this.numberSelectionVerify}>Next</button>
                                                                                </div>
                                                                            </div>
                                                                        : null}
                                                                        </>
                                                                    : null}


                                                                </div>
                                                                }




                                    





                                    {(this.state.numberSelectionVerified === true) && (this.state.iDverified === false) && (this.state.dvs_enabled == true) ?
                                    <>
                                    <h5 className="kt-portlet__head-title" style={{fontWeight: 'bold', background: '#f9f9f9', margin: '0', padding: '1rem 1.5rem', border: '1px solid rgb(222, 226, 230)', borderBottom: '0' }}>ID Validation</h5> 
                                    <form className="kt-form kt-section__content common-layout IdValidation"  style={{padding: '1.5rem',border: '1px solid #dee2e6'}} id="kt_form" method="post" onSubmit={this.myDVSHandler}>
                                        <div className="kt-wizard-v2__content" data-ktwizard-type="step-content">
                                            <div className="row mb60">
                                                <div className="col-lg-6 col-xl-6 order-lg-1 order-xl-1" style={{marginTop: 10}}>
                                                    <div className="kt-portlet__head-label">
                                                        
                                                        <label className="col-xl-12 col-lg-12 col-form-label txt-left" style={{marginTop: -15}}>
                                                            {<p style={{fontWeight: 'bold', color:'red'}}>Please enter your ID document details carefully. Incorrect details will block your SIM card and will delay the service activation.</p>}
                                                        </label>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%'}}>
                                                        <label className="col-xl-12 col-lg-12 col-form-label txt-left">
                                                        ID types
                                                        </label>
                                                        <select className="form-select custom-select kt_selectpicker"
                                                        key="uniqueId1"
                                                        style={{height: 45}} 
                                                        //defaultValue={selected_number} 
                                                        value={this.state.idType}
                                                        onChange={e => {this.changeID(e.target.value);}} 
                                                        name="service_type">
                                                            <option value="">Select Type </option>
                                                            <option value="1">Passport</option>
                                                            <option value="2">Australian Driving License</option>
                                                            <option value="3">Medicare Card</option>
                                                        </select>
                                                    </div>

                                                    {/* passport */}
                                                    {this.state.idType == 1 && (<>
                                                        <div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%', marginTop: 15}}>
                                                            <label className="col-xl-12 col-lg-12 col-form-label txt-left" >
                                                                {<p>Passport number</p>}
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                style={{marginTop: -10, height: 45}}
                                                                type='text'
                                                                name="adl_number" 
                                                                placeholder="Enter Passport Number"
                                                                // maxLength={10}
                                                                // max={10}
                                                                value={this.state.passportNumber}
                                                                onChange={this.passportNumber}
                                                            />
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%', marginTop: 15}}>
                                                            <label className="col-xl-12 col-lg-12 col-form-label txt-left">
                                                            Issuing country
                                                            </label>
                                                            <select className="form-select custom-select kt_selectpicker"
                                                            style={{height: 45}} 
                                                            value={this.state.passportCountry} 
                                                            onChange={e => {this.passportCountry(e.target.value);}} 
                                                            name="pp_country">
                                                                <option value="">Select Country </option>
                                                                { this.state.all_countries.map(country => <option className="m--bg-success kt-font-inverse-success" value={country.country_code}>{country.name}</option>)} 
                                                            </select>
                                                        </div>
                                                    </>)}

                                                    {/* Driving License */}
                                                    {this.state.idType == 2 && (<>
                                                        <div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%', marginTop: 15}}>
                                                            <label className="col-xl-12 col-lg-12 col-form-label txt-left">
                                                            Issuing state
                                                            </label>
                                                            <select className="form-select custom-select kt_selectpicker"
                                                            style={{height: 45}} 
                                                            //defaultValue={selected_number} 
                                                            name="adl_state"
                                                            onChange={e => {this.licenceState(e.target.value);}} 
                                                            value={this.state.licenceState}>
                                                               <option value="">Select State</option> 
                                                                <option value="ACT">ACT</option>
                                                                <option value="NSW">NSW</option>
                                                                <option value="NT">NT</option>
                                                                <option value="QLD">QLD</option>
                                                                <option value="SA">SA</option>
                                                                <option value="TAS">TAS</option>
                                                                <option value="VIC">VIC</option>
                                                                <option value="WA">WA</option> 
                                                            </select>Your Details
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%', marginTop: 15}}>
                                                            <label className="col-xl-12 col-lg-12 col-form-label txt-left" >
                                                                {<p>License number</p>}
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                style={{marginTop: -10, height: 45}}
                                                                type='text'
                                                                name="adl_number" 
                                                                placeholder="Enter License Number"
                                                                // maxLength={10}
                                                                // max={10}
                                                                value={this.state.licenceNumber}
                                                                onChange={this.licenceNumber}
                                                            />
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%', marginTop: 15}}>
                                                            <label className="col-xl-12 col-lg-12 col-form-label txt-left" >
                                                                {<p>Card number</p>}
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                style={{marginTop: -10, height: 45}}
                                                                type='text'
                                                                name="adl_cnumber" 
                                                                min="0" 
                                                                maxlength="20" 
                                                                title="Enter valid card number" 
                                                                placeholder="Enter Card Number"
                                                                // maxLength={10}
                                                                // max={10}
                                                                value={this.state.licenceCardNumber}
                                                                onChange={this.licenceCardNumber}
                                                            />
                                                        </div>
                                                    </>)}

                                                    {/* Medicare Card */}
                                                    {this.state.idType == 3 && (<>
                                                        <div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%', marginTop: 15}}>
                                                            <label className="col-xl-12 col-lg-12 col-form-label txt-left" >
                                                                {<p>Card number</p>}
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                style={{marginTop: -10, height: 45}}
                                                                type='text'
                                                                name="mc_number" 
                                                                placeholder="Enter Card Number"
                                                                required 
                                                                maxlength="10" 
                                                                title="Enter valid card number" 
                                                                pattern="[1-9]{1}[0-9]{9}"
                                                                // maxLength={10}
                                                                // max={10}
                                                                value={this.state.medicareCardNumber}
                                                                onChange={this.medicareCardNumber}
                                                            />
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%', marginTop: 15}}>
                                                            <label className="col-xl-12 col-lg-12 col-form-label txt-left">
                                                            Colour of the card
                                                            </label>
                                                            <select className="form-select custom-select kt_selectpicker"
                                                            style={{height: 45}} 
                                                            //defaultValue={selected_number} 
                                                            name="mc_color"
                                                            onChange={e => {this.medicareColour(e.target.value);}} 
                                                            value={this.state.medicareColour}>
                                                                <option value="">Select Colour </option>
                                                                <option value="Y">Yellow</option>
                                                                <option value="B">Blue</option>
                                                                <option value="G">Green</option> 
                                                            </select>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%', marginTop: 15}}>
                                                            <label className="col-xl-12 col-lg-12 col-form-label txt-left" >
                                                                {<p>Date of expiry</p>}
                                                            </label>
                                                            <DatePicker
                                                                onChange={this.medicareExpiry}
                                                                value={this.state.medicareExpiry}
                                                                className="form-control customdate"
                                                                format="MM-y"
                                                                required
                                                            />
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%', marginTop: 15}}>
                                                            <label className="col-xl-12 col-lg-12 col-form-label txt-left" >
                                                                {<p>Individual Reference Number (IRN)</p>}
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                style={{marginTop: -10, height: 45}}
                                                                type="number" 
                                                                min="0" 
                                                                max="9" 
                                                                name="mc_irn" 
                                                                placeholder="Enter Individual Reference Number"
                                                                value={this.state.medicareIRN}
                                                                onChange={this.medicareIRN}
                                                            />
                                                        </div>
                                                    </>)}


                                                    {/* Personal details */}
                                                    <div className="kt-portlet__head-label"  style={{marginTop: 25}}>
                                                        <h5 className="kt-portlet__head-title" style={{fontWeight: 'bold'}}>Your Details</h5>
                                                        <label className="col-xl-12 col-lg-12 col-form-label txt-left" style={{ marginTop: -5}}>
                                                            {<p style={{fontWeight: 'bold'}}>Your personal details should match with provided ID.</p>}
                                                        </label>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%', marginTop: -5}}>
                                                        <label className="col-xl-12 col-lg-12 col-form-label txt-left" >
                                                            {<p>First name</p>}
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            style={{marginTop: -10, height: 45}}
                                                            type='text'
                                                            name="adl_number" 
                                                            placeholder="Enter First name"
                                                            pattern='[A-Za-z\-\s]*'
                                                            // maxLength={10}
                                                            // max={10}
                                                            value={this.state.FirstName}
                                                            onChange={this.firstName}
                                                            autoComplete="off" onPaste={(e)=>{ e.preventDefault(); return false; }}  onCopy={(e)=>{ e.preventDefault(); return false; }} 
                                                        />
                                                    </div>
                                                    {this.state.idType == 3 && (<div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%', marginTop: 15}}>
                                                        <label className="col-xl-12 col-lg-12 col-form-label txt-left" >
                                                            {<p>Middle Name</p>}
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            style={{marginTop: -10, height: 45}}
                                                            type='text'
                                                            name="mc_middlename_line1" 
                                                            placeholder="Enter Middle Name" 
                                                            title="Enter a valid name"
                                                            value={this.state.MiddleName} 
                                                            pattern='[A-Za-z\-\s]*'
                                                            onChange={this.middleName}
                                                            autoComplete="off" onPaste={(e)=>{ e.preventDefault(); return false; }}  onCopy={(e)=>{ e.preventDefault(); return false; }} 
                                                        />
                                                    </div>)}
                                                    <div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%', marginTop: 15}}>
                                                        <label className="col-xl-12 col-lg-12 col-form-label txt-left" >
                                                            {<p>Last name</p>}
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            style={{marginTop: -10, height: 45}}
                                                            type='text'
                                                            name="adl_number" 
                                                            placeholder="Enter Last name"
                                                            pattern='[A-Za-z\-\s]*'
                                                            // maxLength={10}
                                                            // max={10}
                                                            value={this.state.LastName}
                                                            onChange={this.lastName}
                                                            autoComplete="off" onPaste={(e)=>{ e.preventDefault(); return false; }}  onCopy={(e)=>{ e.preventDefault(); return false; }} 
                                                        />
                                                    </div>
                                                    {this.state.idType != 3 && (<div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%', marginTop: 15}}>
                                                        <label className="col-xl-12 col-lg-12 col-form-label txt-left">
                                                        Date of birth
                                                        </label>
                                                        <DatePicker
                                                            onChange={this.commonDOB}
                                                            style={{height: 45}}
                                                            value={this.state.commonDOB}
                                                            className="form-control customdate"
                                                            format="y-MM-dd"
                                                            required
                                                            minDate={this.state.minDvsDob}
                                                            maxDate={this.state.maxDvsDob}
                                                            onKeyPress={ this.handleKeypress}
                                                            onFocus={e => e.target.blur()}
                                                        />
                                                    </div>)}
                                                    <div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%', marginTop: 15}}>
                                                        <label className="col-xl-12 col-lg-12 col-form-label txt-left">
                                                        Gender
                                                        </label>
                                                        <select className="form-select custom-select kt_selectpicker"
                                                        style={{height: 45}} 
                                                        //defaultValue={selected_number} 
                                                        value={this.state.commonGender}
                                                        name="pp_gender" 
                                                        id="pp_gender" 
                                                        onChange={e => {this.commonGender(e.target.value);}} >
                                                            <option value="">Select Gender</option>
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                            <option value="-">Other</option>
                                                        </select>
                                                    </div>
                                                    
                                                </div>
                                                {(this.state.image != null && this.state.image != '') && (<>
                                                <div className="col-lg-6 col-xl-6 order-lg-1 order-xl-1">
                                                    <div className="kt-portlet__body kt-portlet__body--fit" style={{alignItems: 'center', backgroundColor: 'transparent'}}>
                                                        <img src={this.state.image} alt="image" style={{height: 'auto', width: '100%'}}/>
                                                    </div>
                                                </div>
                                                </>)}
                                            </div>
                                            {/* <div className="kt-heading kt-heading--md">ID Validation</div>
                                            <label style={{textAlign: 'justify'}}><b>If you change your personal details here, it will be automatically updated to your profile.</b></label>
                                            <label style={{textAlign: 'justify',color:'red'}}><b> Please enter your ID document details carefully. Incorrect details will block your SIM card and will delay the service activation.</b></label>
                                            <div className="kt-separator kt-separator--height-xs"></div> */}
                                            <div className="kt-form__section kt-form__section--first" id="card_section" style={{marginTop: -30}}>

                                               
                                                <div className="form-group  row">
                                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                                        <label className="kt-option">
                                                            <label className="kt-checkbox kt-checkbox--bold kt-checkbox--danger">
                                                                <input type="checkbox" name="authorised_check" id="authorised_check" onChange={e => {this.dvsCheckbox(e.target.checked);}} defaultChecked={this.state.dvsCheck} required />
                                                                <span></span>
                                                            </label>
                                                            <span className="kt-option__label">
                                                                <span className="kt-option__head">
                                                                    <span className="kt-option__title">
                                                                        I confirm that I am authorised to provide the personal details presented and I consent to my information being checked with the document issuer or official record holder via third party systems for the purpose of confirming my identity.
                                                                    </span>

                                                                </span>
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                                {this.state.customerror != '' &&
                                                    <span className="" role="alert"><span id="authorized_error" style={{color: 'red',fontWeight: '400'}}><i className="fa fa-exclamation-triangle"></i> {this.state.customerror}</span></span>
                                                }

                                                <div className="kt-form__actions" style={{marginTop:10}}>
                                                    {this.state.disableVerify == false &&
                                                    <button type="submit" className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm" id="wizard_next_btn" data-ktwizard-type="action-next">Verify</button>
                                                    }

                                                </div>
                                            </div>              
                                            
                                        </div>      
                                    </form> 
                                    </>
                                    : null}



                                    {(this.state.iDverified === true) && (this.state.dvs_enabled == true) ?
                                   
                                    <h5 className="kt-portlet__head-title" style={{color:'#44bb44f7', background: 'rgb(246 247 253)', padding: '1rem 1.5rem' }}><span>✓</span> Your ID details verified.</h5>

                                    : null}
            

























































                                                                {(this.state.iDverified == true || this.state.dvs_enabled == false)  && 

                                                                <>


                                                                <div className="form-group row ref_div" style={{marginTop: '1rem',marginBottom: '0rem'}}>
                                                                    <div className="col-lg-12 col-xl-12 text-center" >
                                                                        
                                                                        <label className="col-form-label">
                                                                            <h5> Amount Due Now:  <span id="due_amount" style={{color: '#e41e26',fontSize: '18px'}}>
                                                                            

                                                                             {(this.state.payable_amount == null) &&
                                                                              <>
                                                                               ${this.state.amountDue}
                                                                              </>
                                                                             }

                                                                             {(this.state.payable_amount != null) &&
                                                                              <>
                                                                               ${parseInt(this.state.amountDue) + parseInt(this.state.payable_amount)}
                                                                              </>
                                                                             }


                                                                                </span></h5> 
                                                                            {/* <strong  style={{color: '#e41e26',fontSize: '16px'}}>
                                                                                $<span id="due_amount">
                                                                                    {this.state.amountDue}
                                                                                </span>
                                                                            </strong> */}
                                                                        </label>
                                                                    </div>
                                                                </div>

                                                                <div className="form-group row ref_div margineless" style={{marginBottom: '0rem'}}>
                                                                    
                                                                    <div className="col-lg-12 col-xl-12">
                                                                        <div className="form-group  row">
                                                                            <div className="col-lg-12">
                                                                                <div className="form-group  row">
                                                                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                                                                        <label className="kt-option">
                                                                                        <label className="kt-checkbox kt-checkbox--bold kt-checkbox--danger">
                                                                                        <input type="checkbox" name="user_confirmation" id="user_confirmation" value="1" onChange={this.userAgree}/>
                                                                                        <span></span>
                                                                                        </label>
                                                                                        <span className="kt-option__label">
                                                                                        <span className="kt-option__head">
                                                                                        <span className="kt-option__title"> 
                                                                                        I agree to Telsim's <a href="https://telsim.com.au/terms-and-conditions" target="_blank">terms & conditions</a> , <a href="https://telsim.com.au/website-privacy-policy" target="_blank"> policies</a> and Telsim to charge the current amount due and auto renewals to the existing payment method.
                                                                                        </span>
                                                                                        </span>
                                                                                        </span>
                                                                                        </label>
                                                                                        <span className="" role="alert"><span id="user_confirmation_error" style={{color: 'red'}}></span>
                                                                                        </span>
                                                                                        {/* <span style={{fontSize:'1rem',marginTop: '1rem'}}className="text-sm">Click  <Link to="/Mypayments">Here</Link> to view/update existing payment method.</span> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                </>


                                                                }





                                                            </div>)
                                                        }
                                                    </div>
                                                }


                                                {((this.state.oldsim ==1) && (this.state.verified !== true)) && 
                                                    <form className="kt-form kt-section__content kt-section__content--border" id="kt_form" method="post" onSubmit={this.myoldHandler}>
                                                            <div className="kt-form__section kt-form__section--first">
                                                            <div className="kt-heading kt-heading--md">Your Telsim SIM</div>
                                                            <div className="kt-separator kt-separator--height-xs"></div>
                                                                <div className="row oldsimform">
                                                                        
                                                                    
                                                                        <div className="form-group row">
                                                                            <div className="col-xl-12 col-lg-12">
                                                                            <p className="oldhead">Enter Your Details</p>
                                                                            </div>

                                                                            <div className="col-xl-4 col-lg-4">
                                                                                <label>Sim Number</label>
                                                                                <div className="input-group">
                                                                                    <input type="text" className="form-control" defaultValue={this.state.sim_number} disabled />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-xl-4 col-lg-4">
                                                                                <label>First Name</label>
                                                                                <div className="input-group">
                                                                                    <input type="text" name="Fname" className="form-control" placeholder="Enter First Name" onChange={this.oldSimFName} />
                                                                                    {this.validator.message('First Name', this.state.sim_fname, 'required', { className: 'text-danger' })}
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-xl-4 col-lg-4">
                                                                                <label>Last Name</label>
                                                                                <div className="input-group">
                                                                                    <input type="text" className="form-control" placeholder="Enter Last Name" onChange={this.oldSimLName} />
                                                                                    {this.validator.message('Last Name', this.state.sim_lname, 'required', { className: 'text-danger' })}
                                                                                </div>
                                                                            </div>


                                                                            <div className="col-xl-4 col-lg-4">
                                                                                <label>Email Address</label>
                                                                                <div className="input-group">
                                                                                    <input type="email" className="form-control" placeholder="Enter Email Address" defaultValue={this.state.sim_email}  disabled />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-xl-8 col-lg-8">
                                                                                <div className="input-group" id="curr_addr_div">                                                            
                                                                                    <label>Enter Valid Address</label>
                                                                                    <AddressFinder
                                                                                    addressFinderKey="UJCFN4HL6YRMT3D9K8PG"
                                                                                    inputClassNam="address"
                                                                                    id="address"
                                                                                    onChange={this.setaddressFinder}
                                                                                    />                    
                                                                                </div>
                                                                                {this.validator.message('Address', this.state.address_full, 'required', { className: 'text-danger' })}
                                                                            </div>

                                                                        </div>

                                                                        <br/>
                                                                        <div className="kt-form__actions">
                                                                        <button type="submit" className="btn btn-success btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm" id="backBtn" onClick={this.cancelOld}>Cancel</button>&nbsp;
                                                                        <button type="submit" className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm pull-right" id="wizard_next_btn" data-ktwizard-type="action-next">Submit</button>
                                                                        </div>

                                                                </div>
                                                            </div>
                                                    </form>
                                                }

                                                {((this.state.oldsim ==2) && (this.state.verified !== true)) && 
                                                    <form className="kt-form kt-section__content kt-section__content--border" id="kt_form" method="post" onSubmit={this.confirmOld}>
                                                            <div className="kt-form__section kt-form__section--first">
                                                            <div className="kt-heading kt-heading--md">Your Telsim SIM</div>
                                                            <div className="kt-separator kt-separator--height-xs"></div>
                                                                <div className="row oldsimform">
                                                                        
                                                                    
                                                                        <div className="form-group row" style={{marginBottom: '0'}}>
                                                                            <div className="col-xl-12 col-lg-12">
                                                                            <p className="oldhead" style={{textAlign: 'center',background: '#ff000012',color: '#000',fontWeight: '400',padding:'25px'}}>Telsim is now using parts of Telstra's 4G and 3G network. Your current Telsim SIM card doesn't support this. We will send you a new Telsim SIM to your address for free of cost. Please confirm to proceed. </p>
                                                                            </div>

                                                                        
                                                                        </div>

                                                                        <br/>
                                                                        <div className="kt-form__actions">
                                                                        <button type="submit" className="btn btn-success btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm" id="backBtn" onClick={this.cancelOld} style={{margin:'0 auto'}}>No Cancel</button>&nbsp;
                                                                        <button type="submit" className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm pull-right" id="wizard_next_btn" data-ktwizard-type="action-next" style={{margin:'0 auto'}}>Yes, Proceed!</button>
                                                                        </div>
                                                                        <div className="container-fluid">
                                                                            <br/>
                                                                            <br/>
                                                                        </div>
                                                                        

                                                                </div>
                                                            </div>
                                                    </form>
                                                }
                                                
                                            </div>
                                        </div>
                                    </div>

                                    {(this.state.iDverified == true || this.state.dvs_enabled == false) && 

                                    <div className="kt-portlet__foot">
                                        <div className="kt-form__actions">
                                            <div className="row">

                                                {this.state.verified == true &&

                                                    <div className="col-lg-12 col-xl-12">
                                                    
                                                        <button className="btn btn-brand pull-right" id="submit_btn" onClick={this.submitHandler} disabled={this.state.disabled_btn}>Activate New Number</button>&nbsp;

                                                    </div>

                                                }

                                            </div>
                                        </div>
                                    </div>

                                    }


                                {/* </form> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
                    
	</>
   )
}
}

export default withRouter(AddaMobilePlan);