import React, { Component } from "react";
import {withRouter} from "react-router-dom";
import axios from 'axios';
import PageLoader from './../Loader/loader';
import "react-addressfinder/dist/widget.css";
import SimpleReactValidator from 'simple-react-validator';
import DatePicker from 'react-date-picker';
import {getApiHeader} from '../../utils/CommonFunctions';





const SERVER_URL    = process.env.REACT_APP_SERVER_URL;   



class IDverification extends Component {
    

    constructor(props) {

    super(props);

    let today   = new Date();
    let dd      = today.getDate();
    let mm      = today.getMonth() + 1; //January is 0 so need to add 1 to make it 1!
    let yyyy    = today.getFullYear();
    let maxyyyy = yyyy - 16;
    let minyyyy = yyyy - 110;

    this.validator = new SimpleReactValidator();


    this.state = {
        idType: '',
        passportCountry:'',
        // passportExpiry:'',
        passportFName:'',
        passportLName:'',
        passportNumber:'',
        passportDOB:'',
        passportGender:'',
        licenceNumber:'',
        licenceCardNumber:'',
        licenceFName:'',
        licenceLname:'',
        licenceState:'',
        // licenceExpiry:'',
        licenceDOB:'',
        licenceGender:'',
        medicareCardNumber:'',
        medicareFirstName:'',
        medicareMiddleName:'',
        medicareLastName:'',
        medicareGender:'',
        medicareIRN:'',
        medicareColour:'',
        medicareExpiry:'',
        dvsCheck:'',
        sim:'',
        customerror:'',
        all_countries:[],
        verification_token:'',
        session_id:'',
        dvs_status_check:'',
        loading:true,
        maxDob: new Date(`${maxyyyy}-${mm}-${dd}`),
        minDob: new Date(`${minyyyy}-${mm}-${dd}`),
        dvs_update_status:true,
        mandatoryCard : true
        }
    }

  componentDidMount() {
        this.setState({loading:true});
        this.getCountry();
        this.getActivationAttemptDetailsLogin();  
        this.setState({loading:false});
    }


     getCountry = async () => {
        let headers = await getApiHeader();
        let res         = await axios.get(`${ SERVER_URL }getCountryDetails`, { headers });
        let countrydata        = res.data.data.countries;
        this.setState({ all_countries: countrydata }); 
    };


     getActivationAttemptDetailsLogin = async () => {
        let headers  = await getApiHeader();
        let act_attempt_data        = await axios.get(`${ SERVER_URL }attemptDetailsLogin`, { headers });
        let attempt_data=act_attempt_data.data.data;
        console.log(act_attempt_data);
        //console.log(attempt_data.data.data.activation_attempt.sim_number);
        if(attempt_data.activation_attempt !=null)
        {

            this.setState({ passportFName: attempt_data.activation_attempt.firstName}); 
            this.setState({ passportLName: attempt_data.activation_attempt.surname}); 
            

            if(attempt_data.activation_attempt.dob)
            {
                this.setState({ passportDOB: new Date(attempt_data.activation_attempt.dob)}); 
            }
            else
            {
                this.setState({ passportDOB: this.state.maxDob});  
            }

            this.setState({ passportGender: attempt_data.activation_attempt.gender}); 
            this.setState({ licenceFName: attempt_data.activation_attempt.firstName}); 
            this.setState({ licenceLname: attempt_data.activation_attempt.surname}); 

            if(attempt_data.activation_attempt.dob)
            {
                this.setState({ licenceDOB: new Date(attempt_data.activation_attempt.dob)}); 
            }
            else
            {
                this.setState({ licenceDOB: this.state.maxDob});  
            }

            this.setState({ licenceGender: attempt_data.activation_attempt.gender}); 
            let name=attempt_data.activation_attempt.firstName+' '+attempt_data.activation_attempt.surname;
            this.setState({ medicareFirstName: attempt_data.activation_attempt.firstName}); 
            this.setState({ medicareMiddleName: attempt_data.activation_attempt.middleName}); 
            this.setState({ medicareLastName: attempt_data.activation_attempt.surname}); 
            this.setState({ medicareGender: attempt_data.activation_attempt.gender});
            this.setState({ session_id: attempt_data.activation_attempt.session_id});
            this.setState({ sim: attempt_data.activation_attempt.sim_number});
            this.setState({ dvs_status_check: attempt_data.activation_attempt.customer.dvs_status});

            //if(this.state.dvs_status_check ==1){ this.props.history.push("/IDsuccess");  }
            if(this.state.dvs_status_check ==1)
            {
                // window.location.href = '/IDsuccess';
                this.props.history.push("/IDsuccess");
            }
            
            if(attempt_data.activation_attempt.customer.dvs_attempt_count >= 2){ 
                this.setState({ dvs_update_status: false});
            }


        if(attempt_data.activation_attempt.sim_number !=''){ this.setState({ sim: attempt_data.activation_attempt.sim_number }); }
        
            if(attempt_data.activation_attempt.dvs_data)
            {

                // Passport
                if(attempt_data.activation_attempt.dvs_data.dvs_type == 'Passport')
                {
                    this.setState({ idType: 1 });
                    this.setState({ passportFName: attempt_data.activation_attempt.dvs_data.dvs_passportFName });
                    this.setState({ passportLName: attempt_data.activation_attempt.dvs_data.dvs_passportLName });
                    this.setState({ passportCountry: attempt_data.activation_attempt.dvs_data.dvs_passportCountry });
                    this.setState({ passportNumber: attempt_data.activation_attempt.dvs_data.dvs_passportNumber });
                    // this.setState({ passportExpiry: attempt_data.activation_attempt.dvs_data.dvs_passportExpiry });
                    this.setState({ passportDOB: attempt_data.activation_attempt.dvs_data.dvs_dob });
                    this.setState({ passportGender: attempt_data.activation_attempt.dvs_data.dvs_gender });
                        
                }
                // Passport

                // Licence
                if(attempt_data.activation_attempt.dvs_data.dvs_type == 'Australian Driving License')
                {
                this.setState({ idType: 2 });
                this.setState({ licenceNumber: attempt_data.activation_attempt.dvs_data.dvs_licenceNumber });
                this.setState({ licenceCardNumber: attempt_data.activation_attempt.dvs_data.dvs_licenceCardNumber });
                this.setState({ licenceFName: attempt_data.activation_attempt.dvs_data.dvs_licencefirstName });
                this.setState({ licenceLname: attempt_data.activation_attempt.dvs_data.dvs_licenceLlastName });
                this.setState({ licenceState: attempt_data.activation_attempt.dvs_data.dvs_licenceState });

                if(attempt_data.activation_attempt.dvs_data.dvs_licenceState == 'QLD' || attempt_data.activation_attempt.dvs_data.dvs_licenceState == 'VIC'){
                    this.setState({ mandatoryCard : false });
                }else{
                    this.setState({ mandatoryCard : true });
                }

                // this.setState({ licenceExpiry: attempt_data.activation_attempt.dvs_data.dvs_licenceExpiry });
                this.setState({ licenceDOB: attempt_data.activation_attempt.dvs_data.dvs_dob });
                this.setState({ licenceGender: attempt_data.activation_attempt.dvs_data.dvs_gender });
                }
                // Licence

                //medicare
                if(attempt_data.activation_attempt.dvs_data.dvs_type == 'Medicare Card')
                {
                this.setState({ idType: 3 });
                this.setState({ medicareCardNumber: attempt_data.activation_attempt.dvs_data.dvs_medicareCardNumber });
                this.setState({ medicareFirstName: attempt_data.activation_attempt.dvs_data.dvs_medicareFirstName });
                this.setState({ medicareMiddleName: attempt_data.activation_attempt.dvs_data.dvs_medicareMiddleName });
                this.setState({ medicareLastName: attempt_data.activation_attempt.dvs_data.dvs_medicareLastName });
                this.setState({ medicareGender: attempt_data.activation_attempt.dvs_data.dvs_gender });
                this.setState({ medicareIRN: attempt_data.activation_attempt.dvs_data.dvs_medicareIRN });
                this.setState({ medicareColour: attempt_data.activation_attempt.dvs_data.dvs_medicareColour });
                this.setState({ medicareExpiry: attempt_data.activation_attempt.dvs_data.dvs_medicareExpiry });
                }
                //medicare

                this.setState({ dvsCheck: true});
            }

        }




    };




    changeID = (type) => { this.setState({ idType: type});}

    // Passport
    passportFName = (event) => { this.setState({ passportFName: event.target.value});}
    passportLName = (event) => { this.setState({ passportLName: event.target.value});}
    passportCountry = (country) => { this.setState({ passportCountry: country});}
    passportNumber = (event) => { this.setState({ passportNumber: event.target.value});}
    // passportExpiry = (value) => { this.setState({ passportExpiry: value});}
    passportDOB = (value) => { this.setState({ passportDOB: value});}
    passportGender = (gender) => { this.setState({ passportGender: gender});}
    // Passport


     // Licence
    licenceNumber = (event) => { this.setState({ licenceNumber: event.target.value});}
    licenceCardNumber = (event) => { this.setState({ licenceCardNumber: event.target.value});}
    licenceFName = (event) => { this.setState({ licenceFName: event.target.value});}
    licenceLname = (event) => { this.setState({ licenceLname: event.target.value});}
    licenceState = (value) => { 

        this.setState({ licenceState: value});

        if(value == 'QLD' || value == 'VIC'){
        this.setState({ mandatoryCard: false});
        }else{
        this.setState({ mandatoryCard: true});
        }

    }
    // licenceExpiry = (value) => { this.setState({ licenceExpiry: value});}
    licenceDOB = (value) => {    this.setState({ licenceDOB: value});}
    licenceGender = (gender) => { this.setState({ licenceGender: gender});}
    // Licence



    //medicare
    medicareCardNumber = (event) => { this.setState({ medicareCardNumber: event.target.value});}
    medicareFirstName = (event) => { this.setState({ medicareFirstName: event.target.value});}
    medicareMiddleName = (event) => { this.setState({ medicareMiddleName: event.target.value});}
    medicareLastName = (event) => { this.setState({ medicareLastName: event.target.value});}
    medicareGender = (value) => { this.setState({ medicareGender: value});}
    medicareIRN = (event) => { this.setState({ medicareIRN: event.target.value});}
    medicareColour = (value) => { this.setState({ medicareColour: value});}
    medicareExpiry = (value) => { this.setState({ medicareExpiry: value});}
    //medicare


    dvsCheckbox = (check) => { this.setState({ dvsCheck: check});}





    myDVSHandler = async (event) => { 
        this.setState({loading:true});
        event.preventDefault();  

        if(this.state.dvsCheck==true)
        {


         const post_data =   {  

                                dvsCheck : this.state.dvsCheck,
                                service_type : this.state.idType,
                                sim : this.state.sim,
                                passportFName : this.state.passportFName,
                                passportLName : this.state.passportLName,
                                passportCountry : this.state.passportCountry,
                                passportNumber : this.state.passportNumber,
                                // passportExpiry : this.state.passportExpiry,
                                passportDOB : this.state.passportDOB,
                                passportGender : this.state.passportGender,
                                licenceNumber : this.state.licenceNumber,
                                licenceCardNumber : this.state.licenceCardNumber,
                                licenceFName : this.state.licenceFName,
                                licenceLName : this.state.licenceLname,
                                licenceState : this.state.licenceState,
                                // licenceExpiry : this.state.licenceExpiry,
                                licenceDOB : this.state.licenceDOB,
                                licenceGender : this.state.licenceGender,
                                medicareCardNumber : this.state.medicareCardNumber,
                                medicareFirstName : this.state.medicareFirstName,
                                medicareMiddleName : this.state.medicareMiddleName,
                                medicareLastName : this.state.medicareLastName,
                                medicareGender : this.state.medicareGender,
                                medicareIRN : this.state.medicareIRN,
                                medicareColour : this.state.medicareColour,
                                medicareExpiry : this.state.medicareExpiry,
                                session_id :this.state.session_id,
                            };
        console.log(post_data);
        let headers     = await getApiHeader();
        let res         = await axios.post(`${ SERVER_URL }activation/verify/dvs`,post_data, { headers }).then(response => {
        //console.log(response.data.status);  
            this.setState({ customerror: ''});
            //this.props.nextStep();
             //alert("done");

            //  window.location.href = '/IDsuccess'; 

             this.props.history.push("/IDsuccess");

             //if(response.data.status==200){ this.props.history.push("/IDsuccess");  }
            
        }).catch(error => { 
            
            if((typeof(error.response) !== 'undefined'))
            {
                    if((typeof(error.response.data.message) !== 'undefined') && (typeof(error.response.data) !== 'undefined'))
                    {
                       this.setState({ customerror: error.response.data.message});
                    }
                    else
                    {
                        this.setState({ customerror: "Internal Server Error."});
                    }
            }
            else
            {
               this.setState({ customerror: "Internal Server Error."});
            }
           

            
        });

        }
        else
        {
            this.setState({ customerror: "Please Confirm CheckBox"});
        }
        
        this.setState({loading:false});
    }



    handleKeypress = (e) => {
        e.preventDefault();
        return false
    }





    render() {


       const { values } = this.props;

       return (
     <>
            {(this.state.loading === true) ? <PageLoader/> : null}

            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
              <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
                        <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                            <div className="kt-portlet">
                                <div className="kt-portlet__head">
                                    <div className="kt-portlet__head-label">
                                        <h3 className="kt-portlet__head-title">ID Validation</h3>
                                    </div>
                                </div>
                                {this.state.dvs_update_status === true ? (
                                <form className="kt-form kt-form--label-right IDverification" id="kt_form" method="post" onSubmit={this.myDVSHandler}>
                                                        
                                        <div className="kt-wizard-v2__content" data-ktwizard-type="step-content">
                                                                <label style={{textAlign: 'justify'}}><b>If you change your personal details here, it will be automatically updated to your profile.</b></label>
                                                                <div className="kt-separator kt-separator--height-xs"></div>
                                                                <div className="kt-form__section kt-form__section--first" id="card_section">

                                                                    <div className="row">

                                                                        

                                                                        <div className="col-lg-6" style={{paddingRight: '20px'}}>
                                                                            <div className="form-group">
                                                                                <label>ID types:</label>
                                                                                <select className="form-control kt_selectpicker" name="service_type" onChange={e => {this.changeID(e.target.value);}} value={this.state.idType} required>
                                                                                    <option value="">Select Type </option>
                                                                                    <option value="1">Passport</option>
                                                                                    <option value="2">Australian Driving License</option>
                                                                                    <option value="3">Medicare Card</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>


                                                                                
                                                                                
                                                                                
                                                                                



                                                                        {this.state.idType ==1 && 
                                                                        <div className="col-lg-6 pp_div spcpadding">
                                                                            <div className="form-group">
                                                                                <label>Issuing country:</label>
                                                                                <select className="form-control kt_selectpicker" name="pp_country" onChange={e => {this.passportCountry(e.target.value);}} value={this.state.passportCountry} required>
                                                                                    <option value="">Select Country </option>
                                                                                    { this.state.all_countries.map((country) =>  
                                                                                    <option value={country.country_code}>{country.name}</option>
                                                                                    )}
                                                                                    
                                                                                    
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        }



                                                                    {this.state.idType==1 &&
                                                                    <div className="form-group row">

                                                                        <div className="col-lg-12 pp_div">
                                                                                <div className="form-group">
                                                                                    <label style={{color:'red'}}>First name and Last name should match with visa issued by AU</label>
                                                                                </div>
                                                                        </div>


                                                                        <div className="col-lg-6 pp_div">
                                                                                <div className="form-group">
                                                                                    <label>First name:</label>
                                                                                    <input type="text" className="form-control" name="adl_number" placeholder="Enter Passport First name"  onChange={this.passportFName} defaultValue={this.state.passportFName} pattern='[A-Za-z\-\s]*' required/>
                                                                                    
                                                                                </div>
                                                                        </div>

                                                                        <div className="col-lg-6 pp_div">
                                                                                <div className="form-group">
                                                                                    <label>Last name:</label>
                                                                                    <input type="text" className="form-control" name="adl_number" placeholder="Enter Passport Last name"  onChange={this.passportLName} defaultValue={this.state.passportLName} pattern='[A-Za-z\-\s]*' required />
                                                                                    
                                                                                </div>
                                                                        </div>


                                                                    <div className="col-lg-6 pp_div">
                                                                            <div className="form-group">
                                                                                <label>Passport Number:</label>
                                                                                <input type="text" className="form-control" name="adl_number" placeholder="Enter Passport Number"  onChange={this.passportNumber} defaultValue={this.state.passportNumber} required />
                                                                                
                                                                            </div>
                                                                        </div>

                                                                        {/* <div className="col-lg-6 pp_div">
                                                                            <div className="form-group">
                                                                                <label>Date of expiry:</label>

                                                                                <div>
                                                                                <DatePicker
                                                                                    onChange={this.passportExpiry}
                                                                                    value={this.state.passportExpiry}
                                                                                    className="form-control customdate"
                                                                                    format="y-MM-dd"
                                                                                    minDate={new Date()}
                                                                                    required
                                                                                />
                                                                                </div>

                                                                            </div>
                                                                        </div> */}
                                                                        <div className="col-lg-6 pp_div">
                                                                            <div className="form-group">
                                                                                <label>Date of birth:</label>
                                                                                
                                                                                <div>
                                                                                <DatePicker
                                                                                    onChange={this.passportDOB}
                                                                                    value={this.state.passportDOB}
                                                                                    className="form-control customdate"
                                                                                    format="y-MM-dd"
                                                                                    minDate={this.state.minDob}
                                                                                    maxDate={this.state.maxDob}
                                                                                    required
                                                                                    onKeyPress={ this.handleKeypress}
                                                                                    onFocus={e => e.target.blur()}
                                                                                />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6 pp_div">
                                                                            <div className="form-group">
                                                                                <label>Gender:</label>
                                                                                <select className="form-control kt_selectpicker gender" name="pp_gender" id="pp_gender" onChange={e => {this.passportGender(e.target.value);}} value={this.state.passportGender}>
                                                                                    <option value="">Select Gender</option>
                                                                                    <option value="Male">Male</option>
                                                                                    <option value="Female">Female</option>
                                                                                    <option value="-">Other</option>

                                                                                </select>
                                                                                
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    }
                                                                        

















                                                                    {this.state.idType ==2 && 
                                                                        <div className="col-lg-6 adl_div spcpadding">
                                                                            <div className="form-group">
                                                                                <label>License number:</label>
                                                                                <input type="text" className="form-control" name="adl_number" placeholder="Enter License Number" maxlength="20"  title="Enter valid license number"   onChange={this.licenceNumber} defaultValue={this.state.licenceNumber} required/>
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                    {this.state.idType ==2 &&
                                                                <div className="form-group row">

                                                                        <div className="col-lg-6 adl_div">
                                                                            <div className="form-group">
                                                                                <label>First name:</label>
                                                                                <input type="text" className="form-control" placeholder="Enter First Name" title="Enter a valid name" pattern='[A-Za-z\-\s]*' onChange={this.licenceFName} defaultValue={this.state.licenceFName} required />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6 adl_div">
                                                                            <div className="form-group">
                                                                                <label>Last name:</label>
                                                                                <input type="text" className="form-control" placeholder="Enter Last Name" title="Enter a valid name" pattern='[A-Za-z\-\s]*' onChange={this.licenceLname} defaultValue={this.state.licenceLname} required />
                                                                            </div>
                                                                        </div>

                                                                        

                                                                        <div className="col-lg-6 adl_div">
                                                                            <div className="form-group">
                                                                                <label>Issuing state:</label>
                                                                                <select className="form-control kt_selectpicker" name="adl_state" onChange={e => {this.licenceState(e.target.value);}} value={this.state.licenceState} required>
                                                                                    <option value="">Select State</option> 
                                                                                    <option value="ACT">ACT</option>
                                                                                    <option value="NSW">NSW</option>
                                                                                    <option value="NT">NT</option>
                                                                                    <option value="QLD">QLD</option>
                                                                                    <option value="SA">SA</option>
                                                                                    <option value="TAS">TAS</option>
                                                                                    <option value="VIC">VIC</option>
                                                                                    <option value="WA">WA</option> 
                                                                                </select>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-lg-6 adl_div">
                                                                            <div className="form-group">
                                                                                <label>Card number:</label>

                                                                                {this.state.mandatoryCard == true ? 
                                                                                <input type="text" className="form-control" name="adl_cnumber" placeholder="Enter Card Number" min="0" maxlength="20" pattern='[A-Za-z0-9]{4,20}'  title="Enter valid card number" onChange={this.licenceCardNumber} defaultValue={this.state.licenceCardNumber} required/>
                                                                                :
                                                                                <input type="text" className="form-control" name="adl_cnumber" placeholder="Enter Card Number" min="0" maxlength="20"  title="Enter valid card number" onChange={this.licenceCardNumber} defaultValue={this.state.licenceCardNumber}/>
                                                                                }

                                                                            </div>
                                                                        </div>


                                                                        {/* <div className="col-lg-6 adl_div">
                                                                            <div className="form-group">
                                                                                <label>Date of expiry:</label>
                                                                            
                                                                                
                                                                                <div>
                                                                                <DatePicker
                                                                                    onChange={this.licenceExpiry}
                                                                                    value={this.state.licenceExpiry}
                                                                                    className="form-control customdate"
                                                                                    format="y-MM-dd"
                                                                                    minDate={new Date()}
                                                                                    required
                                                                                />
                                                                                </div>

                                                                            </div>
                                                                        </div> */}
                                                                        <div className="col-lg-6 adl_div">
                                                                            <div className="form-group">
                                                                                <label>Date of birth:</label>
                                                                                
                                                                                
                                                                                <div>
                                                                                <DatePicker
                                                                                    onChange={this.licenceDOB}
                                                                                    value={this.state.licenceDOB}
                                                                                    className="form-control customdate"
                                                                                    format="y-MM-dd"
                                                                                    minDate={this.state.minDob}
                                                                                    maxDate={this.state.maxDob}
                                                                                    required
                                                                                    onKeyPress={ this.handleKeypress}
                                                                                    onFocus={e => e.target.blur()}
                                                                                />
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6 adl_div">
                                                                            <div className="form-group">
                                                                                <label>Gender:</label>
                                                                                <select className="form-control kt_selectpicker gender" name="adl_gender" id="adl_gender" onChange={e => {this.licenceGender(e.target.value);}} value={this.state.licenceGender} >
                                                                                    <option value="">Select Gender</option>
                                                                                    <option value="Male">Male</option>
                                                                                    <option value="Female">Female</option>
                                                                                    <option value="-">Other</option>
                                                                                </select>
                                                                                
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    }














                                                                    {this.state.idType ==3 && 

                                                                        <div className="col-lg-6 mc_div spcpadding">
                                                                            <div className="form-group">
                                                                                <label>Card number:</label>
                                                                                <input type="text" className="form-control" name="mc_number" placeholder="Enter Card Number" min="0" maxlength="10"  title="Enter valid card number" pattern="[1-9]{1}[0-9]{9}"  onChange={this.medicareCardNumber} defaultValue={this.state.medicareCardNumber} required/>
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                    {this.state.idType ==3 && 
                                                                    <div className="form-group row">

                                                                        <div className="col-lg-6 mc_div">
                                                                            <div className="form-group">
                                                                                <label>First Name :</label>
                                                                                <input type="text" className="form-control first_name" name="mc_firstname_line1" placeholder="Enter First Name" title="Enter a valid name" onChange={this.medicareFirstName} defaultValue={this.state.medicareFirstName} pattern='[A-Za-z\-\s]*' required/>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-lg-6 mc_div">
                                                                            <div className="form-group">
                                                                                <label>Middle Name :</label>
                                                                                <input type="text" className="form-control middle_name" name="mc_middlename_line1" placeholder="Enter Middle Name" title="Enter a valid name" onChange={this.medicareMiddleName} defaultValue={this.state.medicareMiddleName} pattern='[A-Za-z\-\s]*' />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6 mc_div">
                                                                            <div className="form-group">
                                                                                <label>Last Name :</label>
                                                                                <input type="text" className="form-control last_name" name="mc_lastname_line1" placeholder="Enter Last Name" title="Enter a valid name" onChange={this.medicareLastName} defaultValue={this.state.medicareLastName} pattern='[A-Za-z\-\s]*' required/>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-lg-6 mc_div">
                                                                            <div className="form-group">
                                                                                <label>Gender:</label>
                                                                                <select className="form-control kt_selectpicker gender" name="mc_gender" id="mc_gender" onChange={e => {this.medicareGender(e.target.value);}} value={this.state.medicareGender}  >
                                                                                    <option value="">Select Gender</option>
                                                                                    <option value="Male">Male</option>
                                                                                    <option value="Female">Female</option>
                                                                                    <option value="-">Other</option>
                                                                                </select>
                                                                                
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6 mc_div">
                                                                            <div className="form-group">
                                                                                <label>Individual Reference Number (IRN)</label>
                                                                                <input type="number" min="0" max="9" maxlength="1"   className="form-control" name="mc_irn" placeholder="Enter Individual Reference Number"  onChange={this.medicareIRN} defaultValue={this.state.medicareIRN} required/>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6 mc_div">
                                                                            <div className="form-group">
                                                                                <label>Colour of the card:</label>
                                                                                <select className="form-control kt_selectpicker" name="mc_color" onChange={e => {this.medicareColour(e.target.value);}} value={this.state.medicareColour} required>
                                                                                    <option value="">Select Colour </option>
                                                                                    <option value="Y">Yellow</option>
                                                                                    <option value="B">Blue</option>
                                                                                    <option value="G">Green</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        <div className="col-lg-6 mc_div_exp_dt">
                                                                            <div className="form-group">
                                                                                <label>Date of expiry:</label>
                                                                                
                                                                                <div>
                                                                                <DatePicker
                                                                                    onChange={this.medicareExpiry}
                                                                                    value={this.state.medicareExpiry}
                                                                                    className="form-control customdate"
                                                                                    format="y-MM-dd"
                                                                                    minDate={new Date()}
                                                                                    required
                                                                                />
                                                                                </div>
                                                                                <label style={{color: 'red',fontWeight: '400'}}>Please select the last day of the Medicare valid to date.</label>
                                                                            </div>
                                                                        </div>
                                                                    
                                                                    
                                                                    </div>
                                                                    }







                                                                    </div>
                                                                    <div className="form-group  row">
                                                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                                                            <label className="kt-option">
                                                                                <label className="kt-checkbox kt-checkbox--bold kt-checkbox--danger">
                                                                                    <input type="checkbox" name="authorised_check" id="authorised_check" onChange={e => {this.dvsCheckbox(e.target.checked);}} defaultChecked={this.state.dvsCheck} required />
                                                                                    <span></span>
                                                                                </label>
                                                                                <span className="kt-option__label">
                                                                                    <span className="kt-option__head">
                                                                                        <span className="kt-option__title">
                                                                                            I confirm that I am authorised to provide the personal details presented and I consent to my information being checked with the document issuer or official record holder via third party systems for the purpose of confirming my identity.
                                                                                        </span>

                                                                                    </span>
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    {this.state.customerror != '' &&
                                                                    <span className="" role="alert"><span id="authorized_error" style={{color: 'red',fontWeight: '400'}}><i className="fa fa-exclamation-triangle"></i> {this.state.customerror}</span></span>
                                                                    }
                                                            </div>


                                                            
                                                            
                                        
                                        <br/>
                                        <div className="kt-form__actions">
                                        {/* <button type="submit" className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm" id="backBtn" onClick={this.back}>Previous</button>&nbsp; */}
                                        <button type="submit" className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm" id="wizard_next_btn" data-ktwizard-type="action-next">Save</button>
                                        <br/>
                                        <br/>
                                        <br/>
                                        </div>

                                    </div>      
                                </form> 
                                ) : (
                                    <div className="row" style={{marginTop:'5px'}}>
                                        <h4 style={{color:'red'}}>Thank you for choosing Telsim. Your new Telsim SIM activation is on hold. Call 1300 TELSIM to verify your ID document.</h4>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>


            </>  
   )
}
}

export default withRouter(IDverification);
