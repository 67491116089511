import React, { Component, useEffect } from "react";
import {withRouter} from "react-router-dom";
import axios from 'axios';    
import {getApiHeader,removeUserSession} from '../../utils/CommonFunctions';  
import { Link} from "react-router-dom";
import swal from '@sweetalert/with-react';
import PageLoader from './../Loader/loader';
import maleUserImg from './../../assets/media/users/computer-icons-user-profile-avatar.jpg';
import femaleUserImg from './../../assets/media/users/user_female_avatar.png';
import {Pie, Doughnut} from 'react-chartjs-2';
 import image1 from "./../../assets/media/offer-img/1-fade.png"
 import image2 from "./../../assets/media/offer-img/2-fade.png"
 import image3 from "./../../assets/media/offer-img/3-fade.png"
 import image4 from "./../../assets/media/offer-img/4-fade.png"
 import image5 from "./../../assets/media/offer-img/5-fade.png"
 import image6 from "./../../assets/media/offer-img/6-fade.png"
 import image7 from "./../../assets/media/offer-img/background.png"
 import image8 from "./../../assets/media/offer-img/1tick.png"
 import image9 from "./../../assets/media/offer-img/2tick.png"
 import image10 from "./../../assets/media/offer-img/3tick.png"
 import image11 from "./../../assets/media/offer-img/4tick.png"
 import image12 from "./../../assets/media/offer-img/5tick.png"
 import image13 from "./../../assets/media/offer-img/6tick.png"
 import image14 from "./../../assets/media/offer-img/discount-200X200.png"
 import image15 from "./../../assets/media/offer-img/tick.png"



const SERVER_URL 	= process.env.REACT_APP_SERVER_URL; 




class Home extends Component { 


	state = {
		customer_activations: [],
		account_summary : [], 
		selected_number: "",
		selected_mobile_number:"",
		customMesssge: null,
		loading:false,
		graph_data : {	
						labels: ['Used Data', 'Balance Data'],
						datasets: [
							{
							label: 'Rainfall',
							backgroundColor: [
								'#e41e26',
								'#4ac959'
							],
							hoverBackgroundColor: [
							'#e41e26',
							'#4ac959'
							],
							data: [0, 0]
							}
						]
					},
		activeShow:true,
		graph_usedData:0,
		graph_TotalData:0,
		data_gift_sent:0,
		data_gift_received:0,
		sixth_recharge_status:false,
		recharge_count:0,
		no_recharge:false

		
	}

	
	componentDidMount() {

		this.setState({ loading: true });
		this.setState({ loading: false, activeShow: true });


		this.getUserStatus()
		this.getActivations()
		const { location } = this.props;
		if (location.state && location.state.selectedNumber && location.state.selectedActivation) {
			this.setState({
				selected_number: location.state.selectedActivation,
				selected_mobile_number :location.state.selectedNumber
			});
			this.getAccountSummary(location.state.selectedActivation);
		}

		
	}

	
	handleChange =  (e) => { 
		var active_number = e.target.value; 
		this.setState({
			selected_number: active_number, 
		}); 
		this.getAccountSummary(active_number);  
	};


	getUserStatus = async () => {
		let headers 	= await getApiHeader();
        let res         = await axios.get(`${ SERVER_URL }user_status`, { headers }).then(async response => {
            
			if(response.data.data.forceLogout){
				if(response.data.data.forceLogout != 'undefined'){
					if(response.data.data.forceLogout == '1'){
						this.handleLogout();
					}
				}
			}
            
        }).catch(error => {            
        });
 
	};




	getActivations = async () => {
		let headers 	= await getApiHeader();
        let res         = await axios.get(`${ SERVER_URL }user_activations`, { headers }).then(async response => {
            
			if(response.data.data.customMessage){
				if(response.data.data.customMessage != 'undefined'){
					this.setState({customMesssge:response.data.data.customMessage});
				}
			}


            let data 		= response.data.data.list; 
			let selected_number = '';
			const { location } = this.props;
			if(data.length > 0){
				
				if (location.state && location.state.selectedNumber && location.state.selectedActivation) {
					selected_number = location.state.selectedActivation;
					
				}else{
					selected_number = data[0].activation_id;
				}
			}else{ 
				
				selected_number = null;
			}
			sessionStorage.setItem('selected_number', selected_number);
			this.setState({
				selected_number: selected_number, 
			}); 
			await this.setState({ customer_activations: data }); 
			await this.getAccountSummary(selected_number);  
			await this.setState({loading:false});
            
        }).catch(error => {
			this.setState({loading:false});
            if((typeof(error.response.data.message) !== 'undefined') && (typeof(error.response.data) !== 'undefined'))
            {
 
               //swal(error.response.data.message, "", "warning");
			   swal({
                text: error.response.data.message,
				allowOutsideClick: false,
				closeOnClickOutside: false, 
                icon: "warning"}).then(()=>{

                    this.handleLogout();

                });
            }
            else
            { 
				swal("Internal Server Error", "", "warning");
            }

            
        });
 
	};

	handleLogout = async () => {  

        

        let p = new Promise(async (resolve, reject)=> {

            let headers     = await getApiHeader();
            let res 		= await axios.get(`${ SERVER_URL }logout`, { headers });
            let data 		= res.data.data;   
            console.log(data);

            removeUserSession();  
            resolve();

        });

        p.then(
            ()=>{ 

                    this.props.history.push("/login");

            },
            ()=>{
                alert("Error in logout");
            }
        )
        
    }

	getAccountSummary = async (number) => {

		this.setState({ loading:true});
		this.setState({ activeShow: true });

		try{

		let headers 	= await getApiHeader(); 
		await sessionStorage.setItem('selected_number', number);
		const post_data = { mobile_number: number };
		let res 		= await axios.post(`${ SERVER_URL }account_summary`,post_data, { headers });
		let data 		= res.data.data.summary; 
		let total_data_balance 	= data.data_balance;
		let graph_data_used 	= data.data_used + data.data_bank_used;
		let graph_data_balance 	= data.data_balance + data.data_bank_balance; 

		let graph_TotalData 	= data.data_bank_allowance + data.data_allowance; 
		let graph_usedData 		= data.data_used + data.data_bank_used; 
		this.setState({ graph_TotalData: graph_TotalData }); 
		this.setState({ graph_usedData: graph_usedData }); 
		 this.setState({sixth_recharge_status:data.sixth_recharge_status})
		//  this.setState({recharge_count:0})
		this.setState({recharge_count:data.recharge_count})
		this.setState({no_recharge:data.no_recharge})
		// this.setState({no_recharge:false})

		
		

		if(data.activation_status != 1){
			this.setState({ activeShow: false });
		} 
		// if(total_data_balance){
		// 	graph_data_balance = data_balance;
		// }
		 

		var garph_details = {	
								labels: ['Used Data', 'Balance Data'],
								datasets: [
									{
									label: 'Rainfall',
									backgroundColor: [
										'#e41e26',
										'#4ac959'
									],
									hoverBackgroundColor: [
									'#e41e26',
									'#4ac959'
									],
									data: [graph_data_used, graph_data_balance]
									}
								]
							}
		this.setState({ graph_data: garph_details }); 
		this.setState({ account_summary: data }); 

		if(typeof(data.gift_data_sent) !='undefined'){
			if(typeof(data.gift_data_sent) != null){ this.setState({ data_gift_sent:data.gift_data_sent});  }
		}

		if(typeof(data.gift_data_received) !='undefined'){
			if(typeof(data.gift_data_received) != null){ this.setState({ data_gift_received:data.gift_data_received});  }
		}


        this.setState({ loading:false});

		}
        catch(err)
        {

            this.setState({loading:false});

			if((typeof(err.response.data.message) !== 'undefined') && (typeof(err.response.data) !== 'undefined'))
            {
 
				swal({
					text: err.response.data.message,
					icon: "error"});
            }else{

				swal({
					text: "Not available",
					icon: "error"});
			}
            

        }


	};


	gotoextraRecharge = async () => {
		window.sessionStorage.setItem("recharge", "pay_as_you_go");
		// window.location.href = "/Recharge";
		// this.props.history.push("/Recharge?extras");

		this.props.history.push({
			pathname: '/Recharge',
			state: {activeSection:'pay_as_you_go'}
		  })

	};






	

render() {




	var account_details = this.state.account_summary; 
	let isShow = true;
	let total_data = 0;
	var main_balance_balance = "";
	var data_bank_balance = "";
	let isIddShow 			= false;  
	let selected_no 		= account_details.mobile_number; 
	var graph_TotalData 	= this.state.graph_TotalData;
	var graph_usedData 		= this.state.graph_usedData;
	let not_active_message  = ""; 
	const { recharge_count, no_recharge } = this.state;

	let displayed_recharge_count = recharge_count;
	if (no_recharge) {
		displayed_recharge_count = 0;
	} else if (recharge_count === 0) {
		displayed_recharge_count = 6;
	}



	if(account_details){
		if((account_details.gender) && (account_details.gender == 'Male')) {
			var profile_image = maleUserImg;
		} else if ( (account_details.gender) && (account_details.gender == 'Female')) {
			var profile_image = femaleUserImg;
		}else{
			var profile_image = maleUserImg;
		} 
		total_data = parseInt(account_details.data)+parseInt(account_details.bonus_data);
		if(account_details.main_balance_balance){
			main_balance_balance = "$"+account_details.main_balance_balance;
		}else{
			main_balance_balance = "";
		}
		if(account_details.idd_pack_balance){
			isIddShow = true;
		}

		if(account_details.activation_status == 9){
			not_active_message = "Mobile Number "+selected_no+" has been disconnected. If you have any queries, please feel free to contact Telsim Customer Care through chat or email at support@telsim.com.au or call us at 1300-TELSIM.";
		}else{
			not_active_message = "Mobile Number "+selected_no+" is not active. Your request is in progress. We will send you an email when the activation is complete. If you have any queries, please feel free to contact Telsim Customer Care through chat or email at support@telsim.com.au or call us at 1300-TELSIM.";
		}
		
	}else{ 
		isShow = false;
	}


	

	 
  return (
    <>
<div className="top-container" >
{this.state.sixth_recharge_status &&(
	<div className="special-offer">
			  <div className="row">
						  <div className="col-lg-5 col-md-12 col-sm-12">
							  <h5 className="offer-title">AFTER EVERY 5 RECHARGES <sup>*</sup><br />
								  GET 50% OFF ON THE NEXT RECHARGE!</h5>
							  <p className="offer-details">* 50% off offer is valid on the  6<sup>th</sup> recharge after five consecutive recharges. <br /><span style= {{ fontSize:"6px" }}><sup> * </sup>T&C Apply </span></p> 

						  </div>
						  
				  <div className="col-lg-6 col-md-12 col-sm-12 circle-container">
					 {displayed_recharge_count === 0 && (
						  <>
							  {/* <img className="circle" src={image8} alt="Image 8" />  */}

							  <img className="circle2" src={image1} alt="Image 1" />
							  <img className="circle2" src={image2} alt="Image 2" />
							  <img className="circle2" src={image3} alt="Image 3" />
							  <img className="circle2" src={image4} alt="Image 4" />
							  <img className="circle2" src={image5} alt="Image 5" />
							  <img className="circle2" src={image6} alt="Image 6" />
							  <img className="offer-circle" src={image14} alt="Image 14" />
						  </>
					  )}
					  {displayed_recharge_count === 1 && (
						  <>
							  <img className="circle" src={image8} alt="Image 8" />
							  <img className="circle2" src={image1} alt="Image 1" />
							  <img className="circle2" src={image2} alt="Image 2" />
							  <img className="circle2" src={image3} alt="Image 3" />
							 
							  <img className="circle2" src={image4} alt="Image 4" />
							  <img className="circle2" src={image5} alt="Image 5" />
							  <img className="circle2" src={image6} alt="Image 6" />
							  <img className="offer-circle" src={image14} alt="Image 14" />
						  </>
					  )}

					  {displayed_recharge_count === 2 && (
						  <>
							  <img className="circle" src={image8} alt="Image 9" />
							  <img className="circle2" src={image1} alt="Image 1" />
							  <img className="circle" src={image9} alt="Image 1" />
							  <img className="circle2" src={image2} alt="Image 2" />
							  <img className="circle2" src={image3} alt="Image 3" />
							  <img className="circle2" src={image4} alt="Image 4" />
							  <img className="circle2" src={image5} alt="Image 5" />
							  <img className="circle2" src={image6} alt="Image 6" />
							  <img className="offer-circle" src={image14} alt="Image 14" />
						  </>
					  )}

					  {displayed_recharge_count === 3 && (
						  <>
							  <img className="circle" src={image8} alt="Image 9" />
							  <img className="circle2" src={image1} alt="Image 1" />
							  <img className="circle" src={image9} alt="Image 1" />
							  <img className="circle2" src={image2} alt="Image 2" />
							  <img className="circle" src={image10} alt="Image 10" />
							  <img className="circle2" src={image3} alt="Image 3" />
							  <img className="circle2" src={image4} alt="Image 4" />
							  <img className="circle2" src={image5} alt="Image 5" />
							  <img className="circle2" src={image6} alt="Image 6" />
							  <img className="offer-circle" src={image14} alt="Image 14" />
						  </>
					  )}
					  {displayed_recharge_count === 4 && (
						  <>
							  <img className="circle" src={image8} alt="Image 9" />
							  <img className="circle2" src={image1} alt="Image 1" />
							  <img className="circle" src={image9} alt="Image 1" />
							  <img className="circle2" src={image2} alt="Image 2" />
							  <img className="circle" src={image10} alt="Image 10" />
							  <img className="circle2" src={image3} alt="Image 3" />

							  <img className="circle" src={image11} alt="Image 10" />
							  <img className="circle2" src={image4} alt="Image 4" />
							  <img className="circle2" src={image5} alt="Image 5" />
							  <img className="circle2" src={image6} alt="Image 6" />
							  <img className="offer-circle" src={image14} alt="Image 14" />
						  </>
					  )}
					  {displayed_recharge_count === 5 && (
						  <>
							  <img className="circle" src={image8} alt="Image 9" />
							  <img className="circle2" src={image1} alt="Image 1" />
							  <img className="circle" src={image9} alt="Image 1" />
							  <img className="circle2" src={image2} alt="Image 2" />
							  <img className="circle" src={image10} alt="Image 10" />
							  <img className="circle2" src={image3} alt="Image 3" />

							  <img className="circle" src={image11} alt="Image 10" />
							  <img className="circle2" src={image4} alt="Image 4" />
							  <img className="circle" src={image12} alt="Image 12" />
							  <img className="circle2" src={image5} alt="Image 5" />
							  {/* <img className="circle" src={image13} alt="Image 13" /> */}
							  <img className="circle2" src={image6} alt="Image 6" />
							  <img className="offer-circle" src={image14} alt="Image 14" />
						  </>
					  )}
					  {displayed_recharge_count === 6 && (
						  <>
							  <img className="circle" src={image8} alt="Image 9" />
							  <img className="circle2" src={image1} alt="Image 1" />
							  <img className="circle" src={image9} alt="Image 1" />
							  <img className="circle2" src={image2} alt="Image 2" />
							  <img className="circle" src={image10} alt="Image 10" />
							  <img className="circle2" src={image3} alt="Image 3" />

							  <img className="circle" src={image11} alt="Image 10" />
							  <img className="circle2" src={image4} alt="Image 4" />
							  <img className="circle" src={image12} alt="Image 12" />
							  <img className="circle2" src={image5} alt="Image 5" />
							  <img className="circle" src={image13} alt="Image 10" />
							  <img className="circle2" src={image6} alt="Image 6" />
							  <img className="offer-circle" src={image14} alt="Image 14" />

						  </>
					  )} 
				 

				  </div>
				  </div>
				  
				   
	</div>
	)}
	</div>
	

	{(this.state.loading === true) ? <PageLoader/> : null}
		<div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content"> 
			<div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
				<div className="row">
					<div className="col-lg-10 col-xl-10 order-lg-1 order-xl-1">
					</div>
					<div className="col-lg-2 col-xl-2 order-lg-2 order-xl-2">
						<div className="form-group row">
							<div className="col-lg-10 col-md-12 col-sm-12 number-select custom-select num-picker">
								<select className="form-select custom-select kt_selectpicker selector" defaultValue={this.state.selected_number}onChange={this.handleChange} name="activation">

									{/* { this.state.customer_activations.map(person => <option className="m--bg-success kt-font-inverse-success" value={person.activation_id} {this.state.selected_number == person.mobile_number ? "selected" : ""}>{person.mobile_number}</option>)}  */}
									  {this.state.customer_activations.map(person => (
										  <option
											  key={person.activation_id}
											  className="m--bg-success kt-font-inverse-success"
											  value={person.activation_id}
											  selected={this.state.selected_mobile_number === person.mobile_number ? "selected" : null}
										  >
											  {person.mobile_number}
										  </option>
									  ))}


								</select>

							</div>
						</div>
					</div>
				</div>
				{isShow ? (
					<>
					{(this.state.activeShow === true) ? (
					<div className="row mb60">

						{(this.state.customMesssge != null ) &&
						<div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1"> 
							<div className="customMsg">
								<p>{this.state.customMesssge}</p>
							</div>
						</div>
						}


						<div className="col-lg-6 col-xl-6 order-lg-1 order-xl-1"> 
							<div className="kt-portlet kt-widget kt-widget--general-3 kt-portlet--height-fluid" style={{border:'1px solid #e41e26'}}>
								<div className="kt-portlet__head  kt-portlet__head--noborder">
									<div className="kt-portlet__head-label">
										<h3 className="kt-portlet__head-title account_summary_head">Account Summary </h3>
									</div>
								</div>
								<div className="kt-portlet__body kt-portlet__body--fit" id="accSummary">
									<div id="user_widget">
										<div className="kt-widget__top ptb0">
											<div className="kt-media kt-media--lg kt-media--circle">
												<img src={profile_image} alt="image"/>
												
											</div>
											<div className="kt-widget__wrapper">
												<div className="kt-widget__label">
													<a className="kt-widget__title"> 
														{account_details.firstName} {account_details.middleName} {account_details.surname}
													</a>
													<span className="email-custom">{account_details.email}</span>
												</div>
											</div>
										</div>
										<div className="kt-widget__top ptb10">
											<div className="kt-widget__wrapper">
												<div className="kt-widget__links">
													<div className="kt-widget__cont">
														<div className="kt-widget__link">
															{/* <i className="flaticon2-paperplane  kt-font-brand"></i> Current Plan : &nbsp;<a> ${account_details.amount} / {account_details.total_data} GB</a> */}
															<i className="flaticon2-paperplane  kt-font-brand"></i> Current Plan : &nbsp;<a> Telsim Prepaid Plan</a>
														</div>
														<div className="kt-widget__link">
															<i className="flaticon2-time  kt-font-brand"></i> Expiry : &nbsp;<a> {account_details.expiry_date}</a>
														</div>
														<div className="kt-widget__link">
															<i className="flaticon-coins  kt-font-brand"></i> Main Balance : &nbsp;<a>{main_balance_balance} </a>
														</div>
														<div className="kt-widget__link">
															<i className="flaticon-up-arrow kt-font-brand"></i> IDD Pack Balance : &nbsp;
															{/* <a>{account_details.idd_pack_balance} / {account_details.idd_pack_allowance} mins</a> */}
															{isIddShow ? (
																<a>{account_details.idd_pack_balance} / {account_details.idd_pack_allowance} mins</a>
															) : (
																<a></a>
															)}
														</div>

														<div className="kt-widget__link">
															<i className="flaticon-gift  kt-font-brand"></i> Data Gifted : &nbsp;<a>{this.state.data_gift_sent} GB </a>
														</div>
														<div className="kt-widget__link">
															<i className="flaticon-gift  kt-font-brand"></i> Data Received : &nbsp;<a>{this.state.data_gift_received} GB</a>
														</div>

														

													</div>
												</div>
												<div className="kt-widget__links">
													<div className="kt-widget__cont">
														<div className="kt-widget__link ">
															<i className="flaticon2-phone  kt-font-brand "></i><a>{account_details.mobile_number}</a>
														</div>
														<div className="kt-widget__link">
															<i className="flaticon2-pie-chart-2  kt-font-brand"></i> Data Balance : &nbsp;<a> {account_details.data_balance} GB </a>
															
														</div>
														<div className="kt-widget__link">
															<i className="flaticon2-pie-chart-2  kt-font-brand"></i> Data Bank Balance : &nbsp;<a> {account_details.data_bank_balance} GB </a>
															
														</div>
														<div className="kt-widget__link">
															<i className="flaticon2-calendar-3  kt-font-brand"></i> Days Remaining : &nbsp;<a>{account_details.remaining_days} </a>
														</div>
														<div className="kt-widget__link">
															<i className="flaticon-star  kt-font-brand"></i> Telsim Wallet Balance	 : &nbsp;<a> ${account_details.credit_balance}</a>
														</div>
														</div>
												</div>
											</div>
										</div>



										{account_details.roaming_pack_status == '1' ?
										<div className="kt-widget__top ptb10">
											<div className="kt-widget__wrapper">

												<div className="kt-widget__links">
													<div className="kt-widget__cont">

													    <div className="kt-widget__link">
															<i className="flaticon-globe  kt-font-brand"></i> IR Pack : &nbsp;<a> {account_details.ir_pack_name}</a>
														</div>

														 <div className="kt-widget__link">
															<i className="flaticon-globe  kt-font-brand"></i> IR Expiry: &nbsp;<a> {account_details.data_ir_expiry}</a>
														</div>
														
														{account_details.ir_activation == 'NA' ?
														
														<div className="kt-widget__link">
															<i className="flaticon-globe  kt-font-brand"></i> IR Data Balance : &nbsp;<a>{account_details.ir_activation}</a>
														</div>

														: 
														<div className="kt-widget__link">
															<i className="flaticon-globe  kt-font-brand"></i> IR Data Balance : &nbsp;<a>{account_details.data_ir_data_balance} / {account_details.data_ir_data_allowance} GB</a>
														</div>

														}
																										

													</div>
												</div>
												<div className="kt-widget__links">
													<div className="kt-widget__cont">
														

														{account_details.ir_activation == 'NA' ?

														<>
														<div className="kt-widget__link">
															<i className="flaticon-globe  kt-font-brand"></i> IR Voice Balance : &nbsp;<a>{account_details.ir_activation}</a>
														</div>

														<div className="kt-widget__link">
															<i className="flaticon-globe  kt-font-brand"></i> IR SMS Balance : &nbsp;<a>{account_details.ir_activation}</a>
														</div>
														</>

														:
														<>
														<div className="kt-widget__link">
															<i className="flaticon-globe  kt-font-brand"></i> IR Voice Balance : &nbsp;<a>{account_details.data_ir_voice_balance} / {account_details.data_ir_voice_allowance} mins</a>
														</div>

														<div className="kt-widget__link">
															<i className="flaticon-globe  kt-font-brand"></i> IR SMS Balance : &nbsp;<a>{account_details.data_ir_sms_balance} / {account_details.data_ir_sms_allowance}</a>
														</div>
														</>
														}
														

													</div>
												</div>
											</div>
										</div>
										: null
										}



									</div>
								</div>
								<div className="kt-portlet__foot">
									<div className="kt-form__actions">
										<div className="row">
											<div className="col-md-4">
											</div>
											<div className="col-md-6">
												<Link to="/RechargeHome" className="btn btn-brand" >Recharge</Link>
											</div>
										</div>
									</div>
								</div>
							</div> 
						</div>
						<div className="col-lg-6 col-xl-6 order-lg-1 order-xl-1"  > 
							<div className="kt-portlet kt-portlet--height-fluid " style ={{border: '1px solid #e41e26'}}>
								<div className="kt-portlet__head  kt-portlet__head--noborder">
									<div className="kt-portlet__head-label">
										<h3 className="kt-portlet__head-title account_summary_head">Data Usage </h3>
									</div>
								</div>
								{/* <div className="kt-portlet__body kt-portlet__body--fluid"  style ={{height: '300px',margin:'auto',position:'relative',width:'300px'}}> */}
								<div className="kt-portlet__body kt-portlet__body--fit"  style ={{height: '300px',margin:'auto',position:'relative',width:'300px'}}>
									<Doughnut
										data={this.state.graph_data} 
										width={'100%'}
										height={'100%'}
										options={{
											title:{
											display:true,
											text:'Data Usage',
											fontSize:20
											},
											legend:{
											display:true,
											position:'right'
											}
										}}
									/>
									
									
								</div>
								<div className="kt-portlet__foot p0">
									<div className="kt-form__actions">
										<div className="row mt10">
											<div className="col-md-12 text-center">
												<div className="kt-widget__link">
													<i className="flaticon2-pie-chart-2  kt-font-brand"></i> Data Used/Total Data : &nbsp;<a> <span id="span_data_used">{graph_usedData}</span> GB / <span id="span_total_data">{graph_TotalData} GB </span> </a>
												</div>
											</div>
										</div>
										<br/>
										<br/>
										<div className="row">
											<div className="col-md-4">
											</div>
											<div className="col-md-6">
												<a  data-toggle="tooltip" data-original-title="Edit" className="btn btn-brand" onClick={this.gotoextraRecharge} >Add Extras</a>
											</div>
										</div>
									</div>
								</div>
							</div> 
						</div>
					</div>
					) : (
						<div className="row" >
							<h5 style={{color: 'red'}}>
							{not_active_message}</h5>
						</div>
					)}
					</>
				) : (
					<div className="row">
						<h2>No Details Available</h2>
					</div>
				)}
			</div> 
		</div> 
		
	</> 
 )
}
}

export default withRouter(Home);