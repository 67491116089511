import React, { Component } from "react";  
import { withRouter } from 'react-router-dom'
import axios from 'axios';    
import {getApiHeader, removeUserSession} from '../../utils/CommonFunctions';
import PlanTabs from './../public/plans/planTabs';
import PageLoader from './../Loader/loader';

const SERVER_URL    = process.env.REACT_APP_SERVER_URL;  

class Recharge extends Component {

    constructor(props)
    {
        super(props);

        let activeSection = '28-day_plan';

        if(this.props && this.props.location && this.props.location.state && this.props.location.state.activeSection) 
        { 
            
            activeSection = this.props.location.state.activeSection;

        } 
        

        this.state = {
            customer_activations: [],
            account_summary : [], 
            selected_number: null,
            activeSection:activeSection,
            loading:true,
            active_status:null,
            isShowPlan: false
        }
        
    }

    componentDidMount() {

        if(this.props && this.props.location && this.props.location.state && this.props.location.state.activeSection) 
        { 
            this.setState({activeSection:this.props.location.state.activeSection});
        }   

        this.getUserStatus();

        this.getActivations();

        this.setState({loading: false});

    }


    // force logout --------------------------------------

    getUserStatus = async () => {
        let headers     = await getApiHeader();
        let res         = await axios.get(`${ SERVER_URL }user_status`, { headers }).then(async response => {
            
            if(response.data.data.forceLogout){
                if(response.data.data.forceLogout != 'undefined'){
                    if(response.data.data.forceLogout == '1'){
                        this.handleLogout();
                    }
                }
            }
            
        }).catch(error => {            
        });
 
    };


     handleLogout = async () => {  
        let p = new Promise(async (resolve, reject)=> {
            let headers     = await getApiHeader();
            let res         = await axios.get(`${ SERVER_URL }logout`, { headers });
            let data        = res.data.data;   
            console.log(data);
            removeUserSession();  
            resolve();

        });

        p.then(
            ()=>{ 
                    this.props.history.push("/login");
            },
            ()=>{
                alert("Error in logout");
            }
        )
    }

     // force logout --------------------------------------



    
    handleChange =  async(e) => { 
        
        var active_number = e.target.value; 
        var activeStatus = e.target.selectedOptions[0].getAttribute('active_status'); 
        await sessionStorage.setItem('selected_number', active_number);
        this.setState({ selected_number: active_number, active_status: activeStatus, isShowPlan: true});
  
    }; 

    getActivations = async () => {
        let headers     = await getApiHeader();
        let res         = await axios.get(`${ SERVER_URL }user_activations`, { headers });
        let data        = res.data.data.list; 
        let selected_number = '';
        let active_status = '';
        let isShowPlan = false;
        if(data.length > 0){
            await sessionStorage.setItem('selected_number', data[0].mobile_number);
            selected_number = data[0].mobile_number;
            isShowPlan = true;
            active_status = data[0].activation_status;
        }else{ 
            await sessionStorage.setItem('selected_number', '');
            selected_number = '';
            active_status = '';
        }
        this.setState({
            selected_number: selected_number, 
        }); 

        this.setState({
            active_status: active_status, isShowPlan
        }); 

        this.setState({ customer_activations: data }); 

    };

    render() {

        let default_tab='28-day_plan';
        let extratab= window.sessionStorage.getItem("recharge");
        if(extratab=="extras_plan") {
            default_tab = "extras_plan";
            window.sessionStorage.setItem("recharge", "28-day_plan");
        }

  return (
    <>

        {(this.state.loading === true) ? <PageLoader/> : null}

        <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor recharge" id="kt_content"> 
            
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                   
                <div className="clearfix"></div>

                <div className="row">

                    <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1">

                        <div className="kt-grid__item kt-grid__item--fluid kt-app__content">

                            <div className="kt-portlet">

                                <div className="kt-portlet__head">
                                    
                                    <div className="row" style={{width:"100%"}}>

                                        <div className="col-md-6">

                                            <div className="kt-portlet__head-label">
                                                <h3 className="kt-portlet__head-title">Recharge</h3>
                                            </div>

                                        </div>

                                        <div className="col-lg-6 col-xl-6 order-lg-6 order-xl-6 pull-right">

                                            <div className="row">

                                                <div className="col-md-6" style={{marginBottom: "0px"}}>

                                                    <span style={{float: "right",paddingTop: ".255rem",color: "red",fontSize: "16px"}}>Please select the number to recharge from here</span>
                                                
                                                </div>
                                            
                                                <div className="col-md-6">

                                                    <div className="" style={{marginBottom:'0'}}>
                                                            
                                                            <select className="form-select custom-select kt_selectpicker" defaultValue={this.state.selected_number} onChange={this.handleChange} name="activation">

                                                                { this.state.customer_activations.map(person => <option className="m--bg-success kt-font-inverse-success" active_status={person.activation_status} value={person.mobile_number}>{person.mobile_number}</option>)} 

                                                            </select>
                                                        
                                                            {/* <button className="btn btn-brand">Recharge Other Number</button> */}
                                                        
                                                    </div>

                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div className="kt-portlet__body" id="payment_method_container">
                                   
                                    {this.state.isShowPlan == true ? <PlanTabs activeSection={this.state.activeSection} mobileNumber={sessionStorage.getItem('selected_number')} activeStatus={this.state.active_status} from="recharge"/> : null}
                                     
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>
             
        </div>
                    
	</>

   )
}
}


export default withRouter(Recharge);