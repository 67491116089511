import { Component } from "react";
import { withRouter } from "react-router-dom";
import "braintree-web";
import { WidgetInput } from "react-addressfinder";
import axios from "axios";
import { DropdownDate,DropdownComponent} from 'react-dropdown-date';
import Payments from "./Payments";
import { Route } from 'react-router-dom';
import { getApiHeader } from "../../../../utils/CommonFunctions";









const SERVER_URL = process.env.REACT_APP_SERVER_URL;



class Connection extends Component{

    
     constructor(props){
        super(props)
    
         this.state={
            activeTab:"connection",
            customerId:null,
            orderDetails:{},
            errors:{},
            isThankyourTab:false,
            orderRefId:'',
            selectedAddress:'',
            apiMessage:[],
            apiStatus:'',
            sessionId:'',
            sal:'',
            apiCalled:false,
            orderStep:"connection",
            submissionType:"customer_details",
            logedUserName:false,
            logedUserEmail:false,
            couponCodeDetails:{},
            sessionVisp:"",
            apiMessage:[],
            apiStatus:'',
            apiCalled:false,
            isAsap:false,
            checkAsServiceAddress:false,
            haveFinderValue:false,
          
            endDate:null,
            endYear:null,
             date: null, 
             selectedDate: new Date(),
            formData:{
                inputAddress:"",
                fixedRequestedConnectionDate: new Date(),
                delivery_address: '',
                visp_qualification_id: "",
                fixedOrderAs: '',
                as_soon_as_possible:"",
                customer_id: '',
                person_accepting_delivery: '',
                customer_company_name: ''
                   },

              state:{
                plan_id:this.props.location.state.plan_id,
                product_name:this.props.location.state.product_name,
                product_amount:this.props.location.state.product_amount,
                shipping_charge:this.props.location.state.shipping_charge,
                vispID:this.props.location.state.vispID,
                gst_plan_amount:this.props.location.state.gst_plan_amount,
                gst_modem_amount:this.props.location.state.gst_modem_amount,
                gst_shipping_charge:this.props.location.state.gst_shipping_charge
              }

              }

            }
          

            //  currentDate = new Date();
            //  monthAbbreviation = this.currentDate.toLocaleString('default', { month: 'short' });
            //  dayNumber = this.currentDate.getDate();
            //  formattedDate = `${this.monthAbbreviation} ${this.dayNumber}`;

              currentDate = new Date();
              monthAbbreviation = this.currentDate.toLocaleString('default', { month: 'short' });
              dayNumber = this.currentDate.getDate();
              yearNumber = this.currentDate.getFullYear();
              formattedDate = `${this.yearNumber} ${this.monthAbbreviation} ${this.dayNumber}`;




              handleChange = (e) => {
                const { name, value } = e.target;
                this.setState(prevState => ({
                    formData: {
                        ...prevState.formData,
                        [name]: value
                    }
                }));
            }

            
            handleAddressFinderChange = (name, value) => {
                this.setState(prevState => ({
                    formData: {
                        ...prevState.formData,
                        [name]: value
                    },
                    haveFinderValue: true // Assuming haveFinderValue is also part of your component state
                }));
            };
            
            
   AddressFinderComponent = ({ addressFinderKey, inputClassName, id, onChange }) => {
   const handleSelectedAddress = (fullAddress, searchAddress) => {
     onChange('delivery_address', fullAddress);
    };

    return (
      <WidgetInput
        addressFinderKey={addressFinderKey}
        inputClassName={inputClassName}
        id={id}
        onSelected={handleSelectedAddress}
      />
    );
  };
   

  handleASAP = (e) => {
    const isChecked = e.target.checked;
    const { formData } = this.state.formData;
    if (isChecked) {
      this.setState({ isAsap: true, formData: { ...formData, as_soon_as_possible: true 
       ,visp_qualification_id:this.props.location.state.vispID,
      } });
    } else {
      this.setState({ isAsap: false });
    }
  }

  changeTabActive = (e, activeTabName) => {
     this.setState({orderStep:"payment"})
  
  }

   openCity = (event, tabName) => {
    if (tabName === 'ThankYouPage') {
       this.setState({isThankyourTab:true})
    }
     this.setState({activeTab:""})
     };

 

  handleSubmit = async(e) => {
    e.preventDefault();

       
    

    const formData=this.state.formData
    let headers =  await getApiHeader();

    axios.post(`${SERVER_URL}nbn/nbn_customer_connection_details`, formData, {headers
    })
      .then(response => {
         
        this.setState({
          apiStatus: response.data.status,
          apiMessage: response.data.message,
          apiCalled: true,
        });

        setTimeout(() => {
          this.setState({
            apiCalled: false,
            activeTab:"payment",
            orderStep:"payment"
            
          });
          // onSuccess(e);
          //onActiveSuccess();
        }, 2000);
      })
      .catch(error => {
        this.setState({
          apiCalled: true,
          apiStatusCode: false,
          apiMessage: error.response.data.message,
        });
      })
      .finally(() => {
        setTimeout(() => {
          this.setState({
            apiCalled: false,
          });
        }, 10000);
      });
  };

  

  handleServiceAddress = (e) => {
    const isChecked = e.target.checked;
    const  address  = this.state.selectedAddress 
            console.log("selected address",address)
    if (isChecked) {
      this.setState(prevState => ({
        checkAsServiceAddress: true,
        formData: {
          ...prevState.formData,
          delivery_address: address,

        },
      }));
    } else {
      this.setState({
        haveFinderValue: false,
        checkAsServiceAddress: false,
        formData: {
          ...this.state.formData,
          delivery_address: '',
        },
      });
    }
  };

 

        

  async fetchData() {
       
    try {
      let headers = await getApiHeader();
      const payload = {
        "visp_qualification_id": this.props.location.state.vispID,
        "plan_id": this.props.location.state.plan_id,
      };

      const response = await axios.post(SERVER_URL +'nbn/check_order_details', payload, { headers });
       

      if (response.status === 200) {
        this.setState({
          selectedAddress: response.data.data.inputAddress,
          orderDetails: {
            plan_name: response.data.data.plan.plan_display_name,
            fixedSpeed: response.data.data.plan.plan_name,
            data: response.data.data.plan.data_text,
            website_amount: response.data.data.plan.website_amount,
            contract_length: response.data.data.plan.validity_text,
            gst_inclusive_sale_amount:response.data.data.plan.gst_inclusive_sale_amount
          }
          

        });
      } else {
        throw new Error("Unexpected status code: " + response.status);
      }
    } catch (error) {
      console.error("Error occurred:", error);
      // Handle error, maybe redirect to home page
       this.props.history.push("/home");
    }
  }

 
  currentDate = new Date();
  currentMonth = this.currentDate.getMonth() + 1;



   calculateEndYear(){
    const today = new Date();
    const currentYear = today.getFullYear();
    const endYear = currentYear + 20;
    return endYear;
   }


    endYear = this.calculateEndYear();
    endDate = `${this.endYear}-12-31`;

    formatDate = (date) => {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
        
         this.setState({
          date: date,
          formData: {
            ...this.state.formData,
            fixedRequestedConnectionDate: [year, month, day].join("-"),
          },
        });

      return [year, month, day].join("-");
  
    };
  
    
       componentDidMount() {
        this.fetchData()
        this.setState({ 
           
          formData: {
            ...this.state.formData,
            visp_qualification_id: this.props.location.state.vispID

          },
          

        })
      }

       changeOrderRefId = (e, value) => {
         this.setState({ orderRefId:e})
      }

       openCity = (event, tabName) => {
        if (tabName === 'ThankYouPage') {
           this.setState({
            isThankyourTab:"true"
           })

        }
        this.setState({activeTab:tabName})
      
      };


      changeCouponCodeDetails = (couponCode) => {
         this.setState({couponCodeDetails:couponCode})
        
      }


    render(){
      
    return(
           
    <div>
    <div className="container">
   <section className="common-section">
    <div className="row">
    <div className="col-md-7" id="checkoutBox">
    <div className="modem-card">

    {this.state.activeTab === 'connection' &&(
               
    <div id="Account" className="tabcontent">
    <section className="get-plan" style={{ marginTop: '0!important;' }}>
      <div className="container" style={{ backgroundColor: '#fff', borderTopLeftRadius: '30px', borderBottomRightRadius: '30px' }}>
        <form className="contact-form row" onSubmit={this.handleSubmit}>
          <div className="form-field col-lg-12">
            <b style={{ color: '#777', float: 'left' }}>Service activation Details</b>
          </div>
          <div className="form-field col-lg-12">
            <label className="label" htmlFor="inputAddress">Service Address </label>
            <input type="text" className="input-text js-input" name="inputAddress" value={this.state.selectedAddress} disabled onChange={this.handleChange} />
          </div>
          {this.state.isAsap ? <div className="form-field col-lg-12 prfActivationDate">
            <label className="label" htmlFor="fixedRequestedConnectionDate">Preferred activation date <span style={{ color: '#777' }}>(DD/MM/YYYY)</span></label>
            <input type="text" className="input-text js-input" name="fixedRequestedConnectionDate" value="ASAP" disabled />
          </div> :

            <div className="form-field col-lg-12 prfActivationDate newDatePicker">
              <label className="label" htmlFor="mobile">Preferred activation date  </label>
                   
              <DropdownDate
           startDate={
              new Date()
           }
          endDate={
            this.endDate 
          }
          selectedDate={
            this.state.selectedDate 
          }
          onDateChange={(date) => {
            this.setState({ selectedDate: this.formatDate(date),
            });
          }}/>

            </div>

          }
          <div className="form-field check-box-section col-lg-12">
            <label class="check-box">  As soon as possible
              <input type="checkbox" required="" name="as_soon_as_possible" value={this.state.formData.as_soon_as_possible} onChange={this.handleASAP} />
              <span class="tick"></span>
            </label>
          </div>

          <div className="form-field col-lg-12">
            <b style={{ color: '#777', float: 'left' }}>Delivery Details</b>
          </div>
          <div className="form-field col-lg-12">
            <label className="label" htmlFor="delivery_address">Delivery address  <span style={{ color: 'red' }}>*</span>
         </label>

            {this.state.checkAsServiceAddress
              ?
              <input type="text" className="input-text js-input" name="delivery_address" disabled readOnly value={this.state.formData.delivery_address} onChange={this.handleChange} />
              :
              <>
                { this.state.haveFinderValue
                  ?
                  <div style={{ position: 'relative' }}>
                    <input type="text" className="input-text js-input" name="delivery_address" disabled readOnly value={this.state.formData.delivery_address} />
                    <span
                      style={{
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        backgroundColor: 'transparent',
                        color: 'red',
                        border: 'none',
                        padding: '5px 10px',
                        cursor: 'pointer'
                      }}

                      onClick={() => {
                        this.setState({
                            haveFinderValue: false,
                            formData: {
                              ...this.state.formData,
                              delivery_address: ''
                            }
                          });
                      }}

                      
                    >
                      x
                    </span>
                  </div>
                  :
                  < this.AddressFinderComponent
                    addressFinderKey="UJCFN4HL6YRMT3D9K8PG"
                    inputClassName="input-text js-input"
                    id="address"
                    value={this.state.formData.delivery_address}
                    onChange={(name, value) => this.handleAddressFinderChange(name, value)}
                  />}
              </>
            }
          </div>
          <div className="form-field check-box-section col-lg-12">
            <label class="check-box"> Same as service address
              <input type="checkbox" required="" onChange={this.handleServiceAddress} />
              <span class="tick"></span>
            </label>
          </div>
          <div className="form-field col-lg-12">
            <label className="label" htmlFor="mobile">Full name <span style={{ color: '#777' }}>(Person accepting delivery)</span> <span style={{ color: 'red' }}>*</span></label>
            <input type="text" className="input-text js-input" name="person_accepting_delivery"  title="Please enter delivery details" value={this.state.formData.person_accepting_delivery} onChange={this.handleChange} />
          </div>
          {/* <div className="form-field col-lg-12">
            <label className="label" htmlFor="mobile">Company Name <span style={{ color: '#777' }}>(if applicable)</span></label>
            <input type="text" className="input-text js-input" name="customer_company_name"  value={this.state.formData.customer_company_name} onChange={this.handleChange} />
          </div> */}
          <div className="form-field col-lg-12">
            <div className="row">
              {/* <div className="form-field col-lg-6">
                <input className="payment-back-btn" type="submit" value="BACK" />
              </div> */}
              <div className="form-field col-lg-12">
                <input className="payment-submit-btn" type="submit" disabled={this.state.apiCalled} value={this.state.apiCalled ? 'Loading...' : 'CONTINUE'} />
              </div>
            </div>
          </div>
        </form>
        {this.state.apiCalled && this.state.apiMessage && (
          <div className={"alert " + (this.state.apiStatus === 200 ? "alert-success" : "alert-danger")} style={{ textAlign: 'left' }}>
            <strong>{this.state.apiStatus === 200 ? "Please wait...." : ""}</strong>
            {this.state.apiMessage.delivery_address && (<strong style={{ display: 'block' }}>{this.state.apiMessage.delivery_address}</strong>)}
            {this.state.apiMessage.fixedRequestedConnectionDate && (<strong style={{ display: 'block' }}>{this.state.apiMessage.fixedRequestedConnectionDate}</strong>)}
            
            {this.state.apiMessage.person_accepting_delivery && (<strong style={{ display: 'block' }}>{this.state.apiMessage.person_accepting_delivery}<br></br></strong>)}
          </div>
        )}

      </div>
    </section>
  </div>

 ) }

 {this.state.activeTab === 'payment' && (
   <Payments vispID={this.props.location.state.vispID}   payable_amount={this.props.location.state.amount} shipping_charge={this.props.location.state.shipping_charge}
      onChangeOrderRefId={e => this.changeOrderRefId(e) }  onSuccessTap={e => this.openCity(e, 'ThankYouPage')}  changeCouponCodeDetails={e => this.changeCouponCodeDetails(e)} 
       conditionAmount={(Number(this.state.orderDetails.gst_inclusive_sale_amount)+ Number(this.state.state.gst_shipping_charge)+Number(this.state.state.gst_modem_amount)).toFixed(2)}  ></Payments>
        )}

                      {this.state.activeTab === 'ThankYouPage' && (
                        <div className='thanks-section'>
                          <i class="fa fa-check-circle thanks-icon" aria-hidden="true"></i>
                          <h3>Thank You.  Your order has been received.</h3>
                          <strong>Please check the email ID used during purchase for more details about the purchase.</strong><br />
                          <hr />
                          <h5>Order number : <span>{this.state.orderRefId}</span></h5>
                          <hr />
                          <h5>Date : <span>{this.formattedDate ?? ''}</span></h5>
                        </div>
                      )}
                    </div>
                  </div>




                  <div className="col-md-5">
                    <div className="modem-card">
      
                      <h3>Your Order Summary</h3>
                      <hr />
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <td valign="top" style={{ textAlign: "left" }}>Plan Name</td>
                            <th valign="top" style={{ textAlign: "left" }}>: {this.state.orderDetails.plan_name}</th>
                          </tr>
                          <tr>
                            <td valign="top" style={{ textAlign: "left" }}>Speed Tier</td>
                            <th valign="top" style={{ textAlign: "left" }}>: {this.state.orderDetails.fixedSpeed} </th>
                          </tr>
                          <tr>
                            <td valign="top" style={{ textAlign: "left" }}>Monthly Data</td>
                            <th valign="top" style={{ textAlign: "left" }}>: {this.state.orderDetails.data}  | ${this.state.orderDetails.website_amount} </th>
      
                          </tr>


                          {this.state.state.product_name &&
                            <>
                            <tr>
                                  <td valign="top" style={{ textAlign: "left" }}>Modem </td>
                                  <th valign="top" style={{ textAlign: "left" }}>: {this.state.state.product_name}  </th>
                                </tr>
                              <tr>
                                <td valign="top" style={{ textAlign: "left" }}>Modem + Shipping</td>
                                <th valign="top" style={{ textAlign: "left" }}>: ${Number(this.state.state.product_amount)+Number(this.state.state.shipping_charge)}</th>
                              </tr>
                            </>
                          }
                          <tr>
                            <td valign="top" style={{ textAlign: "left" }}>Contract Length</td>
                            <th valign="top" style={{ textAlign: "left" }}>: {this.state.orderDetails.contract_length}</th>
                          </tr>

                          {this.state.couponCodeDetails.voucher_name  !== undefined &&this.state.couponCodeDetails.voucher_name !== null  && <tr>
                      <td valign="top" style={{ textAlign: "left" }}>Promocode</td>
                      <th valign="top" style={{ textAlign: "left" }}>: {this.state.couponCodeDetails.voucher_name}</th>
                    </tr>}

                    { this.state.couponCodeDetails.nbn_voucher_discounted_amount !== undefined &&  this.state.couponCodeDetails.nbn_voucher_discounted_amount !== null && (
                      <tr>
                        <td valign="top" style={{ textAlign: "left" }}>Discount</td>
                        <th valign="top" style={{ textAlign: "left" }}>: ${Number(this.state.couponCodeDetails.nbn_voucher_discounted_amount).toFixed(2)}</th>
                      </tr>
                    )}

                        {this.state.couponCodeDetails.nbn_credit_added>0 && 
                        <tr>
                        <td valign="top" style={{ textAlign: "left" }}>NBN credits added</td>
                        <th valign="top" style={{ textAlign: "left" }}>:${this.state.couponCodeDetails.nbn_credit_added.toFixed(2)}</th>
                      </tr>
                        }

                          {/* {this.state.couponCodeDetails.voucher_name && <tr>
                            <td valign="top" style={{ textAlign: "left" }}>Promocode</td>
                            <th valign="top" style={{ textAlign: "left" }}>: {this.state.couponCodeDetails.voucher_name}</th>
                          </tr>}
                          {
                            this.state.couponCodeDetails.nbn_voucher_discounted_amount && <tr>
                              <td valign="top" style={{ textAlign: "left" }}>Discount</td>
                              <th valign="top" style={{ textAlign: "left" }}>: ${this.state.couponCodeDetails.nbn_voucher_discounted_amount.toFixed(2)}
                              </th>
                            </tr>
                          } */}

                          <tr>
                            <td valign="top" style={{ textAlign: "left" }}>Total Upfront Costs</td>
                            <th valign="top" style={{ textAlign: "left" }}>
                              : {Number((this.state.couponCodeDetails.plan_gst_inclusive_sale_amount))<Number((this.state.orderDetails.gst_inclusive_sale_amount)) ? 
                                <>
        
                                ${(Number(this.state.couponCodeDetails.plan_gst_inclusive_sale_amount) + Number(this.state.state.gst_modem_amount) + Number(this.state.state.gst_shipping_charge)).toFixed(2)}
                                
                                </>
                      
                                : 
                                <>
                                ${(Number(this.state.orderDetails.gst_inclusive_sale_amount)+ Number(this.state.state.gst_shipping_charge)+Number(this.state.state.gst_modem_amount)).toFixed(2)}
                                 </>
                              }
                            </th>
                          </tr>
                        </thead>
                      </table>
                      {this.state.couponCodeDetails.nbn_credit_added>0 && 
                      <p><span style={{ color: 'red' }}> * </span> NBN credits can be used for future purchases.</p>
                      }
                      <p><span style={{ color: 'red' }}> * </span>All amounts mentioned are in AUD only</p>

                      <div className="modem-bg">
                        {this.state.activeTab === 'ThankYouPage' ?
                          <h4 style={{ margin: "10px 0 0px 0" }}>Total Amount</h4>
                          :
                          <h4 style={{ margin: "10px 0 0px 0" }}>Your monthly total</h4>
                        }
                        {this.state.orderDetails.website_amount ? (
                          <h2>${this.state.orderDetails.website_amount}/Month<span></span></h2>
                        ) : (
                          <h2>0<span>/mth</span></h2>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>

        )
    }

}

 export default withRouter(Connection)