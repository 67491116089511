import React, { Component } from "react";
import { install } from "resize-observer";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "./components/public/Login";
import {getToken} from './utils/CommonFunctions';
import ProtectedComponents from "./components/ProtectedRoutes/ProtectedComponents";
import {ProtectedRoute} from './components/ProtectedRoutes/protected.route';
import Register from "./components/public/Register";
import ForgotUserId from "./components/public/ForgotUserId";
import ResetPassword from "./components/public/ResetPassword";
import Activate from "./components/public/Activate";
import LoginRedirection from "./components/public/LoginRedirection";

import planTabContent from "./components/public/plans/planTabContent";
import GuestRecharge from "./components/public/guestRecharge";
import Services from "./components/ProtectedComponents/Services";
import NBNProtectedComponents from "./components/ProtectedRoutes/NBNProtectedComponents";
import NbnServiceList from "./components/ProtectedComponents/NBN/NbnServiceList";
import Service from "./components/ProtectedComponents/Services";

install();

export default class App extends Component {
    
render() { 

  return (
    <> 
        
        <BrowserRouter basename={process.env.REACT_APP_BASE_NAME}>
            <Switch>

                {(getToken() === null) ? 
                <Route exact path="/" component={Login} /> 
                : 
                <Route exact path="/" component={Services} />
                }

                <Route exact path="/Login" component={Login} />
                <Route exact path="/Register" component={Register} />
                <Route exact path="/ForgotUserId" component={ForgotUserId} />
                <Route exact path="/ResetPassword" component={ResetPassword} />
                <Route exact path="/Activate" component={Activate} />
                <Route exact path="/LoginRedirection" component={LoginRedirection} />

                <Route exact path="/Plans" component={planTabContent} />
                <Route exact path="/service-list" component={Services} />


                <ProtectedRoute exact path="/app" component={ProtectedComponents} />
                <ProtectedRoute exact path="/home" component={ProtectedComponents} />
                <ProtectedRoute exact path="/MyProfile" component={ProtectedComponents} />
                <ProtectedRoute exact path="/MyPayments" component={ProtectedComponents} />
                <ProtectedRoute exact path="/Recharge" component={ProtectedComponents} />
                <ProtectedRoute exact path="/AddaMobilePlan" component={ProtectedComponents} />
                <ProtectedRoute exact path="/ChangePassword" component={ProtectedComponents} />
                <ProtectedRoute exact path="/UsageHistory" component={ProtectedComponents} />
                <ProtectedRoute exact path="/IDverification" component={ProtectedComponents} />
                <ProtectedRoute exact path="/IDsuccess" component={ProtectedComponents} />
                <ProtectedRoute exact path="/DataGifting" component={ProtectedComponents} />
                <ProtectedRoute exact path="/ReferAbuddy" component={ProtectedComponents} />
                <ProtectedRoute exact path="/Esim" component={ProtectedComponents} />
                <ProtectedRoute exact path="/RechargeAFriend" component={ProtectedComponents} />
                <ProtectedRoute exact path="/RechargeHome" component={ProtectedComponents} />
                <ProtectedRoute exact path="/RechargeWithVoucher" component={ProtectedComponents} />
                <ProtectedRoute exact path="/MyServices" component={ProtectedComponents} />
                <Route exact path="/recharge-your-service" component={GuestRecharge} />
                <Route exact path="/AddNbn" component={ProtectedComponents} />
                <Route exact path="/connection" component={ProtectedComponents} />
                 

                {/*--------------------> NBN ROUTES <-------------------------------------------*/}
                <ProtectedRoute exact path="/nbn_services" component={NBNProtectedComponents} />
                <ProtectedRoute exact path="/nbn-my-account-summary" component={NBNProtectedComponents} />
                <ProtectedRoute exact path="/nbn_my_profile" component={NBNProtectedComponents} />
                <ProtectedRoute exact path="/nbn_my_payments" component={NBNProtectedComponents} />
                <ProtectedRoute exact path="/Activatev2" component={NBNProtectedComponents} />
                <ProtectedRoute exact path="/nbn_change_plan" component={NBNProtectedComponents} />
                <ProtectedRoute exact path="/nbn_change_password" component={NBNProtectedComponents} />
                

                
            </Switch>
        </BrowserRouter>

    </>
    )
}
}