import React, { Component } from 'react';
import PlanTabs from './../plans/planTabs';
import ErrorMsg from './../../public/errorMsg';
import axios from 'axios';
import swal from '@sweetalert/with-react';
import Plan from './../plans/plan';
import {getApiHeader, getGuestRechargeAttemptDetails} from './../../../utils/CommonFunctions';
import PageLoader from './../../Loader/loader';

const SERVER_URL    = process.env.REACT_APP_SERVER_URL;
const isValidNo = /^[0-9]*$/;

export class SelectRechargePlan extends Component {


    constructor(props)
    {
        super(props);

        this.state = {
            loading:false,
            seletedPlanId:null,
            reference_number: '',
            reference_number_applied:false,
            reference_number_plan:null,
            is_sim_credit_plan:false,
            rechargeType:'Recharge',
            voucherDivId:null,
            activation_id:null,
            mobile_number:null,
            customer_id:null,
            active_status:null,
            session_id:null,
            doubleDataPlanId:null
        }

    }




    componentDidMount() { 
        this.setState({loading:false});
        this.getGuestRechargeAttemptDetails();  
    }

    getGuestRechargeAttemptDetails = async () => {
        this.setState({loading:true});
        let attempt_data     = await getGuestRechargeAttemptDetails(); 
        //console.log('get recharge Attempt Data ==>', attempt_data.recharge_guest_attempt);

        if(attempt_data.recharge_guest_attempt != null && attempt_data.recharge_guest_attempt !== undefined)
        {
                if(attempt_data.recharge_guest_attempt.session_id != null && attempt_data.recharge_guest_attempt.session_id !== undefined)
                {
                    await sessionStorage.setItem('guest_recharge_session_id', attempt_data.recharge_guest_attempt.session_id);
                    this.setState({session_id:attempt_data.recharge_guest_attempt.session_id});
                    // alert(attempt_data.recharge_guest_attempt.session_id);

                        if(attempt_data.recharge_guest_attempt.recharge_type == 'MA-Recharge-Web-voucher'){

                            if(attempt_data.recharge_guest_attempt.voucher_verified == '1'){

                                if(attempt_data.recharge_guest_attempt.plan_id != null && attempt_data.recharge_guest_attempt.plan_id !== undefined){

                                    if(attempt_data.plan){

                                           
                                            this.setState({rechargeType: 'RechargeWithVoucher'});
                                            this.setState({seletedPlanId:attempt_data.recharge_guest_attempt.plan_id});
                                            this.setState({reference_number_plan: attempt_data.plan});
                                            this.setState({reference_number_applied:true});
                                            this.setState({voucherDivId:'voucherButtons'});
                                           

                                    }else{
                                        this.setState({rechargeType: 'Recharge'});
                                    }


                                    

                                }else{ this.setState({rechargeType: 'Recharge'}); }
                            
                            }else{
                                this.setState({rechargeType: 'Recharge'});
                            }
                            
                        }else{
                            this.setState({rechargeType: 'Recharge'});

                            //alert(attempt_data.recharge_guest_attempt.plan_id);
                            if(attempt_data.recharge_guest_attempt.plan_id != null && attempt_data.recharge_guest_attempt.plan_id !== undefined){
                               
                                    //this.setState({seletedPlanId:attempt_data.recharge_guest_attempt.plan_id});

                            }

                        }

                        if(attempt_data.recharge_guest_attempt.activation_id != null && attempt_data.recharge_guest_attempt.activation_id !== undefined && attempt_data.recharge_guest_attempt.mobile_number != null && attempt_data.recharge_guest_attempt.mobile_number !== undefined && attempt_data.recharge_guest_attempt.customer_id != null && attempt_data.recharge_guest_attempt.customer_id !== undefined){

                             this.setState({activation_id: attempt_data.recharge_guest_attempt.activation_id});
                             this.setState({mobile_number: attempt_data.recharge_guest_attempt.mobile_number});
                             this.setState({customer_id: attempt_data.recharge_guest_attempt.customer_id});

                              await sessionStorage.setItem('guest_recharge_customer_id', attempt_data.recharge_guest_attempt.customer_id);
                              await sessionStorage.setItem('guest_recharge_mobile_number', attempt_data.recharge_guest_attempt.mobile_number);
                              

                        }else{
                            window.location.reload();
                        }

                       
   



                }else{ window.location.reload(); }

        }else{
            window.location.reload();
        }
        this.setState({loading:false});
    };




    mySubmitHandler = async (event) => { 
        event.preventDefault();  
    };

    back = (e) => {

        e.preventDefault();

        this.props.prevStep()
    };

    goNextStep = async (plan_id,dplan_id)=>{
        this.setState({seletedPlanId: plan_id});
        this.setState({doubleDataPlanId: dplan_id});
        this.addPlan(plan_id);
        return;
    }




    setSelectedPlan = async (plan_id, reference_number_applied, plan, reference_number, is_sim_credit_plan)=>{
        //console.log(plan_id)


        this.setState({seletedPlanId: plan_id, reference_number_applied:reference_number_applied, reference_number_plan:plan, reference_number:reference_number, is_sim_credit_plan:is_sim_credit_plan},()=>{

            if(reference_number_applied === true)
            {
                this.setState({reference_number_plan:plan});
            }

        });

    }

    addPlan  = async ()=>{

            if(this.state.seletedPlanId === null)
            {
                this.setState({loading:false});

                swal({
                    title:"Please select a valid plan.",
                    icon:"warning"
                });
                return;
            }
            this.setState({loading:true});
            let plan_id         = this.state.seletedPlanId;
            //let headers         = await getApiHeader();
            let params          = {session_id: this.state.session_id, plan_id: plan_id, double_plan_id: this.state.doubleDataPlanId};

        try 
        {
             
            // let response        = await axios.post(`${ SERVER_URL }activation/select-plan`, params, { headers });
            let response        = await axios.post(`${ SERVER_URL }guest-recharge-select-plan`, params, {  });
            if(response.data.status==200){ 
         

                  if(response.data.data.recharge_type != null && response.data.data.recharge_type !== undefined){
                 
                        if(response.data.data.recharge_type == 'MA-Recharge-Web-voucher'){
                                
                               if(response.data.data.plan_id != null && response.data.data.plan_id !== undefined){
                                    
                                    // // show data bank and confirm popup -----------------------------
                                    //     var balanceMsg = "";
                                    //     let params = {
                                    //         plan_id: response.data.data.plan_id, 
                                    //         mobile_number: this.state.mobile_number,
                                    //         session_id : this.state.session_id
                                    //     }
                                        
                                    //     await axios.post(`${SERVER_URL}guest-recharge-data-bank-balance`, params, { })
                                    //     .then((responseDatabank) => {
                                    //         console.log('recharge-popup ==>', responseDatabank)
                                    //         if(responseDatabank !== undefined){
                                    //             if(responseDatabank.status == 200) {
                                    //                 if(responseDatabank.data !== undefined && responseDatabank.data != null && responseDatabank.data != ''){
                                    //                     balanceMsg = responseDatabank.data.message;
                                    //                 }else{
                                    //                     balanceMsg = "";
                                    //                 }



                                    //                 if(balanceMsg == ""){
                                    //                             swal({
                                    //                                 buttons: {
                                    //                                 cancel: true,
                                    //                                 confirm: {text: 'Yes'},
                                    //                                 },
                                    //                                 text: "Would you like to proceed with this recharge for "+this.state.mobile_number+ "?",
                                    //                                 dangerMode: true,
                                    //                                 })
                                    //                                 .then(proceed => {
                                    //                                     console.log('error ==>', proceed)
                                    //                                     if(proceed){
                                    //                                        // run recharge function
                                    //                                        this.runRecharge(response.data.data.recharge_type,response.data.data.plan_id);
                                    //                                     }
                                                        
                                    //                             }); 
                                    //                 }else{
                                    //                     swal({
                                    //                         icon: "warning",
                                    //                         buttons: {
                                    //                         cancel: true,
                                    //                         confirm: {text: 'Yes'},
                                    //                         },
                                    //                         text: balanceMsg + "\n Would you like to proceed with this recharge for "+this.state.mobile_number+ "?",
                                    //                         dangerMode: true,
                                    //                         })
                                    //                         .then(proceed => {
                                    //                             console.log('error ==>', proceed)
                                    //                             if(proceed){
                                    //                                 // run recharge function
                                    //                                 this.runRecharge(response.data.data.recharge_type,response.data.data.plan_id);
                                    //                             }
                                    //                     });
                                    //                 }



                                    //             }  
                                    //         }
                                    //         this.setState({ loading: false});
                                    //     })
                                    //     .catch((error) => {
                                    //         this.setState({ loading: false });
                                    //         balanceMsg = "";
                                    //         swal('Something went wrong, Please try again.', "", "warning");
                                    //     });
                                    
                                    // // show data bank and confirm popup -----------------------------



                                    // run recharge function
                                     swal({
                                        icon: "",
                                        buttons: {
                                        cancel: true,
                                        confirm: {text: 'Yes'},
                                        },
                                        text: "Would you like to proceed with this recharge for "+this.state.mobile_number+ "?",
                                        dangerMode: true,
                                        })
                                        .then(proceed => {
                                            console.log('error ==>', proceed)
                                            if(proceed){
                                                // run recharge function
                                                this.runRecharge(response.data.data.recharge_type,response.data.data.plan_id);
                                            }else{
                                                this.setState({loading:false});
                                            }
                                    });
                                    


                                    
                               }else{
                            
                                 this.props.nextStep();
                               } 
                        }else{
             
                            this.props.nextStep();
                        }
                    
                    }else{
                   
                            this.props.nextStep();
                    }



            }else{
                this.setState({loading:false});
                swal(response.data.message, "", "warning");
            }

            
        }
        catch(err)
        {

            this.setState({loading:false});

            if(err)
            {
                let errorMessage = '';

                if(err.response && err.response.data && err.response.data.message)
                {
                    if(typeof(err.response.data.message) === 'object')
                    {

                        swal({
                            title: "Error",
                            content: <ErrorMsg message={err.response.data.message}/>,
                            icon: "error"});
        
                    }
                    else
                    {
                        errorMessage = err.response.data.message;
                        swal(errorMessage,"error");
                        
                    }
                }
                else
                {
                    errorMessage = err;
                    swal(errorMessage,err);
                }
    


            }
            

        }

    }




    runRecharge  = async (type,plan_id)=>{

        try 
        {
            
            this.setState({loading:true});

            let params          = {session_id: this.state.session_id, plan_id: plan_id,recharge_type: type,activation_id:this.state.activation_id,customer_id:this.state.customer_id,mobile_number:this.state.mobile_number};
            // let response        = await axios.post(`${ SERVER_URL }activation/select-plan`, params, { headers });
            let response        = await axios.post(`${ SERVER_URL }guest-recharge-submit`, params, {  });
            if(response.data.status==200){ 

                swal(response.data.message, "Recharge Completed", "success");

                if(response.data.data.customer_myaccount != null && response.data.data.customer_myaccount !== undefined)
                {
                    if(response.data.data.customer_myaccount == 0){
                         window.setTimeout(function(){
                            window.location.href = "https://prepaid.telsim.com.au";
                         }, 3000);
                    }else{

                         window.setTimeout(function(){
                            window.location.reload();
                         }, 3000);

                    }

                }else{
                    
                    window.setTimeout(function(){
                            window.location.reload();
                    }, 3000);
                }


                

            }else{
                this.setState({loading:false});
                swal(response.data.message, "", "warning");
            }

            
        }
        catch(err)
        {

            this.setState({loading:false});

            if(err)
            {
                let errorMessage = '';

                if(err.response && err.response.data && err.response.data.message)
                {
                    if(typeof(err.response.data.message) === 'object')
                    {

                        swal({
                            title: "Error",
                            content: <ErrorMsg message={err.response.data.message}/>,
                            icon: "warning"});
        
                    }
                    else
                    {
                        errorMessage = err.response.data.message;
                        // swal(errorMessage,"error");
                         swal({
                            title: errorMessage,
                            icon: "warning"});

                        if(err.response.data.data.telstra_api_called != null && err.response.data.data.telstra_api_called !== undefined)
                        {
                            if(err.response.data.data.telstra_api_called == 1){
                                 window.setTimeout(function(){
                                    window.location.reload();
                                 }, 3000);
                            }

                        }


                         
        
                        
                    }
                }
                else
                {
                    errorMessage = err;
                    swal(errorMessage,err);
                }
    


            }
            

        }
                
    }








    render() {
        const { values, handleChange } = this.props;
        return (
            <>
                {(this.state.loading === true) ? <PageLoader/> : null}

                <form className="kt-form kt-section__content kt-section__content--border guestPlan" id="kt_form" method="post" onSubmit={this.mySubmitHandler}>
                    <div className="kt-wizard-v2__content " data-ktwizard-type="step-content" id="planboxwidth">
                    
                        <div className="kt-wizard-v2__content" data-ktwizard-type="step-content">

                            {this.state.rechargeType == 'Recharge' &&
                             <div className="kt-heading kt-heading--md">Select Your Plan</div>
                            }


                            {this.state.rechargeType != 'Recharge' &&
                             <div className="kt-heading kt-heading--md" style={{'text-align':'center'}}>Your Plan</div>
                            }


                            {(this.state.reference_number_applied == false) ? 

                             // <PlanTabs setSelectedPlan={this.setSelectedPlan} activeSection="28-day_plan" from="recharge" goNextStep={this.goNextStep} /> 

                             <PlanTabs activeSection="28-day_plan" mobileNumber={this.state.mobile_number} activeStatus="1" from="guest-recharge" goNextStep={this.goNextStep} customer_id={this.state.customer_id}/>
                            : 
                             <Plan plan_reference_number={this.state.reference_number} referenceNumberApplied={true} plan={this.state.reference_number_plan} selectedPlan={this.state.reference_number_plan.plan_id} customer_id={this.state.customer_id}/>

                            }

                            <div className="clearfix">
                                <br/>
                                <div className="kt-form__actions" id={this.state.voucherDivId}>
                                    <button type="submit" className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm" id="backBtn" onClick={this.back}>Back</button>&nbsp;
                                    
                                    {this.state.rechargeType == 'Recharge' &&

                                    <button onClick={this.addPlan} type="submit" className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm" id="wizard_next_btn" data-ktwizard-type="action-next" disabled={this.state.loading}>Next Step</button>
                                
                                    }

                                    {this.state.rechargeType == 'RechargeWithVoucher' &&

                                        <button onClick={this.addPlan} type="button" className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm" id="wizard_next_btn" data-ktwizard-type="action-next" disabled={this.state.loading}>Recharge</button>

                                    }

                                </div>
                            </div>

                        </div>  
                    </div>      
                </form>
            </>
        )
    }
}

export default SelectRechargePlan
