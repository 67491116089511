import * as React from 'react';
import { useState, useEffect } from 'react';
import swal from 'sweetalert';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';

const CheckoutForm = ({setData}) => {
  
  const [isLoading, setIsLoading] = useState(false);

    const stripe = useStripe();
    const elements = useElements();
    const [isFormMounted, setIsFormMounted] = useState(false);

    useEffect(() => {
      setIsFormMounted(true);
  
      return () => {
        setIsFormMounted(false);
      };
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        if (!stripe || !elements) {
          // Stripe.js has not yet loaded.
          return;
        }
        //setIsLoading(true);
        try{
          const cardElement = elements.getElement(CardElement);
          console.log('cardElement ==>', cardElement);

          const {error, token} = await stripe.createToken(cardElement);
  
          if (error) {
            console.error("cardElement ==>", error);
            swal({
                title: error.message,
                content: error.message,
                icon: "error"});
            // showMsg(error.message, Constants.SnackBarType.Error);
          } else {
            console.log(token.id, token.card.id, token);
            setData(token.card.id, token.id)
            
            // Send the token.id to your server to save the card to a customer's Stripe account
          }
        }catch(e){
          //setIsLoading(false);
          console.log('error ==>', e);
        }
    };

    

    

  return (
    <div style={{paddingTop: 10}}>
        <CardElement options={{ hidePostalCode: true }}
        onChange={event=>{
            console.log(event)
        }}/>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginTop: 20}}>
            <button style={{marginTop: 10, height: 35, width: '25%', backgroundColor: '#AA0000', color: '#fff', borderWidth: 0, borderRadius: 5}} disabled={!stripe} onClick={handleSubmit}>Next</button>
        </div>
    </div>
  );
};

export default CheckoutForm;