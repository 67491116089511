import React, { Component } from "react";  
import { withRouter } from 'react-router-dom'
import axios from 'axios';    
import {getApiHeader, removeUserSession} from '../../utils/CommonFunctions';
import PlanTabs from './../public/plans/planTabs';
import swal from "@sweetalert/with-react";
import rechargeFriend from './../../assets/img/rechargefriend.png';
import PageLoader from './../Loader/loader';

const SERVER_URL    = process.env.REACT_APP_SERVER_URL;  
const isValidPhNo = /^[0-9]*$/;

class RechargeAFriend extends Component {

    constructor(props)
    {
        super(props);
        let activeSection = '28-day_plan';
        if(this.props && this.props.location && this.props.location.state && this.props.location.state.activeSection) { 
            activeSection = this.props.location.state.activeSection;
        } 
        this.state = {
            selected_number: null,
            activeSection: activeSection,
            loading: false,
            active_status: '',
            mobileNumber: '',
            showPlan: false,
            activation_id: 0,
            customer_id: 0,
            toRecharge: "recharge-a-friend"
        }
    }

    componentDidMount() {
        if(this.props && this.props.location && this.props.location.state && this.props.location.state.activeSection) 
        { 
            this.setState({activeSection:this.props.location.state.activeSection});
        }

        this.getUserStatus();


    }


    // force logout --------------------------------------
    getUserStatus = async () => {
        let headers     = await getApiHeader();
        let res         = await axios.get(`${ SERVER_URL }user_status`, { headers }).then(async response => {
            
            if(response.data.data.forceLogout){
                if(response.data.data.forceLogout != 'undefined'){
                    if(response.data.data.forceLogout == '1'){
                        this.handleLogout();
                    }
                }
            }
            
        }).catch(error => {            
        });
 
    };

    handleLogout = async () => {  
        let p = new Promise(async (resolve, reject)=> {
            let headers     = await getApiHeader();
            let res         = await axios.get(`${ SERVER_URL }logout`, { headers });
            let data        = res.data.data;   
            console.log(data);
            removeUserSession();  
            resolve();

        });

        p.then(
            ()=>{ 
                    this.props.history.push("/login");
            },
            ()=>{
                alert("Error in logout");
            }
        )
    }
    // force logout --------------------------------------




    onMobileNoEditHandle = (mobileNumber) => {
        if((mobileNumber.target.value.length <= 10) && mobileNumber.target.value.match(isValidPhNo)){
          this.setState({mobileNumber: mobileNumber.target.value});
        }
    }

    validateForm = () => {
        const {mobileNumber} = this.state;

        if(mobileNumber == ''){
          return "Enter a mobile number";
        }
        return undefined
    }

    alertMessage = (msg) => {
        swal(msg, "", "warning");
    }

    toContinuePressHandle = async () => {
        const {mobileNumber} = this.state
        await sessionStorage.setItem('selected_number', '');
        const error = this.validateForm();
        if(error) return this.alertMessage(error);
        this.setState({ isLoading: true });
        const post_data = { 
            mobile_number: mobileNumber
        };
        let headers     = await getApiHeader();
        let res = await axios.post(`${SERVER_URL}recharge/friend_verify_number`, post_data, { headers })
        .then(async(response) => {
            let toRecharge = "recharge-a-friend"
            if(response.data.data.recharge_type == "recharge-own-number") {
                await sessionStorage.setItem('selected_number', mobileNumber);
                toRecharge = "recharge"
            }
            //console.log(response)
            this.setState({ isLoading: false, showPlan: true, active_status: response.data.data.active_status, 
                toRecharge, activation_id: response.data.data.activation_id, customer_id: response.data.data.customer_id});  
        })
        .catch((error) => {
            this.setState({ isLoading: false });
            if (
              typeof error.response.data.message !== "undefined" &&
              typeof error.response.data !== "undefined"
            ) {
              this.alertMessage(error.response.data.message);
            } else {
              this.alertMessage("Internal Server Error");
            }
        });
    }

    render() {
        const {mobileNumber, showPlan, loading, activeSection, active_status, toRecharge, activation_id, customer_id} = this.state;

        let default_tab='28-day_plan';
        let extratab= window.sessionStorage.getItem("recharge");
        if(extratab=="extras_plan") {
            default_tab = "extras_plan";
            window.sessionStorage.setItem("recharge", "28-day_plan");
        }
        
        return (
            <>
                {loading === true && (<PageLoader/>)}
                <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor recharge" id="kt_content"> 
                    <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                        <div className="clearfix"></div>
                        <div className="row">
                            <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
                                <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__head">
                                            <div className="row" style={{width:"100%"}}>
                                                <div className="col-md-6">
                                                    <div className="kt-portlet__head-label" style={{marginLeft: -15}}>
                                                        <h3 className="kt-portlet__head-title">Recharge a Friend</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {showPlan == false && (<div className="row mb60" style={{marginTop: 15}}>
                                            <div className="col-lg-6 col-xl-6 order-lg-1 order-xl-1" style={{marginTop: 20}}>
                                                {/* <div className="kt-portlet kt-widget kt-widget--general-3 kt-portlet--height-fluid"> */}
                                                    <div className="kt-portlet__head-label">
                                                        <h1 className="kt-portlet__head-title" style={{fontWeight: 'bold'}}>Recharge your Service</h1>
                                                        <label className="col-xl-12 col-lg-12 col-form-label txt-left" >
                                                            {<p style={{fontWeight: 'bold'}}>Recharge yourself, or for someone else, on your phone or desktop.</p>}
                                                        </label>
                                                    </div>
                                                
                                                    <div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%'}}>
                                                        <label className="col-xl-12 col-lg-12 col-form-label txt-left" >
                                                            {<p>Enter your mobile number</p>}
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            style={{marginTop: -10, height: 45}}
                                                            type='number'
                                                            maxLength={10}
                                                            max={10}
                                                            value={mobileNumber}
                                                            onChange={this.onMobileNoEditHandle}
                                                        />
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12" style={{marginTop: 15, width: '100%'}}>
                                                        <div className="kt-form__actions">
                                                            <button
                                                            type="submit"
                                                            style={{width: '100%', borderRadius: 5, height: 45}}
                                                            className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm"
                                                            id="wizard_next_btn"
                                                            data-ktwizard-type="action-next"
                                                            onClick={this.toContinuePressHandle}
                                                            >
                                                            Continue
                                                            </button>
                                                        </div>
                                                    </div>
                                                {/* </div> */}
                                            </div>
                                            <div className="col-lg-6 col-xl-6 order-lg-1 order-xl-1 hideView">
                                                <div className="kt-portlet kt-widget kt-widget--general-3 kt-portlet--height-fluid">
                                                    <div className="kt-portlet__body kt-portlet__body--fit">
                                                        <img src={rechargeFriend} alt="image"/>	
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)}

                                        <div className="kt-portlet__body" id="payment_method_container">
                                            {showPlan == true && (
                                            <PlanTabs 
                                            activeSection={activeSection} 
                                            mobileNumber={mobileNumber} 
                                            activeStatus={active_status} 
                                            from={toRecharge}
                                            activation_id={activation_id}
                                            customer_id={customer_id}/>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>            
            </>
        )
    }
}


export default withRouter(RechargeAFriend);