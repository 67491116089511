import React, { Component } from "react";
import axios from 'axios';    
import swal from '@sweetalert/with-react';
import {getApiHeader} from '../../utils/CommonFunctions';
import { Link, withRouter} from "react-router-dom";


const SERVER_URL 	= process.env.REACT_APP_SERVER_URL;   
class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            curr_password: '' ,
            password: '' ,
            password_confirmation: '',
        };
    }

    mySubmitHandler = async (event) => {  
    const headers 		= await getApiHeader();
        event.preventDefault();  
        swal({ 
            text: "Are you sure you want to change the password?",
            icon: "warning", 
            dangerMode: true,
            buttons: {
                confirm : {text:'Yes, Proceed!',className:'sweet-warning'},
                cancel : 'No, Cancel!'
            }
        })
        .then(async (willDelete) => {
            this.setState({loading:true});
            if (willDelete) {
                const post_data =   { password_old: this.state.curr_password ,
                                        password : this.state.password , 
                                        password_confirm : this.state.password_confirmation , 
                                    }; 
                let res         = await axios.post(`${ SERVER_URL }password_change`,post_data, { headers }).then(response => {
             
                    this.setState({loading:false});
                    swal(response.data.message, "", "success");
                    this.props.history.push("/ChangePassword");
                    
                }).catch(error => {
                    this.setState({loading:false});
                    if((typeof(error.response.data.message) !== 'undefined') && (typeof(error.response.data) !== 'undefined'))
                    {
                        swal(error.response.data.message, "", "warning"); 
                    }
                    else
                    { 
                        swal("Internal Server Error", "", "warning");
                    }
        
                    
                });

                
                // let res 		= await axios.post(`${ SERVER_URL }password_change`,post_data, { headers });
                // let data 		= res.data;  
                // if(data.status==200){  
                //     swal(data.message, "", "success");
                // }else{
                //     swal(data.message, "", "warning");
                // } 
                
            } else {
                swal("Cancelled", "", "success");
            }
            this.setState({loading:false});
        });

    }

    changeCurrentPassword = (event) => {
        this.setState({curr_password: event.target.value});
    }

    changePassword = (event) => {
        this.setState({password: event.target.value});
    }

    changeConfirmPassword = (event) => {
        this.setState({password_confirmation: event.target.value});
    }

    render() {
  return (
    <>
        <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
                        <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                            <div className="kt-portlet">
                                <div className="kt-portlet__head">
                                    <div className="kt-portlet__head-label">
                                        <h3 className="kt-portlet__head-title">Change Password</h3>
                                    </div>
                                </div>
                                <form className="kt-form kt-form--label-right"  id="change_password_form" onSubmit={this.mySubmitHandler} > 
                                    <div className="kt-portlet__body">
                                        <div className="kt-section kt-section--first">
                                            <div className="kt-section__body">
                                                <div className="form-group row">
                                                    <label className="col-xl-4 col-lg-4 col-form-label" for="curr_password">Enter Current Password</label>
                                                    <div className="col-lg-6 col-xl-6">
                                                        <input
                                                            className="form-control"
                                                            type="password"
                                                            name="curr_password"
                                                            id="curr_password" 
                                                            required
                                                            onChange={this.changeCurrentPassword} />
                                                        <div id="pass_error" style={{color: 'red'}}></div>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-xl-4 col-lg-4 col-form-label" for="password">Enter New Password</label>
                                                    <div className="col-lg-6 col-xl-6">
                                                        <input
                                                            type="password"
                                                            id="password"
                                                            className="form-control"
                                                            name="password"
                                                            pattern="\s*(\S\s*){6,}"
                                                            title="Should not contain any space and at least 6 or more characters"
                                                            required onChange={this.changePassword} 
                                                            />
                                                        <div id="new_pass_error" style={{color: 'red'}}></div>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-xl-4 col-lg-4 col-form-label" for="password_confirmation">Confirm New Password</label>
                                                    <div className="col-lg-6 col-xl-6">
                                                        <input
                                                            className="form-control"
                                                            type="password"
                                                            name="password_confirmation"
                                                            id="password_confirmation"
                                                            required onChange={this.changeConfirmPassword} 
                                                            />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="kt-portlet__foot">
                                        <div className="kt-form__actions">
                                            <div className="row">
                                                <div className="col-lg-5 col-xl-5"></div>
                                                <div className="col-lg-6 col-xl-6">
                                                    <button type="submit" className="btn btn-brand" id="saveBtn">Save & Submit</button>
                                                    &nbsp;
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
	</>
   )
}
}
export default withRouter(ChangePassword);
