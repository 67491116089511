import React, { Component } from "react"; 
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';   
import { getApiHeaderGuest, setUserSession, getRegisterAttemptData } from '../../utils/CommonFunctions'; 
import App from "../../App";
import swal from '@sweetalert/with-react';
import { Link, withRouter} from "react-router-dom";
import GuestHeader from './../../components/Layout/GuestHeader';
import GuestFooter from "./../../components/Layout/GuestFooter";
import PublicSlider from './slider/PublicSlider';
import { WidgetInput } from "react-addressfinder";
import "react-addressfinder/dist/widget.css";
import PageLoader from './../Loader/loader';
import DropIn from "braintree-web-drop-in-react";
import Loader from "react-loader-spinner";
import ErrorMsg from './../public/errorMsg';

const SERVER_URL 	= process.env.REACT_APP_SERVER_URL;   
const isValidPhNo = /^[0-9]*$/;
//const isValidEmail = /[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[a-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/;
//const isValidEmail =/^([A-Za-z0-9_\-\.]+)@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,3})$/
const isValidEmail= /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
const headers 		= getApiHeaderGuest();


const AddressFinder = ({ addressFinderKey, inputClassName, id ,onChange}) => {
    return(
      <WidgetInput
        addressFinderKey={addressFinderKey}
        inputClassName={inputClassName}
        id={id}
        onSelected={(fullAddress, address) => {
           onChange(fullAddress,address);
        }}
      />
      )
}

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            otp: '' , 
            mobile_number: '' , 
            username: '' , 
            password: '' ,
            isLoggedIn: false,
            redirection: false,
            sapUser:'',
            address_full:'',
            verification_token:'',
            email:'',
            number_success:'',
            number_failed:'',
            addpayment:'',
            ShoWpayment:'',
            clientToken:null,
            payCheck:'',
            customerror:'',
            submitBtnDisplay:'inline-block',
            emailOTP: '',
            sendMOTP: false,
            sendEOTP: false,
            isMobiVerified: false,
            isEmailVerified: false,
            email_verification_token: '',
            isShowPayment: false,
            otp_failed: '',
            emailOTP_failed: '',
            register_attempt_session_id: '',
            verify_status: '',
            activityId: 0,
            register_attempt_data:[],
            button: '',
            Emailstatus:false,
            ExpiredCustomer:null,
            displayPayConfirm:null
        };
        this.validator = new SimpleReactValidator(); 
    }


   onOTPEditHandle = (event) => {
        if(event.target.value.match(isValidPhNo)){
            this.setState({otp: event.target.value});
        }
    }

    onEmailOTPEditHandle = (event) => {
        if(event.target.value.match(isValidPhNo)){
            this.setState({emailOTP: event.target.value});
        }
    }

    enterNumber = (event) => {
        // const re = /^[0-9\b]+$/;
        // if ((event.target.value === '' || (re.test(event.target.value) && event.target.validity.valid) ) && event.target.value.length < 11) {
        //     this.setState({mobile_number: event.target.value});  
        //  }
        if((event.target.value.length <= 10) && event.target.value.match(isValidPhNo)){
            this.setState({mobile_number: event.target.value});
        }
    }

    onUserNameEditHandle = (event) => {
        this.setState({username: event.target.value});
    }

    checkPass = (event) => {
        this.setState({password: event.target.value});
    }

    checkemail = (event) => {
        this.setState({email: event.target.value});
    }

    setaddressFinder= async (full_address,address) => {
        this.setState({ address_full: full_address });
    };

    resetAddress = () => {
        this.setState({ address_full: '' });
    }


    maskUsername = (username) => {
        // Your email masking logic goes here
        // This is a simple example that replaces some characters with asterisks
        return username.substring(0, 3) + '*'.repeat(username.length - 3);
      };







 // Email verification passing Data(email, AttemptID, SessionID, VerifyHash(password))
 Emailverification = ()=>{
    this.setState({loading:true});
    axios.post(`${ SERVER_URL }registration/send-email-verification`, {
        email: this.state.username,
        attemptId: this.state.verification_token,
        sessionId: this.state.register_attempt_session_id,
        verifyHash: this.state.password,
        
    },{headers} )
    
    .then((response) => {
        
   
      // Handle the response from the server
      console.log('Response Status',response.status);
      this.setState({loading:false});
      if(response.status == 200){
        swal({title:'Verification link has been sent to your email id, Please verify',icon:'success'})
        this.setState({Emailstatus:true})
      }else{
        swal("Error sending Verification link")
        this.setState({Emailstatus:false})
      }
      console.log('Data',response.data)
     
    })
    .catch((error) => {
      // Handle any errors
      console.error(error);
    });



}

// END Email verification passing Data(email, AttemptID, SessionID, VerifyHash(password))





mypaymentHandle = async () => { 
    // if(this.validator.allValid() == false) {
    //     this.validator.showMessages();
    //     this.forceUpdate();
    //     return;
    // }


    // Email verification Status 
// const verify_data = {
//          username: this.state.username,
//          attempt_id: this.state.verification_token,
//          session_id: this.state.register_attempt_session_id,
//          verifyHash: this.state.password,
// }

// await axios.post(`${ SERVER_URL }registration/check-email-verification-status`, verify_data, { headers }).then((data)=>{
    
//     console.log(data.status,'Email verification Status')
//     if (data.status == 200){
//         this.setState({button : true})
        const error = this.validateForm();
        if(error) return this.showMessage(error);
        this.setState({loading: true});
        const post_data = {   
        mobile_number: this.state.mobile_number,
        username: this.state.username,
        password: this.state.password,
        email: this.state.username,
        address_full: this.state.address_full,
        session_id: this.state.verification_token,
        register_attempt_session_id: this.state.register_attempt_session_id
    };
    // console.log(post_data);


    axios.post(`${ SERVER_URL }registerTopayment`,post_data, { headers }).then(response => {
        this.setState({isShowPayment: true, verify_status: response.data.data.verify_status, activityId: response.data.data.activityId, loading: false, ShoWpayment: 1, displayPayConfirm:1})
    }).catch(error => {
        if((error.response) && (error.response.data) && (error.response.data.message)) {
            this.setErrorMessages(error.response.data.message,'warning');
            swal(error.response.data.message,"", 'warning')
        } else {
            swal("Internal Server Error", "", 'warning');
        }
        this.setState({loading: false});
    });

    // }


// }).catch((err)=>{
//     console.log(err.response.data.status)
//     swal({title:'Verification link has been sent to your email id, Please verify to proceed to next step.',icon:'info'})
// })

 // end Email verification Status


   
    
   
    
}

    componentDidMount = async () => { 

        this.getRegisterAttemptData();

        try {
            let headers     = await getApiHeaderGuest();
            let res         = await axios.get(`${ SERVER_URL }payments/get-client-token`, { headers });
            let response    = res.data.data;
            this.setState({clientToken:response.clientToken});
        } catch(err) {
            this.setState({loading:false});
            if(err) {
                let errorMessage = '';
                if(err.response && err.response.data && err.response.data.message) {
                    if(typeof(err.response.data.message) === 'object') {
                        swal({
                            title: "Error",
                            content: <ErrorMsg message={err.response.data.message}/>,
                            icon: "error"
                        });
                    } else {
                        errorMessage = err.response.data.message;
                        swal(errorMessage,"error");
                    }
                } else {
                    errorMessage = err;
                    swal(errorMessage,err);
                }
            }
        } 
    }


    getRegisterAttemptData = async () => {
        this.setState({loading:true});
		let register_attempt_data     = await getRegisterAttemptData();   
        if(register_attempt_data.register_attempt !=null)
        {
            this.setState({ register_attempt_session_id: register_attempt_data.register_attempt.session_id});  
        }  
        this.setState({loading:false});
	};


    onRequestOTPBtnClick = async () => { 
        //event.preventDefault();  
        const error = this.validateForm();
        if(error) return this.showMessage(error);
        this.setState({loading:true});
        const post_data =   { 
            mobile_number: this.state.mobile_number, 
            register_session_id: this.state.register_attempt_session_id 
        };
        let res = await axios.post(`${ SERVER_URL }registration/get-otp`,post_data, { headers }).then(response => {
            if(typeof(response.data.message) !== 'undefined') { 
                this.setState({ number_failed: '', number_success: response.data.message }); 
            } else { 
                this.setState({ number_failed: '', number_success: "OTP Sent" }); 
            }
            if(response.data.status == 200) {

                if(response.data.data.expired_customer !== 'undefined' && response.data.data.customer_name !== 'undefined'){

                    this.setState({ExpiredCustomer: response.data.data.customer_name });

                }

                this.setState({
                    sendMOTP: true,
                    verification_token: response.data.data.otp_token,
                    register_attempt_session_id: response.data.data.register_attempt_session_id
                })

                swal(response.data.message,"","success")


                sessionStorage.setItem('register_session_id', response.data.data.register_attempt_session_id); 

            }else if(response.data.status == 422) { 
                var arr = [];
                arr = Object.keys(response.data.message).map(key=>response.data.message[key][0])
                this.setState({ number_failed: arr[0], number_success: '' }); 
            }
        }).catch(error => { 
            this.setState({ 
                register_attempt_session_id: error.response.data.data.register_attempt_session_id
            })
            sessionStorage.setItem('register_session_id', error.response.data.data.register_attempt_session_id); 
            if((typeof(error.response.data.message) !== 'undefined') && (typeof(error.response.data) !== 'undefined')) { 
                this.setState({ number_failed: error.response.data.message, number_success: '' });
            } else {
                swal("Internal Server Error", "", 'warning');
            }
        });
        this.setState({loading:false});
    }

    showMessage = (message) => {
        swal(message, "", 'warning');
    }

    validateForm = () => {
        const {otp, emailOTP, username, mobile_number, isEmailVerified, isMobiVerified, sendEOTP, sendMOTP, password, address_full, ExpiredCustomer} = this.state
        if(isMobiVerified == false){
            if(sendMOTP == false){
                const firstdigit = String(this.state.mobile_number).slice(0, 1); // 👉️ '1'
                if(mobile_number == ''){
                    return "Please enter your 10 digit Telsim mobile number starting with 0 ";
                } else if(mobile_number.length < 10) {
                    return "Please enter your 10 digit Telsim mobile number starting with 0 ";
                } else if(firstdigit !== "0") {
                    return "Please enter your 10 digit Telsim mobile number starting with 0 ";
                }
            } else {
                if(otp == '' && ExpiredCustomer == null){
                    return "Please enter your OTP"
                }
            }
        }else if(isMobiVerified == true) {
            if(username == '') {
                return "Please enter your email address."
            }else if(!username.match(isValidEmail)){
                return "Invalid email address."
            }else if(password == ''){
                return "Please enter your password"
            }else if(password.length < 6){
                return "Enter a valid password with atleast 6 characters";
            }else if(address_full == '') {
                return "Please enter your address"
            }
        }
        return undefined
    }

    onMobiVerifyBtnClick = async() => {
        const {mobile_number, otp, verification_token, register_attempt_session_id, ExpiredCustomer} = this.state;
        const error = this.validateForm();
        if(error) return this.showMessage(error);
        this.setState({loading: true});
        const post_data =   { 
            mobile_number: mobile_number, 
            otp: otp, 
            session_id: verification_token,
            register_attempt_session_id: register_attempt_session_id,
            expired_customer: ExpiredCustomer
        };
        let res = await axios.post(`${ SERVER_URL }registration/verify-otp`, post_data, { headers }).then(response => {
            console.log('onMobiVerifyBtnClick', response)
            this.setState({
                isShowPayment: false,
                isMobiVerified: true, otp_failed: '',
                register_attempt_session_id: response.data.data.register_attempt_session_id
            });

            if(typeof(response.data.data.default_local_address) !== 'undefined') { 

                if(response.data.data.default_local_address != null){

                        this.setState({ address_full: response.data.data.default_local_address}); 

                }

            }

        }).catch(error => {
            console.log('onMobiVerifyBtnClick', error.response.data.message)
            if((typeof(error.response.data.message) !== 'undefined') && (typeof(error.response.data) !== 'undefined')) { 
                this.setState({ otp_failed: error.response.data.message});
            } else {
                swal("Internal Server Error", "", 'warning');
            }
        });
        this.setState({loading: false});
    }

    onEmailRequestOTPBtnClick = async() => {
        const {mobile_number, username, register_attempt_session_id} = this.state;
        const error = this.validateForm();
        if(error) return this.showMessage(error);
        this.setState({loading: true});
        const post_data =   {
            mobile_number: mobile_number, 
            username: username,
            register_attempt_session_id: register_attempt_session_id
        };
        let res = await axios.post(`${ SERVER_URL }registration/get-mailotp`, post_data, { headers }).then(response => {
            if(typeof(response.data.message) !== 'undefined') { 
                this.setState({ email_failed: '', email_success: response.data.message }); 
            } else { 
                this.setState({ email_failed: '', email_success: "OTP Sent" }); 
            }
            this.setState({
                sendEOTP: true,
                email_verification_token: response.data.data.otp_token,
                register_attempt_session_id: response.data.data.register_attempt_session_id
            })
            swal(response.data.message, "", "success")
        }).catch(error => {
            if((typeof(error.response.data.message) !== 'undefined') && (typeof(error.response.data) !== 'undefined')) { 
                this.setState({ email_failed: error.response.data.message, email_success: '' });
                swal(error.response.data.message,"", "warning")
            } else {
                swal("Internal Server Error", "", 'warning');
            }

        });
        this.setState({loading: false});
    }

    onEmailVerifyBtnClick = async() => {
        
        const {mobile_number, emailOTP, email_verification_token, username, register_attempt_session_id} = this.state;
        const error = this.validateForm();
        if(error) return this.showMessage(error);
        this.setState({loading: true});
        const post_data =   { 
            mobile_number: mobile_number, 
            otp: emailOTP, 
            username: username,
            session_id: email_verification_token,
            register_attempt_session_id: register_attempt_session_id
        };
        let res = await axios.post(`${ SERVER_URL }registration/verify-mail-otp`, post_data, { headers }).then(response => {
            // if(typeof(response.data.message) !== 'undefined') { 
            //     this.setState({ number_failed: '', number_success: response.data.message }); 
            // } else { 
            //     this.setState({ number_failed: '', number_success: "OTP Sent" }); 
            // }
            this.setState({
                isEmailVerified: true, emailOTP_failed: '', displayPayConfirm: 0
                
            })
            
        }).catch(error => {
            if((typeof(error.response.data.message) !== 'undefined') && (typeof(error.response.data) !== 'undefined')) { 
                this.setState({ emailOTP_failed: error.response.data.message});
                swal(error.response.data.message,"", 'warning')
            } else {
                swal("Internal Server Error", "", 'warning');
            }
        });
        this.setState({loading: false});
    }

    async buy() {
        if(this.state.payCheck==true) {
            this.setState({loading: true, submitBtnDisplay: 'none', number_failed: ""});
            let payMentnonce =null;
            try {
             const { nonce }  = await this.instance.requestPaymentMethod();
             payMentnonce = nonce;
             // JSON.stringify(nonce);
            } catch(error){
                this.setState({loading:false, submitBtnDisplay:'inline-block'});
                return;
            }
            let post_data   = {
                nonce: payMentnonce,
                mobile_number: this.state.mobile_number,
                // session_id: this.state.verification_token,
                // otp: this.state.otp,
                register_attempt_session_id: this.state.register_attempt_session_id
            };
            let headers     = await getApiHeaderGuest();
            try {
                let res = await axios.post(`${ SERVER_URL }payments/add-card-register`,post_data, { headers });
                let data = res.data;
                this.setState({loading:false});
                this.setState({loading:true});
                this.mySubmitHandlerregSap();
                    
                // }else
                // {
                    //swal(data.message, "", "warning");
                    // alert("error");
                    // console.log(data);
                // this.setState({ number_failed: error.data.message }); 
                    // this.setState({ number_success: '' }); 
                // }

            }
            catch(error) {
                // alert(JSON.stringify(err.response.data));
                this.setState({loading:false, submitBtnDisplay:'inline-block'});
                this.setState({ number_failed: error.response.data.message });
                this.setState({ number_success: '' });
            }
        } else {
            this.setState({ number_failed: "Please Confirm CheckBox"});
        }
    }

    mySubmitHandlerreg = async (event) => { 
        event.preventDefault();  
        this.setState({loading:true});
        const post_data =   {   
            mobile_number: this.state.mobile_number,
            username: this.state.username,
            password: this.state.password,
            email: this.state.username,
            address_full: this.state.address_full,
            session_id: this.state.verification_token,
            verify_status: this.state.verify_status,
            activityId: this.state.activityId,
            register_attempt_session_id: this.state.register_attempt_session_id,
            register_type: this.state.displayPayConfirm
        };
        console.log(post_data);
        let res         = await axios.post(`${ SERVER_URL }register`,post_data, { headers }).then(response => {
            this.setState({loading:false});
            if(typeof(response.data.message) !== 'undefined') {
                setUserSession(response.data.data.token, response.data.data.user); 
                let sucreg='successregister';
                if(response.data.data.sapcheck==1) { 
                    sucreg='successsapregister'; 
                }
                this.setErrorMessages(response.data.message,sucreg);
            } else {
                this.setErrorMessages("Account Registered Successfully",'successregister');
            }
        }).catch(error => {
            this.setState({loading:false});
            if((error.response) && (error.response.data) && (error.response.data.message)) {
                this.setErrorMessages(error.response.data.message,'warning');
            } else {
                swal("Internal Server Error", "", 'warning');
            }
        });
    }

    Checkbox = (check) => { 
        this.setState({ payCheck: check});
    }

    mySubmitHandlerregSap = async (event) => { 
        this.setState({loading:true});
        const post_data = {  
            mobile_number: this.state.mobile_number,
            username: this.state.username,
            password: this.state.password,
            email: this.state.username,
            address_full: this.state.address_full,
            session_id: this.state.verification_token,
            verify_status: this.state.verify_status,
            activityId: this.state.activityId,
            register_attempt_session_id: this.state.register_attempt_session_id,
            register_type: this.state.displayPayConfirm
        };
        console.log(post_data);
        let res = await axios.post(`${ SERVER_URL }register`,post_data, { headers }).then(response => {
            this.setState({loading:false});
            let p = new Promise((resolve, reject)=>{
                setUserSession(response.data.data.token, response.data.data.user);
                resolve();
            })
            p.then(()=>{

                this.setState({loading:false});
                if(typeof(response.data.message) !== 'undefined') {
                    let sucreg='successregister';
                    if(response.data.data.sapcheck==1){ 
                        //sucreg='successsapregister'; 
                    }
                    this.setErrorMessages(response.data.message,sucreg);
                }
                else {
                    this.setErrorMessages("Account Registered Successfully",'successregister');
                }

            }, () => {
                    alert("Error in Login");
                    this.setState({loading:false});
            })
        }).catch(error => {
            this.setState({loading:false});
            if((error.response) && (error.response.data) && (error.response.data.message)) {
                this.setErrorMessages(error.response.data.message,'warning');
            } else {
                swal("Internal Server Error", "", 'warning');
            }
        });
    }

    setErrorMessages = (errorMessage, type)=>{
        let responseError = 'Internal server error';
        if (typeof(errorMessage) !== 'undefined' && errorMessage != null) {
            if(typeof errorMessage === 'object') {
                responseError = '';
                Object.keys(errorMessage).map(function(key, index) {
                    // responseError.push(errorMessage[key]);
                    responseError+=errorMessage[key];
                });
            } else {
                responseError = errorMessage;
            }
        }
        if(type == 'warning') {
            swal(responseError, "", type);
        } else if(type === 'successsapregister' && this.state.displayPayConfirm === null){
            swal({
                buttons: {
                    cancel: true,
                    confirm: {text: 'Yes'},
                },
                title: "Successfully Registered",
                text: "Would you like to add Payment Methods ?",
                dangerMode: true,
            })
            .then(willDelete => {
                if (willDelete) {
                    this.props.history.push("/Mypayments");
                }
                else {
                    this.props.history.push("/home");
                }
            });
        }
        else {

            if(this.state.displayPayConfirm === null){

                swal(responseError, "", 'success').then(()=>{
                    if(type === 'successregister')
                    {
                       
                    sessionStorage.setItem('register_session_id', ""); 
                        this.props.history.push("/home");
                    }
                });


            }else{

                swal(responseError, "", 'success').then(()=>{
                    
                    this.props.history.push("/home");
                    
                });


            }
            
        }
    }

    resetEmail = () => {
        this.setState({
            sendEOTP: false, 
            isEmailVerified: false,
            username: '',
            password: '',
            email_verification_token: '',
            email_success: ''
        })

    }
     
    render() { 
        const { redirection, sendEOTP, sendMOTP, isMobiVerified, isEmailVerified, emailOTP, otp,button, Emailstatus, mobile_number, 
            number_success, number_failed, username, email_success, email_failed, isShowPayment, otp_failed, emailOTP_failed, displayPayConfirm} = this.state;
    
  return (
    <>
    {(this.state.loading === true) ? <PageLoader/> : null}
    {redirection ? (
            <App />
) : (
        <div className="kt-grid kt-grid--ver kt-grid--root">
        <div className="kt-grid__item   kt-grid__item--fluid kt-grid  kt-grid kt-grid--hor kt-login-v2" id="kt_login_v2">
        <GuestHeader/>
        <div className="kt-grid__item  kt-grid  kt-grid--ver  kt-grid__item--fluid common-class">
            <section className="login-block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 login-sec" style={{paddingBottom: '40px' }}>
                            <h2 className="text-center" style={{fontSize: '16px'}}>Register Your New Account</h2>
                            {/* onSubmit={sendMOTP == true ? this.onMobiVerifyBtnClick : this.onRequestOTPBtnClick} */}
                                {isMobiVerified == false && (<form className="login-form">
                                    <div className="form-group">
                                        {/* <input type="hidden" className="form-control" placeholder="" defaultValue="" value=""/> */}
                                        <label className="">Enter your Telsim Mobile Number</label>
                                        <input type='text' className="form-control" placeholder="e.g. 0471111111" onChange={this.enterNumber} value={mobile_number} required readOnly={sendMOTP} maxLength={10} max={10}/>
                                        <span  style={{color: 'green'}} role="alert">{number_success}</span>
                                        <span  style={{color: 'red'}} role="alert">{number_failed}</span>
                                    </div> 
                                    {sendMOTP == true && (
                                    <>
                                        {this.state.ExpiredCustomer == null ?
                                        <div className="form-group">
                                            <label className="">Please check your Telsim mobile number for OTP</label>
                                            <input type="text" className="form-control" placeholder="" onChange={this.onOTPEditHandle} value={otp} required maxLength={6} max={6}/>
                                            <span  style={{color: 'red'}} role="alert">{otp_failed}</span>
                                            {this.validator.message('OTP', otp, 'required', { className: 'text-danger' })}
                                        </div> 
                                        :
                                        <div className="form-group">
                                            
                                            <input type="text" className="form-control" placeholder="" readOnly value={this.state.ExpiredCustomer} required />
                                            <span  style={{color: 'red'}} role="alert">{otp_failed}</span>
                                            {this.validator.message('OTP', otp, 'required', { className: 'text-danger' })}

                                        </div> 
                                        }
                                    </>
                                    )}
                                    
                                    <div className="form-check" style={{textAlign:'center'}}> 
                                        {this.state.ExpiredCustomer == null ?
                                            <button type="button" className="btn btn-brand btn-elevate btn-pill float-right btn-signin" style={{margin:'0 auto'}} onClick={sendMOTP == true ? this.onMobiVerifyBtnClick : this.onRequestOTPBtnClick}>{sendMOTP == false ? 'Verify Mobile Number' : 'Verify!'}</button>
                                            :
                                            <button type="button" className="btn btn-brand btn-elevate btn-pill float-right btn-signin" style={{margin:'0 auto'}} onClick={sendMOTP == true ? this.onMobiVerifyBtnClick : this.onRequestOTPBtnClick}>Next</button>
                                        }

                                    </div>
                                </form>)} 

                                {(isMobiVerified == true && isShowPayment == false && isEmailVerified == false ) && (<form className={"login-form"}>
                                     
                                    {/* {this.state.ShoWpayment !=1 &&
                                    onSubmit={sendEOTP == true ? this.onEmailVerifyBtnClick : this.onEmailRequestOTPBtnClick}
                                    <>
                                    <span  style={{color: 'green'}} role="alert">{this.state.number_success}</span>
                                    <span  style={{color: 'red'}} role="alert">{this.state.number_failed}</span>
                                    <div className="form-group">
                                        <label className="">Please check your Telsim mobile number for OTP</label>
                                        <input type="text" className="form-control" placeholder="" onChange={this.checkOTP} value={this.state.otp} required/>
                                        {this.validator.message('OTP', this.state.otp, 'required', { className: 'text-danger' })}
                                    </div> 
                                    <div className="form-check" style={{textAlign:'center'}}>                                       
                                        <button type="button" className="btn btn-brand btn-elevate btn-pill float-right btn-signin" style={{margin:'0 auto'}} onClick={this.verifySmsOtp}>VERIFY</button>
                                    </div> 
                                    </>
                                     } */}
                                     
                                    <div className="form-group">
                                        <label className="">Enter the email address you'd like to use to login to your Telsim account</label>

                                         <input disabled={sendEOTP == true} type="email" className="form-control" placeholder="" value={username} onChange={this.onUserNameEditHandle} required/>
                                       
                                        {/* <span  style={{color: 'green'}} role="alert">{email_success}</span> */}
                                        <span  style={{color: 'red'}} role="alert">{email_failed}</span>
                                        {this.validator.message('Username', username, 'required|email', { className: 'text-danger' })}
                                    </div> 
                                 
                                        {/* onSubmit={this.mySubmitHandlerreg} */}
                                    <div className="form-group">
                                        <label className="">Enter a password for your Telsim account</label>
                                        <input disabled={sendEOTP == true} type="password" className="form-control" placeholder="" onChange={this.checkPass} value={this.state.password} required pattern="\s*(\S\s*){6,}"
                                                            title="Should not contain any space and at least 6 or more characters"/>
                                        {this.validator.message('Password', this.state.password, 'required', { className: 'text-danger' })}
                                    </div>   
                                    <div className="form-group">
                                   
                                    </div>
                                    
                                    <div className="form-group">
                                        <label className="">Your current address</label>
                                        {this.state.address_full =='' &&
                                        <AddressFinder
                                                        addressFinderKey="UJCFN4HL6YRMT3D9K8PG"
                                                        inputClassName="address form-control"
                                                        id="address"
                                                        onChange={this.setaddressFinder}
                                        />
                                        }
                                        
                                        {this.state.address_full !='' &&
                                            <div className="addrselect">
                                            <input type="text" className="form-control" value={this.state.address_full} placeholder="" disabled/>
                                            <i onClick={this.resetAddress}>x</i>
                                            </div>
                                        }

                                        {this.validator.message('Address', this.state.address_full, 'required', { className: 'text-danger' })}

                                                
                                    </div>  
                                 <div className="form-group">
                                 {sendEOTP == true && isEmailVerified == false &&(
                                    <input style={{width:'100%'}} type="text" className="form-control" onChange={this.onEmailOTPEditHandle} value={this.state.emailOTP} placeholder="Enter OTP" required/>
                                    
                               )}
                               {sendEOTP == true ? (<p style={{color:'green'}}>Verification OTP has been sent to your Email ID, Please enter the OTP.</p>):''}

                                 </div>
                                    
                                    <div className="form-check" style={{textAlign:'center'}}> 

                                        {/* <button type="submit" className="btn btn-brand btn-elevate btn-pill float-right btn-signin" style={{margin:'0 auto'}}>Register</button> */}
                                        {/* <button type="button" className="btn btn-brand btn-elevate btn-pill float-right btn-signin" style={{margin:'0 auto'}} onClick={this.mypaymentHandle}>NEXT</button> */}


                                     

                                        {/* next button function  */}


                                        {sendEOTP == true ? (<button onClick={this.resetEmail} type="button" className="btn btn-brand btn-elevate btn-pill float-right btn-signin" style={{margin:'5px'}} >Reset</button>):''}

                                       <button type="button" className="btn btn-brand btn-elevate btn-pill float-right btn-signin" style={{margin:'0 auto'}} onClick={sendEOTP == false ? this.onEmailRequestOTPBtnClick : this.onEmailVerifyBtnClick}>{sendEOTP == false ? 'NEXT':'Verify OTP'}</button>


                                        

                                        {/* End Next Button function */}
                                        
                                    </div>
                                </form>)}
                                
                                {displayPayConfirm ==0 &&  (

                                    <div className="displayPayConfirm">

                                        <p className="confirmPayText">Do you want to save payment card details to Telsim My Account for future renewals?</p>

                                        <button type="button" onClick={this.mypaymentHandle} className="confirmPayYes">Yes</button>
                                        <button type="button" onClick={this.mySubmitHandlerreg} className="confirmPayNo">No</button>

                                    </div>


                                )}


                                {(isShowPayment == true && displayPayConfirm == 1) &&  (<form className={"login-form"}  onSubmit={this.mySubmitHandlerreg}>         
                                    {this.state.ShoWpayment ==1 &&
                                        <div className="paymentDiv">

                                                <p style={{marginBottom:'0'}}>Save your card and make your next recharge easy</p>

                                                {(this.state.clientToken !== null) ?
                                                <div>
                                                <DropIn
                                                options={{ authorization: this.state.clientToken, card:{ cardholderName: {'required':true}}}}
                                                onInstance={(instance) => (this.instance = instance)}
                                                />




                                                <div className="form-group  row">
                                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                                        <label className="kt-option">
                                                            <label className="kt-checkbox kt-checkbox--bold kt-checkbox--danger">
                                                                <input type="checkbox" name="authorised_check" id="authorised_check" onChange={e => {this.Checkbox(e.target.checked);}} required />
                                                                <span></span>
                                                            </label>
                                                            <span className="kt-option__label">
                                                                <span className="kt-option__head">
                                                                    <span className="kt-option__title">
                                                                        I authorise Telsim to charge my nominated payment method for automatic recharge when my current prepaid recharge expires and I understand payment method can be removed anytime from Telsim My Account.
                                                                    </span>

                                                                </span>
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="form-group  row">
                                                <span style={{color: 'red'}} role="alert">{this.state.number_failed}</span>
                                                </div>

                                                    <div className="form-check" style={{textAlign:'center',paddingLeft: '0px'}}> 

                                                    <button type="button" onClick={this.buy.bind(this)} className="btn btn-brand btn-elevate btn-pill" style={{display:this.state.submitBtnDisplay}} id="saveBtn">Register</button>
                                                    </div>
                                                </div>
                                                : <Loader             
                                                type="Bars"
                                                color="#e90000"
                                                secondaryColor="white"
                                                height={80}
                                                width={80}/>
                                                }
                                        </div>
                                    }



                                </form> )}


                        <br/>
                        <div className="form-check" style={{textAlign:"center"}}>
                         <label className="form-check-label">
                            <Link to="/Login" className="kt-link kt-link--brand" style={{paddingLeft:'0',display:this.state.submitBtnDisplay}}>Back to Login
                            </Link>
                         </label> 
                        </div>



                        </div>


                        <div className="col-md-8 banner-sec">
                            <PublicSlider/>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <GuestFooter/>

        </div>
        </div>     
        )}          
	</>
   )
}
}
export default withRouter(Register);