import React, { Component } from "react";
import axios from 'axios';
import swal from 'sweetalert';
import SimpleReactValidator from 'simple-react-validator';
import PageLoader from './../../Loader/loader';

import "react-addressfinder/dist/widget.css";
import {getApiHeader} from '../../../utils/CommonFunctions';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default class NbnMyProfile extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            loading:false,
            sal:'',
            firstName:'',
            secondName:'',
            dob:'',
            email:'',
            address:'',

        }


    }
    componentDidMount(){
        this.getProfileDetails()
    }

    getProfileDetails = async () => {
       
        this.setState({ loading: true });
        let headers = await getApiHeader();
        const post_data = {
          activation_id: sessionStorage.getItem('nbn_activation_id')
        };
        let res = await axios.post(`${SERVER_URL}nbn-my-profile`, post_data, { headers }).then(response => {
          if (response.data.data.customMessage) {
            if (response.data.data.customMessage != 'undefined') {
              this.setState({ customMesssge: response.data.data.customMessage });
            }
          }
          let data = response.data.data.customer_details;
          this.setState({firstName:data.firstName ,secondName:data.surname , sal:data.sal ,dob:data.dob,email:data.email,address:data.service_address})
          this.setState({ loading: false });
          console.log(data);
        }).catch(error => {
          this.setState({ loading: false });
          if ((typeof (error.response.data.message) !== 'undefined') && (typeof (error.response.data) !== 'undefined')) {
    
            swal({
              text: error.response.data.message,
              allowOutsideClick: false,
              closeOnClickOutside: false,
              icon: "warning"
            }).then(() => {
    
            });
          }
          else {
            swal("Internal Server Error", "", "warning");
          }
        });
      }
    

   
    render() {

        return (
            <> {(this.state.loading === true) ? <PageLoader/> : null}
                <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor common-class" id="kt_content">
                    <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                        <div className="row">
                            <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
                                <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__head">
                                            <div className="kt-portlet__head-label">
                                                <h3 className="kt-portlet__head-title">My Profile</h3>
                                            </div>
                                        </div>

                                        <form className="kt-form kt-form--label-right" id="kt_profile_form" >
                                            <input type="hidden" name="_token" value="ObupZ5m1zGiLOR7PsuEx5ioUiO7d83PmvaanZUVD" />
                                            <div className="kt-portlet__body">
                                                <div className="kt-section kt-section--first">
                                                    <div className="kt-section__body">
                                                        <div className="form-group row">

                                                            <div className="col-lg-12 col-xl-12 myprofilebox">
                                                                <label className="col-xl-3 col-lg-3 col-form-label txt-left label_head"><span>Name </span></label>
                                                                <label className="col-xl-9 col-lg-9 col-form-label txt-left label_value"> : {this.state.sal} {this.state.firstName} {this.state.secondName}</label>
                                                            </div>

                                                            <div className="col-lg-12 col-xl-12 myprofilebox">
                                                                <label className="col-xl-3 col-lg-3 col-form-label txt-left"><span>Date of Birth </span></label>
                                                                <label className="col-xl-9 col-lg-9 col-form-label txt-left label_value"> : {this.state.dob}</label>
                                                            </div>

                                                            <div className="col-lg-12 col-xl-12 myprofilebox">
                                                                <label className="col-xl-3 col-lg-3 col-form-label txt-left"><span>Email Address </span></label>
                                                                <label className="col-xl-7 col-lg-7 col-form-label txt-left label_value">: {this.state.email}
                                                                </label>
                                                            </div>

                                                            <div className="col-lg-12 col-xl-12 myprofilebox">
                                                                <label className="col-xl-3 col-lg-3 col-form-label txt-left"><span>Address </span></label>
                                                                <label className="col-xl-7 col-lg-7 col-form-label txt-left label_value"> : {this.state.address}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}