import React, { Component } from "react";
import CustomerMobileHeader from "./../Layout/CustomerMobileHeader";
import { Route } from "react-router-dom";
import { Navigation, Footer, Header, Home, MyProfile, MyPayments, Recharge, AddaMobilePlan, ChangePassword, UsageHistory, IDverification, IDsuccess, DataGifting, ReferAbuddy, RechargeAFriend, Esim, RechargeHome, RechargeWithVoucher} from "./../../components";
import AddNbn from "../ProtectedComponents/AddNbn/AddNbn";
import Connection from "../ProtectedComponents/AddNbn/Checkout/Connection";
import ForbiddenPage from "../ForbiddenPage";
import MyServices from "../ProtectedComponents/MyServices";
export default class ProtectedComponents extends Component {

    isPerimissionForAccess = () => {
        const isPerimissionForAccessUrl = sessionStorage.getItem('is_prepaid');
        return isPerimissionForAccessUrl
    }

    render() {
        const isPerimissionForAccessUrl = this.isPerimissionForAccess();
        console.log(isPerimissionForAccessUrl);
        if (isPerimissionForAccessUrl === '0') {

            return <ForbiddenPage></ForbiddenPage>
        }

        return <>
    <CustomerMobileHeader/>
    <div className="kt-grid kt-grid--hor kt-grid--root"> 
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page"> 
                <Navigation/>
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper"> 
                    <Header/>        
                        <Route path="/" exact component={() => <Home />} />
						<Route path="/home" exact component={() => <Home />} />
						<Route path="/MyProfile" exact component={() => <MyProfile />} />
						<Route path="/MyPayments" exact component={() => <MyPayments />} />
						<Route path="/Recharge" exact component={() => <Recharge />} />
						<Route path="/AddaMobilePlan" exact component={() => <AddaMobilePlan />} /> 
						<Route path="/ChangePassword" exact component={() => <ChangePassword />} /> 
						<Route path="/UsageHistory" exact component={() => <UsageHistory />} /> 
                        <Route path="/IDverification" exact component={() => <IDverification />} /> 
                        <Route path="/IDsuccess" exact component={() => <IDsuccess />} /> 
                        <Route path="/DataGifting" exact component={() => <DataGifting />} />
                        <Route path="/ReferAbuddy" exact component={() => <ReferAbuddy />} />
                        <Route path="/Esim" exact component={() => <Esim />} />
                        <Route path="/RechargeAFriend" exact component={() => <RechargeAFriend />} />
                        <Route path="/RechargeHome" exact component={() => <RechargeHome />} />
                        <Route path="/RechargeWithVoucher" exact component={() => <RechargeWithVoucher />} />
                        <Route path="/AddNbn" exact component={() => <AddNbn/>} />
                        <Route path="/connection" exact component={() => < Connection/>} />
                        <Route path="/MyServices" exact component={() => <MyServices />} />
                        
                    <Footer/>
                </div> 
            </div> 
        </div>
        <div id="kt_scrolltop" className="kt-scrolltop">
            <i className="la la-arrow-up"></i>
        </div> 
    </>
}
}