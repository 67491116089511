import React, { Component } from 'react';
import nbnIcon from './../../../src/assets/img/nbn-icon.svg'; 
import mobileIcon from './../../../src/assets/img/mobile-icon.svg';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import swal from 'sweetalert';
import PageLoader from '../Loader/loader';

import axios from 'axios';
import { getApiHeader } from '../../utils/CommonFunctions';



const SERVER_URL 	= process.env.REACT_APP_SERVER_URL;   

class Service extends Component {

  state = {
    loading:true,
    customerId:null,

  }
  componentDidMount = async () => {
   this.getMyservices();
  }

  getMyservices = async () => {

    this.setState({ loading: true });
    let headers = await getApiHeader();
    const post_data = {
        nbn_activation_id: sessionStorage.getItem('nbn_activation_id')
    };
     await axios.post(`${SERVER_URL}my-services-list`, post_data, { headers }).then(response => {

        console.log(response.data.data.data);
        if (response.data.data.data.customMessage) {
            if (response.data.data.customMessage !== 'undefined') {
                this.setState({ customMesssge: response.data.data.customMessage });
            }
        }
        let data = response.data.data.data;
       

        if(data.telsim_customer_id){
          this.setState({customerId:data.telsim_customer_id})
        }
        
        this.setState({ loading: false });
        console.log(this.state.nbn_plan_details);

    }).catch(error => {
        
        this.setState({ loading: false });
        if ((typeof (error.response.data.data.data.message) !== 'undefined') && (typeof (error.response.data.data.data) !== 'undefined')) {

            swal({
                text: error.response.data.message,
                allowOutsideClick: false,
                closeOnClickOutside: false,
                icon: "warning"
            }).then(() => {

            });
        }
        else {
            swal("Internal Server Error", "", "warning");
        }
    });
  }


  render() {
    return (
      <>
      {this.state.loading === true ? <PageLoader /> : null}
      <div className="container">
        <section className="nbn-my-account ">
          <div className="nbn-my-service">
            <div className="row">
              <div className="col-md-7">
                <h3>MY SERVICES</h3>
              </div>
              <div className="col-md-5">
                <p>Customer ID : <span>CUS-{this.state.customerId}</span></p>
              </div>
            </div>
          </div>
          <div className="nbn-data-section">
            <div className="row">
              <div className="col-md-1 py-4">
                <center><img src={nbnIcon} alt="NBN icon" /></center>
              </div>
              <div className="col-md-4 py-5">
                <div className="nbn-data-content">
                  <strong>NBN SERVICES</strong>
                </div>
              </div>
              <div className="col-md-5 py-5">
                <div className="nbn-data-content">
                  <p>Manage your NBN services here</p>
                </div>
              </div>

              <div className="col-md-2 py-4">
                <center><Link onClick={this.menuClick} to="/nbn-my-account-summary" className="kt-menu__link  active"><button type="button" className="btn btn-danger btn-data">Manage <i className="fa fa-arrow-circle-right" aria-hidden="true"></i></button></Link></center>
              </div>
            </div>
          </div><br />
          <div className="nbn-data-section2">
            <div className="row">
              <div className="col-md-1 py-4">
                <center><img src={mobileIcon} alt="Mobile icon" /></center>
              </div>

              <div className="col-md-4 py-5">
                <div className="nbn-data-content">
                  <strong>MOBILE SERVICES</strong>
                </div>
              </div>
              <div className="col-md-5 py-5">
                <div className="nbn-data-content">
                  <p>Manage your MOBILE services here</p>
                </div>
              </div>
              <div className="col-md-2 py-4">
				<center><Link onClick={this.menuClick} to="/home" className="kt-menu__link  active"><button type="button" className="btn btn-danger btn-data">Manage <i className="fa fa-arrow-circle-right" aria-hidden="true"></i></button></Link></center>
              </div>
            </div>
          </div><br />
          <div className="nbn-data-section2">
            <div className="row">
              <div className="col-md-1 py-4">
                <center><img src={nbnIcon} alt="NBN icon" width="60px" height="auto" /></center>
              </div>
              <div className="col-md-3 py-4">
                <div className="nbn-data-content">
                  <strong>Can't see a Service?</strong>
                  <p>Maybe it has its own account</p>
                </div>
              </div>
              <div className="col-md-7 py-4">
                <div className="nbn-para-content">
                  <p style={{ textAlign: 'justify' }}>If you can't see a particular service here, it is most
                                    likely because it was created as a separate account when you ordered the service. You
                                    can merge accounts, so you can track all your services under the one My Account Login.
                  </p>
                </div>
              </div>
              <div className="col-md-1 py-4"></div>
            </div>
          </div>
        </section>
        <section className="nbn-my-account">
          <div className="nbn-my-service">
            <div className="row">
              <div className="col-md-7">
                <h3>EXCLUSIVE MEMBER DEALS</h3>
              </div>
              <div className="col-md-5">

              </div>
            </div>
          </div>

          <div className="nbn-data-section">
            <div className="row">
              <div className="col-md-1 py-4">
                <center><img src={nbnIcon} alt="NBN icon" width="60px" height="auto" /></center>
              </div>
              <div className="col-md-3 py-4">
                <div className="nbn-data-content">
                  <strong>Can't see a Service?</strong>
                  <p>Maybe it has its own account</p>
                </div>
              </div>
              <div className="col-md-6 py-4">
                <div className="nbn-para-content">
                  <p style={{ textAlign: 'justify' }}>If you can't see a particular service here, it is most
                                    likely because it was created as a separate account when you ordered the service. You
                                    can merge accounts, so you can track all your services under the one My Account Login.
                  </p>
                </div>
              </div>
              <div className="col-md-2 py-4">
                {/* <center><a href=""><button type="button" className="btn btn-danger btn-data">Manage <i
                                        className="fa fa-arrow-circle-right" aria-hidden="true"></i></button></a></center> */}
              </div>
            </div>
          </div>
        </section>
      </div>
      </>
    );
  }
}

export default Service;
