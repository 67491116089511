import React, { Component } from 'react';
import { getApiHeader, removeUserSession } from '../../../utils/CommonFunctions';
import axios from 'axios';
import swal from 'sweetalert';
import PageLoader from '../../Loader/loader';
import plan from '../../public/plans/plan';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;


class NbnChangePlan extends Component {

    state = {
        loading: false,
        plans: [],
        nbn_api_log_id: '',
        plan_details: [],
        model_visibility: false,
        select_plan_id: '',
        response_message:''
    }

    componentDidMount() {
        this.getDefaultPlans();
    }

    handleClose = () => this.setState({ model_visibility: false });


    getDefaultPlans = async () => {

        this.setState({ loading: true });
        let headers = await getApiHeader();
        const post_data = {
            nbn_activation_id: sessionStorage.getItem('nbn_activation_id')
        };
        let res = await axios.post(`${SERVER_URL}nbn/get_nbn_plans_for_plan_change`, post_data, { headers }).then(response => {
            if (response.data.data.customMessage) {
                if (response.data.data.customMessage != 'undefined') {
                    this.setState({ customMesssge: response.data.data.customMessage });
                }
            }
            let data = response.data.data;
            this.setState({ plans: data.plans })
            this.setState({ loading: false, plans: data.plans, nbn_api_log_id: data.nbn_api_log_id });

        }).catch(error => {
            this.setState({ loading: false });
            if ((typeof (error.response.data.message) !== 'undefined') && (typeof (error.response.data) !== 'undefined')) {

                swal({
                    text: error.response.data.message,
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    icon: "warning"
                }).then(() => {

                });
            }
            else {
                swal("Internal Server Error", "", "warning");
            }
        });
    }

    PlanSelectHandler = async (plan_id) => {
        this.setState({ loading: true });
        let headers = await getApiHeader();
        const post_data = {
            nbn_plan_id: plan_id,
            nbn_api_log_id: this.state.nbn_api_log_id
        };
        let res = await axios.post(`${SERVER_URL}nbn/get_nbn_plan_change_details`, post_data, { headers }).then(response => {
            if (response.data.data.customMessage) {
                if (response.data.data.customMessage !== 'undefined') {
                    this.setState({ customMesssge: response.data.data.customMessage });
                }
            }
            let data = response.data.data;
            console.log(data);
            this.setState({response_message :response.data.message})
            this.setState({ plan_details: data })
            this.setState({ loading: false, select_plan_id: plan_id });
            if (data.type === 'upgrade') {
                const title = data.gst_inclusive_payable_amount > 0 ? 'Amount to be paid $' + data.gst_inclusive_payable_amount : '';

                swal({
                    title: title ,
                    text: response.data.message  ,
                    icon: "warning",
                    dangerMode: true,
                    buttons: {
                        confirm: { text: 'Pay Now', className: 'sweet-warning' },
                        cancel: 'No, Cancel!'
                    }
                })
                .then((confirmed) => {
                    if (confirmed) {
                    this.ChangePlanSubmitHandler();
                    }
                });
            }else if (data.type === 'downgrade') {
                swal({
                    text: response.data.message,
                    icon: "warning",
                    dangerMode: true,
                    buttons: {
                        confirm: { text: 'Confirm', className: 'sweet-warning' },
                        cancel: 'No, Cancel!'
                    }
                })
                .then((confirmed) => {
                    if (confirmed) {
                        this.ChangePlanSubmitHandler();
                    }
                });
            
            }
                // swal({
                //     text: response.data.message,
                //     allowOutsideClick: false,
                //     closeOnClickOutside: false,
                //     icon: "warning"
                // }).then(() => {

                // });

            // this.setState({ loading: false, model_visibility: true, select_plan_id: plan_id });
            
        }).catch(error => {
            this.setState({ loading: false });
            if ((typeof (error.response.data.message) !== 'undefined') && (typeof (error.response.data) !== 'undefined')) {

                swal({
                    text: error.response.data.message,
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    icon: "warning"
                }).then(() => {

                });
            }
            else {
                swal("Internal Server Error", "", "warning");
            }
        });
    }


    ChangePlanSubmitHandler = async () => {
        this.setState({ loading: true, model_visibility: false });
        let headers = await getApiHeader();
        const post_data = {
            nbn_plan_id: this.state.select_plan_id,
            nbn_api_log_id: this.state.nbn_api_log_id
        };
        let res = await axios.post(`${SERVER_URL}nbn/confirm_nbn_plan_change`, post_data, { headers }).then(response => {
            if (response.data.data.customMessage) {
                if (response.data.data.customMessage !== 'undefined') {
                    this.setState({ customMesssge: response.data.data.customMessage });
                }
            }
            this.setState({ loading: false });
            if (response.status === 200) {

                swal(response.data.message, "success");
            }
        }).catch(error => {
            this.setState({ loading: false });
            if ((typeof (error.response.data.message) !== 'undefined') && (typeof (error.response.data) !== 'undefined')) {

                swal({
                    text: error.response.data.message,
                    allowOutsideClick: false,
                    closeOnClickOutside: false,
                    icon: "warning"
                }).then(() => {

                });
            }
            else {
                swal("Internal Server Error", "", "warning");
            }
        });
    }

    render() {
        return (
            <>
                {this.state.loading === true ? <PageLoader /> : null}
                <div className="container" id="nbn-planSelectionBox">
                    <section className="nbn-common-section">
                        <div className="row">
                            <div className="nbn-header-title">
                                <h2 className="nbn-h3"> Choose Your Telsim NBN Home Internet Plans</h2>
                                <p style={{ textAlign: "left" }}>Discover the best home internet plans from Telsim NBN that can be designed to your needs at different speeds.</p>
                            </div>

                            {this.state.plans.map((plan, index) => (
                                <div key={index} className="col-lg-3 nbn-col-md-6">
                                    <div className="nbn-pricing-card">
                                        <div className="nbn-plan-bg">
                                            <img src={plan.plan_card_icon} alt="Plan Icon" />
                                            <h2>${plan.website_sale_amount}</h2>
                                            <h4>Monthly</h4>
                                        </div>
                                        <h3>NBN Home<br />{plan.website_plan_name.replace('NBN Home', '')}</h3>
                                        <h6>NBN<sup>&reg;</sup> {plan.download_speed}/{plan.upload_speed} Mbps</h6>
                                        <p>Typical evening speed</p>
                                        <p>(7pm-11pm)</p>
                                        <center>
                                            <table className="table table-borderless price-table">
                                                <thead>
                                                    <tr>
                                                        <th valign="top"> {plan?.download_speed} Mbps</th>
                                                        <th valign="top" >{plan?.upload_speed} Mbps</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td valign="top">DOWNLOAD</td>
                                                        <td valign="top">UPLOAD</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </center>
                                        <div className="nbn-data-pack">
                                            <b>{plan.data === null ? plan.data_text : `${plan.data} GB`}</b>
                                        </div>

                                        <div className="data-pack-description">
                                            <b>Designed for</b>
                                            <br />
                                            <br />
                                            <ul>
                                                {plan.description.map((value, index) => (
                                                    <li><i class="fa fa-check-circle-o" aria-hidden="true"></i>{value}</li>
                                                ))}
                                            </ul></div>
                                        {/* <p><a href="https://www.telsim.com.au/website-terms-conditions/" target="_blank">Terms & Conditions</a></p> */}
                                        <button className="nbn-button-57" style={{ width: '100%' }} onClick={() => this.PlanSelectHandler(plan.plan_id)} >
                                            <><span className="nbn-text">SELECT</span></>
                                        </button>

                                        {/* <p style={{ marginTop: '10px' }}><a href="https://www.telsim.com.au/critical-information-summary/" target="_blank">Critical Information Summary</a></p> */}
                                        <p style={{ marginTop: '10px' }}><a href="https://www.telsim.com.au/critical-information-summary/" target="_blank">Critical Information Summary</a></p>
                                        <p style={{ marginTop: '10px' }}><a href="https://www.telsim.com.au/wp-content/uploads/2024/05/Telsim_NBN_Key_Facts_Sheet.pdf" target="_blank">Fact Sheet</a></p>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </section>
                    <br />
                </div>
            </>
        );
    }

}

export default NbnChangePlan;

