import React, { Component } from 'react';
import Plan from './plan';

export default class PlanTabContent extends Component {

    constructor(props)
    {
        super(props);
        this.state = {
            plan: ""
        };
    }

    render() {
        return(
            <>
                <div className="kt-portlet">
                    <div className="kt-portlet__body" style={{padding:0}}>
                        <div className="form-group">        
                            <Plan idd={this.props.idd} changePlan={this.props.changePlan} selectedPlan={this.props.selectedPlan} type={this.props.type} plan={this.props.plan} activeStatus={this.props.activeStatus} mobileNumber={this.props.mobileNumber} from={this.props.from} goNextStep={this.props.goNextStep} activation_id={this.props.activation_id} seletedPlanId={this.props.seletedPlanId} doubleDataPlan={this.props.doubleDataPlan} doubleDataPlanName={this.props.doubleDataPlanName}/>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}