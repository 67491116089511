import React, { Component } from "react"; 
import Carousel from 'react-bootstrap/Carousel';
import Image1 from './../../../assets/media/login_slider/slider1.png';
import Image2 from './../../../assets/media/login_slider/slider2.png';
import Image3 from './../../../assets/media/login_slider/slider3.png';
import Image4 from './../../../assets/media/login_slider/slider4.png';
export default class PublicSlider extends Component {


    render()
    {
        return(

        <>
            <Carousel fade>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={Image1}
                    alt="Grab 35GB for 35 dollar unlimited prepaid monthly plan"
                    /> 
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={Image2}
                    alt="Grab 120GB for 55 dollar unlimited prepaid monthly plan"
                    /> 
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={Image3}
                    alt="Grab 160GB for 60 dollar unlimited prepaid monthly plan"
                    /> 
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={Image4}
                    alt="Grab 260GB for 330 dollar unlimited prepaid yearly plan"
                    /> 
                </Carousel.Item>
            </Carousel>
        </>

        )
    }

}