import React, { Component } from "react";
import Address from "./Address";
import axios from "axios";
import Plans from "./Plans"
import Modem from "./Modem";
import Cart from "./Cart"
import { getApiHeader } from "../../../utils/CommonFunctions";
import swal from "sweetalert";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class AddNbn extends Component {

   constructor(props) {
      super(props);
      this.state = {
         apiToken: "",
         error: "",
         vispId: "",
         nbnSessionId: "",
         addressVerify: false,
         avaliblePlan: [],
         checkVispValid: "",
         defaultModem: [],
         shippingCharge: "0",
         gstShippingCharge:"0",
         defaultPlan: [],
         addressError:false,




      }

   }
   componentDidMount() {
      this.fetchApiToken()
      this.checkIsNbnCustomer()



   }



   fetchApiToken = async () => {

      let headers = await getApiHeader();
      let res = await axios.post(`${SERVER_URL}nbn/nbn_get_autocomplete_address_token`, '', { headers }).then(response => {

         const tokenValue = response?.data?.token;
         this.setState({ apiToken: tokenValue })

      }).catch(error => {
         const message = error.message
         this.setState({ error: message })
      })

   }


   checkIsNbnCustomer = async () => {
		let headers = await getApiHeader();
		let res = await axios.get(`${SERVER_URL}nbn/check_is_nbn`, { headers }).then(response => {
			  let data = response.data;
           if(data.data.is_nbn_customer === 1){
            swal({
					text: 'Your have already Purcahsed Active NBN Connection',
					allowOutsideClick: false,
					closeOnClickOutside: false,
					icon: "warning"
				}).then(() => {
					// this.handleLogout();
               // navigator('/home')
                this.props.history.push("/home");
				});
           }
			
		}).catch(error => {
			this.setState({ loading: false });
			if ((typeof (error.response.data.message) !== 'undefined') && (typeof (error.response.data) !== 'undefined')) {
				swal({
					text: error.response.data.message,
					allowOutsideClick: false,
					closeOnClickOutside: false,
					icon: "warning"
				}).then(() => {
					// this.handleLogout();
				});
			}
			else {
				swal("Internal Server Error", "", "warning");
			}
		});
	}





   getVispHandler = (input, e) => {
      var vispqual_id = input;
      if (input !== null) { var vispqual_id = input.split('nbn')[0]; }
      this.setState({ vispId: vispqual_id })

   }

   passNbnSessionId = (input, e) => {
      this.setState({ nbnSessionId: input })
   };

   addressVerified = (input, e) => {
      this.setState({ addressVerify: input })
   };

   getAvailablePlan = (input, e) => {
      this.setState({ avaliblePlan: input })
   };

   getDefaultPlan = (input) => {
      this.setState({ defaultPlan: input });


   };

   getCheckValidVisp = (input, e) => {
      this.setState({ CheckVispValid: input });
   };

   getDefaultModem = (input, e) => {
      this.setState({ defaultModem: input })

   };

   getShippingCharge = (input, e) => {
      this.setState({ shippingCharge: input })
   };

   getGstShippingCharge = (input, e) => {
      this.setState({ gstShippingCharge: input })
   };

    addressError =(input,e)=>{
     this.setState({addressError:input})
    }


   render() {
      return (

         <div>
            <Address getVispHandler={this.getVispHandler} passNbnSessionId={this.passNbnSessionId} addressVerified={this.addressVerified} getAvailablePlan={this.getAvailablePlan} token={this.state.apiToken}  addressError={this.addressError} AddressError={this.state.addressError}/>
            <Plans vispID={this.state.vispId} getDefaultPlan={this.getDefaultPlan} getCheckValidVisp={this.getCheckValidVisp} addressVerify={this.state.addressVerify} nbnSessionId={this.state.nbnSessionId} avaliblePlan={this.state.avaliblePlan} />
            <Modem getDefaultModem={this.getDefaultModem} getShippingCharge={this.getShippingCharge}  getGstShippingCharge={this.getGstShippingCharge}/>
            <Cart defaultPlan={this.state.defaultPlan} defaultModem={this.state.defaultModem} checkVispValid={this.state.checkVispValid} addressVerify={this.state.addressVerify} vispID={this.state.vispId} nbnSessionId={this.state.nbnSessionId} shippingCharge={this.state.shippingCharge} gstShippingCharge={this.state.gstShippingCharge}  addressError={this.addressError}/>

         </div>
      )
   }

}

export default withRouter(AddNbn)