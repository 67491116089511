import {Component} from "react"
import loading from "./../../../src/assets/media/icons/loading.svg"



class LoadingIcon extends Component{
      render(){
         return(

              <div className="loaderDiv">
               <img src={loading} alt="loaderImg" className="loaderImg"/> 
                   
              </div>



         )
      }
}

export default LoadingIcon;