import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import DatePicker from 'react-date-picker';
import axios from 'axios';   
import {getApiHeaderGuest ,getApiHeader, removeUserSession,  getActivationAttemptDataForIDValidation} from '../../../../utils/CommonFunctions';
import rechargeVoucher from '../../../../assets/img/rechargewithvoucher.png';
import rechargeFriend from '../../../../assets/img/rechargefriend.png';
import swal from 'sweetalert';
import { WidgetInput } from "react-addressfinder";
import {withRouter} from "react-router-dom";
import PageLoader from './../../../Loader/loader';
import moment from 'moment';

const SERVER_URL    = process.env.REACT_APP_SERVER_URL;  


export class IdValidation extends Component {

    constructor(props) {

    super(props);


    let today   = new Date();
    let dd      = today.getDate();
    let mm      = today.getMonth() + 1; //January is 0 so need to add 1 to make it 1!
    let yyyy    = today.getFullYear();
    let maxyyyy = yyyy - 16;
    let minyyyy = yyyy - 110;

    this.validator = new SimpleReactValidator();


    this.state = {
        idType: '',
        passportCountry:'',
        passportExpiry:'',
        passportFName:'',
        passportLName:'',
        passportNumber:'',
        passportDOB:'',
        passportGender:'',
        licenceNumber:'',
        licenceCardNumber:'',
        licenceFName:'',
        licenceLname:'',
        licenceState:'',
        licenceExpiry:'',
        licenceDOB:'',
        licenceGender:'',
        medicareCardNumber:'',
        medicareFirstName:'',
        medicareMiddleName:'',
        medicareLastName:'',
        medicareGender:'',
        medicareIRN:'',
        medicareColour:'',
        medicareExpiry:'',
        dvsCheck:'',
        sim:'',
        customerror:'',
        all_countries:[],
        loading:true,
        maxDob: new Date(`${maxyyyy}-${mm}-${dd}`),
        minDob: new Date(`${minyyyy}-${mm}-${dd}`),
        mandatoryCard : true,
        image : ''
        }
    }


    componentDidMount() {
        this.setState({loading:true});
        this.getCountry();
        this.getActivationAttemptData();  
        this.setState({loading:false});
    }


     getCountry = async () => {
        let headers = await getApiHeaderGuest();
        let res         = await axios.get(`${ SERVER_URL }getCountryDetails`, { headers });
        let countrydata        = res.data.data.countries;
        this.setState({ all_countries: countrydata }); 
    };


     getActivationAttemptData = async () => {
        let attempt_data     = await  getActivationAttemptDataForIDValidation(); 
        if(attempt_data.activation_attempt !=null)
        {

            this.setState({ passportFName: attempt_data.activation_attempt.firstName}); 
            this.setState({ passportLName: attempt_data.activation_attempt.surname}); 

            if(attempt_data.activation_attempt.dob)
            {
                this.setState({ passportDOB: new Date(attempt_data.activation_attempt.dob)}); 
            }
            else
            {
                this.setState({ passportDOB: this.state.maxDob});
            }

            this.setState({ passportGender: attempt_data.activation_attempt.gender}); 
            this.setState({ licenceFName: attempt_data.activation_attempt.firstName}); 
            this.setState({ licenceLname: attempt_data.activation_attempt.surname}); 


            if(attempt_data.activation_attempt.dob)
            {
                this.setState({ licenceDOB: new Date(attempt_data.activation_attempt.dob)}); 
            }
            else
            {
                this.setState({ licenceDOB: this.state.maxDob});
            }

            this.setState({ licenceGender: attempt_data.activation_attempt.gender});  
            this.setState({ medicareFirstName: attempt_data.activation_attempt.firstName}); 
            this.setState({ medicareMiddleName: attempt_data.activation_attempt.middleName}); 
            this.setState({ medicareLastName: attempt_data.activation_attempt.surname}); 
            this.setState({ medicareGender: attempt_data.activation_attempt.gender});


        if(attempt_data.activation_attempt.sim_number !=''){ this.setState({ sim: attempt_data.activation_attempt.sim_number }); }
        
            if(attempt_data.activation_attempt.dvs_data)
            {

                // Passport
                if(attempt_data.activation_attempt.dvs_data.dvs_type == 'Passport')
                {
                    this.setState({ idType: 1 });
                    this.setState({ passportFName: attempt_data.activation_attempt.dvs_data.dvs_passportFName });
                    this.setState({ passportLName: attempt_data.activation_attempt.dvs_data.dvs_passportLName });
                    this.setState({ passportCountry: attempt_data.activation_attempt.dvs_data.dvs_passportCountry });
                    this.setState({ passportNumber: attempt_data.activation_attempt.dvs_data.dvs_passportNumber });
                    // this.setState({ passportExpiry: attempt_data.activation_attempt.dvs_data.dvs_passportExpiry });
                    this.setState({ passportDOB: new Date(attempt_data.activation_attempt.dvs_data.dvs_dob) });
                    this.setState({ passportGender: attempt_data.activation_attempt.dvs_data.dvs_gender });
                        
                }
                // Passport

                // Licence
                if(attempt_data.activation_attempt.dvs_data.dvs_type == 'Australian Driving License')
                {
                this.setState({ idType: 2 });
                this.setState({ licenceNumber: attempt_data.activation_attempt.dvs_data.dvs_licenceNumber });
                this.setState({ licenceCardNumber: attempt_data.activation_attempt.dvs_data.dvs_licenceCardNumber });
                this.setState({ licenceFName: attempt_data.activation_attempt.dvs_data.dvs_licencefirstName });
                this.setState({ licenceLname: attempt_data.activation_attempt.dvs_data.dvs_licenceLlastName });
                this.setState({ licenceState: attempt_data.activation_attempt.dvs_data.dvs_licenceState });

                if(attempt_data.activation_attempt.dvs_data.dvs_licenceState == 'QLD' || attempt_data.activation_attempt.dvs_data.dvs_licenceState == 'VIC'){
                    this.setState({ mandatoryCard : false });
                }else{
                    this.setState({ mandatoryCard : true });
                }

                // this.setState({ licenceExpiry: attempt_data.activation_attempt.dvs_data.dvs_licenceExpiry });
                this.setState({ licenceDOB: new Date(attempt_data.activation_attempt.dvs_data.dvs_dob) });
                this.setState({ licenceGender: attempt_data.activation_attempt.dvs_data.dvs_gender });
                }
                // Licence

                //medicare
                if(attempt_data.activation_attempt.dvs_data.dvs_type == 'Medicare Card')
                {
                this.setState({ idType: 3 });
                this.setState({ medicareCardNumber: attempt_data.activation_attempt.dvs_data.dvs_medicareCardNumber });
                this.setState({ medicareFirstName: attempt_data.activation_attempt.dvs_data.dvs_medicareFirstName });
                this.setState({ medicareMiddleName: attempt_data.activation_attempt.dvs_data.dvs_medicareMiddleName });
                this.setState({ medicareLastName: attempt_data.activation_attempt.dvs_data.dvs_medicareLastName });
                this.setState({ medicareGender: attempt_data.activation_attempt.dvs_data.dvs_gender });
                this.setState({ medicareIRN: attempt_data.activation_attempt.dvs_data.dvs_medicareIRN });
                this.setState({ medicareColour: attempt_data.activation_attempt.dvs_data.dvs_medicareColour });
                this.setState({ medicareExpiry: attempt_data.activation_attempt.dvs_data.dvs_medicareExpiry });
                }
                //medicare

                this.setState({ dvsCheck: true});
            }

        }




    };




    changeID = async (type) => { 
        this.setState({ idType: type});
        const post_data ={
            id_type : type
        };
        if(type!=''){
            let headers     = await getApiHeaderGuest();
            let res         = await axios.post(`${ SERVER_URL }activation/verification-type-images`,post_data, { headers }).then(response => {
            let passport    = response.data.data.passport;
            let medicare    = response.data.data.medicare;
            this.setState({ image: ''});
            if(passport){
                    if(passport[0].id_type){
                        this.setState({ image: passport[0].image});
                    } 
                }
                if(medicare){
                    if(medicare[0].id_type){
                        this.setState({ image: medicare[0].image});
                    }
                }
            });
        }else{
            this.setState({ image: ''});
        }
    }

    // Passport
    passportFName = (event) => { 


        this.setState({ passportFName: event.target.value});
        this.setState({ licenceFName: event.target.value});
        this.setState({ medicareFirstName: event.target.value});

    }



    passportLName = (event) => { 

        this.setState({ passportLName: event.target.value});
        this.setState({ licenceLname: event.target.value});
        this.setState({ medicareLastName: event.target.value});
    }


    passportDOB = (value) => { 

        this.setState({ passportDOB: value});
        this.setState({ licenceDOB: value});

    }


    passportGender = (gender) => { 

        this.setState({ passportGender: gender});
        this.setState({ licenceGender: gender});
        this.setState({ medicareGender: gender});

    }


    passportCountry = (country) => { this.setState({ passportCountry: country});}
    passportNumber = (event) => { this.setState({ passportNumber: event.target.value});}
    // passportExpiry = (value) => { this.setState({ passportExpiry: value});}
    // Passport


     // Licence
    licenceNumber = (event) => { this.setState({ licenceNumber: event.target.value});}
    licenceCardNumber = (event) => { this.setState({ licenceCardNumber: event.target.value});}
    licenceFName = (event) => { this.setState({ licenceFName: event.target.value});}
    licenceLname = (event) => { this.setState({ licenceLname: event.target.value});}
    licenceState = async (value) => { 
        this.setState({ licenceState: value});

        if(value == 'QLD' || value == 'VIC'){
        this.setState({ mandatoryCard: false});
        }else{
        this.setState({ mandatoryCard: true});
        }
        const post_data ={
            id_type : 2,
            state_value : value,
        };
        if(value!=''){
            let headers     = await getApiHeaderGuest();
            let res         = await axios.post(`${ SERVER_URL }activation/verification-type-images`,post_data, { headers }).then(response => {
            let driving    = response.data.data.driving;
            this.setState({ image: ''});
                if((driving.length)>0){               
                    this.setState({ image: driving[0].image});               
                }
            });
        }else{
            this.setState({ image: ''});
        }
    }
    // licenceExpiry = (value) => { this.setState({ licenceExpiry: value});}
    licenceDOB = (value) => { this.setState({ licenceDOB: value});}
    licenceGender = (gender) => { this.setState({ licenceGender: gender});}
    // Licence



    //medicare
    medicareCardNumber = (event) => { this.setState({ medicareCardNumber: event.target.value});} 
    medicareFirstName = (event) => { this.setState({ medicareFirstName: event.target.value});}
    medicareMiddleName = (event) => { this.setState({ medicareMiddleName: event.target.value});}
    medicareLastName = (event) => { this.setState({ medicareLastName: event.target.value});}
    medicareGender = (value) => { this.setState({ medicareGender: value});}
    medicareIRN = (event) => { this.setState({ medicareIRN: event.target.value});}
    medicareColour = (value) => { this.setState({ medicareColour: value});}
    medicareExpiry = (value) => { this.setState({ medicareExpiry: value});}
    //medicare


    dvsCheckbox = (check) => { this.setState({ dvsCheck: check});}





    myDVSHandler = async (event) => { 
        event.preventDefault();  
        this.setState({loading:true});

        swal({ 
            text: "Please confirm the ID details provided are exactly matching with the ID document. Incorrect ID details will block your sim and delay the service activation. ",
            icon: "warning", 
            dangerMode: true,
            buttons: {
                confirm : {text:'Yes, Proceed!',className:'sweet-warning'},
                cancel : 'No, Review!'
            }
        })
        .then(async (willDelete) => {
            if (willDelete) {
                if(this.state.idType == 3){
                  var expDate = this.state.medicareExpiry;
                  const today = Date.now();
                  if(today > new Date(expDate)){
                    swal({
                       // title: "Error",
                        text: "Past date cannot be accepted",
                        icon: "error"
                    });
                    this.setState({loading: false});
                    return;
                  }
                    //console.log('myDVSHandler ==>', today, new Date(expDate), (today > new Date(expDate)))
                }
                this.setState({loading:true});
                try
                {
                    if(this.state.dvsCheck==true)
                    {
            
            
                    const post_data =   {
                        dvsCheck : this.state.dvsCheck,
                        service_type : this.state.idType,
                        sim : this.state.sim,
                        passportFName : this.state.passportFName,
                        passportLName : this.state.passportLName,
                        passportCountry : this.state.passportCountry,
                        passportNumber : this.state.passportNumber,
                        // passportExpiry : this.state.passportExpiry,
                        passportDOB : this.state.passportDOB,
                        passportGender : this.state.passportGender,
                        licenceNumber : this.state.licenceNumber,
                        licenceCardNumber : this.state.licenceCardNumber,
                        licenceFName : this.state.licenceFName,
                        licenceLName : this.state.licenceLname,
                        licenceState : this.state.licenceState,
                        // licenceExpiry : this.state.licenceExpiry,
                        licenceDOB : this.state.licenceDOB,
                        licenceGender : this.state.licenceGender,
                        medicareCardNumber : this.state.medicareCardNumber,
                        medicareFirstName : this.state.medicareFirstName,
                        medicareMiddleName : this.state.medicareMiddleName,
                        medicareLastName : this.state.medicareLastName,
                        medicareGender : this.state.medicareGender,
                        medicareIRN : this.state.medicareIRN,
                        medicareColour : this.state.medicareColour,
                        medicareExpiry : this.state.medicareExpiry,
                        session_id : sessionStorage.getItem('activation_session_id'),
                    };
                    console.log(post_data);
                    let headers     = await getApiHeader();
                    let res         = await axios.post(`${ SERVER_URL }activationv2/verify/dvs`,post_data, { headers }).then(response => {
                        
                        this.setState({ customerror: ''});
                        //console.log('verify/dvs ==>', response);
                        if(response.status == 200){
                            this.props.nextStep();
                        }else{
                            swal({
                                title: "Error",
                                content: response.message,
                                icon: "error"});
                        }
                        
                        
                    }).catch(error => {
                        if((typeof(error.response.data.message) !== 'undefined') && (typeof(error.response.data) !== 'undefined'))
                        {
            
                           this.setState({ customerror: error.response.data.message});
                        }
                        else
                        {
                            this.setState({ customerror: "Internal Server Error"});
                        }
            
                        
                    });
            
                    }
                    else
                    {
                        this.setState({ customerror: "Please Confirm CheckBox"});
                    }

                }
                catch(err)
                {
         
        
                    if(err)
                    {
                        let errorMessage = '';
        
                        if(err.response && err.response.data && err.response.data.message)
                        {
                            if(typeof(err.response.data.message) === 'object')
                            {
        
                                swal({
                                    title: "Error",
                                    content: "Error occurred in delete payment method",
                                    icon: "error"});
                
                            }
                            else
                            {
                                errorMessage = err.response.data.message;
                                swal(errorMessage,"error");
                                
                            }
                        }
                        else
                        {
                            errorMessage = err;
                            swal(errorMessage,err);
                        }
            
        
        
                    }
                    
        
                }
            


        
                this.setState({loading:false});
                
                
            } else {
                swal("Cancelled", "", "success");
                this.setState({loading:false});
            }
        });


        
    }






    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    handleKeypress = (e) => {
        e.preventDefault();
        return false
    }

    render() {
        const { values } = this.props;

        return (
            <>
            {(this.state.loading === true) ? <PageLoader/> : null}
             <form className="kt-form kt-section__content kt-section__content--border common-layout IdValidation" id="kt_form" method="post" onSubmit={this.myDVSHandler}>                  
                <div className="kt-wizard-v2__content" data-ktwizard-type="step-content">
                    <div className="row mb60">
                        <div className="col-lg-6 col-xl-6 order-lg-1 order-xl-1" style={{marginTop: 20}}>
                            <div className="kt-portlet__head-label">
                                <h1 className="kt-portlet__head-title" style={{fontWeight: 'bold'}}>ID Validation</h1>
                                <label className="col-xl-12 col-lg-12 col-form-label txt-left" >
                                    {<p style={{fontWeight: 'bold'}}>If you change your personal details here, it will be automatically updated to your profile.</p>}
                                </label>
                                <label className="col-xl-12 col-lg-12 col-form-label txt-left" style={{marginTop: -15}}>
                                    {<p style={{fontWeight: 'bold', color:'red'}}>Please enter your ID document details carefully. Incorrect details will block your SIM card and will delay the service activation.</p>}
                                </label>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%'}}>
                                <label className="col-xl-12 col-lg-12 col-form-label txt-left">
                                ID types
                                </label>
                                <select className="form-select custom-select kt_selectpicker refer-select"
                                key="uniqueId1"
                                style={{height: 45}} 
                                //defaultValue={selected_number} 
                                value={this.state.idType}
                                onChange={e => {this.changeID(e.target.value);}} 
                                name="service_type">
                                    <option value="">Select Type </option>
                                    <option value="1">Passport</option>
                                    <option value="2">Australian Driving License</option>
                                    <option value="3">Medicare Card</option>
                                </select>
                            </div>

                            {/* passport */}
                            {this.state.idType == 1 && (<>
                                <div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%', marginTop: 15}}>
                                    <label className="col-xl-12 col-lg-12 col-form-label txt-left" >
                                        {<p>Passport number</p>}
                                    </label>
                                    <input
                                        className="form-control"
                                        style={{marginTop: -10, height: 45}}
                                        type='text'
                                        name="adl_number" 
                                        placeholder="Enter Passport Number"
                                        // maxLength={10}
                                        // max={10}
                                        value={this.state.passportNumber}
                                        onChange={this.passportNumber}
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%', marginTop: 15}}>
                                    <label className="col-xl-12 col-lg-12 col-form-label txt-left">
                                    Issuing country
                                    </label>
                                    <select className="form-select custom-select kt_selectpicker refer-select"
                                    style={{height: 45}} 
                                    value={this.state.passportCountry} 
                                    onChange={e => {this.passportCountry(e.target.value);}} 
                                    name="pp_country">
                                        <option value="">Select Country </option>
                                        { this.state.all_countries.map(country => <option className="m--bg-success kt-font-inverse-success" value={country.country_code}>{country.name}</option>)} 
                                    </select>
                                </div>
                            </>)}

                            {/* Driving License */}
                            {this.state.idType == 2 && (<>
                                <div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%', marginTop: 15}}>
                                    <label className="col-xl-12 col-lg-12 col-form-label txt-left">
                                    Issuing state
                                    </label>
                                    <select className="form-select custom-select kt_selectpicker refer-select"
                                    style={{height: 45}} 
                                    //defaultValue={selected_number} 
                                    name="adl_state"
                                    onChange={e => {this.licenceState(e.target.value);}} 
                                    value={this.state.licenceState}>
                                       <option value="">Select State</option> 
                                        <option value="ACT">ACT</option>
                                        <option value="NSW">NSW</option>
                                        <option value="NT">NT</option>
                                        <option value="QLD">QLD</option>
                                        <option value="SA">SA</option>
                                        <option value="TAS">TAS</option>
                                        <option value="VIC">VIC</option>
                                        <option value="WA">WA</option> 
                                    </select>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%', marginTop: 15}}>
                                    <label className="col-xl-12 col-lg-12 col-form-label txt-left" >
                                        {<p>License number</p>}
                                    </label>
                                    <input
                                        className="form-control"
                                        style={{marginTop: -10, height: 45}}
                                        type='text'
                                        name="adl_number" 
                                        placeholder="Enter License Number"
                                        // maxLength={10}
                                        // max={10}
                                        value={this.state.licenceNumber}
                                        onChange={this.licenceNumber}
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%', marginTop: 15}}>
                                    <label className="col-xl-12 col-lg-12 col-form-label txt-left" >
                                        {<p>Card number</p>}
                                    </label>
                                    <input
                                        className="form-control"
                                        style={{marginTop: -10, height: 45}}
                                        type='text'
                                        name="adl_cnumber" 
                                        min="0" 
                                        maxlength="20" 
                                        title="Enter valid card number" 
                                        placeholder="Enter Card Number"
                                        // maxLength={10}
                                        // max={10}
                                        value={this.state.licenceCardNumber}
                                        onChange={this.licenceCardNumber}
                                    />
                                </div>
                            </>)}

                            {/* Medicare Card */}
                            {this.state.idType == 3 && (<>
                                <div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%', marginTop: 15}}>
                                    <label className="col-xl-12 col-lg-12 col-form-label txt-left" >
                                        {<p>Card number</p>}
                                    </label>
                                    <input
                                        className="form-control"
                                        style={{marginTop: -10, height: 45}}
                                        type='text'
                                        name="mc_number" 
                                        placeholder="Enter Card Number"
                                        required 
                                        maxlength="10" 
                                        title="Enter valid card number" 
                                        pattern="[1-9]{1}[0-9]{9}"
                                        // maxLength={10}
                                        // max={10}
                                        value={this.state.medicareCardNumber}
                                        onChange={this.medicareCardNumber}
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%', marginTop: 15}}>
                                    <label className="col-xl-12 col-lg-12 col-form-label txt-left">
                                    Colour of the card
                                    </label>
                                    <select className="form-select custom-select kt_selectpicker refer-select"
                                    style={{height: 45}} 
                                    //defaultValue={selected_number} 
                                    name="mc_color"
                                    onChange={e => {this.medicareColour(e.target.value);}} 
                                    value={this.state.medicareColour}>
                                        <option value="">Select Colour </option>
                                        <option value="Y">Yellow</option>
                                        <option value="B">Blue</option>
                                        <option value="G">Green</option> 
                                    </select>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%', marginTop: 15}}>
                                    <label className="col-xl-12 col-lg-12 col-form-label txt-left" >
                                        {<p>Date of expiry</p>}
                                    </label>
                                    <DatePicker
                                        onChange={this.medicareExpiry}
                                        value={this.state.medicareExpiry}
                                        className="form-control customdate"
                                        format="MM-y"
                                        required
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%', marginTop: 15}}>
                                    <label className="col-xl-12 col-lg-12 col-form-label txt-left" >
                                        {<p>Individual Reference Number (IRN)</p>}
                                    </label>
                                    <input
                                        className="form-control"
                                        style={{marginTop: -10, height: 45}}
                                        type="number" 
                                        min="0" 
                                        max="9" 
                                        name="mc_irn" 
                                        placeholder="Enter Individual Reference Number"
                                        value={this.state.medicareIRN}
                                        onChange={this.medicareIRN}
                                    />
                                </div>
                            </>)}


                            {/* Personal details */}
                            <div className="kt-portlet__head-label"  style={{marginTop: 25}}>
                                <h4 className="kt-portlet__head-title" style={{fontWeight: 'bold'}}>Your Details</h4>
                                <label className="col-xl-12 col-lg-12 col-form-label txt-left" style={{ marginTop: -5}}>
                                    {<p style={{fontWeight: 'bold'}}>Your personal details should match with provided ID.</p>}
                                </label>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%', marginTop: -5}}>
                                <label className="col-xl-12 col-lg-12 col-form-label txt-left" >
                                    {<p>First name</p>}
                                </label>
                                <input
                                    className="form-control"
                                    style={{marginTop: -10, height: 45}}
                                    type='text'
                                    name="adl_number" 
                                    placeholder="Enter First name"
                                    pattern='[A-Za-z\-\s]*'
                                    // maxLength={10}
                                    // max={10}
                                    value={this.state.passportFName}
                                    onChange={this.passportFName}
                                />
                            </div>
                            {this.state.idType == 3 && (<div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%', marginTop: 15}}>
                                <label className="col-xl-12 col-lg-12 col-form-label txt-left" >
                                    {<p>Middle Name</p>}
                                </label>
                                <input
                                    className="form-control"
                                    style={{marginTop: -10, height: 45}}
                                    type='text'
                                    name="mc_middlename_line1" 
                                    placeholder="Enter Middle Name" 
                                    title="Enter a valid name"
                                    value={this.state.medicareMiddleName} 
                                    pattern='[A-Za-z\-\s]*'
                                    onChange={this.medicareMiddleName}
                                />
                            </div>)}
                            <div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%', marginTop: 15}}>
                                <label className="col-xl-12 col-lg-12 col-form-label txt-left" >
                                    {<p>Last name</p>}
                                </label>
                                <input
                                    className="form-control"
                                    style={{marginTop: -10, height: 45}}
                                    type='text'
                                    name="adl_number" 
                                    placeholder="Enter Last name"
                                    pattern='[A-Za-z\-\s]*'
                                    // maxLength={10}
                                    // max={10}
                                    value={this.state.passportLName}
                                    onChange={this.passportLName}
                                />
                            </div>
                            {this.state.idType != 3 && (<div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%', marginTop: 15}}>
                                <label className="col-xl-12 col-lg-12 col-form-label txt-left">
                                Date of birth
                                </label>
                                <DatePicker
                                    onChange={this.passportDOB}
                                    style={{height: 45}}
                                    value={this.state.passportDOB}
                                    className="form-control customdate"
                                    format="y-MM-dd"
                                    required
                                    minDate={this.state.minDob}
                                    maxDate={this.state.maxDob}
                                    onKeyPress={ this.handleKeypress}
                                    onFocus={e => e.target.blur()}
                                />
                            </div>)}
                            <div className="col-lg-6 col-md-6 col-sm-12" style={{width: '100%', marginTop: 15}}>
                                <label className="col-xl-12 col-lg-12 col-form-label txt-left">
                                Gender
                                </label>
                                <select className="form-select custom-select kt_selectpicker refer-select"
                                style={{height: 45}} 
                                //defaultValue={selected_number} 
                                value={this.state.passportGender}
                                name="pp_gender" 
                                id="pp_gender" 
                                onChange={e => {this.passportGender(e.target.value);}} >
                                    <option value="">Select Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="-">Other</option>
                                </select>
                            </div>
                            
                        </div>
                        {this.state.image != '' && (<>
                        <div className="col-lg-6 col-xl-6 order-lg-1 order-xl-1">
                            <div className="kt-portlet__body kt-portlet__body--fit" style={{alignItems: 'center', backgroundColor: 'transparent'}}>
                                <img src={this.state.image} alt="image" style={{height: 'auto', width: '100%'}}/>
                            </div>
                        </div>
                        </>)}
                    </div>
                    {/* <div className="kt-heading kt-heading--md">ID Validation</div>
                    <label style={{textAlign: 'justify'}}><b>If you change your personal details here, it will be automatically updated to your profile.</b></label>
                    <label style={{textAlign: 'justify',color:'red'}}><b> Please enter your ID document details carefully. Incorrect details will block your SIM card and will delay the service activation.</b></label>
                    <div className="kt-separator kt-separator--height-xs"></div> */}
                    <div className="kt-form__section kt-form__section--first" id="card_section" style={{marginTop: -30}}>

                        {/* <div className="row">
                            <div className="col-lg-6" style={{paddingRight: '20px'}}>
                                <div className="form-group">
                                    <label>ID types:</label>
                                    <select className="form-control kt_selectpicker" name="service_type" onChange={e => {this.changeID(e.target.value);}} value={this.state.idType} required>
                                        <option value="">Select Type </option>
                                        <option value="1">Passport</option>
                                        <option value="2">Australian Driving License</option>
                                        <option value="3">Medicare Card</option>
                                    </select>
                                </div>
                            </div>

                            {this.state.idType ==1 && 
                                <div className="col-lg-6 pp_div spcpadding">
                                    <div className="form-group">
                                        <label>Issuing country:</label>
                                        <select className="form-control kt_selectpicker" name="pp_country" onChange={e => {this.passportCountry(e.target.value);}} value={this.state.passportCountry} required>
                                            <option value="">Select Country </option>
                                            { this.state.all_countries.map((country) =>  
                                            <option value={country.country_code}>{country.name}</option>
                                            )}
                                            
                                            
                                        </select>
                                    </div>
                                </div>
                            }

                            {this.state.idType==1 &&
                                <div className="form-group row">

                                    <div className="col-lg-12 pp_div">
                                            <div className="form-group">
                                                <label style={{textAlign: 'justify',color:'red'}}>First name and Last name should match with visa issued by AU</label>
                                            </div>
                                    </div>


                                    <div className="col-lg-6 pp_div">
                                            <div className="form-group">
                                                <label>First name:</label>
                                                <input type="text" className="form-control" name="adl_number" placeholder="Enter Passport First name"  onChange={this.passportFName} defaultValue={this.state.passportFName} pattern='[A-Za-z\-\s]*' required />
                                                
                                            </div>
                                    </div>

                                    <div className="col-lg-6 pp_div">
                                            <div className="form-group">
                                                <label>Last name:</label>
                                                <input type="text" className="form-control" name="adl_number" placeholder="Enter Passport Last name"  onChange={this.passportLName} defaultValue={this.state.passportLName} pattern='[A-Za-z\-\s]*' required />
                                                
                                            </div>
                                    </div>


                                <div className="col-lg-6 pp_div">
                                        <div className="form-group">
                                            <label>Passport Number:</label>
                                            <input type="text" className="form-control" name="adl_number" placeholder="Enter Passport Number"  onChange={this.passportNumber} defaultValue={this.state.passportNumber} required />
                                            
                                        </div>
                                    </div>

                                    <div className="col-lg-6 pp_div">
                                        <div className="form-group">
                                            <label>Date of birth:</label>
                                            
                                            <div>
                                            <DatePicker
                                                onChange={this.passportDOB}
                                                value={this.state.passportDOB}
                                                className="form-control customdate"
                                                format="y-MM-dd"
                                                required
                                                minDate={this.state.minDob}
                                                maxDate={this.state.maxDob}
                                                onKeyPress={ this.handleKeypress}
                                                onFocus={e => e.target.blur()}
                                            />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 pp_div">
                                        <div className="form-group">
                                            <label>Gender:</label>
                                            <select className="form-control kt_selectpicker gender" name="pp_gender" id="pp_gender" onChange={e => {this.passportGender(e.target.value);}} value={this.state.passportGender}>
                                                <option value="">Select Gender</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="-">Other</option>

                                            </select>
                                            
                                        </div>
                                    </div>

                                </div>
                            }


                            {this.state.idType ==2 && 
                                <div className="col-lg-6 adl_div spcpadding">
                                    <div className="form-group">
                                        <label>License number:</label>
                                        <input type="text" className="form-control" name="adl_number" placeholder="Enter License Number"  onChange={this.licenceNumber} defaultValue={this.state.licenceNumber} required/>
                                    </div>
                                </div>
                            }

                            {this.state.idType ==2 &&
                                <div className="form-group row">
                                    <div className="col-lg-6 adl_div">
                                        <div className="form-group">
                                            <label>First name:</label>
                                            <input type="text" className="form-control" placeholder="Enter First Name" title="Enter a valid name" pattern='[A-Za-z\-\s]*' onChange={this.licenceFName} defaultValue={this.state.licenceFName} required />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 adl_div">
                                        <div className="form-group">
                                            <label>Last name:</label>
                                            <input type="text" className="form-control" placeholder="Enter Last Name" title="Enter a valid name" pattern='[A-Za-z\-\s]*' onChange={this.licenceLname} defaultValue={this.state.licenceLname} required />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 adl_div">
                                        <div className="form-group">
                                            <label>Issuing state:</label>
                                            <select className="form-control kt_selectpicker" name="adl_state" onChange={e => {this.licenceState(e.target.value);}} value={this.state.licenceState} required>
                                                <option value="">Select State</option> 
                                                <option value="ACT">ACT</option>
                                                <option value="NSW">NSW</option>
                                                <option value="NT">NT</option>
                                                <option value="QLD">QLD</option>
                                                <option value="SA">SA</option>
                                                <option value="TAS">TAS</option>
                                                <option value="VIC">VIC</option>
                                                <option value="WA">WA</option> 
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 adl_div">
                                        <div className="form-group">
                                            <label>Card number:</label>
                                            {this.state.mandatoryCard == true ? 
                                            <input type="text" className="form-control" name="adl_cnumber" min="0" maxlength="20" pattern="[A-Za-z0-9]{4,20}" title="Enter valid card number" placeholder="Enter Card Number"  onChange={this.licenceCardNumber} defaultValue={this.state.licenceCardNumber} required/>
                                            :
                                            <input type="text" className="form-control" name="adl_cnumber" min="0" maxlength="20" title="Enter valid card number" placeholder="Enter Card Number"  onChange={this.licenceCardNumber} defaultValue={this.state.licenceCardNumber}/>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-lg-6 adl_div">
                                        <div className="form-group">
                                            <label>Date of birth:</label>
                                            
                                            
                                            <div>
                                            <DatePicker
                                                onChange={this.licenceDOB}
                                                value={this.state.licenceDOB}
                                                className="form-control customdate"
                                                format="y-MM-dd"
                                                required
                                                minDate={this.state.minDob}
                                                maxDate={this.state.maxDob}
                                                onKeyPress={ this.handleKeypress}
                                                onFocus={e => e.target.blur()}
                                            />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-6 adl_div">
                                        <div className="form-group">
                                            <label>Gender:</label>
                                            <select className="form-control kt_selectpicker gender" name="adl_gender" id="adl_gender" onChange={e => {this.licenceGender(e.target.value);}} value={this.state.licenceGender} >
                                                <option value="">Select Gender</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="-">Other</option>
                                            </select>
                                            
                                        </div>
                                    </div>
                                </div>
                            }

                            {this.state.idType ==3 && 
                                <div className="col-lg-6 mc_div spcpadding">
                                    <div className="form-group">
                                        <label>Card number:</label>
                                        <input type="text" className="form-control" name="mc_number" placeholder="Enter Card Number"  onChange={this.medicareCardNumber} defaultValue={this.state.medicareCardNumber} required maxlength="10" title="Enter valid card number" pattern="[1-9]{1}[0-9]{9}"/>
                                    </div>
                                </div>
                            }

                            {this.state.idType ==3 && 
                                <div className="form-group row">
                                    <div className="col-lg-6 mc_div">
                                        <div className="form-group">
                                            <label>First Name :</label>
                                            <input type="text" className="form-control first_name" name="mc_firstname_line1" placeholder="Enter First Name" title="Enter a valid name" onChange={this.medicareFirstName} defaultValue={this.state.medicareFirstName} pattern='[A-Za-z\-\s]*' required/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mc_div">
                                        <div className="form-group">
                                            <label>Middle Name :</label>
                                            <input type="text" className="form-control middle_name" name="mc_middlename_line1" placeholder="Enter Middle Name" title="Enter a valid name" onChange={this.medicareMiddleName} defaultValue={this.state.medicareMiddleName} pattern='[A-Za-z\-\s]*' />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mc_div">
                                        <div className="form-group">
                                            <label>Last Name :</label>
                                            <input type="text" className="form-control last_name" name="mc_lastname_line1" placeholder="Enter Last Name" title="Enter a valid name" onChange={this.medicareLastName} defaultValue={this.state.medicareLastName} pattern='[A-Za-z\-\s]*' required/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mc_div">
                                        <div className="form-group">
                                            <label>Gender:</label>
                                            <select className="form-control kt_selectpicker gender" name="mc_gender" id="mc_gender" onChange={e => {this.medicareGender(e.target.value);}} value={this.state.medicareGender} >
                                                <option value="">Select Gender</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="-">Other</option>
                                            </select>
                                            
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mc_div">
                                        <div className="form-group">
                                            <label>Individual Reference Number (IRN)</label>
                                            <input type="number" min="0" max="9" className="form-control" name="mc_irn" placeholder="Enter Individual Reference Number"  onChange={this.medicareIRN} defaultValue={this.state.medicareIRN} required/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mc_div">
                                        <div className="form-group">
                                            <label>Colour of the card:</label>
                                            <select className="form-control kt_selectpicker" name="mc_color" onChange={e => {this.medicareColour(e.target.value);}} value={this.state.medicareColour} required>
                                                <option value="">Select Colour </option>
                                                <option value="Y">Yellow</option>
                                                <option value="B">Blue</option>
                                                <option value="G">Green</option>
                                            </select>
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-6 mc_div_exp_dt">
                                        <div className="form-group">
                                            <label>Date of expiry:</label>
                                            
                                            <div>
                                            <DatePicker
                                                onChange={this.medicareExpiry}
                                                value={this.state.medicareExpiry}
                                                className="form-control customdate"
                                                format="MM-y"
                                                required
                                            />
                                            </div>

                                            <label style={{color: 'red',fontWeight: '400'}}>Please select the last day of the Medicare valid to date.</label>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div> */}
                        <div className="form-group  row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <label className="kt-option">
                                    <label className="kt-checkbox kt-checkbox--bold kt-checkbox--danger">
                                        <input type="checkbox" name="authorised_check" id="authorised_check" onChange={e => {this.dvsCheckbox(e.target.checked);}} defaultChecked={this.state.dvsCheck} required />
                                        <span></span>
                                    </label>
                                    <span className="kt-option__label">
                                        <span className="kt-option__head">
                                            <span className="kt-option__title">
                                                I confirm that I am authorised to provide the personal details presented and I consent to my information being checked with the document issuer or official record holder via third party systems for the purpose of confirming my identity.
                                            </span>

                                        </span>
                                    </span>
                                </label>
                            </div>
                        </div>
                        {this.state.customerror != '' &&
                            <span className="" role="alert"><span id="authorized_error" style={{color: 'red',fontWeight: '400'}}><i className="fa fa-exclamation-triangle"></i> {this.state.customerror}</span></span>
                        }
                    </div>              
                    {/* <br/> */}
                    <div className="kt-form__actions">
                       {/* <button type="submit" className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm" id="backBtn" onClick={this.back}>Previous</button>&nbsp; */}
                       <button type="submit" className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm" id="wizard_next_btn" data-ktwizard-type="action-next">Next Step</button>
                    </div>
                </div>      
            </form> 
            </>  
        );
    }
}

export default IdValidation;
