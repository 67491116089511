import React, { Component } from "react";
import DropIn from "braintree-web-drop-in-react";
import Moment from 'react-moment';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'; 
import Table from 'react-bootstrap/Table';  
import Button  from 'react-bootstrap/Button';  
import Modal  from 'react-bootstrap/Modal';  
import swal from 'sweetalert';
import { Link, withRouter } from "react-router-dom";
import axios from 'axios';    
import {getApiHeader, removeUserSession} from '../../utils/CommonFunctions'; 
import PageLoader from './../Loader/loader';
import ErrorMsg from './../public/errorMsg';
import Loader from "react-loader-spinner";

const SERVER_URL 	= process.env.REACT_APP_SERVER_URL;   

export default class MyPayments extends Component {
    state = {
		customer_cards: [], 
		model_visibility: '',
        name_on_card: '' ,
        card_number:'',
        expiry_month:'',
        expiry_year:'',
        cvv_number:'',
        customer_payment: [],
        paystatus:'',
        customer_activations: [],
        loading:true,
        clientToken:null,
        customerId:null
	}

   


    handleClose = () => this.setState({ model_visibility: false });
    handleShow = () => this.setState({ model_visibility: true });

    componentDidMount = async () => { 

        this.getUserStatus();

        try 
        {

            let headers 	= await getApiHeader();
            
            let res 		= await axios.get(`${ SERVER_URL }payments/get-client-token`, { headers });

            let response    = res.data.data;

            this.setState({clientToken:response.clientToken});

        }
        catch(err)
        {

            this.setState({loading:false});

            if(err)
            {
                let errorMessage = '';

                if(err.response && err.response.data && err.response.data.message)
                {
                    if(typeof(err.response.data.message) === 'object')
                    {

                        swal({
                            title: "Error",
                            content: "Error in add card",
                            icon: "error"});
        
                    }
                    else
                    {
                        errorMessage = err.response.data.message;
                        swal(errorMessage,"error");
                        
                    }
                }
                else
                {
                    errorMessage = err;
                    swal(errorMessage,err);
                }
    


            }
            

        }


        //======braintree==============//

        // const response = await fetch("server.test/client_token");
        // const clientToken = await response.json(); // If returned as JSON string
     
        // this.setState({
        //   clientToken,
        // });


        //=======braintree=============//





		await this.getCustomerCards();
        await this.getPayments();
        await this.getActivations();
	}




    // force logout --------------------------------------

    getUserStatus = async () => {
        let headers     = await getApiHeader();
        let res         = await axios.get(`${ SERVER_URL }user_status`, { headers }).then(async response => {
            
            if(response.data.data.forceLogout){
                if(response.data.data.forceLogout != 'undefined'){
                    if(response.data.data.forceLogout == '1'){
                        this.handleLogout();
                    }
                }
            }
            
        }).catch(error => {            
        });
 
    };



     handleLogout = async () => {  
        let p = new Promise(async (resolve, reject)=> {
            let headers     = await getApiHeader();
            let res         = await axios.get(`${ SERVER_URL }logout`, { headers });
            let data        = res.data.data;   
            console.log(data);
            removeUserSession();  
            resolve();

        });

        p.then(
            ()=>{ 
                    // this.props.history.push("/login");
                    window.location.href = "/login";
            },
            ()=>{
                alert("Error in logout");
            }
        )
    }

     // force logout --------------------------------------




    async buy() {

        this.setState({loading:true});

        let payment_nonce = null;

        try
        {

            const { nonce } = await this.instance.requestPaymentMethod();

            payment_nonce   = nonce;

        }
        catch(error)
        {
            
            this.setState({loading:false});

            return;

        }

        // const post_data =   {   
        //                         card_holder_name: this.state.name_on_card ,
        //                         card_number : this.state.card_number , 
        //                         cvv : this.state.cvv_number , 
        //                         card_expiry : this.state.expiry_month+"/"+this.state.expiry_year , 
        //                         default : "false" , 
        //                     };
        // console.log(post_data);

        let post_data   = {nonce:payment_nonce};

        try
        {
            this.handleClose();
            let headers     = await getApiHeader();
            let res 		= await axios.post(`${ SERVER_URL }payments/add-card`,post_data, { headers });
            let data 		= res.data;

            this.getCustomerCards();
            
            swal(data.message, "", "success");

            if(this.props.callBackPayment)
            {
                console.log("callBackPayment");
                this.props.callBackPayment(true);
            }

        }
        catch(err)
        {

            this.setState({loading:false});

            if(err)
            {
                let errorMessage = '';

                if(err.response && err.response.data && err.response.data.message)
                {
                    if(typeof(err.response.data.message) === 'object')
                    {

                        swal({
                            title: "Error",
                            content: "Error occurred in add payment method",
                            icon: "error"});
        
                    }
                    else
                    {
                        errorMessage = err.response.data.message;
                        swal(errorMessage,"error");
                        
                    }
                }
                else
                {
                    errorMessage = err;
                    swal(errorMessage,err);
                }



            }
            
            if(this.props.callBackPayment)
            {
                this.props.callBackPayment(false);
            }

        }

      }


     getActivations = async () => {

        try
        {
            
            this.setState({loading:true});
            let headers     = await getApiHeader();
            let res         = await axios.get(`${ SERVER_URL }user_activations`, { headers });
            let data        = res.data.data.list; 
            this.setState({ customer_activations: data, loading:false });

        }
        catch(err)
        {

            this.setState({loading:false});

            if(err)
            {
                let errorMessage = '';

                if(err.response && err.response.data && err.response.data.message)
                {
                    if(typeof(err.response.data.message) === 'object')
                    {

                        swal({
                            title: "Error",
                            content: "Error occurred in fetch activations",
                            icon: "error"});
        
                    }
                    else
                    {
                        errorMessage = err.response.data.message;
                        swal(errorMessage,"error");
                        
                    }
                }
                else
                {
                    errorMessage = err;
                    swal(errorMessage,err);
                }
    


            }
            

        }

    };

    


    getCustomerCards = async () => {  
        this.setState({loading:true});
        try
        {
            let headers     = await getApiHeader();
            let res 		= await axios.get(`${ SERVER_URL }payments/get-all-cards`, { headers });
            let data 		= res.data.data.cards;  
            this.setState({ customer_cards: data });
            this.setState({loading:false});
        }
        catch(err)
        {

            this.setState({loading:false});

            if(err)
            {
                let errorMessage = '';

                if(err.response && err.response.data && err.response.data.message)
                {
                    if(typeof(err.response.data.message) === 'object')
                    {

                        swal({
                            title: "Error",
                            content: "Error occurred in add payment method",
                            icon: "error"});
        
                    }
                    else
                    {
                        errorMessage = err.response.data.message;
                        swal(errorMessage,"error");
                        
                    }
                }
                else
                {
                    errorMessage = err;
                    swal(errorMessage,err);
                }
    


            }
            

        }
	};

    handleChange(token) { // param is the argument you passed to the function
		this.deleteCustomerCard(token);   
    }

    deleteCustomerCard = async (token) => {  
		 
        swal({ 
            text: "Are you sure you want to delete the card?",
            icon: "warning", 
            dangerMode: true,
            buttons: {
                confirm : {text:'Yes, Proceed!',className:'sweet-warning'},
                cancel : 'No, Cancel!'
            }
        })
        .then(async (willDelete) => {
            if (willDelete) {
                this.setState({loading:true});
                try
                {
                    let headers     = await getApiHeader();
                    const post_data = { card_token: token };
                    let res 		= await axios.post(`${ SERVER_URL }payments/delete-card`,post_data, { headers });
                    let data 		= res.data; 
                    this.setState({loading:false}); 
                    // if(data.status==200){  
                        this.getCustomerCards();
                        swal(data.message, "", "success"); 
                    // }else{
                        // swal(data.message, "", "warning");
                    // } 

                }
                catch(err)
                {
        
                    this.setState({loading:false});
        
                    if(err)
                    {
                        let errorMessage = '';
        
                        if(err.response && err.response.data && err.response.data.message)
                        {
                            if(typeof(err.response.data.message) === 'object')
                            {
        
                                swal({
                                    title: "Error",
                                    content: "Error occurred in delete payment method",
                                    icon: "error"});
                
                            }
                            else
                            {
                                errorMessage = err.response.data.message;
                                swal(errorMessage,"error");
                                
                            }
                        }
                        else
                        {
                            errorMessage = err;
                            swal(errorMessage,err);
                        }
            
        
        
                    }
                    
        
                }



                
                
            } else {
                swal("Cancelled", "", "success");
            }
        });

	};

    changeNameOnCard = (event) => {
        this.setState({name_on_card: event.target.value});
    }

    changeCardNumber = (event) => {
        this.setState({card_number: event.target.value});
    }

    changeExpiryMonth = (event) => {
        this.setState({expiry_month: event.target.value});
    }

    changeExpiryYear = (event) => {
        this.setState({expiry_year: event.target.value});
    }

    changeCVV = (event) => {
        this.setState({cvv_number: event.target.value});
    }

    mySubmitHandler = async (event) => {
        
        event.preventDefault();  

        return;

        const post_data =   {   card_holder_name: this.state.name_on_card ,
                                card_number : this.state.card_number , 
                                cvv : this.state.cvv_number , 
                                card_expiry : this.state.expiry_month+"/"+this.state.expiry_year , 
                                default : "false" , 
                            };
                            console.log(post_data);


        try
        {

        let headers     = await getApiHeader();
        let res 		= await axios.post(`${ SERVER_URL }payments/add-card`,post_data, { headers });
        let data 		= res.data;  
        // if(data.status==200){  
            swal(data.message, "", "success");
        // }else{
            // swal(data.message, "", "warning");
        // } 

        }
        catch(err)
        {

            this.setState({loading:false});

            if(err)
            {
                let errorMessage = '';

                if(err.response && err.response.data && err.response.data.message)
                {
                    if(typeof(err.response.data.message) === 'object')
                    {

                        swal({
                            title: "Error",
                            content: "Error occurred in add payment method",
                            icon: "error"});
        
                    }
                    else
                    {
                        errorMessage = err.response.data.message;
                        swal(errorMessage,"error");
                        
                    }
                }
                else
                {
                    errorMessage = err;
                    swal(errorMessage,err);
                }
    


            }
            

        }

    }

 

     getPayments = async () => {

        this.setState({loading:true});
        try
        {
            let headers     = await getApiHeader();
            const post_data =   { current_page: 0,  per_page_records: 10 };
            let res = await axios.post(`${ SERVER_URL }payments/history`,post_data, { headers });
            let payement_data        = res.data.data.records;
            console.log(payement_data);
            this.setState({ customer_payment: payement_data, customerId: res.data.data.customer_id, loading:false});
        }
        catch(err)
        {

            this.setState({loading:false});

            if(err)
            {
                let errorMessage = '';

                if(err.response && err.response.data && err.response.data.message)
                {
                    if(typeof(err.response.data.message) === 'object')
                    {

                        swal({
                            title: "Error",
                            content: "Error in fetch payment history",
                            icon: "error"});
        
                    }
                    else
                    {
                        errorMessage = err.response.data.message;
                        swal(errorMessage,"error");
                        
                    }
                }
                else
                {
                    errorMessage = err;
                    swal(errorMessage,err);
                }
    


            }
            

        }

    };



    autoChange(stateval,activation_id) {
        this.autorecharge(stateval,activation_id);   
    }


    autorecharge = async (stateval,activation_id) => {  
        var action_string = "Enable";
        var auto_status_val=1;
        if(stateval == 1){ action_string = "Disable"; var auto_status_val=0; }
        swal({ 
            text: "Are you sure you want to "+action_string+" Auto Recharge?",
            icon: "warning", 
            dangerMode: true,
            buttons: {
                confirm : {text:'Yes, Proceed!',className:'sweet-warning'},
                cancel : 'No, Cancel!'
            }
        })
        .then(async (willDelete) => {
            if (willDelete) 
            {

                this.setState({loading:true});

                try
                {

                    let headers     = await getApiHeader();
                    const post_data = { activationid: activation_id, auto_recharge_status: auto_status_val };
                    let res         = await axios.post(`${ SERVER_URL }payments/update-auto-rechange-status`,post_data, { headers });
                    let data        = res.data;
                    this.setState({loading:false});
                        swal(data.message, "", "success"); 
                        this.getActivations();                    

                }
                catch(err)
                {
        
                    this.setState({loading:false});
        
                    if(err)
                    {
                        let errorMessage = '';
        
                        if(err.response && err.response.data && err.response.data.message)
                        {
                            if(typeof(err.response.data.message) === 'object')
                            {
        
                                swal({
                                    title: "Error",
                                    content: "Error in update default card",
                                    icon: "error"});
                
                            }
                            else
                            {
                                errorMessage = err.response.data.message;
                                swal(errorMessage,"error");
                                
                            }
                        }
                        else
                        {
                            errorMessage = err;
                            swal(errorMessage,err);
                        }
            
        
        
                    }

                    this.getActivations();

                }
                
            } 
            else 
            {
                swal("Cancelled", "", "success");
                
                this.getActivations();
                
            }
        });

    };




    handleDefault = async (token,cardnumber,val) => {  

        swal({ 
            text: "Are you sure you want to make this default card?",
            icon: "warning", 
            dangerMode: true,
            buttons: {
                confirm : {text:'Yes, Proceed!',className:'sweet-warning'},
                cancel : 'No, Cancel!'
            }
        })
        .then(async (willDelete) => {
            if (willDelete) {

                this.setState({loading:true});

                try{

                    let headers     = await getApiHeader();
                    const post_data = { card_token: token, card_number: cardnumber, default:val };
                    let res         = await axios.post(`${ SERVER_URL }payments/update-card`,post_data, { headers });
                    let data        = res.data;  
                    this.setState({loading:false});
                    swal(data.message, "", "success"); 
                    this.getCustomerCards();

                }
                catch(err)
                {
        
                    this.setState({loading:false});
        
                    if(err)
                    {
                        let errorMessage = '';
        
                        if(err.response && err.response.data && err.response.data.message)
                        {
                            if(typeof(err.response.data.message) === 'object')
                            {
        
                                swal({
                                    title: "Error",
                                    content: "Error in update default card",
                                    icon: "error"});
                
                            }
                            else
                            {
                                errorMessage = err.response.data.message;
                                swal(errorMessage,"error");
                                
                            }
                        }
                        else
                        {
                            errorMessage = err;
                            swal(errorMessage,err);
                        }
            
        
        
                    }
                    
        
                }
        
                
            } else {
                swal("Cancelled", "", "success");
                
            }
        });

    };

    

    

render() {

  return (
    <>
    {(this.state.loading === true) ? <PageLoader/> : null}
        <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content"> 
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
                        <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                            <div className="kt-portlet">
                                <div className="kt-portlet__head">
                                    <div className="kt-portlet__head-label">
                                        <h3 className="kt-portlet__head-title">My Payments</h3>
                                    </div>
                                </div>
                                <div className="kt-portlet__body" id="payment_method_container">
                                    <Container fluid style={{ padding: '20px' }}>
                                        <Row>
                                        {((this.props.onlyPayment) &&  (this.props.onlyPayment === true)) ? 

                                            <Tabs defaultActiveKey="payment_method" id="uncontrolled-tab-example" className="mb-3">
                                                    <Tab eventKey="payment_method" title="Payment Method" >
                                                        <div className="kt-portlet">
                                                            <div className="kt-portlet__body">
                                                                <div className="form-group row toggle_div">
                                                            
                                                                
                                                                    <div className="col-xl-12 col-lg-12 col-form-label">
                                                                        <Button variant="danger" onClick={this.handleShow}>Add Payment Method</Button>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="table-responsive" style={{textAlign:'center'}}>
                                                                    
                                                                    <Table bordered responsive >
                                                                        <thead>
                                                                            <tr className="bg-danger text-white" >
                                                                                <th className="payment_table_head">#</th>
                                                                                <th className="payment_table_head">Name on Card</th>
                                                                                <th className="payment_table_head">Card No</th>
                                                                                <th className="payment_table_head">Expiry Date</th>
                                                                                <th className="payment_table_head">Manage</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {(this.state.customer_cards.length > 0) ? this.state.customer_cards.map((card,i) =>  
                                                                            <tr>
                                                                                <th scope="row" className="payment_serial_no">{i+1}</th>
                                                                                <td className="payment_content">{card.card_holder_name}</td>
                                                                                <td className="payment_content">{card.masked_number}</td>
                                                                                <td className="payment_content">{card.expiration_date}</td>
                                                                                <td style={{textAlign:'center'}}> 
                                                                                
                                                                                    <Button variant="danger" style={{marginLeft: '10px'}} value={card.token} onClick={() => this.handleChange(card.token)}>Delete Card</Button>
                                                                                    <label className={`${card.default == '1' ? 'btn-danger' : 'btn-secondary' }`+" btn df-btn"}>Default 
                                                                                    
                                                                                    {/* <input type="radio" name="defaultcard" checked={`${card.default == '1' ? 'checked' : '' }`}  onClick={() => this.handleDefault(card.token,card.card_number,1)} /> */}

                                                                                    <input type="radio" name="defaultcard" defaultChecked={(card.default === true) ? true : false }  onClick={() => this.handleDefault(card.token,card.card_number,1)} />

                                                                                    {/* <input type="checkbox" name="defaultcard" defaultChecked={(card.default === true) ? true : false }  onClick={() => this.handleDefault(card.token,card.card_number,1)} /> */}
                                                                                    
                                                                                    </label>
                                                                                </td>
                                                                            </tr>
                                                                            ) : <tr><td colSpan={5}>No records found</td></tr>}
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    </Tab>
                                            </Tabs>
                                                :
                                            <Tabs defaultActiveKey="payment_method" id="uncontrolled-tab-example" className="mb-3">
                                                    <Tab eventKey="payment_method" title="Payment Method" >
                                                        <div className="kt-portlet">
                                                            <div className="kt-portlet__body">
                                                                <div className="form-group row toggle_div">
                                                            
                                                                
                                                                    <div className="col-xl-12 col-lg-12 col-form-label">
                                                                        <Button variant="danger" onClick={this.handleShow}>Add Payment Method</Button>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="table-responsive" style={{textAlign:'center'}}>
                                                                    
                                                                    <Table bordered responsive >
                                                                        <thead>
                                                                            <tr className="bg-danger text-white" >
                                                                                <th className="payment_table_head">#</th>
                                                                                <th className="payment_table_head">Name on Card</th>
                                                                                <th className="payment_table_head">Card No</th>
                                                                                <th className="payment_table_head">Expiry Date</th>
                                                                                <th className="payment_table_head">Manage</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {(this.state.customer_cards.length > 0) ? this.state.customer_cards.map((card,i) =>  
                                                                            <tr>
                                                                                <th scope="row" className="payment_serial_no">{i+1}</th>
                                                                                <td className="payment_content">{card.card_holder_name}</td>
                                                                                <td className="payment_content">{card.masked_number}</td>
                                                                                <td className="payment_content">{card.expiration_date}</td>
                                                                                <td style={{textAlign:'center'}}> 
                                                                                
                                                                                    <Button variant="danger" style={{marginLeft: '10px'}} value={card.token} onClick={() => this.handleChange(card.token)}>Delete Card</Button>
                                                                                    <label className={`${card.default == '1' ? 'btn-danger' : 'btn-secondary' }`+" btn df-btn"}>Default 
                                                                                    
                                                                                    {/* <input type="radio" name="defaultcard" checked={`${card.default == '1' ? 'checked' : '' }`}  onClick={() => this.handleDefault(card.token,card.card_number,1)} /> */}

                                                                                    <input type="radio" name="defaultcard" defaultChecked={(card.default === true) ? true : false }  onClick={() => this.handleDefault(card.token,card.card_number,1)} />

                                                                                    {/* <input type="checkbox" name="defaultcard" defaultChecked={(card.default === true) ? true : false }  onClick={() => this.handleDefault(card.token,card.card_number,1)} /> */}
                                                                                    
                                                                                    </label>
                                                                                </td>
                                                                            </tr>
                                                                            ) : <tr><td colSpan={5}>No records found</td></tr>}
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    </Tab>
                                                    <Tab eventKey="transaction_history" title="Transaction History">
                                                        <div className="kt-section">
                                                            <div className="kt-section__info">
                                                            </div>
                                                            <div className="kt-section__content">
                                                                <div className="table-responsive" style={{textAlign:'center'}} id="trans_history_container">
                                                                    <Table bordered responsive >
                                                                        <thead>
                                                                            <tr className=" bg-danger text-white">
                                                                                <th className="payment_table_head">#</th>
                                                                                {/* <th className="payment_table_head">Transaction Id</th> */}
                                                                                <th className="payment_table_head">Date & Time</th>
                                                                                <th className="payment_table_head">Amount</th>
                                                                                <th className="payment_table_head">Method</th>
                                                                                <th className="payment_table_head">Status</th>
                                                                                <th className="payment_table_head">Invoice</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            { this.state.customer_payment?.map((customer_pay,j) => 
                                                                            <tr>
                                                                                <th scope="row">{j+1}</th>
                                                                                {/* <td>{customer_pay.txn_id}</td> */}
                                                                                <td>
                                                                                    <Moment format="DD-MM-YYYY HH:mm:ss">{customer_pay.created_at}</Moment>
                                                                                </td>
                                                                                <td>{customer_pay.amount}</td>
                                                                                <td>{customer_pay.method}</td>
                                                                                <td>

                                                                                    <span className={`${customer_pay.payment_status == 'Unpaid' ? 'btn-secondary' : customer_pay.payment_status== 'Success' ? 'btn-success' : 'btn-danger' }`+" kt-badge kt-badge--inline kt-badge--pill"}>
                                                                                        { customer_pay.payment_status }
                                                                                    </span>
                                                                                </td>
                                                                                <td> 
                                                                                {/* {customer_pay.payment_id} */}
                                                                                {/* <span onClick={()=>this.downloadPdf(customer_pay.payment_id)}><i className="fas fa-download" style={{color: '#e90000'}}></i></span> */}
                                                                                    {/* <Link to={`${SERVER_URL}payment/payment-invoice-download/${customer_pay.payment_id}` } target="_blank" variant="danger" download><i className="fas fa-download" style={{color: '#e90000'}}></i></Link> */}
                                                                                    {((this.state.customerId !== null) && (customer_pay.payment_status === 'Success')) ? <a href={`${SERVER_URL}payment/payment-invoice-download/${customer_pay.payment_id}/${this.state.customerId}` } target="_blank"><i className="fas fa-download" style={{color: '#e90000'}}></i></a> : null}
                                                                                </td>
                                                                            </tr>
                                                                            )}
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    </Tab>
                                                    <Tab eventKey="auto_recharge" title="Enable/Disable Auto Recharge">
                                                        <div className="kt-section">
                                                            <div className="kt-section__info">
                                                            </div>
                                                            <div className="kt-section__content">
                                                                


                                                                <p>You Can Enable/Disable Your Auto Recharge Here</p>

                                                                <ul className="autorechargeUl telsimtoggle">
                                                                { this.state.customer_activations.map(person => 

                                                                    <li>
                                                                        <span className="lispan">{person.mobile_number}</span>

                                                                        {person.auto_renew == 1 &&
                                                                        <label className="switch">
                                                                        <input type="checkbox" checked onChange={() => this.autoChange(person.auto_renew,person.activation_id)}/>
                                                                        <span className="slider round"></span>
                                                                        </label>
                                                                        }

                                                                        {person.auto_renew == 0 &&
                                                                        <label className="switch">
                                                                        <input type="checkbox"  onChange={() => this.autoChange(person.auto_renew,person.activation_id)}/>
                                                                        <span className="slider round"></span>
                                                                        </label>
                                                                        }


                                                                    </li>)} 
                                                                </ul>






                                                            </div>
                                                        </div> 
                                                    </Tab>
                                            </Tabs>}
                                        </Row>
                                    </Container> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
         
        <Modal className="paybox" show={this.state.model_visibility} onHide={this.handleClose}>
            <form onSubmit={this.mySubmitHandler}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Payment Method</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="kt-portlet__body">
                    <div className="kt-section kt-section--first">
                        <div className="kt-section__body">
                            {/* <div className="form-group row">
                                <label className="col-lg-2 col-form-label"></label>
                                <img src="/assets/media/telsim/securepay_logo.png" alt="logo"  style={{height:'100px'}}/>
                            </div> */}
                            <div id="erralert" className="alert-danger col-lg-8"></div>
                            {/* <div className="form-group row">
                                <label className="col-lg-3 col-form-label">Name on Card:</label>
                                <div className="col-lg-8">
                                    <input type="text" className="form-control" placeholder="Enter Name" name="name" onChange={this.changeNameOnCard} pattern="[A-Za-z ]{3,32}" required title="Enter Valid Name On Your Card"/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-lg-3 col-form-label">Card No:</label>
                                <div className="col-lg-8">
                                    <input type="text" className="form-control" placeholder="Enter Card No" name="number" onChange={this.changeCardNumber} pattern="[0-9]{16}" required title="Enter Valid Card Number"/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-lg-3 col-form-label">Expiry Date:</label>
                                <div className="col-lg-4 ">
                                    <select className="form-control kt_selectpicker" name="exp_month" onChange={this.changeExpiryMonth} required title="Select Expiry Month">
                                        <option value="">--Select Month--</option>
                                        <option value="01">Jan</option>
                                        <option value="02">Feb</option>
                                        <option value="03">Mar</option>
                                        <option value="04">Apr</option>
                                        <option value="05">May</option>
                                        <option value="06">Jun</option>
                                        <option value="07">Jul</option>
                                        <option value="08">Aug</option>
                                        <option value="09">Sep</option>
                                        <option value="10">Oct</option>
                                        <option value="11">Nov</option>
                                        <option value="12">Dec</option>
                                    </select>
                                </div>
                                <div className="col-lg-4">
                                    <select className="form-control kt_selectpicker" name="exp_year" onChange={this.changeExpiryYear} required title="Select Expiry Year">
                                        <option value="">--Select Year--</option>
                                        <option value="2021">2021</option>
                                        <option value="2022">2022</option>
                                        <option value="2023">2023</option>
                                        <option value="2024">2024</option>
                                        <option value="2025">2025</option>
                                        <option value="2026">2026</option>
                                        <option value="2027">2027</option>
                                        <option value="2028">2028</option>
                                        <option value="2029">2029</option>
                                        <option value="2030">2030</option>
                                        <option value="2031">2031</option>
                                        <option value="2032">2032</option>
                                        <option value="2033">2033</option>
                                        <option value="2034">2034</option>
                                        <option value="2035">2035</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-lg-3 col-form-label">CVV No:</label>
                                <div className="col-lg-8">
                                    <input type="text" className="form-control" placeholder="Enter CVV No" name="cvv" onChange={this.changeCVV} pattern="[0-9]{3}" required title="Enter Valid CVV Number" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    <label  style={{textAlign:'1justify',fontSize:'10'}}><i>Your card will be charged 1 AUD for card preauthorization. This pending transaction will be voided by your bank automatically within 7-14 business days. This payment method will be used for all future payments for this account. Payment method can be updated anytime in Telsim My Account.</i></label>
                                </div>
                            </div> */}

                                {(this.state.clientToken !== null) ?
                                    <div>
                                        <DropIn
                                        options={{ authorization: this.state.clientToken, card:{ cardholderName: {'required':true}}}}
                                        onInstance={(instance) => (this.instance = instance)}
                                        />
                                    </div>
                                : <Loader             
                                type="Bars"
                                color="#e90000"
                                secondaryColor="white"
                                height={80}
                                width={80}/>}

                        </div>
                    </div>
                </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>
                        Close
                    </Button>
                    <button onClick={this.buy.bind(this)} className="btn btn-brand" id="saveBtn">Save & Submit</button>
                </Modal.Footer>
            </form>
        </Modal>
	</>
   )
}
}